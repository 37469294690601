import React, { Component } from "react";
import AppContext from "../context/AppContext";

class ExistingPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minage: "",
      od_tp_1yr_opt: false,
      tp_1yr_opt: false,
      od_1yr_tp_3yr_opt: false,
      tp_3yr_opt: false,
      od_1yr_opt: false,
      od_tp_1yr: "",
      tp_1yr: "",
      od_1yr_bundle: "",
      tp_3yr_bundle: "",
      tp_3yr: "",
      od_1yr: "",
      ModelIdv: ""
    };
  }

  componentDidMount() {
    if (this.props.type === "CAR") {
      this.calculateIDV();
    } else {
      this.calculateTwowheeler_IDV();
    }

    this.minage();
  }

  calculateFourWheelerPremium = () => {
    fetch(`${process.env.REACT_APP_API_URL}/FourWheelerCalculatePremium`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        Authorization: window.sessionStorage.getItem("AdvisoryToken")
      },
      body: JSON.stringify({
        model: this.context.state.motor.modelCode,
        RTO: this.context.state.motor.RTO,
        r_date: `12/02/${this.context.state.motor.year_of_registration}`,
        IDV: this.context.state.motor.ModelIdv
      })
    })
      .then(res => res.json())
      .then(data => {})
      .catch(err => {
        return null;
      });
  };

  calculateIDV = () => {
    fetch(`${process.env.REACT_APP_API_URL}/FourWheelerCalculateIDV`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        Authorization: window.sessionStorage.getItem("AdvisoryToken")
      },
      body: JSON.stringify({
        model: this.context.state.motor.modelCode,
        RTO: this.context.state.motor.RTO,
        r_date: `12/02/${this.context.state.motor.year_of_registration}`,
        s_date: `12/02/${this.context.state.motor.year_of_registration + 1}`
      })
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ ModelIdv: data.CalculatedIDV.IDV_AMOUNT }, () => {
          this.props.setIDV(this.state.ModelIdv);
        });
      })
      .catch(err => {
        return null;
      });
  };

  calculateTwowheeler_IDV = () => {
    fetch(`${process.env.REACT_APP_API_URL}/TwoWheelerCalculateIDV`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        Authorization: window.sessionStorage.getItem("AdvisoryToken")
      },
      body: JSON.stringify({
        model: this.context.state.motor.modelCode,
        RTO: this.context.state.motor.RTO,
        r_date: `12/02/${this.context.state.motor.year_of_registration}`,
        s_date: `12/02/${this.context.state.motor.year_of_registration + 1}`
      })
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ ModelIdv: data.CalculatedIDV.IDV_AMOUNT }, () => {
          this.props.setIDV(this.state.ModelIdv);
        });
      })
      .catch(err => {
        return null;
      });
  };

  goBack = () => {
    this.props.changePhase("rto_selection");
  };

  calculateRemainingDays = existingDate => {
    let formated_date =
      existingDate.split("/")[2] +
      "/" +
      existingDate.split("/")[1] +
      "/" +
      existingDate.split("/")[0];
    const date1 = new Date(formated_date);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  };

  MotorPolicySuggest = () => {
    //Comprehensive Package Policy

    if (this.context.state.ExistingMotorPolicies.tp_3yr !== "") {
      if (
        this.calculateRemainingDays(
          this.context.state.ExistingMotorPolicies.tp_3yr
        ) <= 60
      ) {
        let Recommendation = {
          recommendedCover: "Car Insurance",
          recommendedPolicy: "Comprehensive",
          category: "Motor",
          subcategory: "Motor_FourWheeler_comprehensivecover",
          premium: null
        };
        this.context.state.Recommendations.push(Recommendation);
      }
    }

    //Bundle Policy

    if (this.context.state.ExistingMotorPolicies.od_1yr_bundle !== "") {
      if (
        this.calculateRemainingDays(
          this.context.state.ExistingMotorPolicies.od_1yr_bundle
        ) <= 60
      ) {
        let Recommendation = {
          recommendedCover: "Car Insurance",
          recommendedPolicy: "On Damage",
          category: "Motor",
          subcategory: "Motor_FourWheeler_odcover",
          premium: null
        };
        this.context.state.Recommendations.push(Recommendation);
      }
    }
    if (this.context.state.ExistingMotorPolicies.tp_3yr_bundle !== "") {
      if (
        this.calculateRemainingDays(
          this.context.state.ExistingMotorPolicies.tp_3yr_bundle
        ) <= 60
      ) {
        let Recommendation = {
          recommendedCover: "Car Insurance",
          recommendedPolicy: "Comprehensive",
          category: "Motor",
          subcategory: "Motor_FourWheeler_comprehensivecover",
          premium: null
        };
        this.context.state.Recommendations.push(Recommendation);
      }
    }

    //Comprehensive Policy

    if (this.context.state.ExistingMotorPolicies.od_tp_1yr !== "") {
      if (
        this.calculateRemainingDays(
          this.context.state.ExistingMotorPolicies.od_tp_1yr
        ) <= 60
      ) {
        let Recommendation = {
          recommendedCover: "Car Insurance",
          recommendedPolicy: "Comprehensive",
          category: "Motor",
          subcategory: "Motor_FourWheeler_comprehensivecover",
          premium: null
        };
        this.context.state.Recommendations.push(Recommendation);
      }
    }

    //Third Party Covers
    if (this.context.state.ExistingMotorPolicies.tp_1yr !== "") {
      if (
        this.calculateRemainingDays(
          this.context.state.ExistingMotorPolicies.tp_1yr
        ) <= 60
      ) {
        let Recommendation = {
          recommendedCover: "Car Insurance",
          recommendedPolicy: "Comprehensive",
          category: "Motor",
          subcategory: "Motor_FourWheeler_comprehensivecover",
          premium: null
        };
        this.context.state.Recommendations.push(Recommendation);
      } else {
        let Recommendation = {
          recommendedCover: "Car Insurance",
          recommendedPolicy: "On Damage",
          category: "Motor",
          subcategory: "Motor_FourWheeler_odcover",
          premium: null
        };
        this.context.state.Recommendations.push(Recommendation);
      }
    }

    if (this.context.state.policyTaken.Home_Rental === true) {
      this.props.history.push("/home-insurance");
    } else if (this.context.state.policyTaken.Cyber === true) {
      this.props.history.push("/cyber-insurance");
    } else {
      this.props.history.push("/recommendations");
    }
  };

  MotorTwoWheelerPolicySuggest = () => {
    if (this.context.state.ExistingMotorPolicies.tp_3yr !== "") {
      if (
        this.calculateRemainingDays(
          this.context.state.ExistingMotorPolicies.tp_3yr
        ) <= 60
      ) {
        let Recommendation = {
          recommendedCover: "Two Wheeler Insurance",
          recommendedPolicy: "Comprehensive",
          category: "Motor",
          subcategory: "Motor_TwoWheeler_comprehensivecover",
          premium: null
        };
        this.context.state.Recommendations.push(Recommendation);
      }
    }

    //Bundle Policy

    if (this.context.state.ExistingMotorPolicies.od_1yr_bundle !== "") {
      if (
        this.calculateRemainingDays(
          this.context.state.ExistingMotorPolicies.od_1yr_bundle
        ) <= 60
      ) {
        let Recommendation = {
          recommendedCover: "Two Wheeler Insurance",
          recommendedPolicy: "On Damage",
          category: "Motor",
          subcategory: "Motor_TwoWheeler_odcover",
          premium: null
        };
        this.context.state.Recommendations.push(Recommendation);
      }
    }
    if (this.context.state.ExistingMotorPolicies.tp_3yr_bundle !== "") {
      if (
        this.calculateRemainingDays(
          this.context.state.ExistingMotorPolicies.tp_3yr_bundle
        ) <= 60
      ) {
        let Recommendation = {
          recommendedCover: "Two Wheeler Insurance",
          recommendedPolicy: "Comprehensive",
          category: "Motor",
          subcategory: "Motor_TwoWheeler_comprehensivecover",
          premium: null
        };
        this.context.state.Recommendations.push(Recommendation);
      }
    }

    //Comprehensive Policy

    if (this.context.state.ExistingMotorPolicies.od_tp_1yr !== "") {
      if (
        this.calculateRemainingDays(
          this.context.state.ExistingMotorPolicies.od_tp_1yr
        ) <= 60
      ) {
        let Recommendation = {
          recommendedCover: "Two Wheeler Insurance",
          recommendedPolicy: "Comprehensive",
          category: "Motor",
          subcategory: "Motor_TwoWheeler_comprehensivecover",
          premium: null
        };
        this.context.state.Recommendations.push(Recommendation);
      }
    }

    //Third Party Covers
    if (this.context.state.ExistingMotorPolicies.tp_1yr !== "") {
      if (
        this.calculateRemainingDays(
          this.context.state.ExistingMotorPolicies.tp_1yr
        ) <= 60
      ) {
        let Recommendation = {
          recommendedCover: "Two Wheeler Insurance",
          recommendedPolicy: "Comprehensive",
          category: "Motor",
          subcategory: "Motor_TwoWheeler_comprehensivecover",
          premium: null
        };
        this.context.state.Recommendations.push(Recommendation);
      } else {
        let Recommendation = {
          recommendedCover: "Two Wheeler Insurance",
          recommendedPolicy: "On Damage",
          category: "Motor",
          subcategory: "Motor_TwoWheeler_odcover",
          premium: null
        };
        this.context.state.Recommendations.push(Recommendation);
      }
    }

    if (this.context.state.policyTaken.Motor === true) {
      this.props.history.push("/Motor_details");
    } else if (this.context.state.policyTaken.Home_Rental === true) {
      this.props.history.push("/home-insurance");
    } else if (this.context.state.policyTaken.Cyber === true) {
      this.props.history.push("/cyber-insurance");
    } else {
      this.props.history.push("/recommendations");
    }
  };

  minage = () => {
    let eighteenYearsAgo = new Date();
    eighteenYearsAgo = eighteenYearsAgo.setFullYear(
      eighteenYearsAgo.getFullYear() - 0
    );

    let date = new Date(eighteenYearsAgo);

    let min_month = date.getMonth() + 1;
    if (min_month < 10) {
      min_month = "0" + min_month;
    }
    let day = date.getDate();
    if (day < 10) {
      day = "0" + date.getDate();
    }
    let year = date.getFullYear();
    let minage = year + "-" + min_month + "-" + day;

    this.setState({ minage });
  };

  setDob = (e, whoIs) => {
    let self = this;
    var el = document.getElementById("policy_date");
    el.addEventListener("keydown", function(event) {
      const key = event.key;
      if (key === "Backspace" || key === "Delete") {
        if (whoIs === "od_1yr") {
          self.setState({ od_1yr: "" });
        } else if (whoIs === "od_1yr_bundle") {
          self.setState({ od_1yr_bundle: "" });
        } else if (whoIs === "tp_3yr_bundle") {
          self.setState({ tp_3yr_bundle: "" });
        } else if (whoIs === "od_tp_1yr") {
          self.setState({ od_tp_1yr: "" });
        } else if (whoIs === "tp_1yr") {
          self.setState({ tp_1yr: "" });
        } else if (whoIs === "tp_3yr") {
          self.setState({ tp_3yr: "" });
        }
      }
    });

    let dob = e.target.value.split("");
    let formated_dob;
    if (dob[1]) {
      let newDob3 = dob[0] + dob[1];
      if (parseInt(newDob3) <= 31) {
        if (dob[4]) {
          let newDob2 = dob[3] + dob[4];
          if (parseInt(newDob2) <= 12) {
            if (dob[9]) {
              let newDob = dob[6] + dob[7] + dob[8] + dob[9];
              let minage = this.state.minage.split("-")[0];

              if (2021 >= parseInt(newDob) && parseInt(newDob) > 1922) {
                if (e.target.value.length < 11) {
                  if (e.target.value.length === 10) {
                    formated_dob =
                      dob[0] +
                      dob[1] +
                      "/" +
                      dob[3] +
                      dob[4] +
                      "/" +
                      dob[6] +
                      dob[7] +
                      dob[8] +
                      dob[9];
                    if (whoIs === "od_1yr") {
                      this.setState({ od_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "od_1yr_bundle") {
                      this.setState({ od_1yr_bundle: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_3yr_bundle") {
                      this.setState({ tp_3yr_bundle: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "od_tp_1yr") {
                      this.setState({ od_tp_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_1yr") {
                      self.setState({ tp_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_3yr") {
                      self.setState({ tp_3yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    }
                  } else if (e.target.value.length === 5) {
                    formated_dob =
                      dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                    if (whoIs === "od_1yr") {
                      this.setState({ od_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "od_1yr_bundle") {
                      this.setState({ od_1yr_bundle: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_3yr_bundle") {
                      this.setState({ tp_3yr_bundle: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "od_tp_1yr") {
                      this.setState({ od_tp_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_1yr") {
                      self.setState({ tp_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_3yr") {
                      self.setState({ tp_3yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    }
                  } else if (e.target.value.length === 2) {
                    formated_dob = dob[0] + dob[1] + "/";
                    if (whoIs === "od_1yr") {
                      this.setState({ od_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "od_1yr_bundle") {
                      this.setState({ od_1yr_bundle: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_3yr_bundle") {
                      this.setState({ tp_3yr_bundle: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "od_tp_1yr") {
                      this.setState({ od_tp_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_1yr") {
                      self.setState({ tp_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_3yr") {
                      self.setState({ tp_3yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    }
                  } else {
                    if (whoIs === "od_1yr") {
                      this.setState({ od_1yr: e.target.value }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "od_1yr_bundle") {
                      this.setState({ od_1yr_bundle: e.target.value }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_3yr_bundle") {
                      this.setState({ tp_3yr_bundle: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "od_tp_1yr") {
                      this.setState({ od_tp_1yr: e.target.value }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_1yr") {
                      self.setState({ tp_1yr: e.target.value }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_3yr") {
                      self.setState({ tp_3yr: e.target.value }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    }
                  }
                }
                if (dob[6] && dob[7] && dob[8] && dob[9]) {
                  let year = dob[6] + dob[7] + dob[8] + dob[9];
                  let Current_year = new Date().getFullYear();
                  this.setState(
                    {
                      ageInDifferentForm:
                        parseInt(Current_year) - parseInt(year)
                    },
                    () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    }
                  );
                }
              } else {
                if (e.target.value.length < 11) {
                  this.setState({ ageInDifferentForm: "Not Accepted" }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                  if (e.target.value.length === 10) {
                    formated_dob =
                      dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                    if (whoIs === "od_1yr") {
                      this.setState({ od_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "od_1yr_bundle") {
                      this.setState({ od_1yr_bundle: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_3yr_bundle") {
                      this.setState({ tp_3yr_bundle: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "od_tp_1yr") {
                      this.setState({ od_tp_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_1yr") {
                      self.setState({ tp_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_3yr") {
                      self.setState({ tp_3yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    }
                  } else if (e.target.value.length === 5) {
                    formated_dob =
                      dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                    if (whoIs === "od_1yr") {
                      this.setState({ od_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "od_1yr_bundle") {
                      this.setState({ od_1yr_bundle: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_3yr_bundle") {
                      this.setState({ tp_3yr_bundle: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "od_tp_1yr") {
                      this.setState({ od_tp_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_1yr") {
                      self.setState({ tp_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_3yr") {
                      self.setState({ tp_3yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    }
                  } else if (e.target.value.length === 2) {
                    formated_dob = dob[0] + dob[1] + "/";
                    if (whoIs === "od_1yr") {
                      this.setState({ od_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "od_1yr_bundle") {
                      this.setState({ od_1yr_bundle: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_3yr_bundle") {
                      this.setState({ tp_3yr_bundle: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "od_tp_1yr") {
                      this.setState({ od_tp_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_1yr") {
                      self.setState({ tp_1yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "tp_3yr") {
                      self.setState({ tp_3yr: formated_dob }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    }
                  } else {
                    if (whoIs === "od_1yr") {
                      this.setState({ od_1yr: e.target.value }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "father") {
                      this.setState({ father: e.target.value }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "mother") {
                      this.setState({ mother: e.target.value }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "mother-in-law") {
                      self.setState({ motherinlaw: e.target.value }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    } else if (whoIs === "father-in-law") {
                      self.setState({ fatherinlaw: e.target.value }, () => {
                        this.context.updateMotorExistingPolicies(this.state);
                      });
                    }
                  }
                }
              }
            } else {
              if (e.target.value.length < 11) {
                this.setState({ ageInDifferentForm: "Not Accepted" }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
                if (e.target.value.length === 10) {
                  formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                  if (whoIs === "od_1yr") {
                    this.setState({ od_1yr: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "od_1yr_bundle") {
                    this.setState({ od_1yr_bundle: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "tp_3yr_bundle") {
                    this.setState({ tp_3yr_bundle: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "od_tp_1yr") {
                    this.setState({ od_tp_1yr: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "tp_1yr") {
                    self.setState({ tp_1yr: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "tp_3yr") {
                    self.setState({ tp_3yr: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  }
                } else if (e.target.value.length === 5) {
                  formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                  if (whoIs === "od_1yr") {
                    this.setState({ od_1yr: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "od_1yr_bundle") {
                    this.setState({ od_1yr_bundle: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "tp_3yr_bundle") {
                    this.setState({ tp_3yr_bundle: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "od_tp_1yr") {
                    this.setState({ od_tp_1yr: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "tp_1yr") {
                    self.setState({ tp_1yr: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "tp_3yr") {
                    self.setState({ tp_3yr: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  }
                } else if (e.target.value.length === 2) {
                  formated_dob = dob[0] + dob[1] + "/";
                  if (whoIs === "od_1yr") {
                    this.setState({ od_1yr: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "od_1yr_bundle") {
                    this.setState({ od_1yr_bundle: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "tp_3yr_bundle") {
                    this.setState({ tp_3yr_bundle: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "od_tp_1yr") {
                    this.setState({ od_tp_1yr: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "tp_1yr") {
                    self.setState({ tp_1yr: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "tp_3yr") {
                    self.setState({ tp_3yr: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  }
                } else {
                  if (whoIs === "od_1yr") {
                    this.setState({ od_1yr: e.target.value }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "od_1yr_bundle") {
                    this.setState({ od_1yr_bundle: e.target.value }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "tp_3yr_bundle") {
                    this.setState({ tp_3yr_bundle: formated_dob }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "od_tp_1yr") {
                    this.setState({ od_tp_1yr: e.target.value }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "tp_1yr") {
                    self.setState({ tp_1yr: e.target.value }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  } else if (whoIs === "tp_3yr") {
                    self.setState({ tp_3yr: e.target.value }, () => {
                      this.context.updateMotorExistingPolicies(this.state);
                    });
                  }
                }
              }
              if (dob[6] && dob[7] && dob[8] && dob[9]) {
                let year = dob[6] + dob[7] + dob[8] + dob[9];
                let Current_year = new Date().getFullYear();
                this.setState(
                  {
                    ageInDifferentForm: parseInt(Current_year) - parseInt(year)
                  },
                  () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  }
                );
              }
            }
          } else {
            if (e.target.value.length < 11) {
              this.setState({ ageInDifferentForm: "Not Accepted" }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
              if (e.target.value.length === 10) {
                formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                this.setState({ od_1yr: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (e.target.value.length === 5) {
                formated_dob = dob[0] + dob[1] + "/";
                if (whoIs === "od_1yr") {
                  this.setState({ od_1yr: formated_dob }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "od_1yr_bundle") {
                  this.setState({ od_1yr_bundle: formated_dob }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "tp_3yr_bundle") {
                  this.setState({ tp_3yr_bundle: formated_dob }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "od_tp_1yr") {
                  this.setState({ od_tp_1yr: formated_dob }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "tp_1yr") {
                  self.setState({ tp_1yr: formated_dob }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "tp_3yr") {
                  self.setState({ tp_3yr: formated_dob }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                }
              } else if (e.target.value.length === 2) {
                formated_dob = dob[0] + dob[1] + "/";
                if (whoIs === "od_1yr") {
                  this.setState({ od_1yr: formated_dob }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "od_1yr_bundle") {
                  this.setState({ od_1yr_bundle: formated_dob }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "tp_3yr_bundle") {
                  this.setState({ tp_3yr_bundle: formated_dob }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "od_tp_1yr") {
                  this.setState({ od_tp_1yr: formated_dob }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "tp_1yr") {
                  self.setState({ tp_1yr: formated_dob }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "tp_3yr") {
                  self.setState({ tp_3yr: formated_dob }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                }
              } else {
                if (whoIs === "od_1yr") {
                  this.setState({ od_1yr: e.target.value }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "od_1yr_bundle") {
                  this.setState({ od_1yr_bundle: e.target.value }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "tp_3yr_bundle") {
                  this.setState({ tp_3yr_bundle: formated_dob }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "od_tp_1yr") {
                  this.setState({ od_tp_1yr: e.target.value }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "tp_1yr") {
                  self.setState({ tp_1yr: e.target.value }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                } else if (whoIs === "tp_3yr") {
                  self.setState({ tp_3yr: e.target.value }, () => {
                    this.context.updateMotorExistingPolicies(this.state);
                  });
                }
              }
            }
          }
        } else {
          if (e.target.value.length < 11) {
            this.setState({ ageInDifferentForm: "Not Accepted" }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
            if (e.target.value.length === 10) {
              formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
              if (whoIs === "od_1yr") {
                this.setState({ od_1yr: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "od_1yr_bundle") {
                this.setState({ od_1yr_bundle: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "tp_3yr_bundle") {
                this.setState({ tp_3yr_bundle: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "od_tp_1yr") {
                this.setState({ od_tp_1yr: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "tp_1yr") {
                self.setState({ tp_1yr: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "tp_3yr") {
                self.setState({ tp_3yr: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              }
            } else if (e.target.value.length === 5) {
              formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
              if (whoIs === "od_1yr") {
                this.setState({ od_1yr: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "od_1yr_bundle") {
                this.setState({ od_1yr_bundle: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "tp_3yr_bundle") {
                this.setState({ tp_3yr_bundle: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "od_tp_1yr") {
                this.setState({ od_tp_1yr: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "tp_1yr") {
                self.setState({ tp_1yr: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "tp_3yr") {
                self.setState({ tp_3yr: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              }
            } else if (e.target.value.length === 2) {
              formated_dob = dob[0] + dob[1] + "/";
              if (whoIs === "od_1yr") {
                this.setState({ od_1yr: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "od_1yr_bundle") {
                this.setState({ od_1yr_bundle: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "tp_3yr_bundle") {
                this.setState({ tp_3yr_bundle: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "od_tp_1yr") {
                this.setState({ od_tp_1yr: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "tp_1yr") {
                self.setState({ tp_1yr: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "tp_3yr") {
                self.setState({ tp_3yr: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              }
            } else {
              if (whoIs === "od_1yr") {
                this.setState({ od_1yr: e.target.value }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "od_1yr_bundle") {
                this.setState({ od_1yr_bundle: e.target.value }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "tp_3yr_bundle") {
                this.setState({ tp_3yr_bundle: formated_dob }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "od_tp_1yr") {
                this.setState({ od_tp_1yr: e.target.value }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "tp_1yr") {
                self.setState({ tp_1yr: e.target.value }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              } else if (whoIs === "tp_3yr") {
                self.setState({ tp_3yr: e.target.value }, () => {
                  this.context.updateMotorExistingPolicies(this.state);
                });
              }
            }
          }
        }
      } else {
        if (e.target.value.length < 11) {
          this.setState({ ageInDifferentForm: "Not Accepted" }, () => {
            this.context.updateMotorExistingPolicies(this.state);
          });
          if (e.target.value.length === 10) {
            formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
            if (whoIs === "od_1yr") {
              this.setState({ od_1yr: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "od_1yr_bundle") {
              this.setState({ od_1yr_bundle: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "tp_3yr_bundle") {
              this.setState({ tp_3yr_bundle: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "od_tp_1yr") {
              this.setState({ od_tp_1yr: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "tp_1yr") {
              self.setState({ tp_1yr: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "tp_3yr") {
              self.setState({ tp_3yr: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            }
          } else if (e.target.value.length === 5) {
            formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
            if (whoIs === "od_1yr") {
              this.setState({ od_1yr: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "od_1yr_bundle") {
              this.setState({ od_1yr_bundle: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "tp_3yr_bundle") {
              this.setState({ tp_3yr_bundle: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "od_tp_1yr") {
              this.setState({ od_tp_1yr: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "tp_1yr") {
              self.setState({ tp_1yr: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "tp_3yr") {
              self.setState({ tp_3yr: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            }
          } else if (e.target.value.length === 2) {
            formated_dob = "";
            if (whoIs === "od_1yr") {
              this.setState({ od_1yr: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "od_1yr_bundle") {
              this.setState({ od_1yr_bundle: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "tp_3yr_bundle") {
              this.setState({ tp_3yr_bundle: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "od_tp_1yr") {
              this.setState({ od_tp_1yr: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "tp_1yr") {
              self.setState({ tp_1yr: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "tp_3yr") {
              self.setState({ tp_3yr: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            }
          } else {
            if (whoIs === "od_1yr") {
              this.setState({ od_1yr: e.target.value }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "od_1yr_bundle") {
              this.setState({ od_1yr_bundle: e.target.value }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "tp_3yr_bundle") {
              this.setState({ tp_3yr_bundle: formated_dob }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "od_tp_1yr") {
              this.setState({ od_tp_1yr: e.target.value }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "tp_1yr") {
              self.setState({ tp_1yr: e.target.value }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            } else if (whoIs === "tp_3yr") {
              self.setState({ tp_3yr: e.target.value }, () => {
                this.context.updateMotorExistingPolicies(this.state);
              });
            }
          }
        }
      }
    } else {
      if (e.target.value.length < 11) {
        this.setState({ ageInDifferentForm: "Not Accepted" }, () => {
          this.context.updateMotorExistingPolicies(this.state);
        });
        if (e.target.value.length === 10) {
          formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
          if (whoIs === "od_1yr") {
            this.setState({ od_1yr: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "od_1yr_bundle") {
            this.setState({ od_1yr_bundle: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "tp_3yr_bundle") {
            this.setState({ tp_3yr_bundle: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "od_tp_1yr") {
            this.setState({ od_tp_1yr: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "tp_1yr") {
            self.setState({ tp_1yr: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "tp_3yr") {
            self.setState({ tp_3yr: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          }
        } else if (e.target.value.length === 5) {
          formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
          if (whoIs === "od_1yr") {
            this.setState({ od_1yr: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "od_1yr_bundle") {
            this.setState({ od_1yr_bundle: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "tp_3yr_bundle") {
            this.setState({ tp_3yr_bundle: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "od_tp_1yr") {
            this.setState({ od_tp_1yr: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "tp_1yr") {
            self.setState({ tp_1yr: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "tp_3yr") {
            self.setState({ tp_3yr: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          }
        } else if (e.target.value.length === 2) {
          formated_dob = dob[0] + dob[1] + "/";
          if (whoIs === "od_1yr") {
            this.setState({ od_1yr: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "od_1yr_bundle") {
            this.setState({ od_1yr_bundle: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "tp_3yr_bundle") {
            this.setState({ tp_3yr_bundle: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "od_tp_1yr") {
            this.setState({ od_tp_1yr: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "tp_1yr") {
            self.setState({ tp_1yr: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "tp_3yr") {
            self.setState({ tp_3yr: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          }
        } else {
          if (whoIs === "od_1yr") {
            this.setState({ od_1yr: e.target.value }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "od_1yr_bundle") {
            this.setState({ od_1yr_bundle: e.target.value }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "tp_3yr_bundle") {
            this.setState({ tp_3yr_bundle: formated_dob }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "od_tp_1yr") {
            this.setState({ od_tp_1yr: e.target.value }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "tp_1yr") {
            self.setState({ tp_1yr: e.target.value }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          } else if (whoIs === "tp_3yr") {
            self.setState({ tp_3yr: e.target.value }, () => {
              this.context.updateMotorExistingPolicies(this.state);
            });
          }
        }
      }
    }
    this.context.updateMotorExistingPolicies(this.state);
  };

  render() {
    return (
      <div>
        <div className="header-area">
          <div className="">
            <img
              onClick={() => this.goBack()}
              className="back-icon"
              src={process.env.PUBLIC_URL + "/back.png"}
            />
          </div>
          <div className="header-text">Existing Policy</div>
        </div>

        <div>
          <div style={{ margin: 20 }}>
            <h3 className="existing_policy_title">
              Select your existing policy type
            </h3>
            <span className="existing_policy_subtitle">
              we needthis to offer you plans with the right coverage
            </span>
          </div>
        </div>

        <div>
          <div className="existing_policy_option_container">
            <input id="policy_check" type="checkbox" />
            <div style={{ paddingLeft: 10 }}>
              <h4 className="existing_policy_option">
                1-Year Own Damage cover
              </h4>
              <span className="existing_policy_option_subtitle">
                Covers damages to your bike only and not third party. You bought
                this last year because you already had a valid third party
                policy.
              </span>
            </div>
          </div>

          <div className="existing_policy_option_container">
            {this.context.state.ExistingMotorPolicies.od_tp_1yr_opt ===
            false ? (
              <input
                onClick={() =>
                  this.setState({ od_tp_1yr_opt: true, askExpiry: true }, () =>
                    this.context.updateMotorExistingPolicies(this.state)
                  )
                }
                checked={false}
                id="policy_check"
                type="checkbox"
              />
            ) : (
              <input
                onClick={() =>
                  this.setState(
                    { od_tp_1yr_opt: false, askExpiry: false },
                    () => this.context.updateMotorExistingPolicies(this.state)
                  )
                }
                checked={true}
                id="policy_check"
                type="checkbox"
              />
            )}
            <div style={{ paddingLeft: 10 }}>
              <h4 className="existing_policy_option">
                1-Year Own Damage cover
              </h4>
              <span className="existing_policy_option_subtitle">
                Covers your bike and third party for 1 year
              </span>
            </div>
          </div>

          <div className="existing_policy_option_container">
            {this.context.state.ExistingMotorPolicies.tp_1yr_opt === false ? (
              <input
                onClick={() =>
                  this.setState({ tp_1yr_opt: true, askExpiry: true }, () =>
                    this.context.updateMotorExistingPolicies(this.state)
                  )
                }
                checked={false}
                id="policy_check"
                type="checkbox"
              />
            ) : (
              <input
                onClick={() =>
                  this.setState({ tp_1yr_opt: false, askExpiry: false }, () =>
                    this.context.updateMotorExistingPolicies(this.state)
                  )
                }
                checked={true}
                id="policy_check"
                type="checkbox"
              />
            )}
            <div style={{ paddingLeft: 10 }}>
              <h4 className="existing_policy_option">
                1-Year Own Damage cover
              </h4>
              <span className="existing_policy_option_subtitle">
                Covers third party for 1 year
              </span>
            </div>
          </div>

          <div className="existing_policy_option_container">
            {this.context.state.ExistingMotorPolicies.od_1yr_tp_3yr_opt ===
            false ? (
              <input
                onClick={() =>
                  this.setState(
                    { od_1yr_tp_3yr_opt: true, askExpiry_bundelPolicy: true },
                    () => this.context.updateMotorExistingPolicies(this.state)
                  )
                }
                checked={false}
                id="policy_check"
                type="checkbox"
              />
            ) : (
              <input
                onClick={() =>
                  this.setState(
                    { od_1yr_tp_3yr_opt: false, askExpiry: false },
                    () => this.context.updateMotorExistingPolicies(this.state)
                  )
                }
                checked={true}
                id="policy_check"
                type="checkbox"
              />
            )}
            <div style={{ paddingLeft: 10 }}>
              <h4 className="existing_policy_option">
                1+5 Bundled Comprehensive policy
              </h4>
              <span className="existing_policy_option_subtitle">
                {this.props.type === "CAR"
                  ? "Covers your bike for 1 year and third party for 3 years"
                  : "Covers your bike for 1 year and third party for 5 years"}
              </span>
            </div>
          </div>

          <div className="existing_policy_option_container">
            {this.context.state.ExistingMotorPolicies.tp_3yr_opt === false ? (
              <input
                onClick={() =>
                  this.setState({ tp_3yr_opt: true, askExpiry: true }, () =>
                    this.context.updateMotorExistingPolicies(this.state)
                  )
                }
                checked={false}
                id="policy_check"
                type="checkbox"
              />
            ) : (
              <input
                onClick={() =>
                  this.setState({ tp_3yr_opt: false, askExpiry: false }, () =>
                    this.context.updateMotorExistingPolicies(this.state)
                  )
                }
                checked={true}
                id="policy_check"
                type="checkbox"
              />
            )}
            <div style={{ paddingLeft: 10 }}>
              <h4 className="existing_policy_option">
                5-Year Third Party Policy
              </h4>
              <span className="existing_policy_option_subtitle">
                {this.props.type === "CAR"
                  ? "Covers third party only for 3 years"
                  : "Covers third party only for 5 years"}
              </span>
            </div>
          </div>

          {this.state.askExpiry === true ? (
            <div class="pop4">
              <div class="pop3">
                <div class="pop2">
                  <div class="pop1">
                    <p
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        padding: 10
                      }}
                    >
                      {" "}
                      When does your 'Own Damage' policy expire?
                    </p>
                    <input
                      placeholder="DD / MM / YYYY"
                      id="policy_date"
                      value={
                        this.context.state.ExistingMotorPolicies.od_1yr_opt ===
                        true
                          ? this.state.od_1yr
                          : this.context.state.ExistingMotorPolicies
                              .od_tp_1yr_opt === true
                          ? this.state.od_tp_1yr
                          : this.context.state.ExistingMotorPolicies
                              .tp_1yr_opt === true
                          ? this.state.tp_1yr
                          : this.state.tp_3yr
                      }
                      onChange={e =>
                        this.context.state.ExistingMotorPolicies.od_1yr_opt ===
                        true
                          ? this.setDob(e, "od_1yr")
                          : this.context.state.ExistingMotorPolicies
                              .od_tp_1yr_opt === true
                          ? this.setDob(e, "od_tp_1yr")
                          : this.context.state.ExistingMotorPolicies
                              .tp_1yr_opt === true
                          ? this.setDob(e, "tp_1yr")
                          : this.setDob(e, "tp_3yr")
                      }
                      type="text"
                      class="motor_policy_input"
                    />
                  </div>
                </div>
              </div>
              <div></div>
              {this.props.type === "CAR" ? (
                <button
                  class="btn_motor"
                  onClick={() => this.MotorPolicySuggest()}
                >
                  Next
                </button>
              ) : (
                <button
                  class="btn_motor"
                  onClick={() => this.MotorTwoWheelerPolicySuggest()}
                >
                  Next
                </button>
              )}
            </div>
          ) : (
            <div></div>
          )}

          {this.state.askExpiry_bundelPolicy === true ? (
            <div class="pop4">
              <div class="pop3">
                <div class="pop2">
                  <div class="pop1">
                    <span
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        padding: 10
                      }}
                    >
                      When does your 'Own Damage' policy expire?
                    </span>
                    <input
                      id="policy_date"
                      value={this.state.od_1yr_bundle}
                      placeholder="DD / MM / YYYY"
                      onChange={e => this.setDob(e, "od_1yr_bundle")}
                      type="text"
                      class="motor_policy_input"
                    />
                    <br />
                    <span
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        padding: 10
                      }}
                    >
                      When does your 'Third Party Damage' policy expire?
                    </span>
                    <input
                      id="policy_date"
                      value={this.state.tp_3yr_bundle}
                      placeholder="DD / MM / YYYY"
                      onChange={e => this.setDob(e, "tp_3yr_bundle")}
                      type="text"
                      class="motor_policy_input"
                    />
                  </div>
                </div>
              </div>
              {this.props.type === "CAR" ? (
                <button
                  class="btn_motor"
                  onClick={() => this.MotorPolicySuggest()}
                >
                  Next
                </button>
              ) : (
                <button
                  class="btn_motor"
                  onClick={() => this.MotorTwoWheelerPolicySuggest()}
                >
                  Next
                </button>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
}

ExistingPolicy.contextType = AppContext;

export default ExistingPolicy;
