import React, { Component } from 'react';
import AppContext from '../context/AppContext';
import Motor_Brand from './Motor_Brand'
import Motor_varient from './Motor_varient';
import Motor_Model from './Motor_Model';
import Motor_Rto from './Motor_Rto';
import Motor_registration_year from './Motor_registration_year';
import Motor_maker from './Motor_maker';
import Motor_fueltype from './Motor_fueltype';
import Motor_userDetails from './Motor_userDetails';
import ExistingPolicy from './ExistingPolicy';
import TwoWheelerInsurance from './TwoWheelerInsurance';


class Motor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            processPage: 'brand',
            willprocess_start: false,
            brand: '',
            model: '',
            modelCode:'',
            year_of_registration: '',
            varient: '',
            RTO: '',
            IDV:''
        }
    }

    componentDidMount() {
        console.log('Yes hit it')
    }

    setBrand = (brand) => {
        this.setState({ brand })
        this.context.updateMotorState(this.state)
    }

    setYear_of_registration = (year_of_registration) => {
        this.setState({ year_of_registration },() => {
            this.context.updateMotorState(this.state)
        })
        
    }

    setModel = (model) => {
        this.setState({ model:model.model , modelCode:model.modelCode },() => {
            this.context.updateMotorState(this.state)
        })
        
        
    }

    changePhase = (phase) => {
        this.setState({ processPage: phase })
    }

    setVarient = (varient) => {
        this.setState({ varient },() => {
            this.context.updateMotorState(this.state)
        })
        
    }

    setRTO = (RTO) => {
        this.setState({ RTO },() => {
            this.context.updateMotorState(this.state)
            this.changePhase('existing_policy')
        })
        
    }

    setIDV = (IDV) => {
        this.setState({ IDV },() => {
            this.context.updateMotorState(this.state)
        })
    }

    setProcessStatus = (status) => {
        this.setState({ willprocess_start: status })
    }

    render() {
        return (
            <div class='motor_main_container'>
                {this.state.willprocess_start === false ?
                    <TwoWheelerInsurance type="CAR" history={this.props.history} setProcessStatus={this.setProcessStatus} />
                    :
                    (this.state.processPage === 'brand' ?
                        <Motor_Brand setProcessStatus={this.setProcessStatus} setBrand={this.setBrand} type='CAR' changePhase={this.changePhase} />
                        :
                        (this.state.processPage === 'model' ?
                            <Motor_Model setModel={this.setModel} type='CAR' brand={this.state.brand} changePhase={this.changePhase} />
                            :
                            (this.state.processPage === 'rto_selection' ?
                                <Motor_Rto year_of_registration={this.state.year_of_registration} type='CAR' setRTO={this.setRTO} changePhase={this.changePhase} />
                                :
                                (this.state.processPage === 'varient' ?
                                    <Motor_varient model={this.state.model} setVarient={this.setVarient} type='CAR' changePhase={this.changePhase} />
                                    :
                                    (this.state.processPage === 'registration_year' ?
                                        <Motor_registration_year setYear={this.setYear_of_registration} type='CAR' changePhase={this.changePhase} />
                                        :
                                        <ExistingPolicy setIDV={this.setIDV} type="CAR" history={this.props.history} changePhase={this.changePhase} />
                                    )
                                ) 
                            )
                        )
                    )
                }

            </div>
        )
    }


}

Motor.contextType = AppContext
export default Motor;