import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AppContext from "../context/AppContext";
import loginSvg from "../svg/login.svg";

let cities = [
  "MUMBAI",
  "MUMBAI SUBURBAN",
  "THANE",
  "NAVI MUMBAI",
  "SURAT",
  "NATIONAL CAPITAL REGION (NCR)",
  "VADODARA",
  "DELHI",
  "HYDERABAD",
  "KOLKATA",
  "All Cities",
  "Rest of India",
  "AGRA",
  "AHMEDABAD",
  "AMRITSAR",
  "AURANGABAD",
  "BAREILLY",
  "BARODA",
  "BHOPAL",
  "BHUBANESWAR",
  "CALICUT",
  "CHANDIGARH",
  "COCHIN",
  "COIMBATORE",
  "DURGAPUR",
  "FARIDABAD",
  "GHAZIABAD",
  "GURGOAN",
  "GUWAHATI",
  "HUBLI",
  "INDORE",
  "JABALPUR",
  "JAIPUR",
  "JALLANDHAR",
  "JAMMU",
  "JAMSHEDPUR",
  "JODHPUR",
  "KANNUR",
  "KANPUR",
  "KARNAL",
  "KOLHAPUR",
  ,
  "KOLLAM",
  "KOLHAPUR",
  "KOTA",
  "KOTTAYAM",
  "LUCKNOW",
  "LUDHIANA",
  "MADURAI",
  "MANGALORE",
  "MEERUT",
  "MYSORE",
  "NAGPUR",
  "NASIK",
  "NELLORE",
  "NOIDA",
  "PALAKKAD",
  "PANJIM",
  "PATIALA",
  "PATNA",
  "PONDICHERRY",
  "RAIPUR",
  "PUNE",
  "BANGLORE",
  "RAJAHMUNDRY",
  "RAJKOT",
  "RANCHI",
  "ROURKELA",
  "SALEM",
  "TELANGANA",
  "THRISSUR",
  "TIRUCHIRAPPALLI",
  "TRIVANDRUM",
  "UDAIPUR",
  "VAPI",
  "VIJAYWADA",
  "VISAKH`APATNAM",
];

let MetroCity = [
  "MUMBAI",
  "MUMBAI SUBURBAN",
  "THANE",
  "NAVI MUMBAI",
  "SURAT",
  "NATIONAL CAPITAL REGION (NCR)",
  "VADODARA",
  "DELHI",
];
class City extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };
  }

  cityChange = (data) => {
    if (data === "NOT FOUND") {
      this.props.history.push("/personal_details");
      this.context.state.userInfo.selectedCity = "";
    } else {
      this.context.state.userInfo.selectedCity = data;
      let a = 0;
      for (let i = 0; i < MetroCity.length; i++) {
        a++;
        if (MetroCity[i] === this.context.state.userInfo.selectedCity) {
          this.context.state.userInfo.city = "Metro";
          break;
        } else {
          if (a === MetroCity.length) {
            this.context.state.userInfo.city = "Non Metro";
            break;
          }
        }
      }

      this.props.history.push("/personal_details");
    }
  };

  SearchIt = (city) => {
    // console.log({city});
    this.setState({ search: city });
  };

  render() {
    let AllCities = cities.map((items) => {
      if (items === "All Cities") {
        // console.log(items);
        return (
          <div>
            <hr />
            <p style={{ cursor: "pointer", color: "grey" }}>{items}</p>
          </div>
        );
      } else {
        // console.log("else",items);
        return (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => this.cityChange(items)}
          >
            {items}
          </p>
        );
      }
    });
    let searchdata;
    if (this.state.search !== "") {


      console.log("++++", this.state.search);


      searchdata = cities.filter((items) => {
        if (items.toLowerCase().includes(this.state.search.toLowerCase())) {
          console.log("search items", items)
          return (
            <p
              style={{ cursor: "pointer", fontSize: 12 }}
              onClick={() => this.cityChange(items)}
            >
              {items}
            </p>
          );
        }
      });


    }

    console.log("COOOOL", searchdata);

    return (
      <div className="slide_top" style={{ margin: 20 }}>
        <div id="city_header">
          <h3>Select Your City </h3>
          <ion-icon
            onClick={() => this.props.history.push("/personal_details")}
            name="close-outline"
          ></ion-icon>
        </div>

        <div style={{ marginTop: 45 }}>
          <input
            onChange={(e) => this.SearchIt(e.target.value)}
            placeholder="Search City"
            class="city_search"
          />
        </div>

        {this.state.search === "" ? (
          <div>{AllCities}</div>
        ) : (
          <div>
            {/* {searchdata.map((items) => {
              return <p onClick={() => this.cityChange(items)}>{items}</p>;
            })} */}
            {
              (searchdata.length > 0) ?
                searchdata.map((items) => {
                  return <p onClick={() => this.cityChange(items)}>{items}</p>;
                }) : <p onClick={() => this.cityChange("Rest Of India")}>Rest Of India</p>
            }
          </div>
        )}
      </div>
    );
  }
}

City.contextType = AppContext;
export default City;
