import React, { Component } from 'react';
import AppContext from '../context/AppContext'
import { withRouter } from "react-router-dom";
import svgPerson from '../svg/svgPerson.svg';



class Libility extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Libility: 0
        }
        this.LibilityChange = this.LibilityChange.bind(this)
    }
    goBack(){
        this.props.history.goBack();
    }
    LibilityChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    componentDidUpdate() {
        console.log(this.state)
    }
    nextClicked(context) {
        console.log('personal',context.state.userInfo)
        context.updateLibilities(this.state)
        this.props.history.push('/insurance-details')
    }
    componentWillUnmount() {
        this.context.updateLastPage('/libility')
    }

    render() {
        return (
            <div>
                <div className="header-area">
                    <div className=""><img onClick={() => this.goBack()} className="back-icon" src={process.env.PUBLIC_URL + '/back.png'} /></div>
                    <div className="header-text">Liabilities (Step 5/6)</div>
                    <div class="progress-container">
                        <div class="progress-bar" id="myBar" style={{ width: "80%" }}></div>
                    </div>
                </div>
                <div id="slide" className="main-page">
                    <div className="red-background">
                        <div className="page-title">Please enter the sum of current
and future liabilities</div>
                    </div>
                    <div className="page-content">
                        <div className="input-box">
                            <div className="page-subtitle">Liabilities step(5/6)</div>
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                 <label style={{fontSize:45,margin:'unset',height:40}}>&#8377;</label>
                                 <input name="Libility" value={this.state.Libility > 0 && this.state.Libility} onChange={this.LibilityChange} className="Libility-input suminsured-input other_family_input" id="Libility" type="number" placeholder="0" required />
                            </div>
                            <input name="Libility" onChange={this.LibilityChange} type="range" min="0" max="10000000" step='10000' className="slider" id="Libility-range" />
                            <div className="min_amt">₹1 Lakh</div>
                            <div className="max_amt">₹1 Crore+</div>
                        </div>
                        <AppContext.Consumer>
                            {(context) => (
                                <React.Fragment>
                                    <div style={{ display: 'flex', justifyContent: 'center',margin:15 }}>
                                        <button className="btn" onClick={() => this.nextClicked(context)}>Next</button>
                                    </div>
                                </React.Fragment>
                            )}
                        </AppContext.Consumer>
                    </div>
                </div>
            </div>
        );
    }
}

Libility.contextType = AppContext;
export default withRouter(Libility);