import React, { Component } from "react";
import AppContext from "../context/AppContext";
import { withRouter } from "react-router-dom";
import health_icon2 from "../svg/health_icon2.svg";
import parents from "../svg/parents.svg";
import kids from "../svg/kids.svg";
import spouse from "../svg/spouse.svg";
import myself from "../svg/myself.svg";
import HDFC_Bank_Logo from "../svg/HDFC_Bank_Logo.svg"
import HDFC_Ergo_Logo from "../svg/HDFC_Ergo_Logo.svg"

import {
  maxcover_metro_lessthan45,
  mediclaimPercent,
  maxcover_metro_morethan45,
  maxcover_nonmetro_lessthan45,
  maxcover_nonmetro_morethan45,
  dependent_maxcover_metro_lessthan45,
  dependent_maxcover_metro_morethan45,
  dependent_maxcover_nonmetro_lessthan45,
  dependent_maxcover_nonmetro_morethan45,
} from "../config/defaultVariables";
import Recommendations from "./Recommendation";

class Health_insurance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spouse: false,
      myself: false,
      kids: false,
      parents: false,
      suminsured: 0,
      creditscore: 0,
      limit: 0,
    };
    this.suminsuredChange = this.suminsuredChange.bind(this);
  }
  updateLocalStateWithGlobalData(context) {
    if (this.state.limit === 0) {
      this.state.limit++;
      this.setState({
        spouse: context.state.healthInsurance.spouse,
        myself: context.state.healthInsurance.myself,
        kids: context.state.healthInsurance.kids,
        parents: context.state.healthInsurance.parents,
        suminsured: context.state.healthInsurance.suminsured,
        creditscore: context.state.liveCreditScore,
      });
      let filter1 = "Health_Insurance";
      let filter2 = "Super_Top_Up";

      const filters = [filter1, filter2];

      const filteredRecommendations = context.state.Recommendations.filter(
        ({ subcategory }) => !filters.includes(subcategory)
      );
      context.updateRecommendationsStateOnBack(filteredRecommendations);

      // const filteredRecommendations = context.state.Recommendations.filter(recommendation => recommendation.subcategory != 'Health_Insurance')
      // console.log(filteredRecommendations)
      // context.updateRecommendationsStateOnBack(filteredRecommendations)
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (this.context.state.score_board.mediclaim > 0) {
      if (this.context.state.score_board.mediclaim === 24.5) {
        await this.context.updateCreditScore(
          "mediclaim",
          "has_insured",
          "less"
        );
      } else if (this.context.state.score_board.mediclaim === 38.5) {
        await this.context.updateCreditScore(
          "mediclaim",
          "less_than_recommended",
          "less"
        );
      } else if (this.context.state.score_board.mediclaim === 52.5) {
        await this.context.updateCreditScore(
          "mediclaim",
          "greate_than_recommended",
          "less"
        );
      }
    }
  }

  checkValues = (id, value, context) => {
    if (document.getElementById(id).checked) {
      if (value === "Health_myself") {
        this.setState({ myself: true }, () => {
          context.updateHealthInsuranceState(this.state);
        });
      } else if (value === "Health_kids") {
        this.setState({ kids: true }, () => {
          context.updateHealthInsuranceState(this.state);
        });
      } else if (value === "Health_spouse") {
        this.setState({ spouse: true }, () => {
          context.updateHealthInsuranceState(this.state);
        });
      } else {
        this.setState({ parents: true }, () => {
          context.updateHealthInsuranceState(this.state);
        });
      }
    } else {
      if (value === "Health_myself") {
        this.setState({ myself: false }, () => {
          context.updateHealthInsuranceState(this.state);
        });
      } else if (value === "Health_kids") {
        this.setState({ kids: false }, () => {
          context.updateHealthInsuranceState(this.state);
        });
      } else if (value === "Health_spouse") {
        this.setState({ spouse: false }, () => {
          context.updateHealthInsuranceState(this.state);
        });
      } else {
        this.setState({ parents: false }, () => {
          context.updateHealthInsuranceState(this.state);
        });
      }
    }
  };

  // calculateCreditScore = async (context) => {
  //   if (this.context.state.policyTaken.Corporate_insurance === false) {
  //     if (this.context.state.userInfo.city === "Metro") {
  //       if (this.context.state.userInfo.ageInDifferentForm < 45) {
  //         if (
  //           this.context.state.familyDetails.spouse === null &&
  //           this.context.state.familyDetails.father === null &&
  //           this.context.state.familyDetails.mother === null &&
  //           this.context.state.familyDetails.motherinlaw === null &&
  //           this.context.state.familyDetails.fatherinlaw === null
  //         ) {
  //           if (this.state.suminsured < maxcover_metro_lessthan45) {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "less_than_recommended",
  //               "Add"
  //             );
  //           } else {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "greate_than_recommended",
  //               "Add"
  //             );
  //           }
  //         } else {
  //           if (this.state.suminsured < dependent_maxcover_metro_lessthan45) {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "less_than_recommended",
  //               "Add"
  //             );
  //           } else {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "greate_than_recommended",
  //               "Add"
  //             );
  //           }
  //         }
  //       } else {
  //         if (
  //           this.context.state.familyDetails.spouse === null &&
  //           this.context.state.familyDetails.father === null &&
  //           this.context.state.familyDetails.mother === null &&
  //           this.context.state.familyDetails.motherinlaw === null &&
  //           this.context.state.familyDetails.fatherinlaw === null
  //         ) {
  //           if (this.state.suminsured < maxcover_metro_morethan45) {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "less_than_recommended",
  //               "Add"
  //             );
  //           } else {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "greate_than_recommended",
  //               "Add"
  //             );
  //           }
  //         } else {
  //           if (this.state.suminsured < dependent_maxcover_metro_morethan45) {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "less_than_recommended",
  //               "Add"
  //             );
  //           } else {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "greate_than_recommended",
  //               "Add"
  //             );
  //           }
  //         }
  //       }
  //     } else {
  //       if (this.context.state.userInfo.ageInDifferentForm < 45) {
  //         if (
  //           this.context.state.familyDetails.spouse === null &&
  //           this.context.state.familyDetails.father === null &&
  //           this.context.state.familyDetails.mother === null &&
  //           this.context.state.familyDetails.motherinlaw === null &&
  //           this.context.state.familyDetails.fatherinlaw === null
  //         ) {
  //           if (this.state.suminsured < maxcover_nonmetro_lessthan45) {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "less_than_recommended",
  //               "Add"
  //             );
  //           } else {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "greate_than_recommended",
  //               "Add"
  //             );
  //           }
  //         } else {
  //           if (
  //             this.state.suminsured < dependent_maxcover_nonmetro_lessthan45
  //           ) {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "less_than_recommended",
  //               "Add"
  //             );
  //           } else {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "greate_than_recommended",
  //               "Add"
  //             );
  //           }
  //         }
  //       } else {
  //         if (
  //           this.context.state.familyDetails.spouse === null &&
  //           this.context.state.familyDetails.father === null &&
  //           this.context.state.familyDetails.mother === null &&
  //           this.context.state.familyDetails.motherinlaw === null &&
  //           this.context.state.familyDetails.fatherinlaw === null
  //         ) {
  //           if (this.state.suminsured < maxcover_nonmetro_morethan45) {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "less_than_recommended",
  //               "Add"
  //             );
  //           } else {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "greate_than_recommended",
  //               "Add"
  //             );
  //           }
  //         } else {
  //           if (
  //             this.state.suminsured < dependent_maxcover_nonmetro_morethan45
  //           ) {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "less_than_recommended",
  //               "Add"
  //             );
  //           } else {
  //             // await context.updateCreditScore("mediclaim", "has_insured", "less");
  //             await context.updateCreditScore(
  //               "mediclaim",
  //               "greate_than_recommended",
  //               "Add"
  //             );
  //           }
  //         }
  //       }
  //     }
  //   }
  // };

  // calculateCreditScore = async context => {
  //   if (this.context.state.userInfo.city === "Metro") {
  //     if (this.context.state.userInfo.ageInDifferentForm < 45) {
  //       if (this.state.spouse === false && this.state.parents === false) {
  //         if (this.state.suminsured < maxcover_metro_lessthan45) {
  //           let covered =
  //             (this.state.suminsured / maxcover_metro_lessthan45) * 100;
  //           let healthinsurance_score = (mediclaimPercent * covered) / 100;
  //           await context.updateCreditScore(healthinsurance_score, "Add");
  //           await context.updateCreditScore(42, "less");
  //         }
  //       } else {
  //         if (this.state.suminsured < maxcover_metro_lessthan45) {
  //           let covered =
  //             (this.state.suminsured / dependent_maxcover_metro_lessthan45) *
  //             100;
  //           let healthinsurance_score = (mediclaimPercent * covered) / 100;
  //           await context.updateCreditScore(healthinsurance_score, "Add");
  //           await context.updateCreditScore(42, "less");
  //         }
  //       }
  //     } else {
  //       if (this.state.spouse === false && this.state.parents === false) {
  //         if (this.state.suminsured < maxcover_metro_lessthan45) {
  //           let covered =
  //             (this.state.suminsured / maxcover_metro_morethan45) * 100;
  //           let healthinsurance_score = (mediclaimPercent * covered) / 100;
  //           await context.updateCreditScore(healthinsurance_score, "Add");
  //           await context.updateCreditScore(42, "less");
  //         }
  //       } else {
  //         if (this.state.suminsured < maxcover_metro_lessthan45) {
  //           let covered =
  //             (this.state.suminsured / dependent_maxcover_metro_morethan45) *
  //             100;
  //           let healthinsurance_score = (mediclaimPercent * covered) / 100;
  //           await context.updateCreditScore(healthinsurance_score, "Add");
  //           await context.updateCreditScore(42, "less");
  //         }
  //       }
  //     }
  //   } else {
  //     if (this.context.state.userInfo.ageInDifferentForm < 45) {
  //       if (this.state.spouse === false && this.state.parents === false) {
  //         if (this.state.suminsured < maxcover_metro_lessthan45) {
  //           let covered =
  //             (this.state.suminsured / maxcover_nonmetro_lessthan45) * 100;
  //           let healthinsurance_score = (mediclaimPercent * covered) / 100;

  //           await context.updateCreditScore(healthinsurance_score, "Add");
  //           await context.updateCreditScore(42, "less");
  //         }
  //       } else {
  //         if (this.state.suminsured < maxcover_metro_lessthan45) {
  //           let covered =
  //             (this.state.suminsured / dependent_maxcover_nonmetro_lessthan45) *
  //             100;
  //           let healthinsurance_score = (mediclaimPercent * covered) / 100;
  //           await context.updateCreditScore(healthinsurance_score, "Add");
  //           await context.updateCreditScore(42, "less");
  //         }
  //       }
  //     } else {
  //       if (this.state.spouse === false && this.state.parents === false) {
  //         if (this.state.suminsured < maxcover_metro_lessthan45) {
  //           let covered =
  //             (this.state.suminsured / maxcover_nonmetro_morethan45) * 100;
  //           let healthinsurance_score = (mediclaimPercent * covered) / 100;
  //           await context.updateCreditScore(healthinsurance_score, "Add");
  //           await context.updateCreditScore(42, "less");
  //         }
  //       } else {
  //         if (this.state.suminsured < maxcover_metro_lessthan45) {
  //           let covered =
  //             (this.state.suminsured / dependent_maxcover_nonmetro_morethan45) *
  //             100;
  //           let healthinsurance_score = (mediclaimPercent * covered) / 100;
  //           await context.updateCreditScore(healthinsurance_score, "Add");
  //           await context.updateCreditScore(42, "less");
  //         }
  //       }
  //     }
  //   }
  // };

  updateMaincomponent = async (context, page) => {
    // await this.calculateCreditScore(context);
    context.updateHealthInsurance(this.state, page);
  };

  suminsuredChange(context, e) {
    if (e.target.value === "") {
      this.setState(
        {
          suminsured: 0,
        },
        () => {
          context.updateHealthInsuranceState(this.state);
        }
      );
    } else {
      this.setState(
        {
          suminsured: e.target.value,
        },
        () => {
          context.updateHealthInsuranceState(this.state);
        }
      );
    }
  }
  goBack(context) {
    this.props.history.goBack();
  }

  inWords = (num) => {
    var a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    var b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
        : "";
    return str;
  };

  render() {
    let suminsured = this.context.state.healthInsurance.suminsured;
    if (suminsured) {
      if (suminsured.toString().length !== 0) {
        if (
          suminsured.toString().length > 4 &&
          suminsured.toString().length < 7
        ) {
          suminsured = this.inWords(
            this.context.state.healthInsurance.suminsured
          ).toUpperCase();
        } else {
          suminsured = this.inWords(
            this.context.state.healthInsurance.suminsured
          ).toUpperCase();
        }
      } else {
        suminsured = 0;
      }
    }

    return (
      <div style={{}}>
        <AppContext.Consumer>
          {(context) => (
            <React.Fragment>
              <div className="header-area">
                <div className="">
                  <img
                    onClick={() => this.goBack(context)}
                    className="back-icon"
                    src={process.env.PUBLIC_URL + "/back.png"}
                  />
                </div>
                <div className="header-text">Existing Insurance Details</div>
                <div class="progress-container">
                  <div
                    class="progress-bar"
                    id="myBar"
                    style={{ width: "25%" }}
                  ></div>
                </div>
              </div>

              {this.updateLocalStateWithGlobalData(context)}
              <div id="slide">
                <div class="existingInsurance_pages">
                  <img src={health_icon2} />
                </div>

                <div class="super_top_up_main_container">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span class="category_insurance_header">
                      Existing Health Insurance <br />
                      <span
                        style={{
                          color: "lightgrey",
                          fontSize: 14,
                          fontWeight: 500,
                        }}
                      >
                        {" "}
                        Self Bought Health Insurance{" "}
                      </span>
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 25,
                    }}
                  >
                    <div class="suminsured_container">
                      <p
                        style={{
                          textAlign: "center",
                          paddingTop: 10,
                          marginBottom: "unset",
                          paddingLeft: 20,
                        }}
                      >
                        Sum Insured
                      </p>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="income_amount_container">
                          <label style={{ fontSize: 45, margin: "unset" }}>
                            &#8377;
                          </label>
                          <input
                            name="suminsured"
                            value={
                              context.state.healthInsurance.suminsured
                                ? context.state.healthInsurance.suminsured ===
                                  "0"
                                  ? "1,00,000"
                                  : context.state.healthInsurance.suminsured
                                : this.state.suminsured > 0 &&
                                  this.state.suminsured
                            }
                            onChange={(e) => this.suminsuredChange(context, e)}
                            className="suminsured-input other_family_input"
                            id="suminsured"
                            type="number"
                            placeholder="1,00,000"
                            required
                          />
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <p class="textAmount">
                          {context.state.healthInsurance.suminsured
                            ? context.state.healthInsurance.suminsured === "0"
                              ? "ONE LAKH"
                              : suminsured
                            : this.state.suminsured > 0 &&
                              this.state.suminsured}
                        </p>
                      </div>
                      <div
                        className="page-content"
                        style={{ marginBottom: "25%" }}
                      >
                        <input
                          value={context.state.healthInsurance.suminsured}
                          name="suminsured"
                          onChange={(e) => this.suminsuredChange(context, e)}
                          type="range"
                          min="0"
                          max="5000000"
                          step="500000"
                          className="slider"
                          id="suminsured-range"
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="min_amt">₹1 Lakh</div>
                          <div className="max_amt">₹50 Lakh+</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <label class="header_labels">Family members covered</label>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginTop: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                    >
                      <div className="checkbox_input">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: 10,
                            paddingTop: 10,
                          }}
                        >
                          <h5 className="checkbox_title">Spouse</h5>
                          <input
                            type="checkbox"
                            onClick={() =>
                              this.checkValues(
                                "Health_spouse",
                                "Health_spouse",
                                context
                              )
                            }
                            id="Health_spouse"
                            className="family_checkbox"
                            checked={context.state.healthInsurance.spouse}
                          />
                        </div>
                        <div>
                          <img className="health-svg" src={spouse} />
                        </div>
                      </div>
                      <div className="checkbox_input">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: 10,
                            paddingTop: 10,
                          }}
                        >
                          <h5 className="checkbox_title">Parents</h5>
                          <input
                            onClick={() =>
                              this.checkValues(
                                "Health_parents",
                                "Health_parents",
                                context
                              )
                            }
                            id="Health_parents"
                            type="checkbox"
                            class="family_checkbox"
                            checked={context.state.healthInsurance.parents}
                          />
                        </div>
                        <div>
                          <img className="health-svg" src={parents} />
                        </div>
                      </div>

                      <div className="checkbox_input">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            paddingRight: 10,
                            paddingTop: 10,
                          }}
                        >
                          <h5 className="checkbox_title">Kids</h5>
                          <input
                            type="checkbox"
                            onClick={() =>
                              this.checkValues(
                                "Health_kids",
                                "Health_kids",
                                context
                              )
                            }
                            id="Health_kids"
                            className="family_checkbox"
                            checked={context.state.healthInsurance.kids}
                          />
                        </div>
                        <div>
                          <img className="health-svg" src={kids} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "-15%", textAlign: 'center', width: "100%" }}>
                    <div className="brand_icons_header">Powered by</div>
                    <div className="brand_icons">
                      <img src={HDFC_Bank_Logo} style={{marginRight:"8px" , height :"20px"}} alt="HDFC_BANK" />
                      <img src={HDFC_Ergo_Logo} style={{marginLeft:"8px"}}  alt="HDFC_ERGO" />
                    </div>
                  </div>

                {context.state.healthInsurance.suminsured > 100000 ? (
                  context.state.policyTaken.Corporate_insurance === true ? (
                    //Redirect to super-top-up
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.updateMaincomponent(
                            context,
                            "Corporate_insurance"
                          )
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.super_top_up_insurance ===
                    true ? (
                    //Redirect to super-top-up
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.updateMaincomponent(
                            context,
                            "Super_top_up_insurance"
                          )
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.Personal_Accident_Insurance ===
                    true ? (
                    //Redirect to Personal Accident
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.updateMaincomponent(context, "Personal_Accident")
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.critical_illness_insurance ===
                    true ? (
                    //Redirect to Critical illness
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.updateMaincomponent(
                            context,
                            "Critical_insurance"
                          )
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.Motor === true ? (
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.updateMaincomponent(context, "Motor")
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.Bike === true ? (
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.updateMaincomponent(context, "Bike")
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.Home_Rental === true ? (
                    //Redirect to Home
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.updateMaincomponent(context, "Home_Rental")
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.Cyber === true ? (
                    //Redirect to Cyber
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.updateMaincomponent(context, "Cyber")
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : (
                    //Redirect to Recommendations
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.updateMaincomponent(context, "Recommendations")
                        }
                      >
                        Next
                      </button>
                    </div>
                  )
                ) : (
                  <div class="bottom_btn_container">
                    <button class="btn" style={{ backgroundColor: "grey" }}>
                      Next
                    </button>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </AppContext.Consumer>
      </div>
    );
  }
}

Health_insurance.contextType = AppContext;

export default withRouter(Health_insurance);
