import React,{useEffect} from 'react';
import Error from '../svg/error.svg';


function ErrorPage() {
    return(
        <div>
        <div style={{width:'100%',height:550,display:'flex',justifyContent:'center',alignItems:'center'}}>
            <div >
                <div style={{display:'flex',justifyContent:'center',paddingBottom:15}}>
                    <img src={Error} />
                </div>
                <h4 style={{textAlign:'center'}}>Error 404!</h4>
                <p style={{color:'grey',textAlign:'center'}}>Page not found</p>
            </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button class='btn' >Next</button>
        </div>

        </div>

    )
}

export default ErrorPage;