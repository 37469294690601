import React, { Component } from 'react';
import AppContext from '../context/AppContext'

class InsuranceProfile extends Component {
    state = {}
    render() {
        return (
            <div>
                <AppContext.Consumer>
                    {(context) => (
                        <React.Fragment>
                            <div className="header-area">
                                <div className=""><img className="back-icon" src={process.env.PUBLIC_URL + '/back.png'} /></div>
                                <div className="header-text">Your Insurance Profile</div>
                            </div>
                            <div className="main-page">
                                <div className="credit-score credit-score-value">{context.state.finalCreditScore}</div>
                                <div className="credit-title">Your Insurance Credit Score</div>
                                <div className="credit-name">Hey Akash</div>
                                <div className="credit-subtitle">Here's your insurance credit score.</div>
                                <div className="credit-details">Help us with some more details on your existing insurance to build up your insurance credit score.</div>
                                <div className="page-content">
                                    <button className="next-btn">Let's do it</button>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </AppContext.Consumer>
            </div>
        );
    }
}

export default InsuranceProfile;