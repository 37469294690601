import React from 'react'
const jwt = require("jsonwebtoken");


const crypto = require("crypto");
const secret = "54rapidos%$en34"; // Secret Key
const rounds = 9921;
const keySize = 32;
const algorithm = "aes-256-cbc";
const salt = crypto.createHash("sha1").update(secret).digest("hex");


function secure_data_encode(data) {

    try {
  
      let iv = crypto.randomBytes(16);
  
      let key = crypto.pbkdf2Sync(secret, salt, rounds, keySize, "sha512");
  
      let cipher = crypto.createCipheriv(algorithm, Buffer.from(key), iv);
  
      let encryptedData = Buffer.concat([
  
        cipher.update(JSON.stringify(data)),
  
        cipher.final(),
  
      ]);
  
      return iv.toString("base64") + ":" + encryptedData.toString("base64");
  
    } catch (err) {
  
      console.error(err);
  
      return false;
  
    }
  
  }
  
  function secure_data_decode(payload) {
   
    try {
      let textParts = payload.split(":");
  
      let iv = Buffer.from(textParts.shift(), "base64");
  
      let encryptedData = Buffer.from(textParts.join(":"), "base64");
  
      let key = crypto.pbkdf2Sync(secret, salt, rounds, keySize, "sha512");
  
      let decipher = crypto.createDecipheriv(algorithm, Buffer.from(key), iv);
  
      let decryptedData = decipher.update(encryptedData);
  
      decryptedData = Buffer.concat([decryptedData, decipher.final()]);
  
      return JSON.parse(decryptedData.toString());
  
          } catch (err) {
  
      console.error(err);
  
      return false;
  
    }
  
  }

//   let enc_dec_functions = {secure_data_encode, secure_data_decode}

export {secure_data_encode, secure_data_decode}