import React,{useEffect} from 'react';

export default function Sl() {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])
    return(
        <div>
                    <div className="header-area">
                        <div className=""><img className="back-icon" src={process.env.PUBLIC_URL + '/back.png'} /></div>
                        <div className="header-text">Your Insurance Report</div>
                        
                    </div>
                    <div className="recommendation_card_sk" >
                        <div style={{ paddingBottom: 15 }}>
                           
                        </div>
                        <div>
                            <div class="grid-container">
                                <div class="item2">
                                    <p>
                                        <span class='card_creditscroe'></span>
                                        <div style={{ color: 'white', opacity: 0.60, paddingLeft: 5 }}></div>
                                        {/* <div style={{ color: 'white'}}>INADEQUATELY INSURED</div> */}
                                    </p>
                                </div>
                            </div>
                           
                        </div>
                    </div>

                    

                     <div class='sk-circle-container'>
                        <div class='sk-circle'></div>
                        <div class='sk-circle'></div>
                        <div class='sk-circle'></div>
                     </div>

                    
                    <div class='sk-circle-card'></div>
                    <div class='card_sk' style={{margin:20}}>
                        <div class="row-card">
                            
                            <div className="recommend-card-title"></div>
                            {/* <div class="column-card-1">

                            </div>
                            <div class="column-card-2">
                                <div className="recommend-card-title">{items.recommendedPolicy}</div>
                                <div className="recommend-card-subtitle">
                                    <span className="subtitle-span">Health</span>
                                    <span className="subtitle-span">Hospitalisation</span>
                                </div>
                            </div> */}
                        </div>

                        <div class="row-card-1">
                            <div class="column-card-cover column-card-cover-right">
                                <div className="cover-text" style={{ marginLeft: '15px' }}><p></p></div>
                                <div className="recommend-card-amt-black"></div>
                            </div>
                            <div class="column-card-cover column-card-cover-right">
                                <div className="cover-text" style={{ marginLeft: '15px' }}><p></p></div>
                                <div className="recommend-card-amt-black"></div>
                            </div>
                        </div>

                        <div class="row-card-1">
                            <div class="column-card-cover column-card-cover-right">
                                <div className="cover-text" style={{ marginLeft: '15px' }}><p></p></div>
                                <div className="recommend-card-amt-black"></div>
                            </div>
                            <div class="column-card-cover column-card-cover-right">
                                <div className="cover-text" style={{ marginLeft: '15px' }}><p></p></div>
                                <div className="recommend-card-amt-black"></div>
                            </div>
                        </div>
                        
                        <br />
                        <br />

                       <div class="row-card-sk">
                           <div>
                                <div class="column-card-cover column-card-cover-right">
                                </div>
                                <br />
                                <div class="column-card-cover column-card-cover-right">
                                </div>
                            </div>

                             <div>
                                <div style={{borderRadius:10}} class="column-card-cover column-card-cover-right">
                                </div>
                                <br />
                               
                             </div>

                             <br />
                             <br />

                            <div>
                                <div class="column-card-cover column-card-cover-right">
                                </div>
                                <br />
                                <div class="column-card-cover column-card-cover-right">
                                </div>
                            </div>

                             <div>
                                <div style={{borderRadius:10}} class="column-card-cover column-card-cover-right">
                                </div>
                                <br />
                               
                             </div>
                            
                        </div>
                       
                        <br />
                        <br />
                        <div class="row-card-2" style={{marginTop:5}}>
                            <div className="column-card-premium">
                                <div class="column-card-cover column-card-cover-right">
                                        <div className="cover-text" style={{ marginLeft: '15px' }}><p></p></div>
                                        <div className="recommend-card-amt-black"></div>
                                    <div class="column-card-cover column-card-cover-right">
                                        <div className="cover-text" style={{ marginLeft: '15px' }}><p></p></div>
                                        <div className="recommend-card-amt-black"></div>
                                    </div>
                                </div>
                                
                            </div>
                            {/* <div class="column-card-3">
                                <div className="cover-text">Premium (GST inc.)</div>
                                {items.premium ? <div className="recommend-card-gst">₹ {items.premium}</div> : <div><div class="skeleton-h99tl35omha"></div></div>}

                            </div>
                            <div class="column-card-4">
                            </div> */}
                        </div>
                    </div>
                </div>

    )
}
