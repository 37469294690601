import React, { Component } from "react";
import AppContext from "../context/AppContext";
import { withRouter } from "react-router-dom";
import { scoring_logics } from "../scoredb/score_db";
import {
  maxcover_metro_lessthan45,
  mediclaimPercent,
  maxcover_metro_morethan45,
  maxcover_nonmetro_lessthan45,
  maxcover_nonmetro_morethan45,
  dependent_maxcover_metro_lessthan45,
  dependent_maxcover_metro_morethan45,
  dependent_maxcover_nonmetro_lessthan45,
  dependent_maxcover_nonmetro_morethan45,
  parents_in_law_nonmetro,
  parents_in_law_metro,
} from "../config/defaultVariables";
import { secure_data_decode, secure_data_encode } from "../config/enc_dec";
import { kompass_security_decode } from "../commonfunctions/commonfunction";

class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AppSteps: [1, 2, 3, 4, 5],
      OTP: "",
      lastPageTrack: "",
      redirect_to_recommendation: false,
      liveCreditScore: 0,
      income: {
        salary: 0,
      },
      libilities: 0,
      roles: {
        guest: false,
        agent: false,
      },
      score_board: {
        mediclaim: 0,
        super_top_up: 0,
        corporate: 0,
        cyber: 0,
        home_structure: 0,
        home_content: 0,
        rented_home_content: 0,
      },
      mobileNumber: "",
      DND: false,
      userInfo: {
        salary: null,
        age: null,
        ageInDifferentForm: null,
        name: null,
        city: null,
        selectedCity: null,
        gender: null,
        userId: null,
      },
      assets: {
        car: false,
        cars: [],
        bikes: [],
        homenumbers: 0,
        rentedhome: 0,
        bike_scooter: false,
        own_home: false,
        rented_home: false,
        valueofhome: "",
        valueofhomeContent: "",
        pricevalueofhomeContent: "",
        valueofhomeContent_rent: "",
      },
      citical_illness: {
        spouse: false,
        myself: false,
        kids: false,
        parents: false,
        suminsured: 100000,
      },
      corporateInsurance: {
        spouse: false,
        myself: false,
        kids: false,
        parents: false,
        suminsured: 0,
      },
      familyArray: [{ Relation: "Self", memberCode: "S" }],
      familyDetails: {
        spouse_input: false,
        kid_input: false,
        father_input: false,
        mother_input: false,
        fatherinlaw_input: false,
        motherinlaw_input: false,
        spouse: null,
        kids: [],
        mother: null,
        father: null,
        motherinlaw: null,
        fatherinlaw: null,
      },
      membersCovered: "Self",
      motor: {
        fourwheeler: "",
        twowheeler: "",
      },
      healthInsurance: {
        spouse: false,
        myself: false,
        kids: false,
        parents: false,
        suminsured: 0,
      },
      cyberInsurance: {
        suminsured: "",
      },
      homeInsurance: {
        Home_structure_cover: "",
        Home_content_cover: "",
        Home_rent_content_cover: "",
        Home_structure_content_cover: "",
        Not_have: "",
      },
      personalAccidentInsurance: {
        suminsured: "",
      },
      super_topup: {
        spouse: false,
        myself: false,
        kids: false,
        parents: false,
        suminsured: 100000,
      },
      policyTaken: {
        Health_insurance: false,
        Corporate_insurance: false,
        super_top_up_insurance: false,
        Personal_Accident_Insurance: false,
        critical_illness_insurance: false,
        Home_Rental: false,
        Corona_Kavach: false,
        Cyber: false,
        Optima: false,
        Motor: false,
        Bike: false,
      },
      ExistingMotorPolicies: {
        od_tp_1yr_opt: false,
        tp_1yr_opt: false,
        od_1yr_tp_3yr_opt: false,
        tp_3yr_opt: false,
        od_1yr_opt: false,
        od_tp_1yr: "",
        tp_1yr: "",
        od_1yr_tp_3yr: "",
        tp_3yr: "",
        od_1yr: "",
      },
      Recommendations: [],
      apiLimit: 0,
      nothavePolicyRecommendation: [],
      healthRecomendedPremium: {
        premium: 0,
      },
    };
    this.updateGlobalState = this.updateGlobalState.bind(this);
  }
  updateLastPage = (page) => {
    this.setState({ lastPageTrack: page });
  };

  //switch_account

  switch_account = () => {
    this.setState(
      {
        AppSteps: [1, 2, 3, 4, 5],
        OTP: "",
        lastPageTrack: "",
        redirect_to_recommendation: false,
        liveCreditScore: 0,
        income: {
          salary: 0,
        },
        libilities: 0,
        roles: {
          guest: false,
          agent: false,
        },
        score_board: {
          mediclaim: 0,
          super_top_up: 0,
          corporate: 0,
          cyber: 0,
          home_structure: 0,
          home_content: 0,
          rented_home_content: 0,
        },
        mobileNumber: "",
        DND: false,
        userInfo: {
          salary: 500000,
          age: "",
          ageInDifferentForm: "",
          name: "",
          city: "Metro",
          selectedCity: "",
          gender: "",
          userId: "",
        },
        assets: {
          car: false,
          cars: [],
          bikes: [],
          homenumbers: 0,
          rentedhome: 0,
          bike_scooter: false,
          own_home: false,
          rented_home: false,
          valueofhome: "",
          valueofhomeContent: "",
          pricevalueofhomeContent: "",
          valueofhomeContent_rent: "",
        },
        citical_illness: {
          spouse: false,
          myself: false,
          kids: false,
          parents: false,
          suminsured: 100000,
        },
        corporateInsurance: {
          spouse: false,
          myself: false,
          kids: false,
          parents: false,
          suminsured: 0,
        },
        familyArray: [],
        familyDetails: {
          spouse_input: false,
          kid_input: false,
          father_input: false,
          mother_input: false,
          fatherinlaw_input: false,
          motherinlaw_input: false,
          spouse: null,
          kids: [],
          mother: null,
          father: null,
          motherinlaw: null,
          fatherinlaw: null,
        },
        membersCovered: "Self",
        motor: {
          fourwheeler: "",
          twowheeler: "",
        },
        healthInsurance: {
          spouse: false,
          myself: false,
          kids: false,
          parents: false,
          suminsured: 100000,
        },
        cyberInsurance: {
          suminsured: 100000,
        },
        homeInsurance: {
          Home_structure_cover: "",
          Home_content_cover: "",
          Home_rent_content_cover: "",
          Home_structure_content_cover: "",
          Not_have: "",
        },
        personalAccidentInsurance: {
          suminsured: 100000,
        },
        super_topup: {
          spouse: false,
          myself: false,
          kids: false,
          parents: false,
          suminsured: 100000,
        },
        policyTaken: {
          Health_insurance: false,
          Corporate_insurance: false,
          super_top_up_insurance: false,
          Personal_Accident_Insurance: false,
          critical_illness_insurance: false,
          Home_Rental: false,
          Corona_Kavach: false,
          Cyber: false,
          Optima: false,
          Motor: false,
          Bike: false,
        },
        ExistingMotorPolicies: {
          od_tp_1yr_opt: false,
          tp_1yr_opt: false,
          od_1yr_tp_3yr_opt: false,
          tp_3yr_opt: false,
          od_1yr_opt: false,
          od_tp_1yr: "",
          tp_1yr: "",
          od_1yr_tp_3yr: "",
          tp_3yr: "",
          od_1yr: "",
        },
        Recommendations: [],
        apiLimit: 0,
        nothavePolicyRecommendation: [],
        healthRecomendedPremium: {
          premium: 0,
        },
      },
      () => {
        window.sessionStorage.removeItem("sessionToken");
        window.sessionStorage.removeItem("AdvisoryToken");
        this.props.history.push("login");

        window.gtag("event", "ACCOUNT_SWITCHED", {
          event_category: "RECOMMENDATION_DETAILS",
          event_label: "ACCOUNT SWITCHING",
        });
      }
    );
  };

  makeFamilyarray = (callback) => {
    if (this.state.familyDetails.spouse !== null) {
      let spouse = {
        gender: "F",
        memberCode: "W",
        age: this.state.familyDetails.spouse,
        relation: "Wife",
      };
      this.state.familyArray.push(spouse);
      if (this.state.membersCovered.includes(",Spouse")) {
      } else {
        this.state.membersCovered = this.state.membersCovered + ",Spouse";
      }
    }
    if (this.state.familyDetails.kids !== []) {
      this.state.familyDetails.kids.forEach((items) => {
        if (items.gender === "M") {
          let kids = {
            gender: "M",
            memberCode: "C",
            age: items.date,
            relation: "Son",
          };
          this.state.familyArray.push(kids);
        } else {
          let kids = {
            gender: "F",
            memberCode: "C",
            age: items.date,
            relation: "Daughter",
          };
          this.state.familyArray.push(kids);
        }
        if (this.state.membersCovered.includes(",Kids")) {
        } else {
          this.state.membersCovered = this.state.membersCovered + ",Kids";
        }
      });
    }
    if (this.state.familyDetails.mother !== null) {
      let mother = {
        gender: "F",
        memberCode: "W",
        age: this.state.familyDetails.mother,
        relation: "Mother",
      };
      this.state.familyArray.push(mother);
      if (this.state.membersCovered.includes("Parents")) {
      } else {
        this.state.membersCovered = this.state.membersCovered + ",Parents";
      }
    }
    if (this.state.familyDetails.father !== null) {
      let father = {
        gender: "M",
        membersCode: "S",
        age: this.state.familyDetails.father,
        relation: "Father",
      };
      this.state.familyArray.push(father);
      if (this.state.membersCovered.includes("Parents")) {
      } else {
        this.state.membersCovered = this.state.membersCovered + ",Parents";
      }
    }
    if (this.state.familyDetails.fatherinlaw !== null) {
      let fatherinlaw = {
        gender: "M",
        memberCode: "W",
        age: this.state.familyDetails.fatherinlaw,
        relation: "Father-in-law",
      };
      this.state.familyArray.push(fatherinlaw);
    }
    if (this.state.familyDetails.motherinlaw !== null) {
      let motherinlaw = {
        gender: "F",
        membersCode: "C",
        age: this.state.familyDetails.motherinlaw,
        relation: "Mother-in-law",
      };
      this.state.familyArray.push(motherinlaw);
    }
    if (callback) {
      callback();
    }
  };
  async updateLocalStorage() {
    // localStorage.setItem("context", JSON.stringify(this.state));
    localStorage.setItem("context", JSON.stringify(await secure_data_encode(this.state)));
  }
  componentDidUpdate() {
    setTimeout(() => {
      this.updateLocalStorage();
    }, 1000);
  }
  async componentDidMount() {
    if (this.checkSession()) {
      const context = localStorage.getItem("context")
      console.log('context',context)
      const localStorageContext = await secure_data_decode(context)
      console.log('contextDec',localStorageContext)

      // let localStorageContext = await JSON.parse(
      //   contextDec
      // );
      this.props.history.push("/personal_details");
      if (localStorageContext) {
        await this.setState(localStorageContext, () => {
          if (localStorageContext.lastPageTrack === "/recommendations") {
            if (
              this.state.nothavePolicyRecommendation.length !== 0 ||
              this.state.Recommendations.length !== 0
            ) {
              this.props.history.push(this.state.lastPageTrack);
            }
          } else {
            this.props.history.push(this.state.lastPageTrack);
          }
        });
      } else {
        this.props.history.push("/onboarding");
      }
    } else {
      this.props.history.push("/onboarding");
    }
  }

  checkSession = () => {
    var session = window.sessionStorage.getItem("AdvisoryToken");
    if (session) {
      return true;
    } else {
      return false;
    }
  };
  updateLibilities = (data) => {
    this.setState({ libilities: data.Libility });
  };

  filteredRecommendations = (subcategory) => {
    const filteredRecommendations = this.state.Recommendations.filter(
      (recommendation) => recommendation.subcategory != subcategory
    );
    this.setState({
      Recommendations: filteredRecommendations,
    });
  };

  updateRecommendationsStateOnBack = (data) => {
    // healthAbortController.abort()
    this.setState({ Recommendations: data });
  };

  updateMobileNumber = (data) => {
    this.setState({
      mobileNumber: data,
    });
  };

  updateDNDstatus = (data) => {
    this.setState({
      DND: data,
    });
  };

  updateMotorExistingPolicies = (data) => {
    this.setState({ ExistingMotorPolicies: data });
  };
  updatePersoanlInfo = (data, callback) => {
    let date = new Date().getFullYear();
    this.setState(
      {
        userInfo: {
          salary: data.salary,
          age: data.dob ? data.dob : data.age,
          ageInDifferentForm: data.ageInDifferentForm
            ? data.ageInDifferentForm
            : data.age,
          name: data.name !== "" ? data.name : this.state.userInfo.name,
          city: data.city !== "" ? data.city : this.state.userInfo.city,
          selectedCity:
            data.selectedCity !== ""
              ? data.selectedCity
              : this.state.userInfo.selectedCity,
          gender: data.gender !== "" ? data.gender : this.state.userInfo.gender,
          userId: data.userid,
        },
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  updateHealthInsurance = (data, page) => {
    this.setState({
      healthInsurance: {
        spouse: data.spouse,
        myself: data.myself,
        kids: data.kids,
        parents: data.parents,
        suminsured: data.suminsured,
      },
    });
    if (page === "Corporate_insurance") {
      this.props.history.push("/corporate-insurance");
    } else if (page === "Super_top_up_insurance") {
      this.props.history.push("/super-topup");
    } else if (page === "Personal_Accident") {
      this.props.history.push("/personal-accident-insurance");
    } else if (page === "Critical_insurance") {
      this.props.history.push("/critical-illness");
    } else if (page === "Motor") {
      this.props.history.push("/Motor_details");
    } else if (page === "Bike") {
      this.props.history.push("/Twowheeler_motor");
    } else if (page === "Home_Rental") {
      this.props.history.push("/home-insurance");
    } else if (page === "Cyber") {
      this.props.history.push("/cyber-insurance");
    } else if (page === "Recommendations") {
      this.HealthRecommendations(page);
    }

    if (page !== "Recommendations" && page !== "Corporate_insurance") {
      this.HealthRecommendations(page);
    }
  };
  updateHealthInsuranceState = (data) => {
    this.setState({
      healthInsurance: {
        spouse: data.spouse,
        myself: data.myself,
        kids: data.kids,
        parents: data.parents,
        suminsured: data.suminsured,
      },
    });
  };

  updateCorporateInsuranceState = (data) => {
    this.setState({
      corporateInsurance: {
        spouse: data.spouse,
        myself: data.myself,
        kids: data.kids,
        parents: data.parents,
        suminsured: data.suminsured,
      },
    });
  };

  updateSuperTopUpState = (data) => {
    this.setState({
      super_topup: {
        spouse: data.spouse,
        myself: data.myself,
        kids: data.kids,
        parents: data.parents,
        suminsured: data.suminsured,
      },
    });
  };
  updateCriticalInsuranceState = (data) => {
    this.setState({
      citical_illness: {
        spouse: data.spouse,
        myself: data.myself,
        kids: data.kids,
        parents: data.parents,
        suminsured: data.suminsured,
      },
    });
  };

  updatePersonalAccState = (data) => {
    this.setState({
      personalAccidentInsurance: {
        spouse: data.spouse,
        myself: data.myself,
        kids: data.kids,
        parents: data.parents,
        suminsured: data.suminsured,
      },
    });
  };

  // fetchdata = () => {
  //   const result = axios.post('/calculateHealthSurakshaIndividualPremium')
  //     .then(res => {
  //       console.log('response', res.data)
  //       this.setState({
  //         healthRecomendedPremium: {
  //           premium: res.data.Policy_Details.TotalPremium
  //         },
  //       })
  //     })

  // }

  updateSuperTopUp = (data, page) => {
    this.setState({
      super_topup: {
        spouse: data.spouse,
        myself: data.myself,
        kids: data.kids,
        parents: data.parents,
        suminsured: data.suminsured,
      },
    });
    if (page === "Personal_Accident") {
      this.props.history.push("/personal-accident-insurance");
    } else if (page === "Critical_insurance") {
      this.props.history.push("/critical-illness");
    } else if (page === "Motor") {
      this.props.history.push("/Motor_details");
    } else if (page === "Bike") {
      this.props.history.push("/Twowheeler_motor");
    } else if (page === "Home_Rental") {
      this.props.history.push("/home-insurance");
    } else if (page === "Cyber") {
      this.props.history.push("/cyber-insurance");
    } else if (page === "Recommendations") {
      this.props.history.push("/recommendations");
    }
  };
  updatePersonalAccidentSumInsured = (data, page) => {
    this.setState({ personalAccidentInsurance: data });
    if (page === "Critical_insurance") {
      this.props.history.push("/critical-illness");
    } else if (page === "Motor") {
      this.props.history.push("/Motor_details");
    } else if (page === "Bike") {
      this.props.history.push("/Twowheeler_motor");
    } else if (page === "Home_Rental") {
      this.props.history.push("/home-insurance");
    } else if (page === "Cyber") {
      this.props.history.push("/cyber-insurance");
    } else if (page === "Recommendations") {
      this.PersonalAccidentRecommendations(page);
    }
    if (page !== "Recommendations") {
      this.PersonalAccidentRecommendations(page);
    }
  };
  updateCritical_illness = (data, page) => {
    this.setState({
      citical_illness: {
        spouse: data.spouse,
        myself: data.myself,
        kids: data.kids,
        parents: data.parents,
        suminsured: data.suminsured,
      },
    });
    if (page === "Motor") {
      this.props.history.push("/Motor_details");
    } else if (page === "Bike") {
      this.props.history.push("/Twowheeler_motor");
    } else if (page === "Home_Rental") {
      this.props.history.push("/home-insurance");
    } else if (page === "Cyber") {
      this.props.history.push("/cyber-insurance");
    } else if (page === "Recommendations") {
      this.CriticalIllnessRecommendations(page);
    }
    if (page !== "Recommendations") {
      this.CriticalIllnessRecommendations(page);
    }
  };

  updateMotorState = (data) => {
    this.setState({ motor: data });
  };

  updateRoles = (data) => {
    this.setState({
      roles: {
        guest: data.guest,
        agent: data.agent,
      },
    });
    if (data.agent === true) {
      this.props.history.push("/income");
    } else if (data.guest === true) {
      this.props.history.push("/onboarding");
    }
  };

  updateHome_insurance = (data, page) => {
    this.setState({
      homeInsurance: data,
    });
  };

  updateCyberStateSumInsured = (data) => {
    this.setState({ cyberInsurance: data });
  };

  updateCyberSumInsured = (data, page) => {
    this.setState({ cyberInsurance: data });
    if (page === "Recommendations") {
      this.props.history.push("/recommendations");
    }
  };

  updatePolicyTypes = (data, page) => {
    this.setState({
      policyTaken: {
        Health_insurance: data.Health_insurance,
        Corporate_insurance: data.Corporate_insurance,
        super_top_up_insurance: data.super_top_up_insurance,
        Personal_Accident_Insurance: data.Personal_Accident_Insurance,
        critical_illness_insurance: data.critical_illness_insurance,
        Home_Rental: data.Home_Rental,
        Corona_Kavach: data.Corona_Kavach,
        Cyber: data.Cyber,
        Motor: data.Motor,
        Bike: data.Bike,
      },
    });
    if (page === "Health_insurance") {
      this.props.history.push("/health-insurance");
    } else if (page === "Super_top_up_insurance") {
      this.props.history.push("/super-topup");
    } else if (page === "Personal_Accident") {
      this.props.history.push("/personal-accident-insurance");
    } else if (page === "Critical_insurance") {
      this.props.history.push("/critical-illness");
    } else if (page === "Bike") {
      this.props.history.push("/Twowheeler_motor");
    } else if (page === "Recommendations") {
      this.props.history.push("/recommendations");
    }
  };

  updateCorporate_insurance = (data, page) => {
    this.setState({
      corporateInsurance: {
        spouse: data.spouse,
        myself: data.myself,
        kids: data.kids,
        parents: data.parents,
        suminsured: parseInt(data.suminsured),
      },
    });
    if (page === "Super_top_up_insurance") {
      this.props.history.push("/super-topup");
    } else if (page === "Personal_Accident") {
      this.props.history.push("/personal-accident-insurance");
    } else if (page === "Critical_insurance") {
      this.props.history.push("/critical-illness");
    } else if (page === "Motor") {
      this.props.history.push("/Motor_details");
    } else if (page === "Bike") {
      this.props.history.push("/Twowheeler_motor");
    } else if (page === "Home_Rental") {
      this.props.history.push("/home-insurance");
    } else if (page === "Cyber") {
      this.props.history.push("/cyber-insurance");
    } else if (page === "Recommendations") {
    }
    this.HealthRecommendations(page);
  };

  updateFamilyDetails = (data) => {
    this.setState({
      familyDetails: {
        spouse_input: data.spouse_input,
        spouse_input: data.spouse_input,
        father_input: data.father_input,
        mother_input: data.mother_input,
        fatherinlaw_input: data.fatherinlaw_input,
        motherinlaw_input: data.motherinlaw_input,
        spouse: data.spouse,
        kids: data.kids,
        mother: data.mother,
        father: data.father,
        motherinlaw: data.motherinlaw,
        fatherinlaw: data.fatherinlaw,
      },
    });
  };

  updateGlobalState(id, data) {
    if (id === "critical_illness") {
      this.setState({
        citical_illness: data,
      });
    } else if (id === "income") {
      this.setState({
        userInfo: {
          salary: data.salary,
          city: "Metro",
          name: "Rahul",
        },
      });
    } else if (id === "assets") {
      this.setState({
        assets: data,
      });
    } else if (id === "corporate_insurance") {
      this.setState({
        corporateInsurance: data,
      });
    } else if (id === "family_details") {
      this.setState({
        familyDetails: data,
      });
    } else if (id === "health_insurance") {
      this.setState({
        healthInsurance: data,
      });
    } else if (id === "cyber_insurance") {
      this.setState({
        cyberInsurance: data,
      });
    } else if (id === "home_insurance") {
      this.setState({
        homeInsurance: data,
      });
    } else if (id === "personal_accident_insurance") {
      this.setState({
        personalAccidentInsurance: data,
      });
    } else if (id === "super_topup") {
      this.setState({
        super_topup: data,
      });
    }
  }

  updateCreditScore = async (value) => {
    this.setState({ liveCreditScore: value }, () => {
      return;
    });
    // if (status === "default") {
    //   this.setState({
    //     liveCreditScore: scoring_logics.default_score_calculation(),
    //     score_board: {
    //       mediclaim: 0,
    //       super_top_up: 0,
    //       cyber: 0,
    //       home_structure: 0,
    //       home_content: 0,
    //       rented_home_content: 0,
    //     },
    //   });
    //   return;
    // }
    // let score_board = {
    //   mediclaim: this.state.score_board.mediclaim,
    //   super_top_up: this.state.score_board.super_top_up,
    //   cyber: this.state.score_board.cyber,
    //   home_structure: this.state.score_board.home_structure,
    //   home_content: this.state.score_board.home_content,
    //   rented_home_content: this.state.score_board.rented_home_content,
    // };
    // if (status === "Add") {
    //   score_board[type] = scoring_logics[stage][type];
    //   this.setState(
    //     {
    //       liveCreditScore:
    //         this.state.liveCreditScore + scoring_logics[stage][type],
    //       score_board: score_board,
    //     },
    //     () =>
    //       console.log(this.state.liveCreditScore, scoring_logics[stage][type])
    //   );
    // } else {
    //   score_board[type] = score_board[type] - scoring_logics[stage][type];
    //   this.setState(
    //     {
    //       liveCreditScore:
    //         this.state.liveCreditScore - scoring_logics[stage][type],
    //       score_board: score_board,
    //     },
    //     () =>
    //       console.log(this.state.liveCreditScore, scoring_logics[stage][type])
    //   );
    // }
  };

  ageIndifference = (format) => {
    let date = new Date().getFullYear();
    let ageInFormate = parseInt(date) - parseInt(format.split("/")[2]);
    return ageInFormate;
  };

  superTopUpmax_cover = (cover) => {
    let max_cover;
    if (cover < 500000) {
      max_cover =
        500000 +
          parseInt(this.state.healthInsurance.suminsured) +
          parseInt(this.state.corporateInsurance.suminsured) <=
          500000
          ? 0
          : parseInt(this.state.corporateInsurance.suminsured);
    } else if (cover < 1000000) {
      max_cover =
        1000000 +
          parseInt(this.state.healthInsurance.suminsured) +
          parseInt(this.state.corporateInsurance.suminsured) <=
          500000
          ? 0
          : parseInt(this.state.corporateInsurance.suminsured);
    } else if (cover >= 1000000 && cover <= 1500000) {
      max_cover =
        1500000 +
          parseInt(this.state.healthInsurance.suminsured) +
          parseInt(this.state.corporateInsurance.suminsured) <=
          500000
          ? 0
          : parseInt(this.state.corporateInsurance.suminsured);
    } else if (cover >= 1500000 && cover <= 2000000) {
      max_cover =
        2000000 +
          parseInt(this.state.healthInsurance.suminsured) +
          parseInt(this.state.corporateInsurance.suminsured) <=
          500000
          ? 0
          : parseInt(this.state.corporateInsurance.suminsured);
    } else {
      max_cover =
        2500000 +
          parseInt(this.state.healthInsurance.suminsured) +
          parseInt(this.state.corporateInsurance.suminsured) <=
          500000
          ? 0
          : parseInt(this.state.corporateInsurance.suminsured);
    }
    return max_cover;
  };

  ageInDifferentFormFun = (date) => {
    let currentDate = new Date().getFullYear();
    let memberDate = date.split("/")[2];
    let ageinFormate = parseInt(currentDate) - parseInt(memberDate);
    return ageinFormate;
  };

  HealthRecommendations = (page) => {
    let corporate = parseInt(this.state.corporateInsurance.suminsured);
    if (this.state.userInfo.city === "Metro") {
      if (
        this.state.familyDetails.spouse !== null &&
        (this.state.familyDetails.father !== null ||
          this.state.familyDetails.mother !== null ||
          this.state.familyDetails.motherinlaw !== null ||
          this.state.familyDetails.fatherinlaw !== null)
      ) {
        //all family members
        if (
          this.state.familyDetails.motherinlaw !== null &&
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Family
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          let PolicyOwner_age;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) >
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.fatherinlaw
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.motherinlaw
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) <
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            family.push({
              gender: "M",
              memberCode: "H",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Husband",
            });
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Self",
            });
          } else {
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Self",
            });
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Wife",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            let Recommendation = {
              recommededCover: maxCover,
              PolicyOwner: PolicyOwner_age,
              recommendedPolicy: "Optima Secure - In Laws",
              membersCovered: "Father-In-Law, Mother-In-Law",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              premium: null,
              type: "Family",
              family: family,
              PolicyCode: "g",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1500000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }

          //highest age memeber
        } else if (
          this.state.familyDetails.motherinlaw !== null ||
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: maxCover,
              recommendedPolicy: "Optima Secure - In-Laws",
              membersCovered:
                this.state.familyDetails.motherinlaw !== null
                  ? "Mother-In-Law"
                  : "Father-In-Law",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner:
                this.state.familyDetails.motherinlaw !== null
                  ? this.ageIndifference(this.state.familyDetails.motherinlaw)
                  : this.ageIndifference(this.state.familyDetails.fatherinlaw),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                },
              ],
              type: "Individual",
              PolicyCode: "g",
            };
            this.state.Recommendations.push(Recommendation);
          }
          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered:
                    this.state.familyDetails.motherinlaw !== null
                      ? "Mother-In-Law"
                      : "Father-In-Law",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                  type: "Individual",
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.motherinlaw !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.motherinlaw
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.fatherinlaw
                          ),
                    },
                  ],
                  PolicyCode: "m_g",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover >= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered:
                    this.state.familyDetails.motherinlaw !== null
                      ? "Mother-In-Law"
                      : "Father-In-Law",
                  category: "Health",
                  suminsured: 1500000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                  PolicyCode: "m_g",
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.motherinlaw !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.motherinlaw
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.fatherinlaw
                          ),
                    },
                  ],
                  type: "Individual",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }

            //highest age memeber
          }
        }

        // only mother and father are

        if (
          this.state.familyDetails.mother !== null &&
          this.state.familyDetails.father !== null
        ) {
          // optima family
          let PolicyOwner_age;
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) >
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.father
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.mother
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) <
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Self",
            });
            family.push({
              gender: "M",
              memberCode: "H",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              relation: "Husband",
            });
          } else {
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              relation: "Self",
            });
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Wife",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered: "Father, Mother",
              category: "Health",
              suminsured: maxCover,
              type: "Family",
              subcategory: "Optima_Restore",
              PolicyOwner: PolicyOwner_age,
              premium: null,
              family: family,
              PolicyCode: "p",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Father, Mother",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_p",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Mother & Father",
                  category: "Health",
                  suminsured: 1500000,
                  PolicyOwner: PolicyOwner_age,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  type: "Family",
                  PolicyCode: "m_p",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }

          //highest age memeber
        } else if (
          this.state.familyDetails.mother !== null ||
          this.state.familyDetails.father !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered:
                this.state.familyDetails.mother !== null ? "Mother" : "Father",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              type: "Individual",
              PolicyOwner:
                this.state.familyDetails.mother !== null
                  ? this.ageIndifference(this.state.familyDetails.mother)
                  : this.ageIndifference(this.state.familyDetails.father),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                },
              ],
              PolicyCode: "p",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (
            (this.state.policyTaken.super_top_up_insurance === false &&
              this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured > 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured < 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured > 1000000)
          ) {
            if (maxCover <= 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up - Parents",
                membersCovered:
                  this.state.familyDetails.mother !== null
                    ? "Mother"
                    : "Father",
                category: "Health",
                suminsured: 1000000,
                subcategory: "Super_Top_Up",
                premium: null,
                PolicyOwner:
                  this.state.familyDetails.mother !== null
                    ? this.ageIndifference(this.state.familyDetails.mother)
                    : this.ageIndifference(this.state.familyDetails.father),
                type: "Individual",
                family: [
                  {
                    memberCode: "S",
                    relation: "Self",
                    age:
                      this.state.familyDetails.mother !== null
                        ? this.ageIndifference(this.state.familyDetails.mother)
                        : this.ageIndifference(this.state.familyDetails.father),
                  },
                ],
                PolicyCode: "m_p",
              };
              this.state.Recommendations.push(superTopUp_Recommendation);
            } else if (maxCover > 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up -Parents",
                membersCovered:
                  this.state.familyDetails.mother !== null
                    ? "Mother"
                    : "Father",
                category: "Health",
                suminsured: 1500000,
                type: "Individual",
                PolicyOwner:
                  this.state.familyDetails.mother !== null
                    ? this.ageIndifference(this.state.familyDetails.mother)
                    : this.ageIndifference(this.state.familyDetails.father),
                subcategory: "Super_Top_Up",
                premium: null,
                family: [
                  {
                    memberCode: "S",
                    relation: "Self",
                    age:
                      this.state.familyDetails.mother !== null
                        ? this.ageIndifference(this.state.familyDetails.mother)
                        : this.ageIndifference(this.state.familyDetails.father),
                  },
                ],
                PolicyCode: "m_p",
              };
              this.state.Recommendations.push(superTopUp_Recommendation);
            }
          }

          //highest age memeber
        }

        //only spouse kids and self

        if (
          this.state.familyDetails.spouse !== null ||
          this.state.familyDetails.kids.length !== 0
        ) {
          // optima family
          let maxCover;
          if (parseInt(this.state.userInfo.ageInDifferentForm) >= 45) {
            maxCover =
              dependent_maxcover_metro_morethan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          } else {
            maxCover =
              dependent_maxcover_metro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          }
          let family = [];
          family.push({
            gender: "M",
            memberCode: "S",
            age: this.state.userInfo.ageInDifferentForm,
            relation: "Self",
          });
          if (this.state.familyDetails.spouse !== null) {
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.spouse),
              relation: "Wife",
            });
          }
          if (this.state.familyDetails.kids.length !== 0) {
            this.state.familyDetails.kids.forEach((items) => {
              if (items.gender === "M") {
                family.push({
                  gender: "M",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Son",
                });
              } else if (items.gender === "F") {
                family.push({
                  gender: "F",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Daughter",
                });
              }
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Family",
              membersCovered:
                this.state.familyDetails.spouse !== null &&
                  this.state.familyDetails.kids.length !== 0
                  ? "Self, Spouse & Kids"
                  : this.state.familyDetails.spouse !== null
                    ? "Self , Spouse"
                    : "Self , Kids",
              category: "Health",
              type: "Family",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner: this.state.userInfo.ageInDifferentForm,
              premium: null,
              family: family,
              PolicyCode: "s",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered:
                    this.state.familyDetails.spouse !== null &&
                      this.state.familyDetails.kids.length !== 0
                      ? "Self, Spouse & Kids"
                      : this.state.familyDetails.spouse !== null
                        ? "Self , Spouse"
                        : "Self , Kids",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered:
                    this.state.familyDetails.spouse !== null &&
                      this.state.familyDetails.kids.length !== 0
                      ? "Self, Spouse & Kids"
                      : this.state.familyDetails.spouse !== null
                        ? "Self , Spouse"
                        : "Self , Kids",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Family",
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }

            //highest age memeber
          }
        }
      } else if (
        this.state.familyDetails.spouse === null &&
        (this.state.familyDetails.father ||
          this.state.familyDetails.mother ||
          this.state.familyDetails.motherinlaw ||
          this.state.familyDetails.fatherinlaw)
      ) {
        //all family members
        if (
          this.state.familyDetails.motherinlaw !== null &&
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Family
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          let PolicyOwner_age;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) >
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.fatherinlaw
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.motherinlaw
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) <
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            family.push({
              gender: "M",
              memberCode: "H",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Husband",
            });
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Self",
            });
          } else {
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Wife",
            });
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Self",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: maxCover,
              recommendedPolicy: "Optima Secure - In Laws",
              membersCovered: "Father-In-Law, Mother-In-Law",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore - In-Laws",
              PolicyOwner: PolicyOwner_age,
              premium: null,
              family: family,
              PolicyCode: "g",
            };
            this.state.Recommendations.push(Recommendation);
          }
          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1500000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }

          //highest age memeber
        } else if (
          this.state.familyDetails.motherinlaw !== null ||
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: maxCover,
              recommendedPolicy: "Optima Secure - In-Laws",
              membersCovered:
                this.state.familyDetails.motherinlaw !== null
                  ? "Mother-In-Law"
                  : "Father-In-Law",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner:
                this.state.familyDetails.motherinlaw !== null
                  ? this.ageIndifference(this.state.familyDetails.motherinlaw)
                  : this.ageIndifference(this.state.familyDetails.fatherinlaw),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                },
              ],
              PolicyCode: "g",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (
                this.state.healthInsurance.suminsured >= 500000 ||
                parseInt(this.state.healthInsurance.suminsured) === 0
              ) {
                if (maxCover <= 1000000) {
                  let superTopUp_Recommendation = {
                    recommededCover: maxCover,
                    recommendedPolicy: "Medisure Top-Up - In Laws",
                    membersCovered:
                      this.state.familyDetails.motherinlaw !== null
                        ? "Mother-In-Law"
                        : "Father-In-Law",
                    category: "Health",
                    suminsured: 1000000,
                    subcategory: "Super_Top_Up",
                    premium: null,
                    PolicyOwner:
                      this.state.familyDetails.motherinlaw !== null
                        ? this.ageIndifference(
                          this.state.familyDetails.motherinlaw
                        )
                        : this.ageIndifference(
                          this.state.familyDetails.fatherinlaw
                        ),
                    type: "Individual",
                    family: [
                      {
                        memberCode: "S",
                        relation: "Self",
                        age:
                          this.state.familyDetails.motherinlaw !== null
                            ? this.ageIndifference(
                              this.state.familyDetails.motherinlaw
                            )
                            : this.ageIndifference(
                              this.state.familyDetails.fatherinlaw
                            ),
                      },
                    ],
                    PolicyCode: "m_g",
                  };
                  this.state.Recommendations.push(superTopUp_Recommendation);
                } else if (maxCover >= 1000000) {
                  let superTopUp_Recommendation = {
                    recommededCover: maxCover,
                    recommendedPolicy: "Medisure Top-Up - In Laws",
                    membersCovered:
                      this.state.familyDetails.motherinlaw !== null
                        ? "Mother-In-Law"
                        : "Father-In-Law",
                    category: "Health",
                    suminsured: 1500000,
                    subcategory: "Super_Top_Up",
                    premium: null,
                    PolicyOwner:
                      this.state.familyDetails.motherinlaw !== null
                        ? this.ageIndifference(
                          this.state.familyDetails.motherinlaw
                        )
                        : this.ageIndifference(
                          this.state.familyDetails.fatherinlaw
                        ),
                    PolicyCode: "m_g",
                    family: [
                      {
                        memberCode: "S",
                        relation: "Self",
                        age:
                          this.state.familyDetails.motherinlaw !== null
                            ? this.ageIndifference(
                              this.state.familyDetails.motherinlaw
                            )
                            : this.ageIndifference(
                              this.state.familyDetails.fatherinlaw
                            ),
                      },
                    ],
                    type: "Individual",
                  };
                  this.state.Recommendations.push(superTopUp_Recommendation);
                }
              }
            }

            //highest age memeber
          }
        }

        // only mother and father are

        if (
          this.state.familyDetails.mother !== null &&
          this.state.familyDetails.father !== null
        ) {
          // optima family
          let PolicyOwner_age;
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) >
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.father
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.mother
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) <
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Self",
            });
            family.push({
              gender: "M",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              memberCode: "H",
              relation: "Husband",
            });
          } else {
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              relation: "Self",
            });
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Wife",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered: "Father, Mother",
              category: "Health",
              suminsured: maxCover,
              type: "Family",
              subcategory: "Optima_Restore",
              PolicyOwner: PolicyOwner_age,
              premium: null,
              family: family,
              PolicyCode: "p",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Father, Mother",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_p",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Mother & Father",
                  category: "Health",
                  suminsured: 1500000,
                  PolicyOwner: PolicyOwner_age,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  type: "Family",
                  PolicyCode: "m_p",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }
          //highest age memeber
        } else if (
          this.state.familyDetails.mother !== null ||
          this.state.familyDetails.father !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered:
                this.state.familyDetails.mother !== null ? "Mother" : "Father",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              type: "Individual",
              PolicyOwner:
                this.state.familyDetails.mother !== null
                  ? this.ageIndifference(this.state.familyDetails.mother)
                  : this.ageIndifference(this.state.familyDetails.father),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                },
              ],
              PolicyCode: "p",
            };
            this.state.Recommendations.push(Recommendation);
          }
          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (
                this.state.healthInsurance.suminsured >= 500000 ||
                parseInt(this.state.healthInsurance.suminsured) === 0
              ) {
                if (maxCover <= 1000000) {
                  let superTopUp_Recommendation = {
                    recommededCover: maxCover,
                    recommendedPolicy: "Medisure Top-Up - Parents",
                    membersCovered:
                      this.state.familyDetails.mother !== null
                        ? "Mother"
                        : "Father",
                    category: "Health",
                    suminsured: 1000000,
                    subcategory: "Super_Top_Up",
                    premium: null,
                    PolicyOwner:
                      this.state.familyDetails.mother !== null
                        ? this.ageIndifference(this.state.familyDetails.mother)
                        : this.ageIndifference(this.state.familyDetails.father),
                    type: "Individual",
                    family: [
                      {
                        memberCode: "S",
                        relation: "Self",
                        age:
                          this.state.familyDetails.mother !== null
                            ? this.ageIndifference(
                              this.state.familyDetails.mother
                            )
                            : this.ageIndifference(
                              this.state.familyDetails.father
                            ),
                      },
                    ],
                    PolicyCode: "m_p",
                  };
                  this.state.Recommendations.push(superTopUp_Recommendation);
                } else if (maxCover > 1000000) {
                  let superTopUp_Recommendation = {
                    recommededCover: maxCover,
                    recommendedPolicy: "Medisure Top-Up -Parents",
                    membersCovered:
                      this.state.familyDetails.motherinlaw !== null
                        ? "Mother"
                        : "Father",
                    category: "Health",
                    suminsured: 1500000,
                    type: "Individual",
                    PolicyOwner:
                      this.state.familyDetails.mother !== null
                        ? this.ageIndifference(this.state.familyDetails.mother)
                        : this.ageIndifference(this.state.familyDetails.father),
                    subcategory: "Super_Top_Up",
                    premium: null,
                    family: [
                      {
                        memberCode: "S",
                        relation: "Self",
                        age:
                          this.state.familyDetails.mother !== null
                            ? this.ageIndifference(
                              this.state.familyDetails.mother
                            )
                            : this.ageIndifference(
                              this.state.familyDetails.father
                            ),
                      },
                    ],
                    PolicyCode: "m_p",
                  };
                  this.state.Recommendations.push(superTopUp_Recommendation);
                }
              }
            }
          }

          //highest age memeber
        }
        if (this.state.userInfo.ageInDifferentForm) {
          let family = [];
          family.push({
            gender: "M",
            memberCode: "S",
            age: this.state.userInfo.ageInDifferentForm,
            relation: "Self",
          });
          if (this.state.familyDetails.kids.length !== 0) {
            this.state.familyDetails.kids.forEach((items) => {
              if (items.gender === "M") {
                family.push({
                  gender: "M",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Son",
                });
              } else if (items.gender === "F") {
                family.push({
                  gender: "F",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Daughter",
                });
              }
            });
          }
          let maxCover;
          if (parseInt(this.state.userInfo.ageInDifferentForm) >= 45) {
            maxCover =
              family.length <= 1
                ? maxcover_metro_morethan45 -
                parseInt(this.state.healthInsurance.suminsured) -
                corporate
                : dependent_maxcover_metro_morethan45 -
                parseInt(this.state.healthInsurance.suminsured) -
                corporate;
          } else {
            maxCover =
              family.length <= 1
                ? maxcover_metro_morethan45 -
                parseInt(this.state.healthInsurance.suminsured) -
                corporate
                : dependent_maxcover_metro_morethan45 -
                parseInt(this.state.healthInsurance.suminsured) -
                corporate;
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            console.log(maxCover);
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure",
              membersCovered: family.length > 1 ? "Self & Kids" : "Self",
              category: "Health",
              suminsured: maxCover,
              type: family.length > 1 ? "Family" : "Individual",
              subcategory: "Optima_Restore",
              PolicyOwner: this.state.userInfo.ageInDifferentForm,
              premium: null,
              family: family,
              PolicyCode: "s",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up",
                  membersCovered: "Self",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  type: "Individual",
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up",
                  membersCovered: "Self",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Individual",
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }
        }
      } else if (
        this.state.familyDetails.spouse ||
        this.state.familyDetails.kids.length > 0
      ) {
        if (
          this.state.familyDetails.spouse !== null ||
          this.state.familyDetails.kids.length !== 0
        ) {
          // optima family
          let maxCover;
          if (parseInt(this.state.userInfo.ageInDifferentForm) >= 45) {
            maxCover =
              dependent_maxcover_metro_morethan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          } else {
            maxCover =
              dependent_maxcover_metro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
            console.log(
              maxCover,
              dependent_maxcover_metro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate
            );
          }

          let family = [];
          family.push({
            gender: this.state.userInfo.gender,
            memberCode: "S",
            age: this.state.userInfo.ageInDifferentForm,
            relation: "Self",
          });
          if (this.state.familyDetails.spouse !== null) {
            family.push({
              gender: this.state.userInfo.gender === "M" ? "F" : "M",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.spouse),
              relation: "Wife",
            });
          }
          if (this.state.familyDetails.kids.length !== 0) {
            this.state.familyDetails.kids.forEach((items) => {
              if (items.gender === "M") {
                family.push({
                  gender: "M",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Son",
                });
              } else if (items.gender === "F") {
                family.push({
                  gender: "F",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Daughter",
                });
              }
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Family",
              membersCovered:
                this.state.familyDetails.spouse !== null &&
                  this.state.familyDetails.kids.length !== 0
                  ? "Self, Spouse & Kids"
                  : this.state.familyDetails.spouse !== null
                    ? "Self , Spouse"
                    : "Self , Kids",
              category: "Health",
              type: "Family",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner: this.state.userInfo.ageInDifferentForm,
              premium: null,
              family: family,
              PolicyCode: "s",
            };
            this.state.Recommendations.push(Recommendation);
          }
          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered:
                    this.state.familyDetails.spouse !== null &&
                      this.state.familyDetails.kids.length !== 0
                      ? "Self, Spouse & Kids"
                      : this.state.familyDetails.spouse !== null
                        ? "Self , Spouse"
                        : "Self , Kids",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered:
                    this.state.familyDetails.spouse !== null &&
                      this.state.familyDetails.kids.length !== 0
                      ? "Self, Spouse & Kids"
                      : this.state.familyDetails.spouse !== null
                        ? "Self , Spouse"
                        : "Self , Kids",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Family",
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }

          //highest age memeber
        } else {
          //error
        }
      } else {
        let family = [];
        family.push({
          gender: this.state.userInfo.gender,
          memberCode: "S",
          age: this.state.userInfo.ageInDifferentForm,
          relation: "Self",
        });
        if (this.state.familyDetails.kids.length !== 0) {
          this.state.familyDetails.kids.forEach((items) => {
            if (items.gender === "M") {
              family.push({
                gender: "M",
                memberCode: "C",
                age: this.ageInDifferentFormFun(items.date),
                relation: "Son",
              });
            } else if (items.gender === "F") {
              family.push({
                gender: "F",
                memberCode: "C",
                age: this.ageInDifferentFormFun(items.date),
                relation: "Daughter",
              });
            }
          });
        }
        let maxCover;
        console.log(family);
        if (parseInt(this.state.userInfo.ageInDifferentForm) <= 44) {
          maxCover =
            family.length <= 1
              ? maxcover_metro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate
              : dependent_maxcover_metro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
        } else {
          maxCover =
            family.length <= 1
              ? maxcover_metro_morethan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate
              : dependent_maxcover_metro_morethan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
        }
        if (
          (this.state.healthInsurance.suminsured <= 500000 &&
            this.state.corporateInsurance.suminsured === 0) ||
          (this.state.healthInsurance.suminsured === 0 &&
            this.state.corporateInsurance.suminsured <= 500000) ||
          (this.state.healthInsurance.suminsured <= 500000 &&
            this.state.corporateInsurance.suminsured <= 500000) ||
          (this.state.healthInsurance.suminsured > 500000 &&
            this.state.healthInsurance.suminsured <= 1000000 &&
            this.state.corporateInsurance.suminsured === 0) ||
          (this.state.healthInsurance.suminsured === 0 &&
            this.state.corporateInsurance.suminsured > 500000 &&
            this.state.corporateInsurance.suminsured <= 1000000) ||
          (this.state.healthInsurance.suminsured > 500000 &&
            this.state.healthInsurance.suminsured <= 1000000 &&
            this.state.corporateInsurance.suminsured <= 500000) ||
          (this.state.corporateInsurance.suminsured > 500000 &&
            this.state.corporateInsurance.suminsured <= 1000000 &&
            this.state.healthInsurance.suminsured <= 500000)
        ) {
          var Recommendation = {
            recommededCover: null,
            recommendedPolicy: "Optima Secure",
            membersCovered: family.length > 1 ? "Self & Kids" : "Self",
            category: "Health",
            suminsured: maxCover,
            type: family.length > 1 ? "Family" : "Individual",
            subcategory: "Optima_Restore",
            PolicyOwner: this.state.userInfo.ageInDifferentForm,
            premium: null,
            family: family,
            PolicyCode: "s",
          };
          this.state.Recommendations.push(Recommendation);
        }

        if (this.state.policyTaken.super_top_up_insurance === false) {
          if (
            (this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured > 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured < 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured > 1000000)
          ) {
            if (maxCover <= 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up",
                membersCovered: "Self",
                category: "Health",
                suminsured: 1000000,
                subcategory: "Super_Top_Up",
                premium: null,
                PolicyOwner: this.state.userInfo.ageInDifferentForm,
                type: "Individual",
                family: family,
                PolicyCode: "m_s",
              };
              this.state.Recommendations.push(superTopUp_Recommendation);
            } else if (maxCover > 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up",
                membersCovered: "Self",
                category: "Health",
                suminsured: 1500000,
                type: "Individual",
                PolicyOwner: this.state.userInfo.ageInDifferentForm,
                subcategory: "Super_Top_Up",
                premium: null,
                family: family,
                PolicyCode: "m_s",
              };
              this.state.Recommendations.push(superTopUp_Recommendation);
            }
          }
        }

        if (page === "Recommendations") {
          this.props.history.push("/recommendations");
        }
      }
    } else {
      if (
        this.state.familyDetails.spouse &&
        (this.state.familyDetails.father ||
          this.state.familyDetails.mother ||
          this.state.familyDetails.motherinlaw ||
          this.state.familyDetails.fatherinlaw)
      ) {
        //all family members
        if (
          this.state.familyDetails.motherinlaw !== null &&
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Family
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          let PolicyOwner_age;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) >
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.fatherinlaw
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.motherinlaw
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) <
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            family.push({
              gender: "M",
              memberCode: "H",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Husband",
            });
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Self",
            });
          } else {
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Wife",
            });
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Self",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: maxCover,
              recommendedPolicy: "Optima Secure - In Laws",
              membersCovered: "Father-In-Law, Mother-In-Law",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner: PolicyOwner_age,
              premium: null,
              type: "Family",
              family: family,
              PolicyCode: "g",
            };
            this.state.Recommendations.push(Recommendation);
          }
          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1500000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }

          //highest age memeber
        } else if (
          this.state.familyDetails.motherinlaw !== null ||
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: maxCover,
              recommendedPolicy: "Optima Secure - In-Laws",
              membersCovered:
                this.state.familyDetails.motherinlaw !== null
                  ? "Mother-In-Law"
                  : "Father-In-Law",
              category: "Health",
              suminsured: maxCover,
              type: "Individual",
              subcategory: "Optima_Restore",
              PolicyOwner:
                this.state.familyDetails.motherinlaw !== null
                  ? this.ageIndifference(this.state.familyDetails.motherinlaw)
                  : this.ageIndifference(this.state.familyDetails.fatherinlaw),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                },
              ],
              PolicyCode: "g",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered:
                    this.state.familyDetails.motherinlaw !== null
                      ? "Mother-In-Law"
                      : "Father-In-Law",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                  type: "Individual",
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.motherinlaw !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.motherinlaw
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.fatherinlaw
                          ),
                    },
                  ],
                  PolicyCode: "m_g",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover >= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered:
                    this.state.familyDetails.motherinlaw !== null
                      ? "Mother-In-Law"
                      : "Father-In-Law",
                  category: "Health",
                  suminsured: 1500000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                  PolicyCode: "m_g",
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.motherinlaw !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.motherinlaw
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.fatherinlaw
                          ),
                    },
                  ],
                  type: "Individual",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }

          //highest age memeber
        }

        // only mother and father are

        if (
          this.state.familyDetails.mother !== null &&
          this.state.familyDetails.father !== null
        ) {
          // optima family

          let PolicyOwner_age;
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) >
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.father
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.mother
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) <
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Self",
            });
            family.push({
              gender: "M",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              memberCode: "H",
              relation: "Husband",
            });
          } else {
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              relation: "Self",
            });
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Wife",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered: "Father, Mother",
              category: "Health",
              suminsured: maxCover,
              type: "Family",
              subcategory: "Optima_Restore",
              PolicyOwner: PolicyOwner_age,
              premium: null,
              family: family,
              PolicyCode: "p",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Father, Mother",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_p",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Mother & Father",
                  category: "Health",
                  suminsured: 1500000,
                  PolicyOwner: PolicyOwner_age,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  type: "Family",
                  PolicyCode: "m_p",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }

          //highest age memeber
        } else if (
          this.state.familyDetails.mother !== null ||
          this.state.familyDetails.father !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered:
                this.state.familyDetails.mother !== null ? "Mother" : "Father",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              type: "Individual",
              PolicyOwner:
                this.state.familyDetails.mother !== null
                  ? this.ageIndifference(this.state.familyDetails.mother)
                  : this.ageIndifference(this.state.familyDetails.father),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                },
              ],
              PolicyCode: "p",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered:
                    this.state.familyDetails.mother !== null
                      ? "Mother"
                      : "Father",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                  type: "Individual",
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.mother !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.mother
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.father
                          ),
                    },
                  ],
                  PolicyCode: "m_p",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up",
                  membersCovered:
                    this.state.familyDetails.motherinlaw !== null
                      ? "Mother"
                      : "Father",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Individual",
                  PolicyOwner:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.mother !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.mother
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.father
                          ),
                    },
                  ],
                  PolicyCode: "m_p",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }

          //highest age memeber
        }

        //only spouse kids and self

        if (
          this.state.familyDetails.spouse !== null ||
          this.state.familyDetails.kids.length !== 0
        ) {
          // optima family
          let maxCover;
          if (parseInt(this.state.userInfo.ageInDifferentForm) >= 45) {
            maxCover =
              dependent_maxcover_nonmetro_morethan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          } else {
            maxCover =
              dependent_maxcover_nonmetro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          }
          let family = [];
          family.push({
            gender: this.state.userInfo.gender,
            memberCode: "S",
            age: this.state.userInfo.ageInDifferentForm,
            relation: "Self",
          });
          if (this.state.familyDetails.spouse !== null) {
            family.push({
              gender: this.state.userInfo.gender === "M" ? "F" : "M",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.spouse),
              relation: this.state.userInfo.gender === "M" ? "Wife" : "Husband",
            });
          }
          if (this.state.familyDetails.kids.length !== 0) {
            this.state.familyDetails.kids.forEach((items) => {
              if (items.gender === "M") {
                family.push({
                  gender: "M",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Son",
                });
              } else if (items.gender === "F") {
                family.push({
                  gender: "F",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Daughter",
                });
              }
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Family",
              membersCovered:
                this.state.familyDetails.spouse !== null &&
                  this.state.familyDetails.kids.length !== 0
                  ? "Self, Spouse & Kids"
                  : this.state.familyDetails.spouse !== null
                    ? "Self , Spouse"
                    : "Self , Kids",
              category: "Health",
              type: "Family",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner: this.state.userInfo.ageInDifferentForm,
              premium: null,
              family: family,
              PolicyCode: "s",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered: "Self , Spouse",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered: "Self , Spouse",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Family",
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }

          //highest age memeber
        }
      } else if (
        (this.state.familyDetails.spouse === null &&
          this.state.familyDetails.father) ||
        this.state.familyDetails.mother ||
        this.state.familyDetails.motherinlaw ||
        this.state.familyDetails.fatherinlaw
      ) {
        //all family members
        if (
          this.state.familyDetails.motherinlaw !== null &&
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Family
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          let PolicyOwner_age;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) >
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.fatherinlaw
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.motherinlaw
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) <
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Self",
            });
            family.push({
              gender: "M",
              memberCode: "H",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Husband",
            });
          } else {
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Wife",
            });
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Self",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: maxCover,
              recommendedPolicy: "Optima Secure - In Laws",
              membersCovered: "Father-In-Law, Mother-In-Law",
              category: "Health",
              suminsured: maxCover,
              type: "Family",
              subcategory: "Optima_Restore",
              PolicyOwner: PolicyOwner_age,
              premium: null,
              family: family,
              PolicyCode: "g",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1500000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }

          //highest age memeber
        } else if (
          this.state.familyDetails.motherinlaw !== null ||
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: maxCover,
              recommendedPolicy: "Optima Secure - In-Laws",
              membersCovered:
                this.state.familyDetails.motherinlaw !== null
                  ? "Mother-In-Law"
                  : "Father-In-Law",
              category: "Health",
              type: "Individual",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner:
                this.state.familyDetails.motherinlaw !== null
                  ? this.ageIndifference(this.state.familyDetails.motherinlaw)
                  : this.ageIndifference(this.state.familyDetails.fatherinlaw),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                },
              ],
              PolicyCode: "g",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (
            (this.state.policyTaken.super_top_up_insurance === false &&
              this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured > 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured < 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured > 1000000)
          ) {
            if (maxCover <= 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up - In Laws",
                membersCovered:
                  this.state.familyDetails.motherinlaw !== null
                    ? "Mother-In-Law"
                    : "Father-In-Law",
                category: "Health",
                suminsured: 1000000,
                subcategory: "Super_Top_Up",
                premium: null,
                PolicyOwner:
                  this.state.familyDetails.motherinlaw !== null
                    ? this.ageIndifference(this.state.familyDetails.motherinlaw)
                    : this.ageIndifference(
                      this.state.familyDetails.fatherinlaw
                    ),
                type: "Individual",
                family: [
                  {
                    memberCode: "S",
                    relation: "Self",
                    age:
                      this.state.familyDetails.motherinlaw !== null
                        ? this.ageIndifference(
                          this.state.familyDetails.motherinlaw
                        )
                        : this.ageIndifference(
                          this.state.familyDetails.fatherinlaw
                        ),
                  },
                ],
                PolicyCode: "m_g",
              };
              this.state.Recommendations.push(superTopUp_Recommendation);
            } else if (maxCover >= 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up - In Laws",
                membersCovered:
                  this.state.familyDetails.motherinlaw !== null
                    ? "Mother-In-Law"
                    : "Father-In-Law",
                category: "Health",
                suminsured: 1500000,
                subcategory: "Super_Top_Up",
                premium: null,
                PolicyOwner:
                  this.state.familyDetails.motherinlaw !== null
                    ? this.ageIndifference(this.state.familyDetails.motherinlaw)
                    : this.ageIndifference(
                      this.state.familyDetails.fatherinlaw
                    ),
                PolicyCode: "m_g",
                family: [
                  {
                    memberCode: "S",
                    relation: "Self",
                    age:
                      this.state.familyDetails.motherinlaw !== null
                        ? this.ageIndifference(
                          this.state.familyDetails.motherinlaw
                        )
                        : this.ageIndifference(
                          this.state.familyDetails.fatherinlaw
                        ),
                  },
                ],
                type: "Individual",
              };
              this.state.Recommendations.push(superTopUp_Recommendation);
            }
          }

          //highest age memeber
        }

        // only mother and father are

        if (
          this.state.familyDetails.mother !== null &&
          this.state.familyDetails.father !== null
        ) {
          // optima family

          let PolicyOwner_age;
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) >
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.father
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.mother
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) <
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Self",
            });
            family.push({
              gender: "M",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              memberCode: "H",
              relation: "Husband",
            });
          } else {
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              relation: "Self",
            });
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Wife",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered: "Father, Mother",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner: PolicyOwner_age,
              premium: null,
              type: "Family",
              family: family,
              PolicyCode: "p",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Father, Mother",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_p",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Mother & Father",
                  category: "Health",
                  suminsured: 1500000,
                  PolicyOwner: PolicyOwner_age,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  type: "Family",
                  PolicyCode: "m_p",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }

          //highest age memeber
        } else if (
          this.state.familyDetails.mother !== null ||
          this.state.familyDetails.father !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered:
                this.state.familyDetails.mother !== null ? "Mother" : "Father",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              type: "Individual",
              PolicyOwner:
                this.state.familyDetails.mother !== null
                  ? this.ageIndifference(this.state.familyDetails.mother)
                  : this.ageIndifference(this.state.familyDetails.father),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                },
              ],
              PolicyCode: "p",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered:
                    this.state.familyDetails.mother !== null
                      ? "Mother"
                      : "Father",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                  type: "Individual",
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.mother !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.mother
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.father
                          ),
                    },
                  ],
                  PolicyCode: "m_p",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered:
                    this.state.familyDetails.motherinlaw !== null
                      ? "Mother"
                      : "Father",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Individual",
                  PolicyOwner:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.mother !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.mother
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.father
                          ),
                    },
                  ],
                  PolicyCode: "m_p",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }

          //highest age memeber
        }
        if (this.state.userInfo.ageInDifferentForm) {
          let family = [];
          family.push({
            gender: this.state.userInfo.gender,
            memberCode: "S",
            age: this.state.userInfo.ageInDifferentForm,
            relation: "Self",
          });
          if (this.state.familyDetails.kids.length !== 0) {
            this.state.familyDetails.kids.forEach((items) => {
              if (items.gender === "M") {
                family.push({
                  gender: "M",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Son",
                });
              } else if (items.gender === "F") {
                family.push({
                  gender: "F",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Daughter",
                });
              }
            });
          }
          let maxCover;
          if (parseInt(this.state.userInfo.ageInDifferentForm) >= 45) {
            maxCover =
              family.length === 0
                ? maxcover_nonmetro_morethan45 -
                parseInt(this.state.healthInsurance.suminsured) -
                corporate
                : dependent_maxcover_nonmetro_morethan45 -
                parseInt(this.state.healthInsurance.suminsured) -
                corporate;
          } else {
            maxCover =
              family.length === 0
                ? maxcover_nonmetro_lessthan45 -
                parseInt(this.state.healthInsurance.suminsured) -
                corporate
                : dependent_maxcover_nonmetro_lessthan45 -
                parseInt(this.state.healthInsurance.suminsured) -
                corporate;
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure",
              membersCovered: family.length > 1 ? "Self & Kids" : "Self",
              category: "Health",
              suminsured: maxCover,
              type: family.length > 1 ? "Family" : "Individual",
              subcategory: "Optima_Restore",
              PolicyOwner: this.state.userInfo.ageInDifferentForm,
              premium: null,
              family: family,
              PolicyCode: "s",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up",
                  membersCovered: "Self",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  type: "Individual",
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up",
                  membersCovered: "Self",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Individual",
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }
        }
      } else if (
        this.state.familyDetails.spouse ||
        this.state.familyDetails.kids.length > 0
      ) {
        if (
          this.state.familyDetails.spouse !== null ||
          this.state.familyDetails.kids.length !== 0
        ) {
          // optima family
          let maxCover;
          if (parseInt(this.state.userInfo.ageInDifferentForm) >= 45) {
            maxCover =
              dependent_maxcover_nonmetro_morethan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          } else {
            maxCover =
              dependent_maxcover_nonmetro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          }
          let family = [];
          family.push({
            gender: "M",
            memberCode: "S",
            age: this.state.userInfo.ageInDifferentForm,
            relation: "Self",
          });
          if (this.state.familyDetails.spouse !== null) {
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.spouse),
              relation: "Wife",
            });
          }

          if (this.state.familyDetails.kids.length !== 0) {
            this.state.familyDetails.kids.forEach((items) => {
              if (items.gender === "M") {
                family.push({
                  gender: "M",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Son",
                });
              } else if (items.gender === "F") {
                family.push({
                  gender: "F",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Daughter",
                });
              }
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Family",
              membersCovered:
                this.state.familyDetails.spouse !== null &&
                  this.state.familyDetails.kids.length !== 0
                  ? "Self, Spouse & Kids"
                  : this.state.familyDetails.spouse !== null
                    ? "Self , Spouse"
                    : "Self , Kids",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner: this.state.userInfo.ageInDifferentForm,
              premium: null,
              type: "Family",
              family: family,
              PolicyCode: "s",
            };
            this.state.Recommendations.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered:
                    this.state.familyDetails.spouse !== null &&
                      this.state.familyDetails.kids.length !== 0
                      ? "Self, Spouse & Kids"
                      : this.state.familyDetails.spouse !== null
                        ? "Self , Spouse"
                        : "Self , Kids",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered:
                    this.state.familyDetails.spouse !== null &&
                      this.state.familyDetails.kids.length !== 0
                      ? "Self, Spouse & Kids"
                      : this.state.familyDetails.spouse !== null
                        ? "Self , Spouse"
                        : "Self , Kids",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Family",
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.Recommendations.push(superTopUp_Recommendation);
              }
            }
          }

          //highest age memeber
        }
      } else {
        let family = [];
        family.push({
          gender: this.state.userInfo.gender,
          memberCode: "S",
          age: this.state.userInfo.ageInDifferentForm,
          relation: "Self",
        });
        if (this.state.familyDetails.kids.length !== 0) {
          this.state.familyDetails.kids.forEach((items) => {
            if (items.gender === "M") {
              family.push({
                gender: "M",
                memberCode: "C",
                age: this.ageInDifferentFormFun(items.date),
                relation: "Son",
              });
            } else if (items.gender === "F") {
              family.push({
                gender: "F",
                memberCode: "C",
                age: this.ageInDifferentFormFun(items.date),
                relation: "Daughter",
              });
            }
          });
        }
        let maxCover;
        if (parseInt(this.state.userInfo.ageInDifferentForm) <= 44) {
          maxCover =
            family.length <= 1
              ? maxcover_nonmetro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate
              : dependent_maxcover_nonmetro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
        } else {
          maxCover =
            family.length <= 1
              ? maxcover_nonmetro_morethan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate
              : dependent_maxcover_nonmetro_morethan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
        }
        if (
          (this.state.healthInsurance.suminsured <= 500000 &&
            this.state.corporateInsurance.suminsured === 0) ||
          (this.state.healthInsurance.suminsured === 0 &&
            this.state.corporateInsurance.suminsured <= 500000) ||
          (this.state.healthInsurance.suminsured <= 500000 &&
            this.state.corporateInsurance.suminsured <= 500000) ||
          (this.state.healthInsurance.suminsured > 500000 &&
            this.state.healthInsurance.suminsured <= 1000000 &&
            this.state.corporateInsurance.suminsured === 0) ||
          (this.state.healthInsurance.suminsured === 0 &&
            this.state.corporateInsurance.suminsured > 500000 &&
            this.state.corporateInsurance.suminsured <= 1000000) ||
          (this.state.healthInsurance.suminsured > 500000 &&
            this.state.healthInsurance.suminsured <= 1000000 &&
            this.state.corporateInsurance.suminsured <= 500000) ||
          (this.state.corporateInsurance.suminsured > 500000 &&
            this.state.corporateInsurance.suminsured <= 1000000 &&
            this.state.healthInsurance.suminsured <= 500000)
        ) {
          var Recommendation = {
            recommededCover: null,
            recommendedPolicy: "Optima Secure",
            membersCovered: family.length > 1 ? "Self & Kids" : "Self",
            category: "Health",
            suminsured: maxCover,
            type: family.length > 1 ? "Family" : "Individual",
            subcategory: "Optima_Restore",
            PolicyOwner: this.state.userInfo.ageInDifferentForm,
            premium: null,
            family: family,
            PolicyCode: "s",
          };
          this.state.Recommendations.push(Recommendation);
        }

        if (this.state.policyTaken.super_top_up_insurance === false) {
          if (
            (this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured > 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured < 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured > 1000000)
          ) {
            if (maxCover <= 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up",
                membersCovered: "Self",
                category: "Health",
                suminsured: 1000000,
                subcategory: "Super_Top_Up",
                premium: null,
                PolicyOwner: this.state.userInfo.ageInDifferentForm,
                type: "Individual",
                family: family,
                PolicyCode: "m_s",
              };
              this.state.Recommendations.push(superTopUp_Recommendation);
            } else if (maxCover > 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up",
                membersCovered: "Self",
                category: "Health",
                suminsured: 1500000,
                type: "Individual",
                PolicyOwner: this.state.userInfo.ageInDifferentForm,
                subcategory: "Super_Top_Up",
                premium: null,
                family: family,
                PolicyCode: "m_s",
              };
              this.state.Recommendations.push(superTopUp_Recommendation);
            }
          }
        }
      }
    }

    if (page === "Recommendations") {
      this.props.history.push("/recommendations");
    }
  };

  HealthRecommendations_nothave = (page) => {
    let corporate = parseInt(this.state.corporateInsurance.suminsured);
    if (this.state.userInfo.city === "Metro") {
      if (
        this.state.familyDetails.spouse !== null &&
        (this.state.familyDetails.father !== null ||
          this.state.familyDetails.mother !== null ||
          this.state.familyDetails.motherinlaw !== null ||
          this.state.familyDetails.fatherinlaw !== null)
      ) {
        //all family members
        if (
          this.state.familyDetails.motherinlaw !== null &&
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Family
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          let PolicyOwner_age;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) >
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.fatherinlaw
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.motherinlaw
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) <
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            family.push({
              gender: "M",
              memberCode: "H",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Husband",
            });
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Self",
            });
          } else {
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Self",
            });
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Wife",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            let Recommendation = {
              recommededCover: maxCover,
              PolicyOwner: PolicyOwner_age,
              recommendedPolicy: "Optima Secure - In Laws",
              membersCovered: "Father-In-Law, Mother-In-Law",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              premium: null,
              type: "Family",
              family: family,
              PolicyCode: "g",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1500000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }

          //highest age memeber
        } else if (
          this.state.familyDetails.motherinlaw !== null ||
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: maxCover,
              recommendedPolicy: "Optima Secure - In-Laws",
              membersCovered:
                this.state.familyDetails.motherinlaw !== null
                  ? "Mother-In-Law"
                  : "Father-In-Law",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner:
                this.state.familyDetails.motherinlaw !== null
                  ? this.ageIndifference(this.state.familyDetails.motherinlaw)
                  : this.ageIndifference(this.state.familyDetails.fatherinlaw),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                },
              ],
              type: "Individual",
              PolicyCode: "g",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }
          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered:
                    this.state.familyDetails.motherinlaw !== null
                      ? "Mother-In-Law"
                      : "Father-In-Law",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                  type: "Individual",
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.motherinlaw !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.motherinlaw
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.fatherinlaw
                          ),
                    },
                  ],
                  PolicyCode: "m_g",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover >= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered:
                    this.state.familyDetails.motherinlaw !== null
                      ? "Mother-In-Law"
                      : "Father-In-Law",
                  category: "Health",
                  suminsured: 1500000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                  PolicyCode: "m_g",
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.motherinlaw !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.motherinlaw
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.fatherinlaw
                          ),
                    },
                  ],
                  type: "Individual",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }

            //highest age memeber
          }
        }

        // only mother and father are

        if (
          this.state.familyDetails.mother !== null &&
          this.state.familyDetails.father !== null
        ) {
          // optima family
          let PolicyOwner_age;
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) >
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.father
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.mother
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) <
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Self",
            });
            family.push({
              gender: "M",
              memberCode: "H",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              relation: "Husband",
            });
          } else {
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              relation: "Self",
            });
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Wife",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered: "Father, Mother",
              category: "Health",
              suminsured: maxCover,
              type: "Family",
              subcategory: "Optima_Restore",
              PolicyOwner: PolicyOwner_age,
              premium: null,
              family: family,
              PolicyCode: "p",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Father, Mother",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_p",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Mother & Father",
                  category: "Health",
                  suminsured: 1500000,
                  PolicyOwner: PolicyOwner_age,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  type: "Family",
                  PolicyCode: "m_p",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }

          //highest age memeber
        } else if (
          this.state.familyDetails.mother !== null ||
          this.state.familyDetails.father !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered:
                this.state.familyDetails.mother !== null ? "Mother" : "Father",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              type: "Individual",
              PolicyOwner:
                this.state.familyDetails.mother !== null
                  ? this.ageIndifference(this.state.familyDetails.mother)
                  : this.ageIndifference(this.state.familyDetails.father),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                },
              ],
              PolicyCode: "p",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (
            (this.state.policyTaken.super_top_up_insurance === false &&
              this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured > 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured < 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured > 1000000)
          ) {
            if (maxCover <= 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up - Parents",
                membersCovered:
                  this.state.familyDetails.mother !== null
                    ? "Mother"
                    : "Father",
                category: "Health",
                suminsured: 1000000,
                subcategory: "Super_Top_Up",
                premium: null,
                PolicyOwner:
                  this.state.familyDetails.mother !== null
                    ? this.ageIndifference(this.state.familyDetails.mother)
                    : this.ageIndifference(this.state.familyDetails.father),
                type: "Individual",
                family: [
                  {
                    memberCode: "S",
                    relation: "Self",
                    age:
                      this.state.familyDetails.mother !== null
                        ? this.ageIndifference(this.state.familyDetails.mother)
                        : this.ageIndifference(this.state.familyDetails.father),
                  },
                ],
                PolicyCode: "m_p",
              };
              this.state.nothavePolicyRecommendation.push(
                superTopUp_Recommendation
              );
            } else if (maxCover > 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up -Parents",
                membersCovered:
                  this.state.familyDetails.mother !== null
                    ? "Mother"
                    : "Father",
                category: "Health",
                suminsured: 1500000,
                type: "Individual",
                PolicyOwner:
                  this.state.familyDetails.mother !== null
                    ? this.ageIndifference(this.state.familyDetails.mother)
                    : this.ageIndifference(this.state.familyDetails.father),
                subcategory: "Super_Top_Up",
                premium: null,
                family: [
                  {
                    memberCode: "S",
                    relation: "Self",
                    age:
                      this.state.familyDetails.mother !== null
                        ? this.ageIndifference(this.state.familyDetails.mother)
                        : this.ageIndifference(this.state.familyDetails.father),
                  },
                ],
                PolicyCode: "m_p",
              };
              this.state.nothavePolicyRecommendation.push(
                superTopUp_Recommendation
              );
            }
          }

          //highest age memeber
        }

        //only spouse kids and self

        if (
          this.state.familyDetails.spouse !== null ||
          this.state.familyDetails.kids.length !== 0
        ) {
          // optima family
          let maxCover;
          if (parseInt(this.state.userInfo.ageInDifferentForm) >= 45) {
            maxCover =
              dependent_maxcover_metro_morethan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          } else {
            maxCover =
              dependent_maxcover_metro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          }
          let family = [];
          family.push({
            gender: "M",
            memberCode: "S",
            age: this.state.userInfo.ageInDifferentForm,
            relation: "Self",
          });
          if (this.state.familyDetails.spouse !== null) {
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.spouse),
              relation: "Wife",
            });
          }
          if (this.state.familyDetails.kids.length !== 0) {
            this.state.familyDetails.kids.forEach((items) => {
              if (items.gender === "M") {
                family.push({
                  gender: "M",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Son",
                });
              } else if (items.gender === "F") {
                family.push({
                  gender: "F",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Daughter",
                });
              }
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Family",
              membersCovered:
                this.state.familyDetails.spouse !== null &&
                  this.state.familyDetails.kids.length !== 0
                  ? "Self, Spouse & Kids"
                  : this.state.familyDetails.spouse !== null
                    ? "Self , Spouse"
                    : "Self , Kids",
              category: "Health",
              type: "Family",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner: this.state.userInfo.ageInDifferentForm,
              premium: null,
              family: family,
              PolicyCode: "s",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered: "Self , Spouse",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered: "Self , Spouse",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Family",
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }

            //highest age memeber
          }
        }
      } else if (
        this.state.familyDetails.spouse === null &&
        (this.state.familyDetails.father ||
          this.state.familyDetails.mother ||
          this.state.familyDetails.motherinlaw ||
          this.state.familyDetails.fatherinlaw)
      ) {
        //all family members
        if (
          this.state.familyDetails.motherinlaw !== null &&
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Family
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          let PolicyOwner_age;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) >
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.fatherinlaw
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.motherinlaw
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) <
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            family.push({
              gender: "M",
              memberCode: "H",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Husband",
            });
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Self",
            });
          } else {
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Wife",
            });
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Self",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: maxCover,
              recommendedPolicy: "Optima Secure - In Laws",
              membersCovered: "Father-In-Law, Mother-In-Law",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore - In-Laws",
              PolicyOwner: PolicyOwner_age,
              premium: null,
              family: family,
              PolicyCode: "g",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }
          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1500000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }

          //highest age memeber
        } else if (
          this.state.familyDetails.motherinlaw !== null ||
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: maxCover,
              recommendedPolicy: "Optima Secure - In-Laws",
              membersCovered:
                this.state.familyDetails.motherinlaw !== null
                  ? "Mother-In-Law"
                  : "Father-In-Law",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner:
                this.state.familyDetails.motherinlaw !== null
                  ? this.ageIndifference(this.state.familyDetails.motherinlaw)
                  : this.ageIndifference(this.state.familyDetails.fatherinlaw),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                },
              ],
              PolicyCode: "g",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (
                this.state.healthInsurance.suminsured >= 500000 ||
                parseInt(this.state.healthInsurance.suminsured) === 0
              ) {
                if (maxCover <= 1000000) {
                  let superTopUp_Recommendation = {
                    recommededCover: maxCover,
                    recommendedPolicy: "Medisure Top-Up - In Laws",
                    membersCovered:
                      this.state.familyDetails.motherinlaw !== null
                        ? "Mother-In-Law"
                        : "Father-In-Law",
                    category: "Health",
                    suminsured: 1000000,
                    subcategory: "Super_Top_Up",
                    premium: null,
                    PolicyOwner:
                      this.state.familyDetails.motherinlaw !== null
                        ? this.ageIndifference(
                          this.state.familyDetails.motherinlaw
                        )
                        : this.ageIndifference(
                          this.state.familyDetails.fatherinlaw
                        ),
                    type: "Individual",
                    family: [
                      {
                        memberCode: "S",
                        relation: "Self",
                        age:
                          this.state.familyDetails.motherinlaw !== null
                            ? this.ageIndifference(
                              this.state.familyDetails.motherinlaw
                            )
                            : this.ageIndifference(
                              this.state.familyDetails.fatherinlaw
                            ),
                      },
                    ],
                    PolicyCode: "m_g",
                  };
                  this.state.nothavePolicyRecommendation.push(
                    superTopUp_Recommendation
                  );
                } else if (maxCover >= 1000000) {
                  let superTopUp_Recommendation = {
                    recommededCover: maxCover,
                    recommendedPolicy: "Medisure Top-Up - In Laws",
                    membersCovered:
                      this.state.familyDetails.motherinlaw !== null
                        ? "Mother-In-Law"
                        : "Father-In-Law",
                    category: "Health",
                    suminsured: 1500000,
                    subcategory: "Super_Top_Up",
                    premium: null,
                    PolicyOwner:
                      this.state.familyDetails.motherinlaw !== null
                        ? this.ageIndifference(
                          this.state.familyDetails.motherinlaw
                        )
                        : this.ageIndifference(
                          this.state.familyDetails.fatherinlaw
                        ),
                    PolicyCode: "m_g",
                    family: [
                      {
                        memberCode: "S",
                        relation: "Self",
                        age:
                          this.state.familyDetails.motherinlaw !== null
                            ? this.ageIndifference(
                              this.state.familyDetails.motherinlaw
                            )
                            : this.ageIndifference(
                              this.state.familyDetails.fatherinlaw
                            ),
                      },
                    ],
                    type: "Individual",
                  };
                  this.state.nothavePolicyRecommendation.push(
                    superTopUp_Recommendation
                  );
                }
              }
            }

            //highest age memeber
          }
        }

        // only mother and father are

        if (
          this.state.familyDetails.mother !== null &&
          this.state.familyDetails.father !== null
        ) {
          // optima family
          let PolicyOwner_age;
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) >
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.father
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.mother
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) <
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Self",
            });
            family.push({
              gender: "M",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              memberCode: "H",
              relation: "Husband",
            });
          } else {
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              relation: "Self",
            });
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Wife",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered: "Father, Mother",
              category: "Health",
              suminsured: maxCover,
              type: "Family",
              subcategory: "Optima_Restore",
              PolicyOwner: PolicyOwner_age,
              premium: null,
              family: family,
              PolicyCode: "p",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Father, Mother",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_p",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Mother & Father",
                  category: "Health",
                  suminsured: 1500000,
                  PolicyOwner: PolicyOwner_age,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  type: "Family",
                  PolicyCode: "m_p",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }
          //highest age memeber
        } else if (
          this.state.familyDetails.mother !== null ||
          this.state.familyDetails.father !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_metro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered:
                this.state.familyDetails.mother !== null ? "Mother" : "Father",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              type: "Individual",
              PolicyOwner:
                this.state.familyDetails.mother !== null
                  ? this.ageIndifference(this.state.familyDetails.mother)
                  : this.ageIndifference(this.state.familyDetails.father),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                },
              ],
              PolicyCode: "p",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }
          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (
                this.state.healthInsurance.suminsured >= 500000 ||
                parseInt(this.state.healthInsurance.suminsured) === 0
              ) {
                if (maxCover <= 1000000) {
                  let superTopUp_Recommendation = {
                    recommededCover: maxCover,
                    recommendedPolicy: "Medisure Top-Up - Parents",
                    membersCovered:
                      this.state.familyDetails.mother !== null
                        ? "Mother"
                        : "Father",
                    category: "Health",
                    suminsured: 1000000,
                    subcategory: "Super_Top_Up",
                    premium: null,
                    PolicyOwner:
                      this.state.familyDetails.mother !== null
                        ? this.ageIndifference(this.state.familyDetails.mother)
                        : this.ageIndifference(this.state.familyDetails.father),
                    type: "Individual",
                    family: [
                      {
                        memberCode: "S",
                        relation: "Self",
                        age:
                          this.state.familyDetails.mother !== null
                            ? this.ageIndifference(
                              this.state.familyDetails.mother
                            )
                            : this.ageIndifference(
                              this.state.familyDetails.father
                            ),
                      },
                    ],
                    PolicyCode: "m_p",
                  };
                  this.state.nothavePolicyRecommendation.push(
                    superTopUp_Recommendation
                  );
                } else if (maxCover > 1000000) {
                  let superTopUp_Recommendation = {
                    recommededCover: maxCover,
                    recommendedPolicy: "Medisure Top-Up -Parents",
                    membersCovered:
                      this.state.familyDetails.motherinlaw !== null
                        ? "Mother"
                        : "Father",
                    category: "Health",
                    suminsured: 1500000,
                    type: "Individual",
                    PolicyOwner:
                      this.state.familyDetails.mother !== null
                        ? this.ageIndifference(this.state.familyDetails.mother)
                        : this.ageIndifference(this.state.familyDetails.father),
                    subcategory: "Super_Top_Up",
                    premium: null,
                    family: [
                      {
                        memberCode: "S",
                        relation: "Self",
                        age:
                          this.state.familyDetails.mother !== null
                            ? this.ageIndifference(
                              this.state.familyDetails.mother
                            )
                            : this.ageIndifference(
                              this.state.familyDetails.father
                            ),
                      },
                    ],
                    PolicyCode: "m_p",
                  };
                  this.state.nothavePolicyRecommendation.push(
                    superTopUp_Recommendation
                  );
                }
              }
            }
          }

          //highest age memeber
        }
        if (this.state.userInfo.ageInDifferentForm) {
          let family = [];
          family.push({
            gender: "M",
            memberCode: "S",
            age: this.state.userInfo.ageInDifferentForm,
            relation: "Self",
          });
          if (this.state.familyDetails.kids.length !== 0) {
            this.state.familyDetails.kids.forEach((items) => {
              if (items.gender === "M") {
                family.push({
                  gender: "M",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Son",
                });
              } else if (items.gender === "F") {
                family.push({
                  gender: "F",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Daughter",
                });
              }
            });
          }
          let maxCover;
          if (parseInt(this.state.userInfo.ageInDifferentForm) >= 45) {
            maxCover =
              family.length <= 1
                ? maxcover_metro_morethan45
                : dependent_maxcover_metro_morethan45 -
                parseInt(this.state.healthInsurance.suminsured) -
                corporate;
          } else {
            maxCover =
              family.length <= 1
                ? maxcover_metro_morethan45
                : dependent_maxcover_metro_morethan45 -
                parseInt(this.state.healthInsurance.suminsured) -
                corporate;
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            console.log(maxCover);
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure",
              membersCovered: family.length > 1 ? "Self & Kids" : "Self",
              category: "Health",
              suminsured: maxCover,
              type: family.length > 1 ? "Family" : "Individual",
              subcategory: "Optima_Restore",
              PolicyOwner: this.state.userInfo.ageInDifferentForm,
              premium: null,
              family: family,
              PolicyCode: "s",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up",
                  membersCovered: "Self",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  type: "Individual",
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up",
                  membersCovered: "Self",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Individual",
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }
        }
      } else if (
        this.state.familyDetails.spouse ||
        this.state.familyDetails.kids.length > 0
      ) {
        if (
          this.state.familyDetails.spouse !== null ||
          this.state.familyDetails.kids.length !== 0
        ) {
          // optima family
          let maxCover;
          if (parseInt(this.state.userInfo.ageInDifferentForm) >= 45) {
            maxCover =
              dependent_maxcover_metro_morethan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          } else {
            maxCover =
              dependent_maxcover_metro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          }
          let family = [];
          family.push({
            gender: this.state.userInfo.gender,
            memberCode: "S",
            age: this.state.userInfo.ageInDifferentForm,
            relation: "Self",
          });
          if (this.state.familyDetails.spouse !== null) {
            family.push({
              gender: this.state.userInfo.gender === "M" ? "F" : "M",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.spouse),
              relation: "Wife",
            });
          }
          if (this.state.familyDetails.kids.length !== 0) {
            this.state.familyDetails.kids.forEach((items) => {
              if (items.gender === "M") {
                family.push({
                  gender: "M",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Son",
                });
              } else if (items.gender === "F") {
                family.push({
                  gender: "F",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Daughter",
                });
              }
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Family",
              membersCovered:
                this.state.familyDetails.spouse !== null &&
                  this.state.familyDetails.kids.length !== 0
                  ? "Self, Spouse & Kids"
                  : this.state.familyDetails.spouse !== null
                    ? "Self , Spouse"
                    : "Self , Kids",
              category: "Health",
              type: "Family",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner: this.state.userInfo.ageInDifferentForm,
              premium: null,
              family: family,
              PolicyCode: "s",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }
          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered: "Self , Spouse",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered: "Self , Spouse",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Family",
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }

          //highest age memeber
        } else {
          //error
        }
      } else {
        let family = [];
        family.push({
          gender: this.state.userInfo.gender,
          memberCode: "S",
          age: this.state.userInfo.ageInDifferentForm,
          relation: "Self",
        });
        if (this.state.familyDetails.kids.length !== 0) {
          this.state.familyDetails.kids.forEach((items) => {
            if (items.gender === "M") {
              family.push({
                gender: "M",
                memberCode: "C",
                age: this.ageInDifferentFormFun(items.date),
                relation: "Son",
              });
            } else if (items.gender === "F") {
              family.push({
                gender: "F",
                memberCode: "C",
                age: this.ageInDifferentFormFun(items.date),
                relation: "Daughter",
              });
            }
          });
        }
        let maxCover;
        if (parseInt(this.state.userInfo.ageInDifferentForm) <= 44) {
          maxCover =
            family.length <= 1
              ? maxcover_metro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate
              : dependent_maxcover_metro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
        } else {
          maxCover =
            family.length <= 1
              ? maxcover_metro_morethan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate
              : dependent_maxcover_metro_morethan45 -
              -parseInt(this.state.healthInsurance.suminsured) -
              corporate;
        }
        if (
          (this.state.healthInsurance.suminsured <= 500000 &&
            this.state.corporateInsurance.suminsured === 0) ||
          (this.state.healthInsurance.suminsured === 0 &&
            this.state.corporateInsurance.suminsured <= 500000) ||
          (this.state.healthInsurance.suminsured <= 500000 &&
            this.state.corporateInsurance.suminsured <= 500000) ||
          (this.state.healthInsurance.suminsured > 500000 &&
            this.state.healthInsurance.suminsured <= 1000000 &&
            this.state.corporateInsurance.suminsured === 0) ||
          (this.state.healthInsurance.suminsured === 0 &&
            this.state.corporateInsurance.suminsured > 500000 &&
            this.state.corporateInsurance.suminsured <= 1000000) ||
          (this.state.healthInsurance.suminsured > 500000 &&
            this.state.healthInsurance.suminsured <= 1000000 &&
            this.state.corporateInsurance.suminsured <= 500000) ||
          (this.state.corporateInsurance.suminsured > 500000 &&
            this.state.corporateInsurance.suminsured <= 1000000 &&
            this.state.healthInsurance.suminsured <= 500000)
        ) {
          var Recommendation = {
            recommededCover: null,
            recommendedPolicy: "Optima Secure",
            membersCovered: family.length > 1 ? "Self & Kids" : "Self",
            category: "Health",
            suminsured: maxCover,
            type: family.length > 1 ? "Family" : "Individual",
            subcategory: "Optima_Restore",
            PolicyOwner: this.state.userInfo.ageInDifferentForm,
            premium: null,
            family: family,
            PolicyCode: "s",
          };
          this.state.nothavePolicyRecommendation.push(Recommendation);
        }

        if (this.state.policyTaken.super_top_up_insurance === false) {
          if (
            (this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured > 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured < 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured > 1000000)
          ) {
            if (maxCover <= 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up",
                membersCovered: "Self",
                category: "Health",
                suminsured: 1000000,
                subcategory: "Super_Top_Up",
                premium: null,
                PolicyOwner: this.state.userInfo.ageInDifferentForm,
                type: "Individual",
                family: family,
                PolicyCode: "m_s",
              };
              this.state.nothavePolicyRecommendation.push(
                superTopUp_Recommendation
              );
            } else if (maxCover > 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up",
                membersCovered: "Self",
                category: "Health",
                suminsured: 1500000,
                type: "Individual",
                PolicyOwner: this.state.userInfo.ageInDifferentForm,
                subcategory: "Super_Top_Up",
                premium: null,
                family: family,
                PolicyCode: "m_s",
              };
              this.state.nothavePolicyRecommendation.push(
                superTopUp_Recommendation
              );
            }
          }
        }

        if (page === "Recommendations") {
          this.props.history.push("/recommendations");
        }
      }
    } else {
      if (
        this.state.familyDetails.spouse &&
        (this.state.familyDetails.father ||
          this.state.familyDetails.mother ||
          this.state.familyDetails.motherinlaw ||
          this.state.familyDetails.fatherinlaw)
      ) {
        //all family members
        if (
          this.state.familyDetails.motherinlaw !== null &&
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Family
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          let PolicyOwner_age;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) >
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.fatherinlaw
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.motherinlaw
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) <
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            family.push({
              gender: "M",
              memberCode: "H",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Husband",
            });
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Self",
            });
          } else {
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Wife",
            });
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Self",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: maxCover,
              recommendedPolicy: "Optima Secure - In Laws",
              membersCovered: "Father-In-Law, Mother-In-Law",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner: PolicyOwner_age,
              premium: null,
              type: "Family",
              family: family,
              PolicyCode: "g",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }
          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1500000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }

          //highest age memeber
        } else if (
          this.state.familyDetails.motherinlaw !== null ||
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: maxCover,
              recommendedPolicy: "Optima Secure - In-Laws",
              membersCovered:
                this.state.familyDetails.motherinlaw !== null
                  ? "Mother-In-Law"
                  : "Father-In-Law",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner:
                this.state.familyDetails.motherinlaw !== null
                  ? this.ageIndifference(this.state.familyDetails.motherinlaw)
                  : this.ageIndifference(this.state.familyDetails.fatherinlaw),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                },
              ],
              PolicyCode: "g",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered:
                    this.state.familyDetails.motherinlaw !== null
                      ? "Mother-In-Law"
                      : "Father-In-Law",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                  type: "Individual",
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.motherinlaw !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.motherinlaw
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.fatherinlaw
                          ),
                    },
                  ],
                  PolicyCode: "m_g",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover >= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered:
                    this.state.familyDetails.motherinlaw !== null
                      ? "Mother-In-Law"
                      : "Father-In-Law",
                  category: "Health",
                  suminsured: 1500000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                  PolicyCode: "m_g",
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.motherinlaw !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.motherinlaw
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.fatherinlaw
                          ),
                    },
                  ],
                  type: "Individual",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }

          //highest age memeber
        }

        // only mother and father are

        if (
          this.state.familyDetails.mother !== null &&
          this.state.familyDetails.father !== null
        ) {
          // optima family

          let PolicyOwner_age;
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) >
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.father
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.mother
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) <
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Self",
            });
            family.push({
              gender: "M",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              memberCode: "H",
              relation: "Husband",
            });
          } else {
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              relation: "Self",
            });
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Wife",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered: "Father, Mother",
              category: "Health",
              suminsured: maxCover,
              type: "Family",
              subcategory: "Optima_Restore",
              PolicyOwner: PolicyOwner_age,
              premium: null,
              family: family,
              PolicyCode: "p",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Father, Mother",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_p",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Mother & Father",
                  category: "Health",
                  suminsured: 1500000,
                  PolicyOwner: PolicyOwner_age,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  type: "Family",
                  PolicyCode: "m_p",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }

          //highest age memeber
        } else if (
          this.state.familyDetails.mother !== null ||
          this.state.familyDetails.father !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered:
                this.state.familyDetails.mother !== null ? "Mother" : "Father",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              type: "Individual",
              PolicyOwner:
                this.state.familyDetails.mother !== null
                  ? this.ageIndifference(this.state.familyDetails.mother)
                  : this.ageIndifference(this.state.familyDetails.father),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                },
              ],
              PolicyCode: "p",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered:
                    this.state.familyDetails.mother !== null
                      ? "Mother"
                      : "Father",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                  type: "Individual",
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.mother !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.mother
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.father
                          ),
                    },
                  ],
                  PolicyCode: "m_p",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up",
                  membersCovered:
                    this.state.familyDetails.motherinlaw !== null
                      ? "Mother"
                      : "Father",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Individual",
                  PolicyOwner:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.mother !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.mother
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.father
                          ),
                    },
                  ],
                  PolicyCode: "m_p",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }

          //highest age memeber
        }

        //only spouse kids and self

        if (
          this.state.familyDetails.spouse !== null ||
          this.state.familyDetails.kids.length !== 0
        ) {
          // optima family
          let maxCover;
          if (parseInt(this.state.userInfo.ageInDifferentForm) >= 45) {
            maxCover =
              dependent_maxcover_nonmetro_morethan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          } else {
            maxCover =
              dependent_maxcover_nonmetro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          }
          let family = [];
          family.push({
            gender: this.state.userInfo.gender,
            memberCode: "S",
            age: this.state.userInfo.ageInDifferentForm,
            relation: "Self",
          });
          if (this.state.familyDetails.spouse !== null) {
            family.push({
              gender: this.state.userInfo.gender === "M" ? "F" : "M",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.spouse),
              relation: this.state.userInfo.gender === "M" ? "Wife" : "Husband",
            });
          }
          if (this.state.familyDetails.kids.length !== 0) {
            this.state.familyDetails.kids.forEach((items) => {
              if (items.gender === "M") {
                family.push({
                  gender: "M",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Son",
                });
              } else if (items.gender === "F") {
                family.push({
                  gender: "F",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Daughter",
                });
              }
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Family",
              membersCovered:
                this.state.familyDetails.spouse !== null &&
                  this.state.familyDetails.kids.length !== 0
                  ? "Self, Spouse & Kids"
                  : this.state.familyDetails.spouse !== null
                    ? "Self , Spouse"
                    : "Self , Kids",
              category: "Health",
              type: "Family",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner: this.state.userInfo.ageInDifferentForm,
              premium: null,
              family: family,
              PolicyCode: "s",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered: "Self , Spouse",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered: "Self , Spouse",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Family",
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }

          //highest age memeber
        }
      } else if (
        (this.state.familyDetails.spouse === null &&
          this.state.familyDetails.father) ||
        this.state.familyDetails.mother ||
        this.state.familyDetails.motherinlaw ||
        this.state.familyDetails.fatherinlaw
      ) {
        //all family members
        if (
          this.state.familyDetails.motherinlaw !== null &&
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Family
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          let PolicyOwner_age;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) >
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.fatherinlaw
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.motherinlaw
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.fatherinlaw) <
            this.ageInDifferentFormFun(this.state.familyDetails.motherinlaw)
          ) {
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Self",
            });
            family.push({
              gender: "M",
              memberCode: "H",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Husband",
            });
          } else {
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.motherinlaw
              ),
              relation: "Wife",
            });
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(
                this.state.familyDetails.fatherinlaw
              ),
              relation: "Self",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: maxCover,
              recommendedPolicy: "Optima Secure - In Laws",
              membersCovered: "Father-In-Law, Mother-In-Law",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore - In-Laws",
              PolicyOwner: PolicyOwner_age,
              premium: null,
              family: family,
              PolicyCode: "g",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - In Laws",
                  membersCovered: "Father-In-Law, Mother-In-Law",
                  category: "Health",
                  suminsured: 1500000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_g",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }

          //highest age memeber
        } else if (
          this.state.familyDetails.motherinlaw !== null ||
          this.state.familyDetails.fatherinlaw !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: maxCover,
              recommendedPolicy: "Optima Secure - In-Laws",
              membersCovered:
                this.state.familyDetails.motherinlaw !== null
                  ? "Mother-In-Law"
                  : "Father-In-Law",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner:
                this.state.familyDetails.motherinlaw !== null
                  ? this.ageIndifference(this.state.familyDetails.motherinlaw)
                  : this.ageIndifference(this.state.familyDetails.fatherinlaw),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.motherinlaw !== null
                      ? this.ageIndifference(
                        this.state.familyDetails.motherinlaw
                      )
                      : this.ageIndifference(
                        this.state.familyDetails.fatherinlaw
                      ),
                },
              ],
              PolicyCode: "g",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (
            (this.state.policyTaken.super_top_up_insurance === false &&
              this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured > 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured < 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured > 1000000)
          ) {
            if (maxCover <= 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up - In Laws",
                membersCovered:
                  this.state.familyDetails.motherinlaw !== null
                    ? "Mother-In-Law"
                    : "Father-In-Law",
                category: "Health",
                suminsured: 1000000,
                subcategory: "Super_Top_Up",
                premium: null,
                PolicyOwner:
                  this.state.familyDetails.motherinlaw !== null
                    ? this.ageIndifference(this.state.familyDetails.motherinlaw)
                    : this.ageIndifference(
                      this.state.familyDetails.fatherinlaw
                    ),
                type: "Individual",
                family: [
                  {
                    memberCode: "S",
                    relation: "Self",
                    age:
                      this.state.familyDetails.motherinlaw !== null
                        ? this.ageIndifference(
                          this.state.familyDetails.motherinlaw
                        )
                        : this.ageIndifference(
                          this.state.familyDetails.fatherinlaw
                        ),
                  },
                ],
                PolicyCode: "m_g",
              };
              this.state.nothavePolicyRecommendation.push(
                superTopUp_Recommendation
              );
            } else if (maxCover >= 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up - In Laws",
                membersCovered:
                  this.state.familyDetails.motherinlaw !== null
                    ? "Mother-In-Law"
                    : "Father-In-Law",
                category: "Health",
                suminsured: 1500000,
                subcategory: "Super_Top_Up",
                premium: null,
                PolicyOwner:
                  this.state.familyDetails.motherinlaw !== null
                    ? this.ageIndifference(this.state.familyDetails.motherinlaw)
                    : this.ageIndifference(
                      this.state.familyDetails.fatherinlaw
                    ),
                PolicyCode: "m_g",
                family: [
                  {
                    memberCode: "S",
                    relation: "Self",
                    age:
                      this.state.familyDetails.motherinlaw !== null
                        ? this.ageIndifference(
                          this.state.familyDetails.motherinlaw
                        )
                        : this.ageIndifference(
                          this.state.familyDetails.fatherinlaw
                        ),
                  },
                ],
                type: "Individual",
              };
              this.state.nothavePolicyRecommendation.push(
                superTopUp_Recommendation
              );
            }
          }

          //highest age memeber
        }

        // only mother and father are

        if (
          this.state.familyDetails.mother !== null &&
          this.state.familyDetails.father !== null
        ) {
          // optima family

          let PolicyOwner_age;
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) >
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.father
            );
          } else {
            PolicyOwner_age = this.ageInDifferentFormFun(
              this.state.familyDetails.mother
            );
          }
          let family = [];
          if (
            this.ageInDifferentFormFun(this.state.familyDetails.father) <
            this.ageInDifferentFormFun(this.state.familyDetails.mother)
          ) {
            family.push({
              gender: "F",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Self",
            });
            family.push({
              gender: "M",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              memberCode: "H",
              relation: "Husband",
            });
          } else {
            family.push({
              gender: "M",
              memberCode: "S",
              age: this.ageInDifferentFormFun(this.state.familyDetails.father),
              relation: "Self",
            });
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.mother),
              relation: "Wife",
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered: "Father, Mother",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner: PolicyOwner_age,
              premium: null,
              type: "Family",
              family: family,
              PolicyCode: "p",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Father, Mother",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: PolicyOwner_age,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_p",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered: "Mother & Father",
                  category: "Health",
                  suminsured: 1500000,
                  PolicyOwner: PolicyOwner_age,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  type: "Family",
                  PolicyCode: "m_p",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }

          //highest age memeber
        } else if (
          this.state.familyDetails.mother !== null ||
          this.state.familyDetails.father !== null
        ) {
          //optima Personal
          let maxCover =
            parents_in_law_nonmetro -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Parents",
              membersCovered:
                this.state.familyDetails.mother !== null ? "Mother" : "Father",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              type: "Individual",
              PolicyOwner:
                this.state.familyDetails.mother !== null
                  ? this.ageIndifference(this.state.familyDetails.mother)
                  : this.ageIndifference(this.state.familyDetails.father),
              premium: null,
              family: [
                {
                  memberCode: "S",
                  relation: "Self",
                  age:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                },
              ],
              PolicyCode: "p",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered:
                    this.state.familyDetails.mother !== null
                      ? "Mother"
                      : "Father",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                  type: "Individual",
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.mother !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.mother
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.father
                          ),
                    },
                  ],
                  PolicyCode: "m_p",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Parents",
                  membersCovered:
                    this.state.familyDetails.motherinlaw !== null
                      ? "Mother"
                      : "Father",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Individual",
                  PolicyOwner:
                    this.state.familyDetails.mother !== null
                      ? this.ageIndifference(this.state.familyDetails.mother)
                      : this.ageIndifference(this.state.familyDetails.father),
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: [
                    {
                      memberCode: "S",
                      relation: "Self",
                      age:
                        this.state.familyDetails.mother !== null
                          ? this.ageIndifference(
                            this.state.familyDetails.mother
                          )
                          : this.ageIndifference(
                            this.state.familyDetails.father
                          ),
                    },
                  ],
                  PolicyCode: "m_p",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }

          //highest age memeber
        }
        if (this.state.userInfo.ageInDifferentForm) {
          let family = [];
          family.push({
            gender: this.state.userInfo.gender,
            memberCode: "S",
            age: this.state.userInfo.ageInDifferentForm,
            relation: "Self",
          });
          if (this.state.familyDetails.kids.length !== 0) {
            this.state.familyDetails.kids.forEach((items) => {
              if (items.gender === "M") {
                family.push({
                  gender: "M",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Son",
                });
              } else if (items.gender === "F") {
                family.push({
                  gender: "F",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Daughter",
                });
              }
            });
          }
          let maxCover;
          if (parseInt(this.state.userInfo.ageInDifferentForm) >= 45) {
            maxCover =
              (family.length === 0
                ? maxcover_nonmetro_morethan45
                : dependent_maxcover_nonmetro_morethan45) -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          } else {
            maxCover =
              (family.length === 0
                ? maxcover_nonmetro_lessthan45
                : dependent_maxcover_nonmetro_lessthan45) -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure",
              membersCovered: family.length > 1 ? "Self & Kids" : "Self",
              category: "Health",
              suminsured: maxCover,
              type: family.length > 1 ? "Family" : "Individual",
              subcategory: "Optima_Restore",
              PolicyOwner: this.state.userInfo.ageInDifferentForm,
              premium: null,
              family: family,
              PolicyCode: "s",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up",
                  membersCovered: "Self",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  type: "Individual",
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up",
                  membersCovered: "Self",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Individual",
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }
        }
      } else if (
        this.state.familyDetails.spouse ||
        this.state.familyDetails.kids.length > 0
      ) {
        if (
          this.state.familyDetails.spouse !== null ||
          this.state.familyDetails.kids.length !== 0
        ) {
          // optima family
          let maxCover;
          if (parseInt(this.state.userInfo.ageInDifferentForm) >= 45) {
            maxCover =
              dependent_maxcover_nonmetro_morethan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          } else {
            maxCover =
              dependent_maxcover_nonmetro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate;
          }
          let family = [];
          family.push({
            gender: "M",
            memberCode: "S",
            age: this.state.userInfo.ageInDifferentForm,
            relation: "Self",
          });
          if (this.state.familyDetails.spouse !== null) {
            family.push({
              gender: "F",
              memberCode: "W",
              age: this.ageInDifferentFormFun(this.state.familyDetails.spouse),
              relation: "Wife",
            });
          }

          if (this.state.familyDetails.kids.length !== 0) {
            this.state.familyDetails.kids.forEach((items) => {
              if (items.gender === "M") {
                family.push({
                  gender: "M",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Son",
                });
              } else if (items.gender === "F") {
                family.push({
                  gender: "F",
                  memberCode: "C",
                  age: this.ageInDifferentFormFun(items.date),
                  relation: "Daughter",
                });
              }
            });
          }

          if (
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured <= 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.corporateInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured <= 1000000 &&
              this.state.healthInsurance.suminsured <= 500000)
          ) {
            var Recommendation = {
              recommededCover: null,
              recommendedPolicy: "Optima Secure - Family",
              membersCovered:
                this.state.familyDetails.spouse !== null &&
                  this.state.familyDetails.kids.length !== 0
                  ? "Self, Spouse & Kids"
                  : this.state.familyDetails.spouse !== null
                    ? "Self , Spouse"
                    : "Self , Kids",
              category: "Health",
              suminsured: maxCover,
              subcategory: "Optima_Restore",
              PolicyOwner: this.state.userInfo.ageInDifferentForm,
              premium: null,
              type: "Family",
              family: family,
              PolicyCode: "s",
            };
            this.state.nothavePolicyRecommendation.push(Recommendation);
          }

          if (this.state.policyTaken.super_top_up_insurance === false) {
            if (
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured === 0) ||
              (this.state.healthInsurance.suminsured === 0 &&
                this.state.corporateInsurance.suminsured > 1000000) ||
              (this.state.healthInsurance.suminsured > 500000 &&
                this.state.corporateInsurance.suminsured > 500000) ||
              (this.state.healthInsurance.suminsured > 1000000 &&
                this.state.corporateInsurance.suminsured <= 500000) ||
              (this.state.healthInsurance.suminsured <= 500000 &&
                this.state.corporateInsurance.suminsured > 1000000)
            ) {
              if (maxCover <= 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered:
                    this.state.familyDetails.spouse !== null &&
                      this.state.familyDetails.kids.length !== 0
                      ? "Self, Spouse & Kids"
                      : this.state.familyDetails.spouse !== null
                        ? "Self , Spouse"
                        : "Self , Kids",
                  category: "Health",
                  suminsured: 1000000,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  type: "Family",
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              } else if (maxCover > 1000000) {
                let superTopUp_Recommendation = {
                  recommededCover: maxCover,
                  recommendedPolicy: "Medisure Top-Up - Family",
                  membersCovered:
                    this.state.familyDetails.spouse !== null &&
                      this.state.familyDetails.kids.length !== 0
                      ? "Self, Spouse & Kids"
                      : this.state.familyDetails.spouse !== null
                        ? "Self , Spouse"
                        : "Self , Kids",
                  category: "Health",
                  suminsured: 1500000,
                  type: "Family",
                  PolicyOwner: this.state.userInfo.ageInDifferentForm,
                  subcategory: "Super_Top_Up",
                  premium: null,
                  family: family,
                  PolicyCode: "m_s",
                };
                this.state.nothavePolicyRecommendation.push(
                  superTopUp_Recommendation
                );
              }
            }
          }

          //highest age memeber
        }
      } else {
        let family = [];
        family.push({
          gender: this.state.userInfo.gender,
          memberCode: "S",
          age: this.state.userInfo.ageInDifferentForm,
          relation: "Self",
        });
        if (this.state.familyDetails.kids.length !== 0) {
          this.state.familyDetails.kids.forEach((items) => {
            if (items.gender === "M") {
              family.push({
                gender: "M",
                memberCode: "C",
                age: this.ageInDifferentFormFun(items.date),
                relation: "Son",
              });
            } else if (items.gender === "F") {
              family.push({
                gender: "F",
                memberCode: "C",
                age: this.ageInDifferentFormFun(items.date),
                relation: "Daughter",
              });
            }
          });
        }
        let maxCover;
        if (parseInt(this.state.userInfo.ageInDifferentForm) <= 44) {
          maxCover =
            (family.length <= 1
              ? maxcover_nonmetro_lessthan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate
              : dependent_maxcover_nonmetro_lessthan45) -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
        } else {
          maxCover =
            (family.length <= 1
              ? maxcover_nonmetro_morethan45 -
              parseInt(this.state.healthInsurance.suminsured) -
              corporate
              : dependent_maxcover_nonmetro_morethan45) -
            parseInt(this.state.healthInsurance.suminsured) -
            corporate;
        }
        if (
          (this.state.healthInsurance.suminsured <= 500000 &&
            this.state.corporateInsurance.suminsured === 0) ||
          (this.state.healthInsurance.suminsured === 0 &&
            this.state.corporateInsurance.suminsured <= 500000) ||
          (this.state.healthInsurance.suminsured <= 500000 &&
            this.state.corporateInsurance.suminsured <= 500000) ||
          (this.state.healthInsurance.suminsured > 500000 &&
            this.state.healthInsurance.suminsured <= 1000000 &&
            this.state.corporateInsurance.suminsured === 0) ||
          (this.state.healthInsurance.suminsured === 0 &&
            this.state.corporateInsurance.suminsured > 500000 &&
            this.state.corporateInsurance.suminsured <= 1000000) ||
          (this.state.healthInsurance.suminsured > 500000 &&
            this.state.healthInsurance.suminsured <= 1000000 &&
            this.state.corporateInsurance.suminsured <= 500000) ||
          (this.state.corporateInsurance.suminsured > 500000 &&
            this.state.corporateInsurance.suminsured <= 1000000 &&
            this.state.healthInsurance.suminsured <= 500000)
        ) {
          var Recommendation = {
            recommededCover: null,
            recommendedPolicy: "Optima Secure",
            membersCovered: family.length > 1 ? "Self & Kids" : "Self",
            category: "Health",
            suminsured: maxCover,
            type: family.length > 1 ? "Family" : "Individual",
            subcategory: "Optima_Restore",
            PolicyOwner: this.state.userInfo.ageInDifferentForm,
            premium: null,
            family: family,
            PolicyCode: "s",
          };
          this.state.nothavePolicyRecommendation.push(Recommendation);
        }

        if (this.state.policyTaken.super_top_up_insurance === false) {
          if (
            (this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured === 0) ||
            (this.state.healthInsurance.suminsured === 0 &&
              this.state.corporateInsurance.suminsured > 1000000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.corporateInsurance.suminsured > 500000) ||
            (this.state.healthInsurance.suminsured > 500000 &&
              this.state.healthInsurance.suminsured < 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured > 1000000 &&
              this.state.corporateInsurance.suminsured <= 500000) ||
            (this.state.healthInsurance.suminsured <= 500000 &&
              this.state.corporateInsurance.suminsured > 1000000)
          ) {
            if (maxCover <= 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up",
                membersCovered: "Self",
                category: "Health",
                suminsured: 1000000,
                subcategory: "Super_Top_Up",
                premium: null,
                PolicyOwner: this.state.userInfo.ageInDifferentForm,
                type: "Individual",
                family: family,
                PolicyCode: "m_s",
              };
              this.state.nothavePolicyRecommendation.push(
                superTopUp_Recommendation
              );
            } else if (maxCover > 1000000) {
              let superTopUp_Recommendation = {
                recommededCover: maxCover,
                recommendedPolicy: "Medisure Top-Up",
                membersCovered: "Self",
                category: "Health",
                suminsured: 1500000,
                type: "Individual",
                PolicyOwner: this.state.userInfo.ageInDifferentForm,
                subcategory: "Super_Top_Up",
                premium: null,
                family: family,
                PolicyCode: "m_s",
              };
              this.state.nothavePolicyRecommendation.push(
                superTopUp_Recommendation
              );
            }
          }
        }
      }
    }

    if (page === "Recommendations") {
      this.props.history.push("/recommendations");
    }
  };

  RecommendationforHomePolicy = (page) => {
    let { policyTaken } = this.state;

    if (
      parseInt(this.state.assets.valueofhomeContent_rent) -
      parseInt(this.state.homeInsurance.rent_content_cover) >
      1000 &&
      parseInt(this.state.assets.valueofhomeContent_rent) >
      parseInt(this.state.homeInsurance.rent_content_cover) &&
      this.state.assets.valueofhomeContent_rent !== ""
    ) {
      let HomeStructureRecommendation = {
        recommendedCover:
          parseInt(this.state.assets.valueofhomeContent_rent) -
          parseInt(this.state.homeInsurance.rent_content_cover),
        recommendedPolicy: "Home Content Insurance - Home Rental",
        category: "Others",
        subcategory: "Home Rent Content Insurance",
        membersCovered: "Home Content",
        premium: null,
      };
      this.state.Recommendations.push(HomeStructureRecommendation);
    }

    if (
      this.state.homeInsurance.Home_content_cover === true &&
      this.state.homeInsurance.Home_structure_cover === false &&
      this.state.assets.valueofhome !== ""
    ) {
      let HomeStructureRecommendation = {
        recommendedCover: this.state.assets.valueofhome,
        recommendedPolicy: "Home Structure Insurance - Own Home",
        category: "Others",
        subcategory: "Home Structure Insurance",
        membersCovered: "Home Structure",
        premium: null,
      };
      this.state.Recommendations.push(HomeStructureRecommendation);
      if (page === "Cyber") {
        this.props.history.push("/cyber-insurance");
      } else if (page === "Recommendations") {
        this.props.history.push("/recommendations");
      }
    }

    if (
      parseInt(this.state.assets.valueofhomeContent) -
      parseInt(this.state.homeInsurance.content_cover) >
      1000 &&
      parseInt(this.state.assets.valueofhomeContent) >
      parseInt(this.state.homeInsurance.content_cover) &&
      this.state.assets.valueofhomeContent !== ""
    ) {
      let Recommendation = {
        recommendedCover:
          parseInt(this.state.assets.valueofhomeContent) -
          parseInt(this.state.homeInsurance.content_cover),
        recommendedPolicy: "Home Content Insurance - Own Home",
        category: "Others",
        subcategory: "Home Content Insurance",
        membersCovered: "Home Contents",
        premium: null,
      };
      this.state.Recommendations.push(Recommendation);
      if (page === "Cyber") {
        this.props.history.push("/cyber-insurance");
      } else if (page === "Recommendations") {
        this.props.history.push("/recommendations");
      }
    }
    if (this.state.homeInsurance.Not_have === true) {
      let HomeStructureRecommendation = {
        recommendedCover:
          this.state.assets.valueofhome !== ""
            ? this.state.assets.valueofhome
            : 3000000,
        recommendedPolicy: "Home Structure Insurance - Own Home",
        category: "Others",
        subcategory: "Home Structure Insurance",
        membersCovered: "Home Structure",
        premium: null,
      };
      this.state.Recommendations.push(HomeStructureRecommendation);

      let Recommendation = {
        recommendedCover:
          this.state.assets.valueofhomeContent !== ""
            ? this.state.assets.valueofhomeContent
            : 700000,
        recommendedPolicy: "Home Content Insurance - Own Home",
        category: "Others",
        subcategory: "Home Content Insurance",
        membersCovered: "Home Contents",
        premium: null,
      };
      this.state.Recommendations.push(Recommendation);

      let HomeContentRentRecommendation = {
        recommendedCover: this.state.assets.valueofhomeContent_rent,
        recommendedPolicy: "Home Content Insurance - Rental Home",
        category: "Others",
        subcategory: "Home Rent Content Insurance",
        membersCovered: "Home Content",
        premium: null,
      };
      this.state.Recommendations.push(HomeContentRentRecommendation);
      if (page === "Cyber") {
        this.props.history.push("/cyber-insurance");
      } else if (page === "Recommendations") {
        this.props.history.push("/recommendations");
      }
    } else {
      if (page === "Cyber") {
        this.props.history.push("/cyber-insurance");
      } else if (page === "Recommendations") {
        this.props.history.push("/recommendations");
      }
    }
  };

  setOtp = (OTP) => {
    this.setState({ OTP });
  };

  RecommendationForIdonthavePolicy = () => {
    let cyberSachetRecommendation;

    if (
      this.state.familyDetails.father !== null ||
      this.state.familyDetails.mother !== null ||
      this.state.familyDetails.spouse !== null ||
      this.state.familyDetails.fatherinlaw !== null ||
      this.state.familyDetails.motherinlaw !== null ||
      this.state.familyDetails.kids.length !== 0
    ) {
      cyberSachetRecommendation = {
        recommendedCover: 100000,
        currentCover: 0,
        recommendedPolicy: "Cyber Sachet",
        membersCovered: "Family",
        category: "Others",
        subcategory: "Cyber_Sachet",
        premium: null,
      };
    } else {
      cyberSachetRecommendation = {
        recommendedCover: 100000,
        currentCover: 0,
        recommendedPolicy: "Cyber Sachet",
        membersCovered: "Myself",
        category: "Others",
        subcategory: "Cyber_Sachet",
        premium: null,
      };
    }

    if (this.state.assets.valueofhome !== "") {
      let HomeStructureRecommendation = {
        recommendedCover: this.state.assets.valueofhome,
        recommendedPolicy: "Home Structure Insurance",
        category: "Others",
        subcategory: "Home Structure Insurance",
        membersCovered: "Home Structure",
        premium: null,
      };
      this.state.nothavePolicyRecommendation.push(HomeStructureRecommendation);
    }

    if (this.state.assets.valueofhomeContent !== "") {
      let Recommendation = {
        recommendedCover: this.state.assets.valueofhomeContent,
        recommendedPolicy: "Home Content Insurance",
        category: "Others",
        subcategory: "Home Content Insurance",
        membersCovered: "Home Contents",
        premium: null,
      };
      this.state.nothavePolicyRecommendation.push(Recommendation);
    }

    if (this.state.assets.valueofhomeContent_rent !== "") {
      let Recommendation = {
        recommendedCover: this.state.assets.valueofhomeContent_rent,
        recommendedPolicy: "Home Content Insurance - Rent",
        category: "Others",
        subcategory: "Home Rent Content Insurance",
        membersCovered: "Home Contents",
        premium: null,
      };
      this.state.nothavePolicyRecommendation.push(Recommendation);
    }

    var Policies = {
      // CriticalIllnessRecommendation: {
      //   recommendedCover: null,
      //   currentCover: this.state.crit,
      //   recommendedPolicy: "Critical Illness Insurance",
      //   membersCovered: "Myelf",
      //   category: "Health",
      //   subcategory: "Critical_Illness_Insurance",
      //   premium: null,
      // },
      CyberRecommendation: cyberSachetRecommendation,
    };

    var a = 0;
    for (let items in Policies) {
      a++;
      this.state.nothavePolicyRecommendation.push(Policies[items]);
    }
    this.HealthRecommendations_nothave("Non");
    this.props.history.push("/recommendations");

    window.gtag("event", "PERSONAL_NOT_HAVE_POLICY_DETAILS", {
      event_category: "DETAILS",
      event_label: "PERSONAL_NOT_HAVE_POLICY",
    });
  };

  ReturnMaxCover = () => {
    if (this.state.userInfo.city === "Metro") {
      if (this.state.userInfo.ageInDifferentForm < 45) {
        if (
          this.state.familyDetails.spouse === null &&
          this.state.familyDetails.father === null &&
          this.state.familyDetails.mother === null &&
          this.state.familyDetails.motherinlaw === null &&
          this.state.familyDetails.fatherinlaw === null
        ) {
          return maxcover_metro_lessthan45;
        } else {
          return dependent_maxcover_metro_lessthan45;
        }
      } else {
        if (
          this.state.familyDetails.spouse === null &&
          this.state.familyDetails.father === null &&
          this.state.familyDetails.mother === null &&
          this.state.familyDetails.motherinlaw === null &&
          this.state.familyDetails.fatherinlaw === null
        ) {
          return maxcover_metro_morethan45;
          // await context.updateCreditScore("mediclaim", "has_insured", "less");
        } else {
          return dependent_maxcover_metro_morethan45;
        }
      }
    } else {
      if (this.state.userInfo.ageInDifferentForm < 45) {
        if (
          this.state.familyDetails.spouse === null &&
          this.state.familyDetails.father === null &&
          this.state.familyDetails.mother === null &&
          this.state.familyDetails.motherinlaw === null &&
          this.state.familyDetails.fatherinlaw === null
        ) {
          return maxcover_nonmetro_lessthan45;
        } else {
          return dependent_maxcover_nonmetro_lessthan45;
        }
      } else {
        if (
          this.state.familyDetails.spouse === null &&
          this.state.familyDetails.father === null &&
          this.state.familyDetails.mother === null &&
          this.state.familyDetails.motherinlaw === null &&
          this.state.familyDetails.fatherinlaw === null
        ) {
          return maxcover_nonmetro_morethan45;
        } else {
          return dependent_maxcover_nonmetro_morethan45;
        }
      }
    }
  };
  ScoreCalculation = () => {
    let CreditScore = 0;
    // Health Recommendation score
    if (
      this.state.policyTaken.Health_insurance === false &&
      this.state.policyTaken.Corporate_insurance === false
    ) {
      CreditScore += scoring_logics["default"]["mediclaim"];
    } else if (
      this.ReturnMaxCover() >
      parseInt(this.state.healthInsurance.suminsured) +
      parseInt(this.state.corporateInsurance.suminsured)
    ) {
      CreditScore += parseInt(
        scoring_logics["less_than_recommended"]["mediclaim"]
      );
    } else if (
      this.ReturnMaxCover() <=
      parseInt(this.state.healthInsurance.suminsured) +
      parseInt(this.state.corporateInsurance.suminsured)
    ) {
      CreditScore += parseInt(
        scoring_logics["greate_than_recommended"]["mediclaim"]
      );
    }

    // super top up

    if (this.state.policyTaken.super_top_up_insurance === false) {
      CreditScore += scoring_logics["default"]["super_top_up"];
    } else if (
      parseInt(this.state.super_topup.suminsured) < this.ReturnMaxCover()
      // parseInt(this.state.policyTaken.Health_insurance) +
      //   parseInt(this.state.policyTaken.Corporate_insurance)
    ) {
      CreditScore += parseInt(
        scoring_logics["less_than_recommended"]["super_top_up"]
      );
    } else if (
      parseInt(this.state.super_topup.suminsured) >= this.ReturnMaxCover()
    ) {
      CreditScore += parseInt(
        scoring_logics["greate_than_recommended"]["super_top_up"]
      );
    }

    //  Cyber
    if (this.state.policyTaken.Cyber === false) {
      CreditScore += parseInt(scoring_logics["default"]["cyber"]);
    } else if (
      parseInt(this.state.cyberInsurance.suminsured) < 100000
      // parseInt(this.state.policyTaken.Health_insurance) +
      //   parseInt(this.state.policyTaken.Corporate_insurance)
    ) {
      CreditScore += scoring_logics["less_than_recommended"]["cyber"];
    } else if (parseInt(this.state.cyberInsurance.suminsured) >= 100000) {
      CreditScore += parseInt(
        scoring_logics["greate_than_recommended"]["cyber"]
      );
    }

    // Home Insurance

    if (
      this.state.homeInsurance.Home_structure_cover !== false &&
      this.state.assets.valueofhome !== ""
    ) {
      if (
        parseInt(this.state.homeInsurance.structure_cover) <
        parseInt(this.state.assets.valueofhome)
      ) {
        CreditScore +=
          scoring_logics["less_than_recommended"]["home_structure"];
      } else if (
        parseInt(this.state.homeInsurance.structure_cover) >=
        parseInt(this.state.assets.valueofhome)
      ) {
        CreditScore +=
          scoring_logics["greate_than_recommended"]["home_structure"];
      }
    }

    if (
      this.state.homeInsurance.Home_content_cover !== false &&
      this.state.assets.valueofhomeContent !== ""
    ) {
      if (
        parseInt(this.state.homeInsurance.content_cover) <
        parseInt(this.state.assets.valueofhomeContent)
      ) {
        CreditScore += scoring_logics["less_than_recommended"]["home_content"];
      } else if (
        parseInt(this.state.homeInsurance.content_cover) >=
        parseInt(this.state.assets.valueofhomeContent)
      ) {
        CreditScore +=
          scoring_logics["greate_than_recommended"]["home_content"];
      }
    }

    if (
      this.state.homeInsurance.Home_rent_content_cover !== false &&
      this.state.assets.valueofhomeContent_rent !== ""
    ) {
      if (
        parseInt(this.state.homeInsurance.rent_content_cover) <
        parseInt(this.state.assets.valueofhomeContent_rent)
      ) {
        CreditScore +=
          scoring_logics["less_than_recommended"]["rented_home_content"];
      } else if (
        parseInt(this.state.homeInsurance.rent_content_cover) >=
        parseInt(this.state.assets.valueofhomeContent_rent)
      ) {
        CreditScore +=
          scoring_logics["greate_than_recommended"]["rented_home_content"];
      }
    }
    // setTimeout(() => {
    //   console.log(this.state.liveCreditScore);
    // }, 5000);
    this.setState({ liveCreditScore: CreditScore }, () => {
      return;
    });
  };

  RecommendNonSelectedPolicies = () => {
    const { policyTaken } = this.state;

    if (
      policyTaken.Health_insurance === false &&
      policyTaken.Corporate_insurance === false
    ) {
      this.HealthRecommendations("Non");
    }
    // if (policyTaken.Personal_Accident_Insurance === false) {
    //   let maxCover = this.state.userInfo.salary * 10;
    //   var Recommendation = {
    //     recommendedCover: null,
    //     currentCover: 0,
    //     recommendedPolicy: 'Personal Accident',
    //     membersCovered: 'Myself',
    //     category: 'Health',
    //     suminsured: maxCover,
    //     subcategory: 'Personal_Accident_Insurance',
    //     premium: null
    //   }
    //   const personalPresent = this.state.Recommendations.find(x => x.subcategory === 'Personal_Accident_Insurance')
    //   if (personalPresent) {
    //     this.filteredRecommendations('Personal_Accident_Insurance')

    //     this.state.Recommendations.push(Recommendation)
    //   } else {
    //     this.state.Recommendations.push(Recommendation)
    //   }
    // }

    // if (policyTaken.critical_illness_insurance === false) {
    //   let Recommendation = {
    //     recommendedCover: parseInt(this.state.userInfo.salary) + 100000,
    //     currentCover: this.state.citical_illness.suminsured,
    //     recommendedPolicy: "Critical Illness Insurance",
    //     membersCovered: "Self",
    //     category: "Health",
    //     subcategory: "Critical_Illness_Insurance",
    //     premium: null,
    //   };
    //   const criticalPresent = this.state.Recommendations.find(
    //     (x) => x.subcategory === "Critical_Illness_Insurance"
    //   );
    //   if (criticalPresent) {
    //     this.filteredRecommendations("Critical_Illness_Insurance");

    //     this.state.Recommendations.push(Recommendation);
    //   } else {
    //     this.state.Recommendations.push(Recommendation);
    //   }
    // }

    if (policyTaken.Cyber === false || policyTaken.Cyber === true) {
      this.CyberRecommendations();
    } else {
      let Recommendation = {
        recommendedCyber: null,
      };
      this.state.Recommendations.push(Recommendation);
    }

    if (policyTaken.Home_Rental === false) {
      if (this.state.assets.valueofhome !== "") {
        let HomeStructureRecommendation = {
          recommendedCover: this.state.assets.valueofhome,
          recommendedPolicy: "Home Structure Insurance - Own Home",
          category: "Others",
          subcategory: "Home Structure Insurance",
          membersCovered: "Home Structure",
          premium: null,
        };
        this.state.Recommendations.push(HomeStructureRecommendation);
      }

      if (this.state.assets.valueofhomeContent !== "") {
        let Recommendation = {
          recommendedCover: this.state.assets.valueofhomeContent,
          recommendedPolicy: "Home Content Insurance - Own Home",
          category: "Others",
          subcategory: "Home Content Insurance",
          membersCovered: "Home Contents",
          premium: null,
        };
        this.state.Recommendations.push(Recommendation);
      }

      if (this.state.assets.valueofhomeContent_rent !== "") {
        let Recommendation = {
          recommendedCover: this.state.assets.valueofhomeContent_rent,
          recommendedPolicy: "Home Content Insurance - Rented Home",
          category: "Others",
          subcategory: "Home Rent Content Insurance",
          membersCovered: "Home Contents",
          premium: null,
        };
        this.state.Recommendations.push(Recommendation);
      }
    }
  };

  CriticalIllnessRecommendations = (page) => {
    let maxCover;

    if (
      parseInt(this.state.citical_illness.suminsured) <=
      parseInt(this.state.userInfo.salary)
    ) {
      maxCover =
        parseInt(this.state.userInfo.salary) -
        parseInt(this.state.citical_illness.suminsured) +
        100000;
    } else {
      maxCover = parseInt(this.state.citical_illness.suminsured) + 100000;
    }

    let Recommendation = {
      recommendedCover: maxCover,
      currentCover: this.state.citical_illness.suminsured,
      recommendedPolicy: "Critical Illness Insurance",
      membersCovered: "Self",
      category: "Health",
      subcategory: "Critical_Illness_Insurance",
      premium: null,
    };
    const criticalPresent = this.state.Recommendations.find(
      (x) => x.subcategory === "Critical_Illness_Insurance"
    );
    if (criticalPresent) {
      this.filteredRecommendations("Critical_Illness_Insurance");

      this.state.Recommendations.push(Recommendation);
    } else {
      this.state.Recommendations.push(Recommendation);
    }

    if (page === "Recommendations") {
      this.props.history.push("/recommendations");
    }
  };

  CyberRecommendations = () => {
    let cyberRecommendation;
    let cyberSachetRecommendation;
    // if (
    //   this.state.familyDetails.father !== null ||
    //   this.state.familyDetails.mother !== null ||
    //   this.state.familyDetails.spouse !== null ||
    //   this.state.familyDetails.fatherinlaw !== null ||
    //   this.state.familyDetails.motherinlaw !== null ||
    //   this.state.familyDetails.kids.length !== 0
    // ) {
    //   cyberRecommendation = {
    //     recommendedCover: 500000,
    //     currentCover: 0,
    //     recommendedPolicy: "E-Secure",
    //     membersCovered: "Myself",
    //     category: "Others",
    //     subcategory: "Cyber_Insurance",
    //     premium: null,
    //   };
    // } else {
    //   cyberRecommendation = {
    //     recommendedCover: 100000,
    //     currentCover: 0,
    //     recommendedPolicy: "E-Secure",
    //     membersCovered: "Myself",
    //     category: "Others",
    //     subcategory: "Cyber_Insurance",
    //     premium: null,
    //   };
    // }

    if (
      this.state.familyDetails.father !== null ||
      this.state.familyDetails.mother !== null ||
      this.state.familyDetails.spouse !== null ||
      this.state.familyDetails.fatherinlaw !== null ||
      this.state.familyDetails.motherinlaw !== null ||
      this.state.familyDetails.kids.length !== 0
    ) {
      cyberSachetRecommendation = {
        recommendedCover: 100000,
        currentCover: 0,
        recommendedPolicy: "Cyber Sachet",
        membersCovered: "Family",
        category: "Others",
        subcategory: "Cyber_Sachet",
        premium: null,
      };
    } else {
      cyberSachetRecommendation = {
        recommendedCover: 100000,
        currentCover: 0,
        recommendedPolicy: "Cyber Sachet",
        membersCovered: "Myself",
        category: "Others",
        subcategory: "Cyber_Sachet",
        premium: null,
      };
    }

    // const cyberPresent = this.state.Recommendations.find(
    //   (x) => x.subcategory === "Cyber_Insurance"
    // );
    // if (cyberPresent) {
    //   this.filteredRecommendations("Cyber_Insurance");

    //   this.state.Recommendations.push(cyberRecommendation);
    // } else {
    //   this.state.Recommendations.push(cyberRecommendation);
    // }

    const cyberSachetPresent = this.state.Recommendations.find(
      (x) => x.subcategory === "Cyber_Sachet"
    );
    if (cyberSachetPresent) {
      this.filteredRecommendations("Cyber_Sachet");

      this.state.Recommendations.push(cyberSachetRecommendation);
    } else {
      this.state.Recommendations.push(cyberSachetRecommendation);
    }
  };

  render() {
    return (
      <div className="app-provider">
        <AppContext.Provider
          value={{
            state: this.state,
            updateGlobalState: this.updateGlobalState,
            updatePolicyTypes: this.updatePolicyTypes,
            healthAbortControllerStart: this.healthAbortControllerStart,
            updateRecommendationsStateOnBack:
              this.updateRecommendationsStateOnBack,
            updateHealthInsurance: this.updateHealthInsurance,
            updateHealthInsuranceState: this.updateHealthInsuranceState,
            updateCorporateInsuranceState: this.updateCorporateInsuranceState,
            updateCriticalInsuranceState: this.updateCriticalInsuranceState,
            updateCorporate_insurance: this.updateCorporate_insurance,
            RecommendationforHomePolicy: this.RecommendationforHomePolicy,
            updateSuperTopUp: this.updateSuperTopUp,
            updateDNDstatus: this.updateDNDstatus,
            updatePersonalAccidentSumInsured:
              this.updatePersonalAccidentSumInsured,
            updateCritical_illness: this.updateCritical_illness,
            updateHome_insurance: this.updateHome_insurance,
            updateCyberSumInsured: this.updateCyberSumInsured,
            updateCreditScore: this.updateCreditScore,
            RecommendNonSelectedPolicies: this.RecommendNonSelectedPolicies,
            RecommendationForIdonthavePolicy:
              this.RecommendationForIdonthavePolicy,
            setOtp: this.setOtp,
            updateRoles: this.updateRoles,
            updatePersoanlInfo: this.updatePersoanlInfo,
            updateMobileNumber: this.updateMobileNumber,
            updateFamilyDetails: this.updateFamilyDetails,
            updateLibilities: this.updateLibilities,
            updateLibilities: this.updateLibilities,
            updateLastPage: this.updateLastPage,
            updateSuperTopUpState: this.updateSuperTopUpState,
            updateMotorState: this.updateMotorState,
            makeFamilyarray: this.makeFamilyarray,
            updateMotorExistingPolicies: this.updateMotorExistingPolicies,
            updatePersonalAccState: this.updatePersonalAccState,
            updateCyberStateSumInsured: this.updateCyberStateSumInsured,
            switch_account: this.switch_account,
            ScoreCalculation: this.ScoreCalculation,
          }}
        >
          {this.props.children}
        </AppContext.Provider>
      </div>
    );
  }
}

export default withRouter(AppProvider);
