/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import kompassintro from "../svg/kompassintro.svg";
import initImage from "../svg/initImage.svg"
import Carousal from "./Carousal.js"
import PersonalDetails from "./PersonalDetails";
import OTPvalidation from "./Otpvalidation";
import Income from "./Income";
import Assets from "./Assets";
import Familydetails from "./Familydetails";
import ExistingPolicy from "./ExistingPolicy";
import Insurancedetails from "./Insurancedetails";
class Onboarding extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: true
    }
    this.nextClicked = this.nextClicked.bind(this);
  }
  nextClicked() {
    this.props.history.push("/login");
    console.log("click");
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ show: false })
    }, 3000)
  }

  render() {
    return (
      <div id="slide" class="onboarding_maincomponent">

          {this.state.show ?
            <img src={initImage} width="100%" alt="image-init" /> :
            <div id="slide">

              <Carousal handleClick={this.nextClicked} />
            </div>
          }

        {/* <OTPvalidation/> */}

        {/* <PersonalDetails/> */}
        {/* <Familydetails /> */}

        {/* <Income/> */}

        {/* <Assets/> */}
        {/* <Insurancedetails/> */}

        {/* <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <img
            style={{ width: 170, height: "auto" }}
            src="kompass logo final-01.png"
          />
        </div>
        <div style={{ backgroundColor: "white" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="assets-svg">
              <img style={{ marginRight: "20px" }} src={kompassintro} />
            </div>
          </div>

          <div>
            <div class="onbording_content">
              <p>
                Change and uncertainty are part of life. As a shield against
                life’s adversities, insurance acts as your safety net and gives
                you peace of mind. With insurance on your side, you can be
                assured about the security of your life and that of your loved
                ones.
              </p>
              <p>
                Just buying insurance is not enough, you should also undertake
                timely review of your portfolio to ensure you are adequately
                covered.
              </p>
              <p>
                Here is KOMPASS to guide you on any insurance gaps and suggest
                ways to overcome them.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button class="btn" onClick={() => this.nextClicked()}>
              Let's Start 
            </button>
          </div>
        </div> */}

      </div>
    );
  }
}

export default withRouter(Onboarding);
