import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AppContext from "../context/AppContext";
import { kompass_encrypt_fun } from "../commonfunctions/commonfunction";
import Loader from "./Loader.js";
import { GA_trigger } from "../config/ga";
import {secure_data_decode} from "../config/enc_dec";
import { logDOM } from "@testing-library/react";
import kompasslogin from "../svg/kompasslogin.svg";
import kompass from "../svg/Kompass.svg";
// crypto file
let CryptoJS = require("crypto-js");

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: 0,
      otpstatus: false,
      roles: {
        guest: false,
        agent: false,
      },
      warning: false,
      msg: "",
      wcolor: "",
      DND: false,
      otp_sending_process: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const contextStateMobileNumber = this.context.state;
    this.setState({
      mobile: contextStateMobileNumber.mobileNumber,
      DND: contextStateMobileNumber.DND,
    });
  }

  componentWillUnmount() {
    this.context.updateLastPage("/login");
  }

  authTokenGeneration() {
    alert("token");
  }

  changestyle = (id) => {
    if (id === "mobilelabel") {
      document.getElementById("mobile").placeholder = "";
      document.getElementById(id).style.display = "inline";
      document.getElementById(id).style.position = "relative";
      document.getElementById(id).style.top = "-57px";
      document.getElementById(id).style.left = "35px";
      document.getElementById(id).style.padding = "2px";
      document.getElementById("mobile").borderColor = "red";
      document.getElementById(id).style.backgroundColor = "white";
      document.getElementById(id).style.fontWeight = "500px";
    }
  };

  roleChangeAgent(context) {
    var roles = { ...this.state.roles };
    roles.agent = true;
    this.setState({ roles }, () => {
      context.updateRoles(this.state.roles);
    });
  }

  generateotp = async () => {
    this.setState({ otp_sending_process: true });
    try {
      let result = await fetch(`${process.env.REACT_APP_API_URL}/generateOTP`, {
        method: "post",
        headers: {
          "content-type": "application/json",
          // "Content-Security-Policy": "default-src none",
          Authorization: window.sessionStorage.getItem("AdvisoryToken"),
        },
        body: JSON.stringify({
          // mobileno: kompass_encrypt_fun(this.state.mobile),
          // mobileno: CryptoJS.AES.encrypt(this.state.mobile,process.env.REACT_APP_ENC_SECRET_KEY).toString(),
          //enc fun 
           mobileno: CryptoJS.AES.encrypt(this.state.mobile,process.env.REACT_APP_ENC_SECRET_KEY).toString(),
        }),
      });
    
      result=await result.json()
      let finalResult = await secure_data_decode(result)
      if (finalResult.status !== "Err") {
        this.context.setOtp(finalResult);
        this.setState({
          warning: true,
          msg: "OTP sent successfully.",
          wcolor: "green",
        });
        setTimeout(() => {
          this.setState({ warning: false });
        }, 3000);

        this.props.history.push("/validation");
        this.setState({ otp_sending_process: false });
        window.gtag("event", "OTP FETCHED", {
          event_category: "LOGIN",
          event_label: "OTP FETCHED SUCCESSFULLY",
        });
      } else if (finalResult.error === "Limit Exceed") {
        this.setState({
          warning: true,
          msg: "Your OTP request limit exceeds. Please try after one hour.",
          wcolor: "red",
        });
        setTimeout(() => {
          this.setState({ warning: false });
        }, 3000);
        this.setState({ otp_sending_process: false });
      } else if (finalResult.status === "Err" && finalResult.data) {
        this.setState({
          warning: true,
          msg: finalResult.data,
          wcolor: "red",
          otp_sending_process: false,
          mobile: 0,
        });
      }
    } catch (err) {
      this.setState({
        warning: true,
        msg: "Something Went Wrong Please try again",
        wcolor: "red",
      });
      setTimeout(() => {
        this.setState({ warning: false });
      }, 3000);
      this.setState({ otp_sending_process: false });
    }
  };

  render() {
    return (
      <div id="slide">
        <AppContext.Consumer>
          {(context) => (
            <React.Fragment>
              {this.authTokenGeneration}
              <div className="onboarding_maincomponent">
                <div
                  style={{
                    backgroundColor: "white",
                    paddingBottom: 15,
                    marginBottom: 15,
                  }}
                >
                  <div className="kompass_logo">
                    <img src={kompass} alt="img" />
                  </div>

                  <div
                    className="login_logo"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 30,
                      marginTop: 20,
                    }}
                  >
                    <img src={kompasslogin} />
                  </div>

                  <div style={{ height: 40 }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <input
                        onFocus={() => this.changestyle("mobilelabel")}
                        onBlur={() =>
                          (document.getElementById(
                            "mobilelabel"
                          ).style.display = "none")
                        }
                        type="number"
                        value={context.state.mobileNumber}
                        onChange={(e) =>
                          this.setState({ mobile: e.target.value }, () => {
                            context.updateMobileNumber(this.state.mobile);
                          })
                        }
                        // type="number"
                        className="mobile_text_field"
                        id="mobile"
                        placeholder="Enter Your Mobile Number"
                      />
                    </div>
                    <label
                      style={{
                        display: "none",
                        fontSize: 14,
                        color: "#6d6d6d",
                      }}
                      id="mobilelabel"
                    >
                      Mobile Number
                    </label>
                  </div>
                  <p className="warning_title">
                    Your mobile number is perfectly safe with us. We promise not
                    to spam you or share your details with anyone else.
                  </p>
                  {this.state.warning === false ? (
                    <></>
                  ) : (
                    // <div
                    //   style={{
                    //     display: "flex",
                    //     alignItems: "center",
                    //     paddingLeft: 15,
                    //     paddingTop: 5,
                    //   }}
                    // >
                    //   <input
                    //     type="checkbox"
                    //     onClick={() =>
                    //       this.state.DND
                    //         ? this.setState({ DND: false }, () =>
                    //             context.updateDNDstatus(false)
                    //           )
                    //         : this.setState({ DND: true }, () =>
                    //             context.updateDNDstatus(true)
                    //           )
                    //     }
                    //     checked={this.state.DND}
                    //   />
                    //   <div>
                    //     <label
                    //       onClick={() =>
                    //         this.props.history.push("/terms&conditions")
                    //       }
                    //       style={{
                    //         fontSize: 10,
                    //         color: "lightgrey",
                    //         paddingLeft: 10,
                    //       }}
                    //     >
                    //       I agree to the{" "}
                    //       <span style={{ color: "dodgerblue" }}>
                    //         Terms & Conditions
                    //       </span>
                    //     </label>
                    //   </div>
                    // </div>
                    <p
                      style={{
                        fontSize: 13,
                        color: this.state.wcolor,
                        margin: "10px 5px 20px 15px",
                      }}
                    >
                      {this.state.msg}
                    </p>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 15,
                    paddingTop: 5,
                  }}
                >
                  <input
                    type="checkbox"
                    onClick={() =>
                      this.state.DND
                        ? this.setState({ DND: false }, () =>
                            context.updateDNDstatus(false)
                          )
                        : this.setState({ DND: true }, () =>
                            context.updateDNDstatus(true)
                          )
                    }
                    checked={this.state.DND}
                  />
                  <div>
                    <label
                      onClick={() =>
                        this.props.history.push("/terms&conditions")
                      }
                      style={{
                        fontSize: 10,
                        color: "lightgrey",
                        paddingLeft: 10,
                      }}
                    >
                      I agree to the
                      <span style={{ color: "dodgerblue" }}>
                        Terms & Conditions
                      </span>
                    </label>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  {this.state.otp_sending_process === false ? (
                    this.state.mobile.length > 9 &&
                    this.state.mobile.length < 11 &&
                    this.context.state.DND ? (
                      <button
                        onClick={() => this.generateotp(context)}
                        className="btn"
                      >
                        Generate OTP
                      </button>
                    ) : (
                      <button
                        style={{ backgroundColor: "lightgrey" }}
                        className="btn"
                      >
                        Generate OTP
                      </button>
                    )
                  ) : (
                    <button style={{ opacity: 0.7 }} className="btn">
                      <Loader balls_color="white" />
                    </button>
                  )}
                </div>
                <br />
              </div>
            </React.Fragment>
          )}
        </AppContext.Consumer>
      </div>
    );
  }
}

Login.contextType = AppContext;

export default withRouter(Login);
