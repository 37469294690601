import React, { Component } from "react";
import AppContext from "../context/AppContext";
import { withRouter } from "react-router-dom";
import svgPerson from "../svg/svgPerson.svg";
import incomePerson from "../svg/incomeperson.svg";
import {
  kompass_encrypt_fun,
  // userDetailEncrypter,
} from "../commonfunctions/commonfunction";
import { GA_trigger } from "../config/ga";
import {secure_data_decode} from "../config/enc_dec";
import HDFC_Bank_Logo from "../svg/HDFC_Bank_Logo.svg";
import HDFC_Ergo_Logo from "../svg/HDFC_Ergo_Logo.svg";
var CryptoJS = require("crypto-js");

class Income extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salary: 0,
    };
  }
  goBack() {
    // this.props.history.push("/family-details");
    this.props.history.push("/allthing-money");
  }
  salaryChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const contextStateUserInfo = this.context.state;
    this.setState({
      salary: contextStateUserInfo.userInfo.salary,
    });

    this.context.state.familyArray = [{ Relation: "Self", memberCode: "S" }];
    this.context.state.membersCovered = "Self";
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    this.context.updateLastPage("/income");
  }
  componentDidUpdate() {}

  updateUserInfo = async () => {
    console.log("incomeJS");
    try {
      let data = await fetch(
        `${process.env.REACT_APP_API_URL}/updateUserInfo`,
        {
          method: "post",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({
            personal_data: CryptoJS.AES.encrypt(
            kompass_encrypt_fun({
              name: this.context.state.userInfo.name,
              age: this.context.state.userInfo.ageInDifferentForm,
              dob: this.context.state.userInfo.age,
              userid: this.context.state.userInfo.userId,
              city: this.context.state.userInfo.selectedCity,
              gender: this.context.state.userInfo.gender,
              citytype: this.context.state.userInfo.city,
              MobileNo: this.context.state.mobileNumber,
              family: this.context.state.familyArray,
              salary: this.state.salary,
              hostname: window.location.href,
            }),
            process.env.REACT_APP_ENC_SECRET_KEY
          ).toString(),
          }),
        }
      );
      let result = await secure_data_decode(data).json();
      if (result) {
        GA_trigger.EVENT(
          "INCOME DETAILS UPDATED",
          "INCOME DETAILS UPDATED",
          "INCOME DETAILS"
        );
        window.gtag("event", "PERSONAL_INCOME_DETAILS", {
          event_category: "DETAILS",
          event_label: "PERSONAL INCOME UPDATED",
        });
      }
    } catch (err) {
      return;
    }
  };

  nextClicked = (context) => {
    var userInfo = {
      name: context.state.userInfo.name,
      age: context.state.userInfo.age,
      city: context.state.userInfo.city,
      gender: context.state.userInfo.gender,
      selectedCity: context.state.userInfo.selectedCity,
      salary: this.state.salary,
      ageInDifferentForm: context.state.userInfo.ageInDifferentForm,
      userid: context.state.userInfo.userId,
    };
    this.context.makeFamilyarray(this.updateUserInfo);
    this.context.updatePersoanlInfo(userInfo);
    this.props.history.push("/assets");
  };
  inWords = (num) => {
    var a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    var b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
        : "";
    return str;
  };

  render() {
    let suminsured = this.state.salary;
    if (suminsured) {
      if (suminsured.toString().length !== 0) {
        if (
          suminsured.toString().length > 4 &&
          suminsured.toString().length < 7
        ) {
          suminsured = this.inWords(this.state.salary).toUpperCase();
        } else {
          suminsured = this.inWords(this.state.salary).toUpperCase();
        }
      } else {
        suminsured = 0;
      }
    }

    return (
      <div>
        <div className="header-area">
          <div className="">
            <img
              onClick={() => this.goBack()}
              className="back-icon"
              src={process.env.PUBLIC_URL + "/back.png"}
            />
          </div>
          <div className="header-text">
            Income (Step {this.context.state.AppSteps[2]}/
            {this.context.state.AppSteps.length})
          </div>
          <div class="progress-container">
            <div class="progress-bar" id="myBar" style={{ width: "60%" }}></div>
          </div>
        </div>
        <div id="slide" className="main-page">
          <div className="red-background">
            <div className="page-title">Current Annual Income</div>
          </div>
          <div className="page-content">
            <div className="input-box">
              <br />
              <div class="income_amount_container">
                <label style={{ fontSize: 45, margin: "unset" }}>&#8377;</label>
                <input
                  name="salary"
                  value={this.state.salary > 0 && this.state.salary}
                  onChange={this.salaryChange}
                  className="salary-input suminsured-input other_family_input"
                  id="salary"
                  type="number"
                  placeholder="1,00,000"
                  required
                />
              </div>
              <div style={{ width: "100%" }}>
                <p class="textAmount">
                  {this.state.salary ? suminsured : this.state.salary}
                </p>
              </div>

              <input
                name="salary"
                onChange={this.salaryChange}
                value={this.state.salary}
                type="range"
                min="100000"
                max="50000000"
                step="100000"
                className="slider"
                id="salary-range"
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="min_amt">₹1 Lakh</div>
                <span
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    color: "#000000",
                  }}
                >
                  Slide to enter amount
                </span>
                <div className="max_amt">₹5 crore+</div>
              </div>
            </div>
            <div>
              <img
                style={{
                  marginLeft: "20px",
                  height: 90,
                  position: "absolute",
                  top: 67,
                  left: 1,
                }}
                src={incomePerson}
              />
            </div>
            <AppContext.Consumer>
              {(context) => (
                <React.Fragment>
                  <div
                    style={{
                      paddingTop: "15%",
                      paddingBottom: "10%",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <div className="brand_icons_header">Powered by</div>
                    <div className="brand_icons">
                      <img
                        src={HDFC_Bank_Logo}
                        style={{ marginRight: "8px", height: "20px" }}
                        alt="HDFC_BANK"
                      />
                      <img
                        src={HDFC_Ergo_Logo}
                        style={{ marginLeft: "8px" }}
                        alt="HDFC_ERGO"
                      />
                    </div>
                  </div>
                  <div class="bottom_btn_container">
                    {this.state.salary >= 100000 ? (
                      <button
                        className="btn"
                        onClick={() => this.nextClicked(context)}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        className="btn"
                        style={{ backgroundColor: "lightgrey" }}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </React.Fragment>
              )}
            </AppContext.Consumer>
          </div>
        </div>
      </div>
    );
  }
}

Income.contextType = AppContext;

export default withRouter(Income);
