import React from 'react'
import {Route, Redirect} from "react-router-dom"

const UnproctedRoutes = ({ children, ...rest }) => {
    const isAuth = window.sessionStorage.getItem("sessionToken")

    return (
        <Route
            {...rest}
            render={({ location }) => {
                return isAuth ? (

                    <Redirect
                        to={{

                            pathname: '/personal_details',

                            state: { from: location },

                        }}

                    />

                ) : (

                    children

                );

            }}

        ></Route>

    );

};



export default UnproctedRoutes