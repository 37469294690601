import React from "react";
import Carousel from "nuka-carousel";
import carousal_svg_1 from "../svg/carousal_svg_1.svg";
import carousal_svg_2a from "../svg/carousal_svg_2a.png";
import carousel_svg_3 from "../svg/carousel_svg_3.svg";
import carousel_svg_4 from "../svg/carousel_svg_4.svg";

// import letsGetPersonal from "../svg/letsGetPersonal.svg"
// import laFamily from "../svg/laFamily.svg"

function carousal({ handleClick }) {
  return (
    <Carousel
      style={{ height: "100vh" }}
      autoplay
      pauseOnHover={true}
      swiping={true}
      speed={500}
    >
      <div style={{ position: "relative" }}>
        <img width="100%" src={carousal_svg_1}></img>
        <div className="carousal_header"> Life is a celebration! </div>
        <div className="carousal_content">
          Marked with one milestone after another!
        </div>
        <button className="kom_skip_btn " onClick={handleClick}>
          Skip
        </button>
      </div>
      <div style={{ position: "relative" }}>
        <img width="100%" src={carousal_svg_2a}></img>
        <div className="carousal_header">Life is also an adventure </div>
        <div className="carousal_content">
          So while you celebrate your milestones, what a fantastic feeling to
          have a safety net, a protective gear?
        </div>
        <button className="kom_skip_btn " onClick={handleClick}>
          Skip
        </button>
      </div>
      <div style={{ position: "relative" }}>
        <img width="100%" src={carousel_svg_3}></img>
        <div className="carousal_header"> Insurance</div>
        <div className="carousal_content">
          The best protector ever! A defender of mental peace. Your own
          superhero.
        </div>
        <button className="kom_skip_btn " onClick={handleClick}>
          Skip
        </button>
      </div>
      <div>
        <div style={{ height: "40vh" }}>
          <img width="100%" src={carousel_svg_4}></img>
        </div>

        <div className="carousal_header"> Kompass</div>
        <div className="carousal_content">
          To show you the right direction for insurance gaps and ways to bridge
          them!
        </div>
        <div style={{ padding: "0 30px" }}>
          <button
            className="kom_start_btn mt-20"
            style={{ marginTop: "25vh" }}
            onClick={handleClick}
          >
            <span onClick={handleClick}>Get Started</span>
          </button>
          {/* <div className='kom_start_btn mt-20' onClick={handleClick}>Get Started</div> */}
        </div>
      </div>
    </Carousel>
  );
}

export default carousal;

// // "swiper": "^6.8.4",
// import React from "react";
// import SwiperCore, { EffectCoverflow, Pagination, } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
// import carousal_svg_1 from "../svg/carousal_svg_1.svg"
// import carousal_svg_2 from "../svg/carousal_svg_2.svg"
// import carousel_svg_3 from "../svg/carousel_svg_3.svg"
// import carousel_svg_4 from "../svg/carousel_svg_4.svg"

// SwiperCore.use([EffectCoverflow, Pagination,]);
// // if you want to use array
// const slide_img = [
//     {
//         img: carousal_svg_1,
//         header: "Life is a celebration! ",
//         content: "Marked with one milestone after another!",
//         skipBtn: true

//     },
//     {
//         img: carousal_svg_2,
//         header: "Life is also an adventure",
//         content: "So while you celebrate your milestones, what a fantastic feeling to have a safety net, a protective gear?",
//         skipBtn: true
//     },
//     {
//         img: carousel_svg_3,
//         header: "Insurance",
//         content: "The best protector ever! A defender of mental peace. Your own superhero.",
//         skipBtn: true
//     },
//     {
//         img: carousel_svg_4,
//         header: "Kompass",
//         content: "To show you the right direction for insurance gaps and ways to bridge them!",
//         startBtn: true
//     }

// ];

// const Carousal = () => {
//     return (
//         <div>
//             <Swiper
//                 // effect={"coverflow"}
//                 spaceBetween={50}
//                 slidesPerView={1}
//                 loop={true}
//                 grabCursor={true}
//                 centeredSlides={true}
//                 pagination={true}
//                 className="mySwiper"
//             >

//                 {
//                     slide_img.map((ele, i) => {
//                         return (
//                             <SwiperSlide key={i}>
//                                 <img src={ele.img} alt="img" />
//                                 {
//                                     ele.skipBtn &&
//                                     <button className="kom_skip_btn" type="submit" >Skip</button>
//                                 }
//                                 <div className="carousal_header">{ele.header}</div>
//                                 <p className="carousal_content" style={{ marginBottom: "10px" }}>{ele.content}</p>
//                                 <div style={{ padding: "0 20px" }}>
//                                     {
//                                         ele.startBtn &&
//                                         <button className="kom_start_btn" type="submit" >Get Started</button>
//                                     }
//                                 </div>
//                             </SwiperSlide>
//                         );
//                     })
//                 }
//             </Swiper>
//         </div >
//     );
// };

// export default Carousal;
