import React from 'react'
import AppContext from "../context/AppContext";
import back from "../svg/back.svg"
import { useHistory } from "react-router-dom"
import All_Things_Money from "../svg/All_Things_Money.svg"

function AllThingsMoneyScreen() {
    const history = useHistory()
    function handleClick() {
        history.push("/income");
    }
    function handleGoBack() {
        // history.push("/personal_details");
        history.push("/laFamilia");
    }

  

    return (
        <AppContext.Consumer>
            {(context) => (
                <>
                    <div style={{ padding: "30px 16px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                            <img src={back} alt="icon" onClick={handleGoBack} />
                            <div className='la_familia_skip_btn'></div>
                        </div>
                        <div className='filler_screen_Header'>All Things Money </div>
                        <div className='filler_screen_content'>Hey <span style={{ textTransform: "capitalize", fontWeight: 600 }}>{String(context.state.userInfo.name).split(" ")[0]}</span>, your basic financial hygiene to recommend products</div>
                        <img src={All_Things_Money} width="100%" alt="image" />
                        {/* <div style={{ padding: "0 30px" }}> */}
                        {/* </div> */}
                    </div>
                    {/* <button className='kom_start_btn mt-15' onClick={handleClick}>Continue</button> */}
                    <button className='btn' onClick={handleClick}>Continue</button>
                </>
            )}
        </AppContext.Consumer>
    )
}
AllThingsMoneyScreen.contextType = AppContext;
export default AllThingsMoneyScreen