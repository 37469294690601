import React,{Component} from 'react';
import paper from '../svg/paper.svg'
import document from '../svg/document.svg'
import inspect from '../svg/inspect.svg'
import AppContext from '../context/AppContext'

class TwoWheelerInsurance extends Component{
    constructor(props){
        super(props)
        this.state={
            bikenumber:''
        }
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        return(
            <div>   
                <div className="header-area">
                    <div className=""><img onClick={() => this.goBack()} className="back-icon" className="back-icon" src={process.env.PUBLIC_URL + '/back.png'} /></div>
                    <div className="header-text">{this.props.type === 'BIKE' ? 'Two Wheeler Insurance' : 'Car Insurance'}</div>
                </div>

                <div >
                    <div style={{margin:20}}>
                        <h3>
                        {this.props.type === 'BIKE' ? 'Save upto 85% * on Two Wheeler Insurance' : 'Save upto 85% * on Car Insurance'}
                        </h3>

                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:35}}>
                            <div>
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    <img src={paper} />
                                </div>
                                <span className='two_wheeler_presentation'>Insurance Policy</span>
                            </div>
                            <div>
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    <img src={inspect} />
                                </div>
                                <span className='two_wheeler_presentation'>No Inspection</span>
                            </div>
                            <div>
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    <img src={document} />
                                </div>
                                <span className='two_wheeler_presentation'>No Paperwork</span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div style={{display:'flex',justifyContent:'center'}}>
                          <input type='text' placeholder={this.props.type === 'BIKE' ? 'Enter Your Bike Number' : 'Enter Your Car Number'} className='Bike_number_input' />
                        </div>
                        <br />
                        <div style={{display:'flex',justifyContent:'center'}}>
                            {this.state.bikenumber !== '' ?
                            <button className="Two_Wheeler_btn" onClick={() => this.nextClicked()}>View Prices</button>
                            :
                            <button className="Two_Wheeler_btn" style={{backgroundColor:'lightgrey'}}>View Prices</button>
                            } 
                        </div>
                    </div>

                    <div style={{display:'flex',alignItems:'center',marginTop:20,marginBottom:10}}>
                        <hr />
                        <span style={{backgroundColor:'lightgrey',borderRadius:100,padding:5,width:15,height:15,display:'flex',justifyContent:'center',alignItems:'center'}}>or</span>
                        <hr />
                    </div>

                    <div style={{display:'flex',justifyContent:'center'}}>
                        <button onClick={()=>this.props.setProcessStatus(true)} className="Two_Wheeler_bikenumber_button" >{this.props.type === 'BIKE' ? 'Do not have bike number' : 'Do not have car number'}</button>
                    </div>

                    <div style={{display:'flex',justifyContent:'center'}}>
                        <p style={{color:'grey',margin:'unset'}}>{this.props.type === 'BIKE' ? 'Bought new bike?' : 'Bought new car?'} <span style={{color:'#e41e26'}}>click here</span></p>
                    </div>
                </div>

            </div>


        )
    }


}

TwoWheelerInsurance.contextType = AppContext

export default TwoWheelerInsurance;