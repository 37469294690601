import React, { Component } from "react";
import AppContext from "../context/AppContext";
import Terms from "../components/Terms_condtions";
import {
  kompass_encrypt_fun,
  // userDetailEncrypter,
} from "../commonfunctions/commonfunction";
import { GA_trigger } from "../config/ga";
import HDFC_Bank_Logo from "../svg/HDFC_Bank_Logo.svg";
import HDFC_Ergo_Logo from "../svg/HDFC_Ergo_Logo.svg";
var CryptoJS = require("crypto-js");

class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      age: "",
      city: "",
      gender: "",
      warning: false,
      city_options: false,
      selectedCity: "",
      minage: "",
      userid: "",
      ageInDifferentForm: "",
      ageInput: false,
    };
  }

  afterCallback = async (context) => {
    console.log("personalDetail.js");
    try {
      let result = await fetch(
        `${process.env.REACT_APP_API_URL}/updateUserInfo`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            // "Content-Security-Policy": "default-src none",
            Authorization: window.sessionStorage.getItem("AdvisoryToken"),
          },
          body: JSON.stringify({
            personal_data: CryptoJS.AES.encrypt(
              kompass_encrypt_fun({
                name: this.state.name,
                age: this.context.state.userInfo.ageInDifferentForm,
                dob: this.state.age,
                userid: this.context.state.userInfo.userId,
                city: this.state.selectedCity,
                citytype: this.state.city,
                gender: this.state.gender,
                MobileNo: this.context.state.mobileNumber,
                ageInDifferentForm: this.context.state.ageInDifferentForm,
                salary: null,
                family: [],
                hostname: window.location.href,
              }),
              process.env.REACT_APP_ENC_SECRET_KEY
            ).toString(),
          }),
          // body: JSON.stringify({
          //   personal_data: kompass_encrypt_fun({
          //     name: this.state.name,
          //     age: this.context.state.userInfo.ageInDifferentForm,
          //     dob: this.state.age,
          //     userid: this.context.state.userInfo.userId,
          //     city: this.state.selectedCity,
          //     citytype: this.state.city,
          //     gender: this.state.gender,
          //     MobileNo: this.context.state.mobileNumber,
          //     ageInDifferentForm: this.context.state.ageInDifferentForm,
          //     salary: null,
          //     family: [],
          //     hostname: window.location.href,
          //   }),
          // }),
        }
      );

      if (result) {
        GA_trigger.EVENT(
          "PERSONAL DETAILS UPDATED",
          "PERSONAL DETAILS UPDATED",
          "PERSONAL DETAILS"
        );
        window.gtag("event", "PERSONAL_DETAILS", {
          event_category: "DETAILS",
          event_label: "PERSONAL DETAILS UPDATED",
        });
      }
    } catch (err) {
      return;
    }
  };

  updateInformation = async (context) => {
    this.context.updatePersoanlInfo(this.state, this.afterCallback);
    // this.props.history.push("/family-details");
    this.props.history.push("/laFamilia");
  };

  goBack() {
    // this.props.history.goBack();
    this.props.history.push("/login");
  }

  cityChange = (city) => {
    this.setState({ selectedCity: city, city: "Metro", city_options: false });
  };

  getAge = () => {
    var dateString = document.getElementById("age").value;
    if (dateString != "") {
      var today = new Date();
      var birthDate = new Date(dateString); //format is mm.dd.yyyy
      var age = today.getFullYear() - birthDate.getFullYear();
      if (age > 100) {
        this.setState({ warning: true, age: null });
        setTimeout(() => {
          this.setState({ warning: false });
        }, 2000);
      } else {
        // onClick route to next page (fimaly detail)
        this.updateInformation(this.context);
      }
    } else {
      alert("please provide your date of birth");
    }
  };

  componentWillUnmount() {
    this.context.updateLastPage("/personal_details");
  }

  changestyle = (id) => {
    if (id === "namelabel") {
      this.setState({ city_options: false });
      document.getElementById("age").placeholder = "DD / MM / YYYY";
      document.getElementById("agelabel").style.display = "none";
      document.getElementById("agelabel").style.position = "relative";
      document.getElementById("agelabel").style.top = "-85px";
      document.getElementById("agelabel").style.transition = "0.5s";
      document.getElementById("agelabel").style.left = "30px";
      document.getElementById("agelabel").style.paddingLeft = "5px";
      document.getElementById("agelabel").style.paddingRight = "5px";
      document.getElementById("agelabel").style.backgroundColor = "white";
      document.getElementById("agelabel").style.fontWeight = "bold";

      document.getElementById("city").placeholder = "City";
      document.getElementById("citylabel").style.display = "none";
      document.getElementById("citylabel").style.position = "relative";
      document.getElementById("citylabel").style.top = "-85px";
      document.getElementById("citylabel").style.left = "30px";
      document.getElementById("citylabel").style.paddingLeft = "5px";
      document.getElementById("citylabel").style.paddingRight = "5px";
      document.getElementById("citylabel").style.backgroundColor = "white";
      document.getElementById("citylabel").style.fontWeight = "bold";

      document.getElementById("fullname").placeholder = "";
      document.getElementById(id).style.display = "inline";
      document.getElementById(id).style.position = "relative";
      document.getElementById(id).style.top = "-85px";
      document.getElementById(id).style.left = "30px";
      document.getElementById(id).style.paddingLeft = "5px";
      document.getElementById(id).style.paddingRight = "5px";
      document.getElementById(id).style.backgroundColor = "white";
      document.getElementById(id).style.fontWeight = "bold";
    } else if (id === "agelabel") {
      this.setState({ city_options: false });
      document.getElementById("fullname").placeholder = "Full Name";
      document.getElementById("namelabel").style.display = "none";
      document.getElementById("namelabel").style.position = "relative";
      document.getElementById("namelabel").style.top = "-85px";
      document.getElementById("namelabel").style.left = "30px";
      document.getElementById("namelabel").style.paddingLeft = "5px";
      document.getElementById("namelabel").style.paddingRight = "5px";
      document.getElementById("namelabel").style.backgroundColor = "white";
      document.getElementById("namelabel").style.fontWeight = "bold";

      document.getElementById("city").placeholder = "City";
      document.getElementById("citylabel").style.display = "none";
      document.getElementById("citylabel").style.position = "relative";
      document.getElementById("citylabel").style.top = "-85px";
      document.getElementById("citylabel").style.left = "30px";
      document.getElementById("citylabel").style.paddingLeft = "5px";
      document.getElementById("citylabel").style.paddingRight = "5px";
      document.getElementById("citylabel").style.backgroundColor = "white";
      document.getElementById("citylabel").style.fontWeight = "bold";

      document.getElementById("age").placeholder = "";
      document.getElementById(id).style.display = "inline";
      document.getElementById(id).style.position = "relative";
      document.getElementById(id).style.top = "-85px";
      document.getElementById(id).style.left = "30px";
      document.getElementById(id).style.paddingLeft = "5px";
      document.getElementById(id).style.paddingRight = "5px";
      document.getElementById(id).style.backgroundColor = "white";
      document.getElementById(id).style.fontWeight = "bold";
    } else {
      this.context.state.userInfo.name = this.state.name;
      // this.context.state.userInfo.firstName = this.state.name.split(' ')[0];
      this.context.state.userInfo.age = this.state.age;
      this.context.updatePersoanlInfo(this.state, () => {
        this.props.history.push("/city");
      });
      document.getElementById("age").placeholder = "DD / MM / YYYY";
      document.getElementById("agelabel").style.display = "none";
      document.getElementById("agelabel").style.position = "relative";
      document.getElementById("agelabel").style.top = "-85px";
      document.getElementById("agelabel").style.left = "30px";
      document.getElementById("agelabel").style.paddingLeft = "5px";
      document.getElementById("agelabel").style.paddingRight = "5px";
      document.getElementById("agelabel").style.backgroundColor = "white";
      document.getElementById("agelabel").style.fontWeight = "bold";

      document.getElementById("fullname").placeholder = "Full Name";
      document.getElementById("namelabel").style.display = "none";
      document.getElementById(id).style.position = "relative";
      document.getElementById(id).style.top = "-85px";
      document.getElementById(id).style.left = "30px";
      document.getElementById(id).style.paddingLeft = "5px";
      document.getElementById(id).style.paddingRight = "5px";
      document.getElementById(id).style.backgroundColor = "white";
      document.getElementById(id).style.fontWeight = "bold";

      document.getElementById("city").placeholder = "";
      document.getElementById("citylabel").style.display = "inline";
      document.getElementById("citylabel").style.position = "relative";
      document.getElementById("citylabel").style.top = "-85px";
      document.getElementById("citylabel").style.left = "30px";
      document.getElementById("citylabel").style.paddingLeft = "5px";
      document.getElementById("citylabel").style.paddingRight = "5px";
      document.getElementById("citylabel").style.backgroundColor = "white";
      document.getElementById("citylabel").style.fontWeight = "bold";
    }
  };

  put = () => {};

  setDob = (e) => {
    let self = this;
    var el = document.getElementById("age");
    el.addEventListener("keydown", function(event) {
      const key = event.key;
      if (key === "Backspace" || key === "Delete") {
        document.getElementById("age").value = "";
        self.setState({ age: "" });
        self.context.state.userInfo.age = "";
      }
    });

    let dob = e.target.value.split("");
    let formated_dob;
    if (dob[1]) {
      let newDob3 = dob[0] + dob[1];
      if (parseInt(newDob3) <= 31) {
        if (dob[4]) {
          let newDob2 = dob[3] + dob[4];
          if (parseInt(newDob2) <= 12) {
            if (dob[9]) {
              let newDob = dob[6] + dob[7] + dob[8] + dob[9];
              let minage = this.state.minage.split("-")[0];

              if (
                parseInt(minage) > parseInt(newDob) &&
                parseInt(newDob) >= 1956
              ) {
                if (e.target.value.length < 11) {
                  if (e.target.value.length === 10) {
                    formated_dob =
                      dob[0] +
                      dob[1] +
                      "/" +
                      dob[3] +
                      dob[4] +
                      "/" +
                      dob[6] +
                      dob[7] +
                      dob[8] +
                      dob[9];
                    this.setState({ age: formated_dob });
                  } else if (e.target.value.length === 5) {
                    formated_dob =
                      dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                    this.setState({ age: formated_dob });
                  } else if (e.target.value.length === 2) {
                    formated_dob = dob[0] + dob[1] + "/";
                    this.setState({ age: formated_dob });
                  } else {
                    this.setState({ age: e.target.value });
                  }
                }
                if (dob[6] && dob[7] && dob[8] && dob[9]) {
                  let year = dob[6] + dob[7] + dob[8] + dob[9];
                  let Current_year = new Date().getFullYear();
                  this.setState({
                    ageInDifferentForm: parseInt(Current_year) - parseInt(year),
                  });
                }
              } else {
                if (e.target.value.length < 11) {
                  this.setState({ ageInDifferentForm: "Not Accepted" });
                  if (e.target.value.length === 10) {
                    formated_dob =
                      dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                    this.setState({ age: formated_dob });
                  } else if (e.target.value.length === 5) {
                    formated_dob =
                      dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                    this.setState({ age: formated_dob });
                  } else if (e.target.value.length === 2) {
                    formated_dob = dob[0] + dob[1] + "/";
                    this.setState({ age: formated_dob });
                  } else {
                    this.setState({ age: e.target.value });
                  }
                }
              }
            } else {
              if (e.target.value.length < 11) {
                this.setState({ ageInDifferentForm: "Not Accepted" });
                if (e.target.value.length === 10) {
                  formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                  this.setState({ age: formated_dob });
                } else if (e.target.value.length === 5) {
                  formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                  this.setState({ age: formated_dob });
                } else if (e.target.value.length === 2) {
                  formated_dob = dob[0] + dob[1] + "/";
                  this.setState({ age: formated_dob });
                } else {
                  this.setState({ age: e.target.value });
                }
              }
              if (dob[6] && dob[7] && dob[8] && dob[9]) {
                let year = dob[6] + dob[7] + dob[8] + dob[9];
                let Current_year = new Date().getFullYear();
                this.setState({
                  ageInDifferentForm: parseInt(Current_year) - parseInt(year),
                });
              }
            }
          } else {
            if (e.target.value.length < 11) {
              this.setState({ ageInDifferentForm: "Not Accepted" });
              if (e.target.value.length === 10) {
                formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                this.setState({ age: formated_dob });
              } else if (e.target.value.length === 5) {
                formated_dob = dob[0] + dob[1] + "/";
                this.setState({ age: formated_dob });
              } else if (e.target.value.length === 2) {
                formated_dob = dob[0] + dob[1] + "/";
                this.setState({ age: formated_dob });
              } else {
                this.setState({ age: e.target.value });
              }
            }
          }
        } else {
          if (e.target.value.length < 11) {
            this.setState({ ageInDifferentForm: "Not Accepted" });
            if (e.target.value.length === 10) {
              formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
              this.setState({ age: formated_dob });
            } else if (e.target.value.length === 5) {
              formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
              this.setState({ age: formated_dob });
            } else if (e.target.value.length === 2) {
              formated_dob = dob[0] + dob[1] + "/";
              this.setState({ age: formated_dob });
            } else {
              this.setState({ age: e.target.value });
            }
          }
        }
      } else {
        if (e.target.value.length < 11) {
          this.setState({ ageInDifferentForm: "Not Accepted" });
          if (e.target.value.length === 10) {
            formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
            this.setState({ age: formated_dob });
          } else if (e.target.value.length === 5) {
            formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
            this.setState({ age: formated_dob });
          } else if (e.target.value.length === 2) {
            formated_dob = "";
            this.setState({ age: formated_dob });
          } else {
            this.setState({ age: e.target.value });
          }
        }
      }
    } else {
      if (e.target.value.length < 11) {
        this.setState({ ageInDifferentForm: "Not Accepted" });
        if (e.target.value.length === 10) {
          formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
          this.setState({ age: formated_dob });
        } else if (e.target.value.length === 5) {
          formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
          this.setState({ age: formated_dob });
        } else if (e.target.value.length === 2) {
          formated_dob = dob[0] + dob[1] + "/";
          this.setState({ age: formated_dob });
        } else {
          this.setState({ age: e.target.value });
        }
      }
    }
  };

  minage = () => {
    let eighteenYearsAgo = new Date();
    eighteenYearsAgo = eighteenYearsAgo.setFullYear(
      eighteenYearsAgo.getFullYear() - 18
    );
    let date = new Date(eighteenYearsAgo);
    let min_month = date.getMonth() + 1;
    if (min_month < 10) {
      min_month = "0" + min_month;
    }
    let day = date.getDate();
    if (day < 10) {
      day = "0" + date.getDate();
    }
    let year = date.getFullYear();
    let minage = year + "-" + min_month + "-" + day;
    this.setState({ minage });
  };

  componentDidMount() {
    this.minage();
    const contextStateUserInfo = this.context.state;
    this.setState({
      name: contextStateUserInfo.userInfo.name,
      age: contextStateUserInfo.userInfo.age,
      salary: contextStateUserInfo.userInfo.salary,
      city: contextStateUserInfo.userInfo.city,
      salary: contextStateUserInfo.userInfo.salary,
      selectedCity: contextStateUserInfo.userInfo.selectedCity,
      gender: contextStateUserInfo.userInfo.gender,
      userid: contextStateUserInfo.userInfo.userId,
      ageInDifferentForm: contextStateUserInfo.userInfo.ageInDifferentForm,
    });
  }

  render() {
    return (
      <div id="slide">
        <AppContext.Consumer>
          {(context) => {
            return (
              <React.Fragment>
                


                <div className="header-area">
                  <div className="">
                    <img
                      onClick={() => this.goBack()}
                      className="back-icon"
                      // className="back-icon"
                      src={process.env.PUBLIC_URL + "/back.png"}
                    />
                  </div>
                  <div className="header-text">
                    Personal Details (Step {context.state.AppSteps[0]}/
                    {context.state.AppSteps.length})
                  </div>
                  <div className="progress-container">
                    <div
                      className="progress-bar"
                      id="myBar"
                      style={{ width: "20%" }}
                    ></div>
                  </div>
                </div>

                {this.state.warning === true ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "fixed",
                      width: "100%",
                      top: "10%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "red",
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "70%",
                      }}
                    >
                      <span
                        style={{
                          color: "white",
                          fontWeight: 500,
                          padding: 7,
                          fontSize: 16,
                        }}
                      >
                        Age above 100 is not allowed
                      </span>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div id="mobview">
                  <div
                    style={{ marginTop: 70, paddingBottom: 15 }}
                    class="page-title"
                  >
                    Getting To Know You

                  </div>
                  <div style={{ height: 80 }}>
                    <div class="personal_input">
                      <input
                        id="fullname"
                        autoFocus={true}
                        type="text"
                        onFocus={() => this.changestyle("namelabel")}
                        value={this.state.name}
                        onChange={(e) => {
                          let valid = /[^a-zA-Z ]/;
                          if (!e.target.value.match(valid)) {
                            this.setState({ name: e.target.value });
                          }
                        }}
                        placeholder="Full name*"
                        class="personal_input"
                        required
                      />
                      {this.state.name === "" ? (
                        <ion-icon
                          style={{ color: "rgba(0, 0, 0, 0.34)", fontSize: 18 }}
                          name="person"
                        ></ion-icon>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <label style={{ display: "none" }} id="namelabel">
                      Full Name
                    </label>
                  </div>

                  <div style={{ height: 80 }}>
                    <div class="personal_input2">
                      <input
                        id="age"
                        type="string"
                        onFocus={() => this.changestyle("agelabel")}
                        min="1992-10-1"
                        max={this.state.minage}
                        value={this.state.age}
                        onChange={(e) => this.setDob(e)}
                        placeholder="DD / MM / YYYY*"
                        class="personal_input"
                      />

                      {this.state.age !== "" ? (
                        <span class="total-age">
                          {" "}
                          {this.state.ageInDifferentForm}
                        </span>
                      ) : (
                        <span></span>
                      )}
                      {/* <ion-icon style={{color:'rgba(0, 0, 0, 0.34)',fontSize:18}} name="calendar"></ion-icon> */}
                    </div>
                    <label style={{ display: "none" }} id="agelabel">
                      Date Of Birth
                    </label>
                  </div>
                  <div style={{ height: 80 }}>
                    <div class="personal_input">
                      <input
                        id="city"
                        type="text"
                        onFocus={() => this.changestyle("citylabel")}
                        value={this.state.selectedCity}
                        onChange={(e) =>
                          this.setState({ city: e.target.value })
                        }
                        placeholder="City*"
                      />
                      {this.state.city === "" ? (
                        <ion-icon
                          style={{ color: "rgba(0, 0, 0, 0.34)", fontSize: 18 }}
                          name="earth"
                        ></ion-icon>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <label style={{ display: "none" }} id="citylabel">
                      City
                    </label>

                    <div
                      className={
                        this.state.city_options === true
                          ? "checkbox_input_city_container show"
                          : "checkbox_input_city_container"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingTop: 10,
                        }}
                      >
                        <h4 style={{ fontSize: 18 }}>Select City</h4>
                        <ion-icon
                          onClick={() => this.setState({ city_options: false })}
                          style={{ fontSize: 20, color: "grey" }}
                          name="close-outline"
                        ></ion-icon>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="webview">
                  <div style={{ width: "80%" }}>
                    <div
                      style={{ marginTop: 60, paddingBottom: 20 }}
                      class="page-title"
                    >
                      Enter Personal Details
                    </div>
                    <div style={{ marginBottom: 20 }}>
                      <div class="input">
                        <label style={{ color: "grey" }}>Full Name</label>
                        <input
                          autoFocus={true}
                          type="text"
                          onFocus={() => this.changestyle("namelabel")}
                          value={this.state.name}
                          onChange={(e) => {
                            let valid = /[^a-zA-Z ]/;
                            if (!e.target.value.match(valid)) {
                              this.setState({ name: e.target.value });
                            }
                          }}
                          // type="text"
                        />
                      </div>
                    </div>
                    <div style={{ marginBottom: 20 }}>
                      <div class="input">
                        <label style={{ color: "grey" }}>Date of Birth</label>
                        {this.state.ageInput ? (
                          <input
                            id="age"
                            type="text"
                            onFocus={() => this.changestyle("agelabel")}
                            min="1992-10-1"
                            max={this.state.minage}
                            value={this.state.age}
                            onChange={(e) => this.setDob(e)}
                            placeholder="DD / MM / YYY"
                          />
                        ) : (
                          <input
                            id="age"
                            type="text"
                            onFocus={() => this.setState({ ageInput: true })}
                            placeholder="DD / MM / YYY"
                          />
                        )}
                        {this.state.age !== "" ? (
                          <span class="total-age">
                            {" "}
                            {this.state.ageInDifferentForm} Y
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>
                    <div style={{ marginBottom: 20 }}>
                      <div class="input">
                        <label style={{ color: "grey" }}>City</label>
                        <input
                          onFocus={() => this.changestyle("citylabel")}
                          type="text"
                          value={this.state.selectedCity}
                        />
                        <div
                          className={
                            this.state.city_options === true
                              ? "checkbox_input_city_container show"
                              : "checkbox_input_city_container"
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingTop: 10,
                            }}
                          >
                            <h4 style={{ fontSize: 18 }}>Select City</h4>
                            <ion-icon
                              onClick={() =>
                                this.setState({ city_options: false })
                              }
                              style={{ fontSize: 20, color: "grey" }}
                              name="close-outline"
                            ></ion-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.city_options === false ? (
                  <div id="radio" class="genderDetails">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <label
                        for="other"
                        style={{ marginLeft: 10, fontWeight: 600 }}
                      >
                        Gender
                      </label>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        onClick={() => this.setState({ gender: "M" })}
                        type="radio"
                        id="male"
                        name="gender"
                        value="male"
                        checked={this.state.gender === "M" ? true : false}
                      />
                      <label
                        onClick={() => this.setState({ gender: "M" })}
                        style={{ marginLeft: 15, fontWeight: 600 }}
                        for="male"
                      >
                        Male
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        onClick={() => this.setState({ gender: "F" })}
                        type="radio"
                        id="female"
                        name="gender"
                        value="female"
                        checked={this.state.gender === "F" ? true : false}
                      />
                      <label
                        onClick={() => this.setState({ gender: "F" })}
                        style={{ marginLeft: 15, fontWeight: 600 }}
                        for="female"
                      >
                        Female
                      </label>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                {/* <p style={{textAlign:'center',padding:10,fontSize:14,color:'#757575'}}>
                                    All Above Fields Are Mandatory*
                                </p> */}

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div
                    style={{
                      paddingTop: "40%",
                      paddingBottom: "20%",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <div className="brand_icons_header">Powered by</div>
                    <div className="brand_icons">
                      <img
                        src={HDFC_Bank_Logo}
                        style={{ marginRight: "8px", height: "20px" }}
                        alt="HDFC_BANK"
                      />
                      <img
                        src={HDFC_Ergo_Logo}
                        style={{ marginLeft: "8px" }}
                        alt="HDFC_ERGO"
                      />
                    </div>
                  </div>
                  {this.state.name &&
                  this.state.age &&
                  this.state.selectedCity &&
                  this.state.gender ? (
                    <button class="btn" onClick={() => this.getAge()}>
                      Next
                    </button>
                  ) : (
                    <button
                      class="btn"
                      style={{ backgroundColor: "lightgrey" }}
                    >
                      Next
                    </button>
                  )}
                </div>
              </React.Fragment>
            );
          }}
        </AppContext.Consumer>
      </div>
    );
  }
}
PersonalDetails.contextType = AppContext;

export default PersonalDetails;
