import React,{Component} from 'react';


class Motor_fueltype extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }

    render() {
        return(
            <div>   
                <div className="header-area">
                    <div className=""><img onClick={() => this.goBack()} className="back-icon" className="back-icon" src={process.env.PUBLIC_URL + '/back.png'} /></div>
                    <div className="header-text">Car Details</div>
                    
                </div>

                <div >
                    <div style={{margin:20}}>
                        <h3>
                            Select Car Fuel Type
                        </h3>

                    </div>
                </div>

            </div>


        )
    }


}

export default Motor_fueltype;