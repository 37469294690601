import React, { Component } from "react";
import AppContext from "../context/AppContext";
import kids from "../svg/kids.svg";
import birthdate from "../svg/birthdate.svg";
import spouse from "../svg/spouse.svg";
import father_icon from "../svg/father_icon.svg";
import mother_icon from "../svg/mother_icon.svg";
import motherinlaw from "../svg/motherinlaw.svg";
import fatherinlaw from "../svg/fatherinlaw.svg";
import husband from "../svg/husband.svg";
import cross from "../svg/cross.svg";
import { Drawer } from "@mui/material";
import { GA_trigger } from "../config/ga";
import HDFC_Bank_Logo from "../svg/HDFC_Bank_Logo.svg"
import HDFC_Ergo_Logo from "../svg/HDFC_Ergo_Logo.svg"


class Familydetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spouse_input: false,
      kid_input: false,
      father_input: false,
      mother_input: false,
      fatherinlaw_input: false,
      motherinlaw_input: false,
      spouse: null,
      kidbirthinput: "",
      kidgenderinput: "M",
      kids: [],
      mother: null,
      father: null,
      motherinlaw: null,
      fatherinlaw: null,
      spousepanel: false,
      kidpanel: false,
      fatherpanel: false,
      motherpanel: false,
      fatherinlawpanel: false,
      motherinlawpanel: false,
      minage: "",
      warning: false,
      msg: "",
      spouseValid: true,
      fatherValid: true,
      motherValid: true,
      motherinlawValid: true,
      fatherinlawValid: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    var context = this.context;
    this.setState({
      spouse_input: context.state.familyDetails.spouse_input,
      kid_input: context.state.familyDetails.kid_input,
      father_input: context.state.familyDetails.father_input,
      mother_input: context.state.familyDetails.mother_input,
      fatherinlaw_input: context.state.familyDetails.fatherinlaw_input,
      motherinlaw_input: context.state.familyDetails.motherinlaw_input,
      spouse: context.state.familyDetails.spouse,
      kids: context.state.familyDetails.kids,
      mother: context.state.familyDetails.mother,
      father: context.state.familyDetails.father,
      motherinlaw: context.state.familyDetails.motherinlaw,
      fatherinlaw: context.state.familyDetails.fatherinlaw,
      spousepanel: context.state.familyDetails.spousepanel,
      kidpanel: context.state.familyDetails.kidpanel,
      fatherpanel: context.state.familyDetails.fatherpanel,
      motherpanel: context.state.familyDetails.motherpanel,
      fatherinlawpanel: context.state.familyDetails.fatherinlawpanel,
      motherinlawpanel: context.state.familyDetails.motherinlawpanel,
    });
    this.minage();
    this.context.state.familyArray = [{ Relation: "Self", memberCode: "S" }];
    this.context.state.membersCovered = "Self";
  }

  componentWillUnmount() {
    this.context.updateGlobalState("family_details", this.state);
    this.context.updateLastPage("/family-details");
    GA_trigger.EVENT(
      "FAMILY DETAILS UPDATED",
      "FAMILY DETAILS UPDATED",
      "FAMILY DETAILS"
    );
  }

  checkValues(id, context) {
    if (document.getElementById(id).checked) {
      if (id === "spouse") {
        this.setState(
          {
            spouse_input: true,
            spousepanel: true,
            kidpanel: false,
            motherpanel: false,
            fatherpanel: false,
            fatherinlawpanel: false,
            motherinlawpanel: false,
            kid_input: false,
            father_input: false,
            mother_input: false,
            motherinlaw_input: false,
            fatherinlaw_input: false,
          },
          () => {
            context.updateGlobalState("family_details", this.state);
          }
        );
      } else if (id === "kid") {
        this.setState(
          {
            kid_input: true,
            kidpanel: true,
            spousepanel: false,
            motherpanel: false,
            fatherpanel: false,
            fatherinlawpanel: false,
            motherinlawpanel: false,
            spouse_input: false,
            father_input: false,
            mother_input: false,
            motherinlaw_input: false,
            fatherinlaw_input: false,
          },
          () => {
            context.updateGlobalState("family_details", this.state);
          }
        );
      } else if (id === "mother") {
        this.setState(
          {
            mother_input: true,
            kidpanel: false,
            spousepanel: false,
            motherpanel: true,
            fatherpanel: false,
            fatherinlawpanel: false,
            motherinlawpanel: false,
            spouse_input: false,
            father_input: false,
            kid_input: false,
            motherinlaw_input: false,
            fatherinlaw_input: false,
          },
          () => {
            context.updateGlobalState("family_details", this.state);
          }
        );
      } else if (id === "father") {
        this.setState(
          {
            father_input: true,
            kidpanel: false,
            spousepanel: false,
            motherpanel: false,
            fatherpanel: true,
            fatherinlawpanel: false,
            motherinlawpanel: false,
            spouse_input: false,
            kid_input: false,
            mother_input: false,
            motherinlaw_input: false,
            fatherinlaw_input: false,
          },
          () => {
            context.updateGlobalState("family_details", this.state);
          }
        );
      } else if (id === "motherinlaw") {
        this.setState(
          {
            motherinlaw_input: true,
            kidpanel: false,
            spousepanel: false,
            motherpanel: false,
            fatherpanel: false,
            fatherinlawpanel: false,
            motherinlawpanel: true,
            spouse_input: false,
            father_input: false,
            kid_input: false,
            mother_input: false,
            fatherinlaw_input: false,
          },
          () => {
            context.updateGlobalState("family_details", this.state);
          }
        );
      } else if (id === "fatherinlaw") {
        this.setState(
          {
            fatherinlaw_input: true,
            kidpanel: false,
            spousepanel: false,
            motherpanel: false,
            fatherpanel: false,
            fatherinlawpanel: true,
            motherinlawpanel: false,
            spouse_input: false,
            father_input: false,
            kid_input: false,
            mother_input: false,
            motherinlaw_input: false,
          },
          () => {
            context.updateGlobalState("family_details", this.state);
          }
        );
      }
    } else {
      if (id === "spouse") {
        this.setState({ spouse_input: false, spousepanel: false }, () => {
          context.updateGlobalState("family_details", this.state);
        });
      } else if (id === "kid") {
        this.setState({ kid_input: false, kidpanel: false }, () => {
          context.updateGlobalState("family_details", this.state);
        });
      } else if (id === "mother") {
        this.setState({ mother_input: false, motherpanel: false }, () => {
          context.updateGlobalState("family_details", this.state);
        });
      } else if (id === "father") {
        this.setState({ father_input: false, fatherpanel: false }, () => {
          context.updateGlobalState("family_details", this.state);
        });
      } else if (id === "fatherinlaw") {
        this.setState(
          { fatherinlaw_input: false, fatherinlawpanel: false },
          () => {
            context.updateGlobalState("family_details", this.state);
          }
        );
      } else if (id === "motherinlaw") {
        this.setState(
          { motherinlaw_input: false, motherinlawpanel: false },
          () => {
            context.updateGlobalState("family_details", this.state);
          }
        );
      }
    }
  }

  pushKid = (data) => {
    if (data.date !== "" && data.gender !== "") {
      if (new Date().getFullYear() - data.date.split("/")[2] < 25) {
        this.state.kids.push(data);
        this.setState({ kidbirthinput: "", kidpanel: false, kid_input: false });
      } else {
        this.setState({
          warning: true,
          msg: "Child age should be less than 5 Years",
          kid_input: true,
          kidpanel: true,
        });
        setTimeout(() => {
          this.setState({ warning: false, msg: "" });
        }, 4000);
      }
    }
  };

  goBack = () => {
    this.props.history.push("/laFamilia");
  };

  RemoveKids = (index) => {
    this.state.kids.splice(index, 1);
    this.setState({ kids: this.state.kids });
  };

  minage = () => {
    let eighteenYearsAgo = new Date();
    eighteenYearsAgo = eighteenYearsAgo.setFullYear(
      eighteenYearsAgo.getFullYear() - 18
    );

    let date = new Date(eighteenYearsAgo);

    let min_month = date.getMonth() + 1;

    if (min_month < 10) {
      min_month = "0" + min_month;
    }
    let day = date.getDate();
    if (day < 10) {
      day = "0" + date.getDate();
    }
    let year = date.getFullYear();
    let minage = year + "-" + min_month + "-" + day;

    this.setState({ minage });
  };

  setDob = (e, whoIs) => {
    let self = this;
    var el =
      whoIs === "spouse1"
        ? document.getElementById("spouse1")
        : whoIs === "father"
        ? document.getElementById("father1")
        : whoIs === "mother"
        ? document.getElementById("mother1")
        : whoIs === "mother-in-law"
        ? document.getElementById("motherlaw1")
        : document.getElementById("fatherlaw1");
    el.addEventListener("keydown", function (event) {
      const key = event.key;
      if (key === "Backspace" || key === "Delete") {
        if (whoIs === "spouse1") {
          self.setState({ spouse: "" });
        } else if (whoIs === "father") {
          self.setState({ father: "" });
        } else if (whoIs === "mother") {
          self.setState({ mother: "" });
        } else if (whoIs === "mother-in-law") {
          self.setState({ motherinlaw: "" });
        } else if (whoIs === "father-in-law") {
          self.setState({ fatherinlaw: "" });
        }
      }
    });

    let dob = e.target.value.split("");
    let formated_dob;
    if (dob[1]) {
      let newDob3 = dob[0] + dob[1];
      if (parseInt(newDob3) <= 31) {
        if (dob[4]) {
          let newDob2 = dob[3] + dob[4];

          if (parseInt(newDob2) <= 12) {
            if (dob[9]) {
              let newDob = dob[6] + dob[7] + dob[8] + dob[9];

              let minage = this.state.minage.split("-")[0];

              if (
                parseInt(minage) > parseInt(newDob) &&
                parseInt(newDob) > 1956
              ) {
                if (e.target.value.length < 11) {
                  if (e.target.value.length === 10) {
                    if (whoIs !== "spouse1") {
                      if (
                        parseInt(new Date().getFullYear() - parseInt(newDob)) >
                        parseInt(this.context.state.userInfo.ageInDifferentForm)
                      ) {
                        formated_dob =
                          dob[0] +
                          dob[1] +
                          "/" +
                          dob[3] +
                          dob[4] +
                          "/" +
                          dob[6] +
                          dob[7] +
                          dob[8] +
                          dob[9];
                      } else {
                        formated_dob =
                          dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                      }
                    } else {
                      formated_dob =
                        dob[0] +
                        dob[1] +
                        "/" +
                        dob[3] +
                        dob[4] +
                        "/" +
                        dob[6] +
                        dob[7] +
                        dob[8] +
                        dob[9];
                    }

                    if (whoIs === "spouse1") {
                      this.setState({
                        spouse: formated_dob,
                        spousepanel: false,
                        spouseValid: true,
                      });
                    } else if (whoIs === "father") {
                      if (formated_dob.length === 6) {
                        this.setState({ father: formated_dob });
                      } else {
                        this.setState({
                          father: formated_dob,
                          fatherpanel: false,
                          fatherValid: true,
                        });
                      }
                    } else if (whoIs === "mother") {
                      if (formated_dob.length === 6) {
                        this.setState({ mother: formated_dob });
                      } else {
                        this.setState({
                          mother: formated_dob,
                          motherpanel: false,
                          motherValid: true,
                        });
                      }
                    } else if (whoIs === "mother-in-law") {
                      if (formated_dob.length === 6) {
                        this.setState({ motherinlaw: formated_dob });
                      } else {
                        self.setState({
                          motherinlaw: formated_dob,
                          motherinlawpanel: false,
                          motherinlawValid: true,
                        });
                      }
                    } else if (whoIs === "father-in-law") {
                      if (formated_dob.length === 6) {
                        this.setState({ fatherinlaw: formated_dob });
                      } else {
                        self.setState({
                          fatherinlaw: formated_dob,
                          fatherinlawpanel: false,
                          fatherinlawValid: true,
                        });
                      }
                    }
                  } else if (e.target.value.length === 5) {
                    formated_dob =
                      dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                    if (whoIs === "spouse1") {
                      this.setState({ spouse: formated_dob });
                    } else if (whoIs === "father") {
                      this.setState({ father: formated_dob });
                    } else if (whoIs === "mother") {
                      this.setState({ mother: formated_dob });
                    } else if (whoIs === "mother-in-law") {
                      self.setState({ motherinlaw: formated_dob });
                    } else if (whoIs === "father-in-law") {
                      self.setState({ fatherinlaw: formated_dob });
                    }
                  } else if (e.target.value.length === 2) {
                    formated_dob = dob[0] + dob[1] + "/";
                    if (whoIs === "spouse1") {
                      this.setState({ spouse: formated_dob });
                    } else if (whoIs === "father") {
                      this.setState({ father: formated_dob });
                    } else if (whoIs === "mother") {
                      this.setState({ mother: formated_dob });
                    } else if (whoIs === "mother-in-law") {
                      self.setState({ motherinlaw: formated_dob });
                    } else if (whoIs === "father-in-law") {
                      self.setState({ fatherinlaw: formated_dob });
                    }
                  } else {
                    if (whoIs === "spouse1") {
                      this.setState({ spouse: e.target.value });
                    } else if (whoIs === "father") {
                      this.setState({ father: e.target.value });
                    } else if (whoIs === "mother") {
                      this.setState({ mother: e.target.value });
                    } else if (whoIs === "mother-in-law") {
                      self.setState({ motherinlaw: e.target.value });
                    } else if (whoIs === "father-in-law") {
                      self.setState({ fatherinlaw: e.target.value });
                    }
                  }
                }
                if (dob[6] && dob[7] && dob[8] && dob[9]) {
                  let year = dob[6] + dob[7] + dob[8] + dob[9];
                  let Current_year = new Date().getFullYear();
                  this.setState({
                    ageInDifferentForm: parseInt(Current_year) - parseInt(year),
                  });
                }
              } else {
                if (e.target.value.length < 11) {
                  this.setState({ ageInDifferentForm: "Not Accepted" });
                  if (e.target.value.length === 10) {
                    formated_dob =
                      dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                    if (whoIs === "spouse1") {
                      this.setState({ spouse: formated_dob });
                    } else if (whoIs === "father") {
                      this.setState({ father: formated_dob });
                    } else if (whoIs === "mother") {
                      this.setState({ mother: formated_dob });
                    } else if (whoIs === "mother-in-law") {
                      self.setState({ motherinlaw: formated_dob });
                    } else if (whoIs === "father-in-law") {
                      self.setState({ fatherinlaw: formated_dob });
                    }
                  } else if (e.target.value.length === 5) {
                    formated_dob =
                      dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                    if (whoIs === "spouse1") {
                      this.setState({ spouse: formated_dob });
                    } else if (whoIs === "father") {
                      this.setState({ father: formated_dob });
                    } else if (whoIs === "mother") {
                      this.setState({ mother: formated_dob });
                    } else if (whoIs === "mother-in-law") {
                      self.setState({ motherinlaw: formated_dob });
                    } else if (whoIs === "father-in-law") {
                      self.setState({ fatherinlaw: formated_dob });
                    }
                  } else if (e.target.value.length === 2) {
                    formated_dob = dob[0] + dob[1] + "/";
                    if (whoIs === "spouse1") {
                      this.setState({ spouse: formated_dob });
                    } else if (whoIs === "father") {
                      this.setState({ father: formated_dob });
                    } else if (whoIs === "mother") {
                      this.setState({ mother: formated_dob });
                    } else if (whoIs === "mother-in-law") {
                      self.setState({ motherinlaw: formated_dob });
                    } else if (whoIs === "father-in-law") {
                      self.setState({ fatherinlaw: formated_dob });
                    }
                  } else {
                    if (whoIs === "spouse1") {
                      this.setState({ spouse: e.target.value });
                    } else if (whoIs === "father") {
                      this.setState({ father: e.target.value });
                    } else if (whoIs === "mother") {
                      this.setState({ mother: e.target.value });
                    } else if (whoIs === "mother-in-law") {
                      self.setState({ motherinlaw: e.target.value });
                    } else if (whoIs === "father-in-law") {
                      self.setState({ fatherinlaw: e.target.value });
                    }
                  }
                }
              }
            } else {
              if (e.target.value.length < 11) {
                this.setState({ ageInDifferentForm: "Not Accepted" });
                if (e.target.value.length === 10) {
                  formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                  if (whoIs === "spouse1") {
                    this.setState({ spouse: formated_dob });
                  } else if (whoIs === "father") {
                    this.setState({ father: formated_dob });
                  } else if (whoIs === "mother") {
                    this.setState({ mother: formated_dob });
                  } else if (whoIs === "mother-in-law") {
                    self.setState({ motherinlaw: formated_dob });
                  } else if (whoIs === "father-in-law") {
                    self.setState({ fatherinlaw: formated_dob });
                  }
                } else if (e.target.value.length === 5) {
                  formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                  if (whoIs === "spouse1") {
                    this.setState({ spouse: formated_dob });
                  } else if (whoIs === "father") {
                    this.setState({ father: formated_dob });
                  } else if (whoIs === "mother") {
                    this.setState({ mother: formated_dob });
                  } else if (whoIs === "mother-in-law") {
                    self.setState({ motherinlaw: formated_dob });
                  } else if (whoIs === "father-in-law") {
                    self.setState({ fatherinlaw: formated_dob });
                  }
                } else if (e.target.value.length === 2) {
                  formated_dob = dob[0] + dob[1] + "/";
                  if (whoIs === "spouse1") {
                    this.setState({ spouse: formated_dob });
                  } else if (whoIs === "father") {
                    this.setState({ father: formated_dob });
                  } else if (whoIs === "mother") {
                    this.setState({ mother: formated_dob });
                  } else if (whoIs === "mother-in-law") {
                    self.setState({ motherinlaw: formated_dob });
                  } else if (whoIs === "father-in-law") {
                    self.setState({ fatherinlaw: formated_dob });
                  }
                } else {
                  if (whoIs === "spouse1") {
                    this.setState({ spouse: e.target.value });
                  } else if (whoIs === "father") {
                    this.setState({ father: e.target.value });
                  } else if (whoIs === "mother") {
                    this.setState({ mother: e.target.value });
                  } else if (whoIs === "mother-in-law") {
                    self.setState({ motherinlaw: e.target.value });
                  } else if (whoIs === "father-in-law") {
                    self.setState({ fatherinlaw: e.target.value });
                  }
                }
              }
              if (dob[6] && dob[7] && dob[8] && dob[9]) {
                let year = dob[6] + dob[7] + dob[8] + dob[9];
                let Current_year = new Date().getFullYear();
                this.setState({
                  ageInDifferentForm: parseInt(Current_year) - parseInt(year),
                });
              }
            }
          } else {
            if (e.target.value.length < 11) {
              this.setState({ ageInDifferentForm: "Not Accepted" });
              if (e.target.value.length === 10) {
                formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                this.setState({ spouse: formated_dob });
              } else if (e.target.value.length === 5) {
                formated_dob = dob[0] + dob[1] + "/";
                if (whoIs === "spouse1") {
                  this.setState({ spouse: formated_dob });
                } else if (whoIs === "father") {
                  this.setState({ father: formated_dob });
                } else if (whoIs === "mother") {
                  this.setState({ mother: formated_dob });
                } else if (whoIs === "mother-in-law") {
                  self.setState({ motherinlaw: formated_dob });
                } else if (whoIs === "father-in-law") {
                  self.setState({ fatherinlaw: formated_dob });
                }
              } else if (e.target.value.length === 2) {
                formated_dob = dob[0] + dob[1] + "/";
                if (whoIs === "spouse1") {
                  this.setState({ spouse: formated_dob });
                } else if (whoIs === "father") {
                  this.setState({ father: formated_dob });
                } else if (whoIs === "mother") {
                  this.setState({ mother: formated_dob });
                } else if (whoIs === "mother-in-law") {
                  self.setState({ motherinlaw: formated_dob });
                } else if (whoIs === "father-in-law") {
                  self.setState({ fatherinlaw: formated_dob });
                }
              } else {
                if (whoIs === "spouse1") {
                  this.setState({ spouse: e.target.value });
                } else if (whoIs === "father") {
                  this.setState({ father: e.target.value });
                } else if (whoIs === "mother") {
                  this.setState({ mother: e.target.value });
                } else if (whoIs === "mother-in-law") {
                  self.setState({ motherinlaw: e.target.value });
                } else if (whoIs === "father-in-law") {
                  self.setState({ fatherinlaw: e.target.value });
                }
              }
            }
          }
        } else {
          if (e.target.value.length < 11) {
            this.setState({ ageInDifferentForm: "Not Accepted" });
            if (e.target.value.length === 10) {
              formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
              if (whoIs === "spouse1") {
                this.setState({ spouse: formated_dob });
              } else if (whoIs === "father") {
                this.setState({ father: formated_dob });
              } else if (whoIs === "mother") {
                this.setState({ mother: formated_dob });
              } else if (whoIs === "mother-in-law") {
                self.setState({ motherinlaw: formated_dob });
              } else if (whoIs === "father-in-law") {
                self.setState({ fatherinlaw: formated_dob });
              }
            } else if (e.target.value.length === 5) {
              formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
              if (whoIs === "spouse1") {
                this.setState({ spouse: formated_dob });
              } else if (whoIs === "father") {
                this.setState({ father: formated_dob });
              } else if (whoIs === "mother") {
                this.setState({ mother: formated_dob });
              } else if (whoIs === "mother-in-law") {
                self.setState({ motherinlaw: formated_dob });
              } else if (whoIs === "father-in-law") {
                self.setState({ fatherinlaw: formated_dob });
              }
            } else if (e.target.value.length === 2) {
              formated_dob = dob[0] + dob[1] + "/";
              if (whoIs === "spouse1") {
                this.setState({ spouse: formated_dob });
              } else if (whoIs === "father") {
                this.setState({ father: formated_dob });
              } else if (whoIs === "mother") {
                this.setState({ mother: formated_dob });
              } else if (whoIs === "mother-in-law") {
                self.setState({ motherinlaw: formated_dob });
              } else if (whoIs === "father-in-law") {
                self.setState({ fatherinlaw: formated_dob });
              }
            } else {
              if (whoIs === "spouse1") {
                this.setState({ spouse: e.target.value });
              } else if (whoIs === "father") {
                this.setState({ father: e.target.value });
              } else if (whoIs === "mother") {
                this.setState({ mother: e.target.value });
              } else if (whoIs === "mother-in-law") {
                self.setState({ motherinlaw: e.target.value });
              } else if (whoIs === "father-in-law") {
                self.setState({ fatherinlaw: e.target.value });
              }
            }
          }
        }
      } else {
        if (e.target.value.length < 11) {
          this.setState({ ageInDifferentForm: "Not Accepted" });
          if (e.target.value.length === 10) {
            formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
            if (whoIs === "spouse1") {
              this.setState({ spouse: formated_dob });
            } else if (whoIs === "father") {
              this.setState({ father: formated_dob });
            } else if (whoIs === "mother") {
              this.setState({ mother: formated_dob });
            } else if (whoIs === "mother-in-law") {
              self.setState({ motherinlaw: formated_dob });
            } else if (whoIs === "father-in-law") {
              self.setState({ fatherinlaw: formated_dob });
            }
          } else if (e.target.value.length === 5) {
            formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
            if (whoIs === "spouse1") {
              this.setState({ spouse: formated_dob });
            } else if (whoIs === "father") {
              this.setState({ father: formated_dob });
            } else if (whoIs === "mother") {
              this.setState({ mother: formated_dob });
            } else if (whoIs === "mother-in-law") {
              self.setState({ motherinlaw: formated_dob });
            } else if (whoIs === "father-in-law") {
              self.setState({ fatherinlaw: formated_dob });
            }
          } else if (e.target.value.length === 2) {
            formated_dob = "";
            if (whoIs === "spouse1") {
              this.setState({ spouse: formated_dob });
            } else if (whoIs === "father") {
              this.setState({ father: formated_dob });
            } else if (whoIs === "mother") {
              this.setState({ mother: formated_dob });
            } else if (whoIs === "mother-in-law") {
              self.setState({ motherinlaw: formated_dob });
            } else if (whoIs === "father-in-law") {
              self.setState({ fatherinlaw: formated_dob });
            }
          } else {
            if (whoIs === "spouse1") {
              this.setState({ spouse: e.target.value });
            } else if (whoIs === "father") {
              this.setState({ father: e.target.value });
            } else if (whoIs === "mother") {
              this.setState({ mother: e.target.value });
            } else if (whoIs === "mother-in-law") {
              self.setState({ motherinlaw: e.target.value });
            } else if (whoIs === "father-in-law") {
              self.setState({ fatherinlaw: e.target.value });
            }
          }
        }
      }
    } else {
      if (e.target.value.length < 11) {
        this.setState({ ageInDifferentForm: "Not Accepted" });
        if (e.target.value.length === 10) {
          formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
          if (whoIs === "spouse1") {
            this.setState({ spouse: formated_dob });
          } else if (whoIs === "father") {
            this.setState({ father: formated_dob });
          } else if (whoIs === "mother") {
            this.setState({ mother: formated_dob });
          } else if (whoIs === "mother-in-law") {
            self.setState({ motherinlaw: formated_dob });
          } else if (whoIs === "father-in-law") {
            self.setState({ fatherinlaw: formated_dob });
          }
        } else if (e.target.value.length === 5) {
          formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
          if (whoIs === "spouse1") {
            this.setState({ spouse: formated_dob });
          } else if (whoIs === "father") {
            this.setState({ father: formated_dob });
          } else if (whoIs === "mother") {
            this.setState({ mother: formated_dob });
          } else if (whoIs === "mother-in-law") {
            self.setState({ motherinlaw: formated_dob });
          } else if (whoIs === "father-in-law") {
            self.setState({ fatherinlaw: formated_dob });
          }
        } else if (e.target.value.length === 2) {
          formated_dob = dob[0] + dob[1] + "/";
          if (whoIs === "spouse1") {
            this.setState({ spouse: formated_dob });
          } else if (whoIs === "father") {
            this.setState({ father: formated_dob });
          } else if (whoIs === "mother") {
            this.setState({ mother: formated_dob });
          } else if (whoIs === "mother-in-law") {
            self.setState({ motherinlaw: formated_dob });
          } else if (whoIs === "father-in-law") {
            self.setState({ fatherinlaw: formated_dob });
          }
        } else {
          if (whoIs === "spouse1") {
            this.setState({ spouse: e.target.value });
          } else if (whoIs === "father") {
            this.setState({ father: e.target.value });
          } else if (whoIs === "mother") {
            this.setState({ mother: e.target.value });
          } else if (whoIs === "mother-in-law") {
            self.setState({ motherinlaw: e.target.value });
          } else if (whoIs === "father-in-law") {
            self.setState({ fatherinlaw: e.target.value });
          }
        }
      }
    }
  };

  setDobKid = (e) => {
    let self = this;
    var el = document.getElementById("kid1");
    el.addEventListener("keydown", function (event) {
      const key = event.key;
      if (key === "Backspace" || key === "Delete") {
        document.getElementById("kid1").value = "";
        self.setState({ kidbirthinput: "" });
      }
    });

    let dob = e.target.value.split("");
    let formated_dob;
    if (dob[1]) {
      let newDob3 = dob[0] + dob[1];
      if (parseInt(newDob3) <= 31) {
        if (dob[4]) {
          let newDob2 = dob[3] + dob[4];

          if (parseInt(newDob2) <= 12) {
            if (dob[9]) {
              let newDob = dob[6] + dob[7] + dob[8] + dob[9];
              let minage = this.state.minage.split("-")[0];

              if (1922 < parseInt(newDob)) {
                if (e.target.value.length < 11) {
                  if (e.target.value.length === 10) {
                    formated_dob =
                      dob[0] +
                      dob[1] +
                      "/" +
                      dob[3] +
                      dob[4] +
                      "/" +
                      dob[6] +
                      dob[7] +
                      dob[8] +
                      dob[9];
                    this.setState({ kidbirthinput: formated_dob });
                  } else if (e.target.value.length === 5) {
                    formated_dob =
                      dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                    this.setState({ kidbirthinput: formated_dob });
                  } else if (e.target.value.length === 2) {
                    formated_dob = dob[0] + dob[1] + "/";
                    this.setState({ kidbirthinput: formated_dob });
                  } else {
                    this.setState({ kidbirthinput: e.target.value });
                  }
                }
                if (dob[6] && dob[7] && dob[8] && dob[9]) {
                  let year = dob[6] + dob[7] + dob[8] + dob[9];
                  let Current_year = new Date().getFullYear();
                  this.setState({
                    kidbirthinputInDifferentForm:
                      parseInt(Current_year) - parseInt(year),
                  });
                }
              } else {
                if (e.target.value.length < 11) {
                  this.setState({ ageInDifferentForm: "Not Accepted" });
                  if (e.target.value.length === 10) {
                    formated_dob =
                      dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                    this.setState({ kidbirthinput: formated_dob });
                  } else if (e.target.value.length === 5) {
                    formated_dob =
                      dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                    this.setState({ kidbirthinput: formated_dob });
                  } else if (e.target.value.length === 2) {
                    formated_dob = dob[0] + dob[1] + "/";
                    this.setState({ kidbirthinput: formated_dob });
                  } else {
                    this.setState({ kidbirthinput: e.target.value });
                  }
                }
              }
            } else {
              if (e.target.value.length < 11) {
                this.setState({ ageInDifferentForm: "Not Accepted" });
                if (e.target.value.length === 10) {
                  formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                  this.setState({ kidbirthinput: formated_dob });
                } else if (e.target.value.length === 5) {
                  formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                  this.setState({ kidbirthinput: formated_dob });
                } else if (e.target.value.length === 2) {
                  formated_dob = dob[0] + dob[1] + "/";
                  this.setState({ kidbirthinput: formated_dob });
                } else {
                  this.setState({ kidbirthinput: e.target.value });
                }
              }
              if (dob[6] && dob[7] && dob[8] && dob[9]) {
                let year = dob[6] + dob[7] + dob[8] + dob[9];
                let Current_year = new Date().getFullYear();
                this.setState({
                  ageInDifferentForm: parseInt(Current_year) - parseInt(year),
                });
              }
            }
          } else {
            if (e.target.value.length < 11) {
              this.setState({ ageInDifferentForm: "Not Accepted" });
              if (e.target.value.length === 10) {
                formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
                this.setState({ kidbirthinput: formated_dob });
              } else if (e.target.value.length === 5) {
                formated_dob = dob[0] + dob[1] + "/";
                this.setState({ kidbirthinput: formated_dob });
              } else if (e.target.value.length === 2) {
                formated_dob = dob[0] + dob[1] + "/";
                this.setState({ kidbirthinput: formated_dob });
              } else {
                this.setState({ kidbirthinput: e.target.value });
              }
            }
          }
        } else {
          if (e.target.value.length < 11) {
            this.setState({ ageInDifferentForm: "Not Accepted" });
            if (e.target.value.length === 10) {
              formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
              this.setState({ kidbirthinput: formated_dob });
            } else if (e.target.value.length === 5) {
              formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
              this.setState({ kidbirthinput: formated_dob });
            } else if (e.target.value.length === 2) {
              formated_dob = dob[0] + dob[1] + "/";
              this.setState({ kidbirthinput: formated_dob });
            } else {
              this.setState({ kidbirthinput: e.target.value });
            }
          }
        }
      } else {
        if (e.target.value.length < 11) {
          this.setState({ ageInDifferentForm: "Not Accepted" });
          if (e.target.value.length === 10) {
            formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
            this.setState({ kidbirthinput: formated_dob });
          } else if (e.target.value.length === 5) {
            formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
            this.setState({ kidbirthinput: formated_dob });
          } else if (e.target.value.length === 2) {
            formated_dob = "";
            this.setState({ kidbirthinput: formated_dob });
          } else {
            this.setState({ kidbirthinput: e.target.value });
          }
        }
      }
    } else {
      if (e.target.value.length < 11) {
        this.setState({ ageInDifferentForm: "Not Accepted" });
        if (e.target.value.length === 10) {
          formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
          this.setState({ kidbirthinput: formated_dob });
        } else if (e.target.value.length === 5) {
          formated_dob = dob[0] + dob[1] + "/" + dob[3] + dob[4] + "/";
          this.setState({ kidbirthinput: formated_dob });
        } else if (e.target.value.length === 2) {
          formated_dob = dob[0] + dob[1] + "/";
          this.setState({ kidbirthinput: formated_dob });
        } else {
          this.setState({ kidbirthinput: e.target.value });
        }
      }
    }
  };

  render() {
    let Kids_data;
    if (this.state.kids.length !== 0) {
      Kids_data = this.state.kids.map((items, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: 10,
            }}
          >
            <p class="multiple_input">
              {items.date} ({items.gender})
            </p>
            <img onClick={() => this.RemoveKids(index)} src={cross} />
          </div>
        );
      });
    }

    return (
      <div id="slide" style={{ position: "relative" }}>
        <AppContext.Consumer>
          {(context) => (
            <React.Fragment>
              <div className="Family_animation">
                <div className="header-area">
                  <div className="">
                    <img
                      onClick={() => this.goBack()}
                      className="back-icon"
                      // className="back-icon"
                      src={process.env.PUBLIC_URL + "/back.png"}
                    />
                  </div>
                  <div className="header-text">
                    Family (Step {context.state.AppSteps[1]}/
                    {context.state.AppSteps.length})
                  </div>
                  <div class="progress-container">
                    <div
                      class="progress-bar"
                      id="myBar"
                      style={{ width: "40%" }}
                    ></div>
                  </div>
                </div>
                <div className="Familydetails_maincomponent_family">
                  <div>
                    <h4 className="familydetails_content">Add Your Family</h4>
                  </div>
                  {this.state.warning === true ? (
                    <div class="family_age_warning">
                      <div
                        style={{
                          backgroundColor: "red",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "90%",
                        }}
                      >
                        <span
                          style={{
                            color: "white",
                            fontWeight: 500,
                            padding: 7,
                            fontSize: 14,
                          }}
                        >
                          {this.state.msg}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                      >
                        <div style={{ paddingBottom: 20, width: "48%" }}>
                          {this.state.spouse === null ||
                          this.state.spouse === "" ? (
                            <div className="checkbox_input_family">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  paddingRight: 10,
                                  paddingTop: 10,
                                }}
                              >
                                <h5 className="checkbox_title">Spouse</h5>
                                {context.state.familyDetails.spouse_input ===
                                  false || this.state.spouse_input === false ? (
                                  <input
                                    onClick={() =>
                                      this.checkValues("spouse", context)
                                    }
                                    id="spouse"
                                    type="checkbox"
                                    checked={false}
                                    className="family_checkbox"
                                  />
                                ) : (
                                  <input
                                    onClick={() =>
                                      this.checkValues("spouse", context)
                                    }
                                    id="spouse"
                                    type="checkbox"
                                    checked={true}
                                    className="family_checkbox"
                                  />
                                )}
                              </div>
                              {this.state.spouse_input ? (
                                <div
                                  onClick={() =>
                                    this.setState(
                                      {
                                        spouse_input: false,
                                        spouseValid: true,
                                        spousepanel: false,
                                        kidpanel: false,
                                        motherpanel: false,
                                        fatherpanel: false,
                                        fatherinlawpanel: false,
                                        motherinlawpanel: false,
                                        kid_input: false,
                                        father_input: false,
                                        mother_input: false,
                                        motherinlaw_input: false,
                                        fatherinlaw_input: false,
                                      },
                                      () => {
                                        context.updateGlobalState(
                                          "family_details",
                                          this.state
                                        );
                                      }
                                    )
                                  }
                                >
                                  {this.context.state.userInfo.gender ===
                                  "M" ? (
                                    <img
                                      className="health-svg-family"
                                      src={spouse}
                                    />
                                  ) : (
                                    <img
                                      className="health-svg-family"
                                      src={husband}
                                    />
                                  )}
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    this.setState(
                                      {
                                        spouse_input: true,
                                        spouseValid: false,
                                        spousepanel: true,
                                        kidpanel: false,
                                        motherpanel: false,
                                        fatherpanel: false,
                                        fatherinlawpanel: false,
                                        motherinlawpanel: false,
                                        kid_input: false,
                                        father_input: false,
                                        mother_input: false,
                                        motherinlaw_input: false,
                                        fatherinlaw_input: false,
                                      },
                                      () => {
                                        context.updateGlobalState(
                                          "family_details",
                                          this.state
                                        );
                                      }
                                    )
                                  }
                                >
                                  {this.context.state.userInfo.gender ===
                                  "M" ? (
                                    <img
                                      className="health-svg-family"
                                      src={spouse}
                                    />
                                  ) : (
                                    <img
                                      className="health-svg-family"
                                      src={husband}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="checkbox_input_family_display">
                              <div>
                                <h5 class="input_display">Spouse</h5>
                                <span
                                  class="input_display_2"
                                  style={{ color: "#242424" }}
                                >
                                  Date of Birth
                                </span>
                                <p class="single_input">{this.state.spouse}</p>
                                {this.state.spouse !== null ? (
                                  this.state.spouse.split("/")[2] ? (
                                    this.state.spouse.split("/")[2].toString()
                                      .length === 4 ? (
                                      <h3
                                        onClick={() =>
                                          this.setState(
                                            {
                                              spouse_input: true,
                                              spouse: null,
                                              spouseValid: false,
                                              spousepanel: true,
                                              kidpanel: false,
                                              motherpanel: false,
                                              fatherpanel: false,
                                              fatherinlawpanel: false,
                                              motherinlawpanel: false,
                                              kid_input: false,
                                              father_input: false,
                                              mother_input: false,
                                              motherinlaw_input: false,
                                              fatherinlaw_input: false,
                                            },
                                            () => {
                                              context.updateGlobalState(
                                                "family_details",
                                                this.state
                                              );
                                            }
                                          )
                                        }
                                        class="input_edit"
                                      >
                                        Edit
                                      </h3>
                                    ) : (
                                      <div></div>
                                    )
                                  ) : (
                                    <div></div>
                                  )
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div style={{ paddingBottom: 20, width: "48%" }}>
                          {this.state.kids.length === 0 ? (
                            <div className="checkbox_input_family">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  paddingRight: 10,
                                  paddingTop: 10,
                                }}
                              >
                                <h5 className="checkbox_title">Kids</h5>
                                {context.state.familyDetails.kid_input ===
                                  false || this.state.kid_input === false ? (
                                  <input
                                    type="checkbox"
                                    onClick={() =>
                                      this.checkValues("kid", context)
                                    }
                                    id="kid"
                                    checked={false}
                                    className="family_checkbox"
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    onClick={() =>
                                      this.checkValues("kid", context)
                                    }
                                    id="kid"
                                    checked={true}
                                    className="family_checkbox"
                                  />
                                )}
                              </div>
                              {this.state.kid_input ? (
                                <div
                                  onClick={() =>
                                    this.setState(
                                      {
                                        kid_input: false,
                                        kidpanel: false,
                                        spousepanel: false,
                                        motherpanel: false,
                                        fatherpanel: false,
                                        fatherinlawpanel: false,
                                        motherinlawpanel: false,
                                        spouse_input: false,
                                        father_input: false,
                                        mother_input: false,
                                        motherinlaw_input: false,
                                        fatherinlaw_input: false,
                                      },
                                      () => {
                                        context.updateGlobalState(
                                          "family_details",
                                          this.state
                                        );
                                      }
                                    )
                                  }
                                >
                                  <img
                                    className="health-svg-family"
                                    src={kids}
                                  />
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    this.setState(
                                      {
                                        kid_input: true,
                                        kidpanel: true,
                                        spousepanel: false,
                                        motherpanel: false,
                                        fatherpanel: false,
                                        fatherinlawpanel: false,
                                        motherinlawpanel: false,
                                        spouse_input: false,
                                        father_input: false,
                                        mother_input: false,
                                        motherinlaw_input: false,
                                        fatherinlaw_input: false,
                                      },
                                      () => {
                                        context.updateGlobalState(
                                          "family_details",
                                          this.state
                                        );
                                      }
                                    )
                                  }
                                >
                                  <img
                                    className="health-svg-family"
                                    src={kids}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="checkbox_input_family_display">
                              <div>
                                <h5 class="input_display">Kids</h5>
                                <span
                                  class="input_display_2"
                                  style={{ color: "#242424" }}
                                >
                                  Date of Birth
                                </span>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    padding: "unset",
                                    margin: "unset",
                                    fontSize: "4vw",
                                    paddingLeft: 10,
                                  }}
                                >
                                  {Kids_data}
                                </p>
                                <h3
                                  onClick={() =>
                                    this.setState(
                                      {
                                        kid_input: true,
                                        kidpanel: true,
                                        spousepanel: false,
                                        motherpanel: false,
                                        fatherpanel: false,
                                        fatherinlawpanel: false,
                                        motherinlawpanel: false,
                                        spouse_input: false,
                                        father_input: false,
                                        mother_input: false,
                                        motherinlaw_input: false,
                                        fatherinlaw_input: false,
                                      },
                                      () => {
                                        context.updateGlobalState(
                                          "family_details",
                                          this.state
                                        );
                                      }
                                    )
                                  }
                                  class="input_edit"
                                >
                                  Add
                                </h3>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <Drawer
                        anchor="bottom"
                        style={{
                          borderTopLeftRadius: 20,
                          borderTopRightRadius: 20,
                        }}
                        open={this.state.spousepanel}
                        onClose={() =>
                          this.setState({
                            spousepanel: false,
                            spouse_input: false,
                          })
                        }
                      >
                        <div style={{ padding: 10 }}>
                          <div class="family_input">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <label className="checkbox_subtitle">
                                Enter Spouse's Birth Date
                              </label>
                              {/* <input type='checkbox' onClick={()=>this.setState({ spousepanel:false})} id='kid' className='family_checkbox' /> */}
                            </div>
                            <div
                              style={{
                                color: "#757575",
                                fontSize: 12,
                                lineHeight: 1,
                              }}
                            >
                              Year of Birth should be after 1956
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <img src={birthdate} />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  width: "85%",
                                }}
                              >
                                <input
                                  autoFocus={true}
                                  id="spouse1"
                                  type="text"
                                  value={this.state.spouse}
                                  min="1922-10-01"
                                  max={this.state.minage}
                                  onBlur={() =>
                                    this.state.spouse !== null
                                      ? this.state.spouse.split("/")[2]
                                        ? this.state.spouse.split("/")[2]
                                            .length === 4
                                          ? this.setState({
                                              spousepanel: false,
                                            })
                                          : this.setState({
                                              spousepanel: false,
                                              spouse_input: false,
                                              spouse: null,
                                              spouseValid: true,
                                            })
                                        : this.setState({
                                            spousepanel: false,
                                            spouse_input: false,
                                            spouse: null,
                                            spouseValid: true,
                                          })
                                      : this.setState({ spousepanel: false })
                                  }
                                  onChange={(e) => this.setDob(e, "spouse1")}
                                  placeholder="DD / MM / YYYY"
                                  class="familydate_input"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Drawer>

                      <Drawer
                        anchor="bottom"
                        style={{
                          borderTopLeftRadius: 20,
                          borderTopRightRadius: 20,
                        }}
                        open={this.state.kidpanel}
                        onClose={() =>
                          this.setState({ kidpanel: false, kid_input: false })
                        }
                      >
                        <div class="family_input">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <label className="checkbox_subtitle">
                              Kid's Birth Date
                            </label>
                           
                            {/* <input type='checkbox' onClick={()=>this.setState({ kidpanel:false})}  id='kid' className='family_checkbox' /> */}
                          </div>
                            <div
                              style={{
                                color: "#757575",
                                fontSize: 12,
                                lineHeight: 1,
                              }}
                            >
                              Year of Birth should be after 1956
                            </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <img src={birthdate} />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                width: "85%",
                              }}
                            >
                              <input
                                autoFocus={true}
                                value={this.state.kidbirthinput}
                                min="1922-10-01"
                                type="text"
                                onChange={(e) => this.setDobKid(e)}
                                placeholder="DD / MM / YYYY"
                                id="kid1"
                                class="familydate_input"
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: 60,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <input
                                onClick={() =>
                                  this.setState({ kidgenderinput: "M" })
                                }
                                type="radio"
                                id="male"
                                name="gender"
                                value="male"
                                checked={
                                  this.state.kidgenderinput === "M"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                style={{ marginLeft: 10, fontWeight: 600 }}
                                for="male"
                              >
                                Male
                              </label>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginLeft: 20,
                              }}
                            >
                              <input
                                onClick={() =>
                                  this.setState({ kidgenderinput: "F" })
                                }
                                type="radio"
                                id="female"
                                name="gender"
                                value="female"
                                checked={
                                  this.state.kidgenderinput === "F"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                style={{ marginLeft: 10, fontWeight: 600 }}
                                for="female"
                              >
                                Female
                              </label>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              onClick={() =>
                                this.pushKid({
                                  date: this.state.kidbirthinput,
                                  gender: this.state.kidgenderinput,
                                  memberCode: "C",
                                })
                              }
                              style={{ color: "red", paddingTop: 10 }}
                            >
                              Add Kid+
                            </span>
                            <span
                              onClick={() =>
                                this.setState(
                                  { kidpanel: true, kid_input: true },
                                  () => {
                                    this.pushKid({
                                      date: this.state.kidbirthinput,
                                      gender: this.state.kidgenderinput,
                                      memberCode: "C",
                                    });
                                    context.updateGlobalState(
                                      "family_details",
                                      this.state
                                    );
                                  }
                                )
                              }
                              style={{ color: "red", paddingTop: 10 }}
                            >
                              Done
                            </span>
                          </div>
                        </div>
                      </Drawer>
                    </div>

                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                      >
                        <div style={{ paddingBottom: 20, width: "48%" }}>
                          {this.state.father === null ||
                          this.state.father === "" ? (
                            <div className="checkbox_input_family">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  paddingRight: 10,
                                  paddingTop: 10,
                                }}
                              >
                                <h5 className="checkbox_title">Father</h5>
                                {context.state.familyDetails.father_input ===
                                  false || this.state.father_input === false ? (
                                  <input
                                    onClick={() =>
                                      this.checkValues("father", context)
                                    }
                                    id="father"
                                    type="checkbox"
                                    checked={false}
                                    className="family_checkbox"
                                  />
                                ) : (
                                  <input
                                    onClick={() =>
                                      this.checkValues("father", context)
                                    }
                                    id="father"
                                    type="checkbox"
                                    checked={true}
                                    className="family_checkbox"
                                  />
                                )}
                              </div>
                              {this.state.father_input ? (
                                <div
                                  onClick={() =>
                                    this.setState(
                                      {
                                        father_input: false,
                                        fatherValid: true,
                                        kidpanel: false,
                                        spousepanel: false,
                                        motherpanel: false,
                                        fatherpanel: false,
                                        fatherinlawpanel: false,
                                        motherinlawpanel: false,
                                        spouse_input: false,
                                        kid_input: false,
                                        mother_input: false,
                                        motherinlaw_input: false,
                                        fatherinlaw_input: false,
                                      },
                                      () => {
                                        context.updateGlobalState(
                                          "family_details",
                                          this.state
                                        );
                                      }
                                    )
                                  }
                                >
                                  <img
                                    className="health-svg-family"
                                    src={father_icon}
                                  />
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    this.setState(
                                      {
                                        father_input: true,
                                        fatherValid: false,
                                        kidpanel: false,
                                        spousepanel: false,
                                        motherpanel: false,
                                        fatherpanel: true,
                                        fatherinlawpanel: false,
                                        motherinlawpanel: false,
                                        spouse_input: false,
                                        kid_input: false,
                                        mother_input: false,
                                        motherinlaw_input: false,
                                        fatherinlaw_input: false,
                                      },
                                      () => {
                                        context.updateGlobalState(
                                          "family_details",
                                          this.state
                                        );
                                      }
                                    )
                                  }
                                >
                                  <img
                                    className="health-svg-family"
                                    src={father_icon}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="checkbox_input_family_display">
                              <div>
                                <h5 class="input_display">Father</h5>
                                <span
                                  class="input_display_2"
                                  style={{ color: "#242424" }}
                                >
                                  Date of Birth
                                </span>
                                <p class="single_input">{this.state.father}</p>
                                {this.state.father !== null ? (
                                  this.state.father.split("/")[2] ? (
                                    this.state.father.split("/")[2].toString()
                                      .length === 4 ? (
                                      <h3
                                        onClick={() =>
                                          this.setState(
                                            {
                                              father_input: true,
                                              father: null,
                                              fatherValid: false,
                                              kidpanel: false,
                                              spousepanel: false,
                                              motherpanel: false,
                                              fatherpanel: true,
                                              fatherinlawpanel: false,
                                              motherinlawpanel: false,
                                              spouse_input: false,
                                              kid_input: false,
                                              mother_input: false,
                                              motherinlaw_input: false,
                                              fatherinlaw_input: false,
                                            },
                                            () => {
                                              context.updateGlobalState(
                                                "family_details",
                                                this.state
                                              );
                                            }
                                          )
                                        }
                                        class="input_edit"
                                      >
                                        Edit
                                      </h3>
                                    ) : (
                                      <div></div>
                                    )
                                  ) : (
                                    <div></div>
                                  )
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>

                        <div style={{ paddingBottom: 20, width: "48%" }}>
                          {this.state.mother === null ||
                          this.state.mother === "" ? (
                            <div className="checkbox_input_family">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  paddingRight: 10,
                                  paddingTop: 10,
                                }}
                              >
                                <h5 className="checkbox_title">Mother</h5>
                                {context.state.familyDetails.mother_input ===
                                  false || this.state.mother_input === false ? (
                                  <input
                                    onClick={() =>
                                      this.checkValues("mother", context)
                                    }
                                    id="mother"
                                    checked={false}
                                    type="checkbox"
                                    className="family_checkbox"
                                  />
                                ) : (
                                  <input
                                    onClick={() =>
                                      this.checkValues("mother", context)
                                    }
                                    id="mother"
                                    checked={true}
                                    type="checkbox"
                                    className="family_checkbox"
                                  />
                                )}
                              </div>
                              {this.state.mother_input ? (
                                <div
                                  onClick={() =>
                                    this.setState(
                                      {
                                        mother_input: false,
                                        motherValid: true,
                                        kidpanel: false,
                                        spousepanel: false,
                                        motherpanel: false,
                                        fatherpanel: false,
                                        fatherinlawpanel: false,
                                        motherinlawpanel: false,
                                        spouse_input: false,
                                        father_input: false,
                                        kid_input: false,
                                        motherinlaw_input: false,
                                        fatherinlaw_input: false,
                                      },
                                      () => {
                                        context.updateGlobalState(
                                          "family_details",
                                          this.state
                                        );
                                      }
                                    )
                                  }
                                >
                                  <img
                                    className="health-svg-family"
                                    src={mother_icon}
                                  />
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    this.setState(
                                      {
                                        mother_input: true,
                                        motherValid: false,
                                        kidpanel: false,
                                        spousepanel: false,
                                        motherpanel: true,
                                        fatherpanel: false,
                                        fatherinlawpanel: false,
                                        motherinlawpanel: false,
                                        spouse_input: false,
                                        father_input: false,
                                        kid_input: false,
                                        motherinlaw_input: false,
                                        fatherinlaw_input: false,
                                      },
                                      () => {
                                        context.updateGlobalState(
                                          "family_details",
                                          this.state
                                        );
                                      }
                                    )
                                  }
                                >
                                  <img
                                    className="health-svg-family"
                                    src={mother_icon}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="checkbox_input_family_display">
                              <div>
                                <h5 class="input_display">Mother</h5>
                                <span
                                  class="input_display_2"
                                  style={{ color: "#242424" }}
                                >
                                  Date of Birth
                                </span>
                                <p class="single_input">{this.state.mother}</p>
                                {this.state.mother !== null ? (
                                  this.state.mother.split("/")[2] ? (
                                    this.state.mother.split("/")[2].toString()
                                      .length === 4 ? (
                                      <h3
                                        onClick={() =>
                                          this.setState(
                                            {
                                              mother_input: true,
                                              motherValid: false,
                                              mother: null,
                                              kidpanel: false,
                                              spousepanel: false,
                                              motherpanel: true,
                                              fatherpanel: false,
                                              fatherinlawpanel: false,
                                              motherinlawpanel: false,
                                              spouse_input: false,
                                              father_input: false,
                                              kid_input: false,
                                              motherinlaw_input: false,
                                              fatherinlaw_input: false,
                                            },
                                            () => {
                                              context.updateGlobalState(
                                                "family_details",
                                                this.state
                                              );
                                            }
                                          )
                                        }
                                        class="input_edit"
                                      >
                                        Edit
                                      </h3>
                                    ) : (
                                      <div></div>
                                    )
                                  ) : (
                                    <div></div>
                                  )
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <Drawer
                        anchor="bottom"
                        style={{
                          borderTopLeftRadius: 20,
                          borderTopRightRadius: 20,
                        }}
                        open={this.state.motherpanel}
                        onClose={() =>
                          this.setState({
                            motherpanel: false,
                            mother_input: false,
                          })
                        }
                      >
                        <div class="family_input">
                          <div>
                            <label className="checkbox_subtitle">
                              Mothers's Birth Date
                            </label>
                            <br />
                            <div
                              style={{
                                color: "#757575",
                                fontSize: 12,
                                lineHeight: 1,
                              }}
                            >
                              Year of Birth should be after 1956
                              {/* Age should be Greater than 1956 and less than your
                              age */}
                            </div>
                            {/* <input type='checkbox' onClick={()=>this.setState({ motherpanel:false})}  id='kid' className='family_checkbox' /> */}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <img src={birthdate} />
                            <div style={{ display: "flex", width: "85%" }}>
                              <input
                                autoFocus={true}
                                value={this.state.mother}
                                min="1922-10-01"
                                max={this.state.minage}
                                type="text"
                                onChange={(e) => this.setDob(e, "mother")}
                                onBlur={() =>
                                  this.state.mother !== null
                                    ? this.state.mother.split("/")[2]
                                      ? this.state.mother.split("/")[2]
                                          .length === 4
                                        ? this.setState({ motherpanel: false })
                                        : this.setState({
                                            motherpanel: false,
                                            mother_input: false,
                                            mother: null,
                                            motherValid: true,
                                          })
                                      : this.setState({
                                          motherpanel: false,
                                          mother: null,
                                          mother_input: false,
                                          motherValid: true,
                                        })
                                    : this.setState({ motherpanel: false })
                                }
                                id="mother1"
                                placeholder="DD / MM / YYYY"
                                class="familydate_input"
                              />
                            </div>
                          </div>
                        </div>
                      </Drawer>

                      <Drawer
                        anchor="bottom"
                        style={{
                          borderTopLeftRadius: 20,
                          borderTopRightRadius: 20,
                        }}
                        open={this.state.fatherpanel}
                        onClose={() =>
                          this.setState({
                            fatherpanel: false,
                            father_input: false,
                          })
                        }
                      >
                        <div class="family_input">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <label className="checkbox_subtitle">
                              Fathers's Birth Date
                            </label>
                            {/* <input type='checkbox' onClick={()=>this.setState({ fatherpanel:false})}  id='kid' className='family_checkbox' /> */}
                          </div>
                          <div
                              style={{
                                color: "#757575",
                                fontSize: 12,
                                lineHeight: 1,
                              }}
                            >
                              Year of Birth should be after 1956
                            </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <img src={birthdate} />
                            <div style={{ display: "flex", width: "85%" }}>
                              <input
                                autoFocus={true}
                                value={this.state.father}
                                min="1922-10-01"
                                max={this.state.minage}
                                type="text"
                                onChange={(e) => this.setDob(e, "father")}
                                onBlur={() =>
                                  this.state.father !== null
                                    ? this.state.father.split("/")[2]
                                      ? this.state.father.split("/")[2]
                                          .length === 4
                                        ? this.setState({ fatherpanel: false })
                                        : this.setState({
                                            fatherpanel: false,
                                            father_input: false,
                                            father: null,
                                          })
                                      : this.setState({
                                          fatherpanel: false,
                                          father_input: false,
                                          father: null,
                                        })
                                    : this.setState({ fatherpanel: false })
                                }
                                id="father1"
                                placeholder="DD / MM / YYYY"
                                class="familydate_input"
                              />
                            </div>
                          </div>
                        </div>
                      </Drawer>
                    </div>

                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                      >
                        <div style={{ paddingBottom: 25, width: "48%" }}>
                          {this.state.fatherinlaw === null ||
                          this.state.fatherinlaw === "" ? (
                            <div className="checkbox_input_family">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  paddingRight: 10,
                                  paddingTop: 10,
                                }}
                              >
                                <h5 className="checkbox_title">
                                  Father-in-law
                                </h5>
                                {context.state.familyDetails
                                  .fatherinlaw_input === false ||
                                this.state.fatherinlaw_input === false ? (
                                  <input
                                    onClick={() =>
                                      this.checkValues("fatherinlaw", context)
                                    }
                                    id="fatherinlaw"
                                    type="checkbox"
                                    checked={false}
                                    className="family_checkbox"
                                  />
                                ) : (
                                  <input
                                    onClick={() =>
                                      this.checkValues("fatherinlaw", context)
                                    }
                                    id="fatherinlaw"
                                    type="checkbox"
                                    checked={true}
                                    className="family_checkbox"
                                  />
                                )}
                              </div>
                              {this.state.fatherinlaw_input ? (
                                <div
                                  onClick={() =>
                                    this.setState(
                                      {
                                        fatherinlaw_input: false,
                                        fatherinlawValid: true,
                                        kidpanel: false,
                                        spousepanel: false,
                                        motherpanel: false,
                                        fatherpanel: false,
                                        fatherinlawpanel: false,
                                        motherinlawpanel: false,
                                        spouse_input: false,
                                        father_input: false,
                                        kid_input: false,
                                        mother_input: false,
                                        motherinlaw_input: false,
                                      },
                                      () => {
                                        context.updateGlobalState(
                                          "family_details",
                                          this.state
                                        );
                                      }
                                    )
                                  }
                                >
                                  <img
                                    className="health-svg-family"
                                    src={fatherinlaw}
                                  />
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    this.setState(
                                      {
                                        fatherinlaw_input: true,
                                        fatherinlawValid: false,
                                        kidpanel: false,
                                        spousepanel: false,
                                        motherpanel: false,
                                        fatherpanel: false,
                                        fatherinlawpanel: true,
                                        motherinlawpanel: false,
                                        spouse_input: false,
                                        father_input: false,
                                        kid_input: false,
                                        mother_input: false,
                                        motherinlaw_input: false,
                                      },
                                      () => {
                                        context.updateGlobalState(
                                          "family_details",
                                          this.state
                                        );
                                      }
                                    )
                                  }
                                >
                                  <img
                                    className="health-svg-family"
                                    src={fatherinlaw}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="checkbox_input_family_display">
                              <div>
                                <h5 class="input_display">Father-In-Law</h5>
                                <span
                                  class="input_display_2"
                                  style={{ color: "#242424" }}
                                >
                                  Date of Birth
                                </span>
                                <p class="single_input">
                                  {this.state.fatherinlaw}
                                </p>
                                {this.state.fatherinlaw !== null ? (
                                  this.state.fatherinlaw.split("/")[2] ? (
                                    this.state.fatherinlaw
                                      .split("/")[2]
                                      .toString().length === 4 ? (
                                      <h3
                                        onClick={() =>
                                          this.setState(
                                            {
                                              fatherinlaw_input: true,
                                              fatherinlawValid: false,
                                              fatherinlaw: null,
                                              kidpanel: false,
                                              spousepanel: false,
                                              motherpanel: false,
                                              fatherpanel: false,
                                              fatherinlawpanel: true,
                                              motherinlawpanel: false,
                                              spouse_input: false,
                                              father_input: false,
                                              kid_input: false,
                                              mother_input: false,
                                              motherinlaw_input: false,
                                            },
                                            () => {
                                              context.updateGlobalState(
                                                "family_details",
                                                this.state
                                              );
                                            }
                                          )
                                        }
                                        class="input_edit"
                                      >
                                        Edit
                                      </h3>
                                    ) : (
                                      <div></div>
                                    )
                                  ) : (
                                    <div></div>
                                  )
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>

                        <div style={{ paddingBottom: 25, width: "48%" }}>
                          {this.state.motherinlaw === null ||
                          this.state.motherinlaw === "" ? (
                            <div className="checkbox_input_family">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  paddingRight: 10,
                                  paddingTop: 10,
                                }}
                              >
                                <h5 className="checkbox_title">
                                  Mother-in-law
                                </h5>
                                {context.state.familyDetails
                                  .motherinlaw_input === false ||
                                this.state.motherinlaw_input === false ? (
                                  <input
                                    onClick={() =>
                                      this.checkValues("motherinlaw", context)
                                    }
                                    id="motherinlaw"
                                    checked={false}
                                    type="checkbox"
                                    className="family_checkbox"
                                  />
                                ) : (
                                  <input
                                    onClick={() =>
                                      this.checkValues("motherinlaw", context)
                                    }
                                    id="motherinlaw"
                                    type="checkbox"
                                    checked={true}
                                    className="family_checkbox"
                                  />
                                )}
                              </div>
                              {this.state.motherinlaw_input ? (
                                <div
                                  onClick={() =>
                                    this.setState(
                                      {
                                        motherinlaw_input: false,
                                        motherinlawValid: true,
                                        kidpanel: false,
                                        spousepanel: false,
                                        motherpanel: false,
                                        fatherpanel: false,
                                        fatherinlawpanel: false,
                                        motherinlawpanel: false,
                                        spouse_input: false,
                                        father_input: false,
                                        kid_input: false,
                                        mother_input: false,
                                        fatherinlaw_input: false,
                                      },
                                      () => {
                                        context.updateGlobalState(
                                          "family_details",
                                          this.state
                                        );
                                      }
                                    )
                                  }
                                >
                                  <img
                                    className="health-svg-family"
                                    src={motherinlaw}
                                  />
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    this.setState(
                                      {
                                        motherinlaw_input: true,
                                        motherinlawValid: false,
                                        kidpanel: false,
                                        spousepanel: false,
                                        motherpanel: false,
                                        fatherpanel: false,
                                        fatherinlawpanel: false,
                                        motherinlawpanel: true,
                                        spouse_input: false,
                                        father_input: false,
                                        kid_input: false,
                                        mother_input: false,
                                        fatherinlaw_input: false,
                                      },
                                      () => {
                                        context.updateGlobalState(
                                          "family_details",
                                          this.state
                                        );
                                      }
                                    )
                                  }
                                >
                                  <img
                                    className="health-svg-family"
                                    src={motherinlaw}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="checkbox_input_family_display">
                              <div>
                                <h5 class="input_display">Mother-In-Law</h5>
                                <span
                                  class="input_display_2"
                                  style={{ color: "#242424" }}
                                >
                                  Date of Birth
                                </span>
                                <p class="single_input">
                                  {this.state.motherinlaw}
                                </p>
                                {this.state.motherinlaw !== null ? (
                                  this.state.motherinlaw.split("/")[2] ? (
                                    this.state.motherinlaw
                                      .split("/")[2]
                                      .toString().length === 4 ? (
                                      <h3
                                        onClick={() =>
                                          this.setState(
                                            {
                                              motherinlaw_input: true,
                                              motherinlawValid: false,
                                              motherinlaw: null,
                                              kidpanel: false,
                                              spousepanel: false,
                                              motherpanel: false,
                                              fatherpanel: false,
                                              fatherinlawpanel: false,
                                              motherinlawpanel: true,
                                              spouse_input: false,
                                              father_input: false,
                                              kid_input: false,
                                              mother_input: false,
                                              fatherinlaw_input: false,
                                            },
                                            () => {
                                              context.updateGlobalState(
                                                "family_details",
                                                this.state
                                              );
                                            }
                                          )
                                        }
                                        class="input_edit"
                                      >
                                        Edit
                                      </h3>
                                    ) : (
                                      <div></div>
                                    )
                                  ) : (
                                    <div></div>
                                  )
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <Drawer
                      anchor="bottom"
                      style={{
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20,
                      }}
                      open={this.state.motherinlawpanel}
                      onClose={() =>
                        this.setState({
                          motherinlawpanel: false,
                          motherinlaw_input: false,
                        })
                      }
                    >
                      <div class="family_input">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <label className="checkbox_subtitle">
                            Mother-In-Law's Birth Date
                          </label>
                        </div>
                        <div
                              style={{
                                color: "#757575",
                                fontSize: 12,
                                lineHeight: 1,
                              }}
                            >
                              Year of Birth should be after 1956
                            </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img src={birthdate} />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              width: "85%",
                            }}
                          >
                            <input
                              autoFocus={true}
                              value={this.state.motherinlaw}
                              min="1922-10-01"
                              max={this.state.minage}
                              type="text"
                              placeholder="DD / MM / YYYY"
                              onChange={(e) => this.setDob(e, "mother-in-law")}
                              onBlur={() =>
                                this.state.motherinlaw !== null
                                  ? this.state.motherinlaw.split("/")[2]
                                    ? this.state.motherinlaw.split("/")[2]
                                        .length === 4
                                      ? this.setState({
                                          motherinlawpanel: false,
                                        })
                                      : this.setState({
                                          motherinlawpanel: false,
                                          motherinlaw_input: false,
                                          motherinlaw: null,
                                          motherinlawValid: true,
                                        })
                                    : this.setState({
                                        motherinlawpanel: false,
                                        motherinlaw_input: false,
                                        motherinlaw: null,
                                        motherinlawValid: true,
                                      })
                                  : this.setState({ motherinlawpanel: false })
                              }
                              id="motherlaw1"
                              class="familydate_input"
                            />
                          </div>
                        </div>
                      </div>
                    </Drawer>

                    <Drawer
                      anchor="bottom"
                      style={{
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20,
                      }}
                      open={this.state.fatherinlawpanel}
                      onClose={() =>
                        this.setState({
                          fatherinlawpanel: false,
                          fatherinlaw_input: false,
                        })
                      }
                    >
                      <div class="family_input">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <label className="checkbox_subtitle">
                            Father-In-Law's Birth Date
                          </label>
                        </div>
                        <div
                              style={{
                                color: "#757575",
                                fontSize: 12,
                                lineHeight: 1,
                              }}
                            >
                              Year of Birth should be after 1956
                            </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img src={birthdate} />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              width: "85%",
                            }}
                          >
                            <input
                              autoFocus={true}
                              value={this.state.fatherinlaw}
                              min="1922-10-01"
                              max="2003-10-01"
                              type="text"
                              placeholder="DD / MM / YYYY"
                              onChange={(e) => this.setDob(e, "father-in-law")}
                              onBlur={() =>
                                this.state.fatherinlaw !== null
                                  ? this.state.fatherinlaw.split("/")[2]
                                    ? this.state.fatherinlaw.split("/")[2]
                                        .length === 4
                                      ? this.setState({
                                          fatherinlawpanel: false,
                                        })
                                      : this.setState({
                                          fatherinlawpanel: false,
                                          fatherinlaw_input: false,
                                          fatherinlaw: null,
                                          fatherinlawValid: true,
                                        })
                                    : this.setState({
                                        fatherinlawpanel: false,
                                        fatherinlaw_input: false,
                                        fatherinlaw: null,
                                        fatherinlawValid: true,
                                      })
                                  : this.setState({ fatherinlawpanel: false })
                              }
                              id="fatherlaw1"
                              class="familydate_input"
                            />
                          </div>
                        </div>
                      </div>
                    </Drawer>
                  </div>

                  <div style={{ paddingTop: "10%",paddingBottom : "10%", textAlign: 'center', width: "100%" }}>
                    <div className="brand_icons_header">Powered by</div>
                    <div className="brand_icons">
                      <img src={HDFC_Bank_Logo} style={{marginRight:"8px",height :"20px"}} alt="HDFC_BANK" />
                      <img src={HDFC_Ergo_Logo} style={{marginLeft:"8px"}}  alt="HDFC_ERGO" />
                    </div>
                  </div>

                  <AppContext.Consumer>
                    {(context) => (
                      <React.Fragment>
                        {(this.state.kidpanel === true &&
                          this.state.kids.length === 0) ||
                        (this.state.spouse_input === true &&
                          this.state.spouseValid === false) ||
                        (this.state.motherpanel === true &&
                          this.state.motherValid === false) ||
                        (this.state.fatherpanel === true &&
                          this.state.fatherValid === false) ||
                        (this.state.motherinlaw_input === true &&
                          this.state.motherinlawValid === false) ||
                        (this.state.fatherinlaw_input === true &&
                          this.state.fatherinlawValid === false) ? (
                          <div class="bottom_btn_container">
                            <button
                              className="btn"
                              style={{ backgroundColor: "grey" }}
                            >
                              Next{" "}
                            </button>
                          </div>
                        ) : (
                          <div class="bottom_btn_container">
                            <button
                              className="btn"
                              // onClick={() => this.props.history.push("/income")}
                              onClick={() => this.props.history.push("/allthing-money")}
                            >
                              Next{" "}
                            </button>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </AppContext.Consumer>
                </div>
              </div>
            </React.Fragment>
          )}
        </AppContext.Consumer>
      </div>
    );
  }
}
Familydetails.contextType = AppContext;

export default Familydetails;
