const mediclaimPercent = 45;
const personal_accident = 23;
const maxcover_metro_lessthan45 = 500000; // 500000
const maxcover_metro_morethan45 = 1000000; //
const maxcover_nonmetro_lessthan45 = 500000 - (500000 * 15) / 100;
const maxcover_nonmetro_morethan45 = 1000000 - (1000000 * 15) / 100;
const dependent_maxcover_metro_lessthan45 = 500000 * 1.5;
const dependent_maxcover_metro_morethan45 = 1000000 * 1.5; // 1500000
const dependent_maxcover_nonmetro_lessthan45 =
  dependent_maxcover_metro_lessthan45 * 0.85; // 637500
const dependent_maxcover_nonmetro_morethan45 =
  dependent_maxcover_metro_morethan45 * 0.85; // 1275000
const parents_in_law_nonmetro = dependent_maxcover_metro_morethan45 * 0.85;
const parents_in_law_metro = parents_in_law_nonmetro * 0.85;

export {
  mediclaimPercent,
  personal_accident,
  maxcover_metro_lessthan45,
  maxcover_metro_morethan45,
  maxcover_nonmetro_lessthan45,
  maxcover_nonmetro_morethan45,
  dependent_maxcover_metro_lessthan45,
  dependent_maxcover_metro_morethan45,
  dependent_maxcover_nonmetro_lessthan45,
  dependent_maxcover_nonmetro_morethan45,
  parents_in_law_metro,
  parents_in_law_nonmetro,
};
