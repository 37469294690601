import React,{Component} from 'react';
import AppContext from '../context/AppContext'
import honda from '../svg/honda.svg'
import tvs from '../svg/tvs.svg'
import bajaj from '../svg/bajaj.svg'
import yamaha from '../svg/yamaha.svg'
import hero from '../svg/hero.svg'
import herohonda from '../svg/herohonda.svg'
import royal from '../svg/royal.svg'
import davidson from '../svg/davidson.svg'
import suzuki from '../svg/suzuki.svg'
import ktm from '../svg/ktm.svg'
import hyundai from '../svg/hyundai.svg'
import marutisuzuki from '../svg/marutisuzuki.svg'
import skoda from '../svg/skoda.svg';
import tatamotors from '../svg/tatamotors.svg'
import toyota from '../svg/toyota.svg'
import chevrlot from '../svg/chevrlot.svg'
import mahindra from '../svg/mahindra.svg'
import volkswagen from '../svg/volkswagen.svg'
import ford from '../svg/ford.svg'

let brands = [{img:honda,name:'HERO HONDA'},{img:tvs,name:'TVS'},{img:bajaj,name:'BAJAJ AUTO.'},{img:yamaha,name:'YAMAHA'},
    {img:suzuki,name:'SUZUKI'},{img:davidson,name:'HARLEY DAVIDSON'},{img:royal,name:'ROYAL ENFIELD'},{img:hero,name:'HERO MOTOCORP'},{img:ktm,name:'KTM'},
]

let car_brands = [{img:tatamotors, name:'TATA MOTORS LTD'},{img:mahindra,name:'MAHINDRA.'},{img:marutisuzuki, name:'MARUTI'},{img:hyundai,name:'HYUNDAI'},{img:toyota,name:'TOYOTA KIRLOSKAR'},
{img:ford, name:'FORD'},{img:volkswagen,name:'VOLKSWAGEN'},{img:skoda,name:'SKODA'},]
class Motor_Brand extends Component{
    constructor(props){
        super(props)
        this.state={
            searchData:[],
            searchit:''
        }
    }

    componentDidMount() {

    }

    searchit = (data) => {
        this.setState({searchData:[]})
        this.setState({searchit:data})
    }

    brandSelected = (brand) => {
       this.props.setBrand(brand)
       this.props.changePhase('model')
    }

    goBack=() => {
        this.props.setProcessStatus(false)
    }

    render() {

        let allbrands
        if(this.state.searchit === '' && this.props.type === 'BIKE') {
            allbrands = brands.map(items => {
                return(
                    <img onClick={()=>this.brandSelected(items.name)} src={items.img} class='brands' />
                )
            })
        }
        else if (this.state.searchit === '' && this.props.type === 'CAR') {
            allbrands = car_brands.map(items => {
                return(
                    <img onClick={()=>this.brandSelected(items.name)} src={items.img} class='brands' />
                )
            })
        }

        let searchData
        if(this.state.searchit !== '' && this.props.type === 'CAR') {
            for(let i=0;i<car_brands.length;i++) {
                if(car_brands[i].name.toLowerCase().includes(this.state.searchit)) {
                    this.state.searchData.push(car_brands[i])
                }
            }
        }
        else if(this.state.searchit !== '' && this.props.type === 'BIKE') {
            for(let i=0;i<brands.length;i++) {
                if(brands[i].name.toLowerCase().includes(this.state.searchit)) {
                    this.state.searchData.push(brands[i])
                }
            }
        }

        if(this.state.searchData.length !== 0) {
            searchData = this.state.searchData.map(items => {
                return(
                    <img onClick={()=>this.brandSelected(items.name)} src={items.img} class='brands' />
                )
            })
        }
        
        return(
            <div>   
                <div className="header-area">
                    <div className=""><img onClick={() => this.goBack()} className="back-icon" className="back-icon" src={process.env.PUBLIC_URL + '/back.png'} /></div>
                    <div className="header-text">{this.props.type === 'CAR' ?' Car Details' : 'Bike Details'}</div>
                    
                </div>

                <div >
                    <div style={{margin:20}}>
                        <h3>
                            {this.props.type === 'BIKE' ? 'Select Bike Brand' : 'Select Car Brand'}
                        </h3>

                        <div style={{padding:10,display:'flex',alignItems:'center'}} class='city_search'>
                            <input value={this.state.searchit} onChange={(e)=>this.searchit(e.target.value)} placeholder='e.g Renault' style={{border:'none',outline:'none',backgroundColor:'transparent',width:'90%',fontSize:16,padding:5}} />
                            <ion-icon style={{fontSize:20}} name="search-outline"></ion-icon>
                        </div>
                    </div>
                </div>

                <div style={{margin:20}}>
                    {this.state.searchit === '' ? allbrands : searchData}
                </div>

            </div>

        )
    }


}

Motor_Brand.contextType = AppContext;

export default Motor_Brand;