import React,{Component} from 'react';


class Motor_registration_year extends Component{
    constructor(props){
        super(props)
        this.state={
            years:['Brand New Bike']
        }
    }

    componentDidMount() {
        
    }

    selectedYear = (year) => {
        this.props.setYear(year)
        this.props.changePhase('rto_selection')
    }

    goBack = () => {
        this.props.changePhase('varient')
    }

    render() {
        for(let i=2021; i>1941; i--) {
            this.state.years.push(i)
            console.log(i)
         }
        let yearsData = this.state.years.map(items => {
            return (
                <span class='model_option_container' onClick={()=>this.selectedYear(items)}>{items}</span>
            )
        })
        return(
            <div>   
                <div className="header-area">
                    <div className=""><img onClick={() => this.goBack()} className="back-icon" className="back-icon" src={process.env.PUBLIC_URL + '/back.png'} /></div>
                    <div className="header-text">Car Details</div>
                    
                </div>

                <div >
                    <div >
                        <h3>
                        {this.props.type === 'BIKE' ? 'Select Bike Registration Year' : 'Select Car Registration Year'}
                        </h3>

                        <div style={{padding:10,display:'flex',alignItems:'center'}} class='city_search'>
                            <input placeholder='Renault' style={{border:'none',outline:'none',backgroundColor:'transparent',width:'90%',fontSize:16,padding:5}} />
                            <ion-icon style={{fontSize:20}} name="search-outline"></ion-icon>
                        </div>
                    </div>
                </div>

                <div>
                    {yearsData}
                </div>

            </div>


        )
    }


}

export default Motor_registration_year;