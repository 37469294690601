import React, { Component } from "react";

class Motor_Rto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rto_data: [],
      searchit: "",
      searchData: [],
      error: "Something Went Wrong"
    };
  }

  componentDidMount() {
    fetch(
      `${process.env.REACT_APP_API_URL}/getTwoWheelerProcessData?registration_year=${this.props.year_of_registration}`
    )
      .then(res => res.json())
      .then(data => {
        if (data !== "error") {
          if (data.length !== 0) {
            this.setState({ rto_data: data });
          } else {
            this.setState({ msg: "No data found", error: true });
          }
        } else {
          this.setState({ msg: "Something Went Wrong", error: true });
        }
      });
  }

  selectedRTO = RTO => {
    this.props.setRTO(RTO);
  };

  goBack = () => {
    this.props.changePhase("registration_year");
  };

  searchIt = city => {
    this.setState({ searchData: [] });
    this.setState({ searchit: city });
  };

  render() {
    let RTO_data;
    if (this.state.searchit === "") {
      RTO_data = this.state.rto_data.map(items => {
        return (
          <span
            onClick={() => this.selectedRTO(items.rto_code)}
            class="model_option_container"
          >
            {items.registration_state_city}
          </span>
        );
      });
    }

    let searchData;
    if (this.state.searchit !== "") {
      for (let i = 0; i < this.state.rto_data.length; i++) {
        if (
          this.state.rto_data[i].registration_state_city
            .toLowerCase()
            .includes(this.state.searchit)
        ) {
          this.state.searchData.push(this.state.rto_data[i]);
        }
      }
    }

    if (this.state.searchData.length !== 0) {
      searchData = this.state.searchData.map(items => {
        return (
          <span
            onClick={() => this.selectedRTO(items.rto_code)}
            class="model_option_container"
          >
            {items.registration_state_city}
          </span>
        );
      });
    }

    return (
      <div>
        <div className="header-area">
          <div className="">
            <img
              onClick={() => this.goBack()}
              className="back-icon"
              className="back-icon"
              src={process.env.PUBLIC_URL + "/back.png"}
            />
          </div>
          <div className="header-text">Car Details</div>
        </div>

        <div>
          <div style={{ margin: 20 }}>
            <h3>Select City & RTO</h3>

            <div
              style={{ padding: 10, display: "flex", alignItems: "center" }}
              class="city_search"
            >
              <input
                onChange={e => this.searchIt(e.target.value)}
                value={this.state.searchit}
                placeholder="e.g Renault"
                style={{
                  border: "none",
                  outline: "none",
                  backgroundColor: "transparent",
                  width: "90%",
                  fontSize: 16,
                  padding: 5
                }}
              />
              <ion-icon
                style={{ fontSize: 20 }}
                name="search-outline"
              ></ion-icon>
            </div>
          </div>
        </div>

        {this.state.error === true ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh"
            }}
          >
            <p style={{ color: "red" }}>{this.state.msg}</p>
          </div>
        ) : (
          <div>{this.state.searchit === "" ? RTO_data : searchData}</div>
        )}
      </div>
    );
  }
}

export default Motor_Rto;
