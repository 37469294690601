import React, { Component } from "react";
import AppContext from "../context/AppContext";
import cyber_icon from "../svg/cyber_icon.svg";
import HDFC_Bank_Logo from "../svg/HDFC_Bank_Logo.svg"
import HDFC_Ergo_Logo from "../svg/HDFC_Ergo_Logo.svg"

class Cyber_insurance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suminsured: 0,
    };
    this.suminsuredChange = this.suminsuredChange.bind(this);
  }

  updateLoacalStatewithGlobal = () => {
    this.setState({
      suminsured: this.context.state.cyberInsurance.suminsured,
    });
  };

  update_insurance_score = async () => {
    this.context.updateCyberSumInsured(this.state, "Recommendations");
  };

  goBack() {
    this.props.history.goBack();
  }
  suminsuredChange(context, e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.context.updateCyberStateSumInsured(this.state);
      }
    );
  }
  async componentDidMount() {
    this.updateLoacalStatewithGlobal();
    if (this.context.state.score_board.cyber > 0) {
      if (this.context.state.score_board.cyber === 7) {
        await this.context.updateCreditScore("cyber", "has_insured", "less");
      } else if (this.context.state.score_board.cyber === 7) {
        await this.context.updateCreditScore(
          "cyber",
          "less_than_recommended",
          "less"
        );
      } else if (this.context.state.score_board.cyber === 15) {
        await this.context.updateCreditScore(
          "cyber",
          "greate_than_recommended",
          "less"
        );
      }
    }
    window.scrollTo(0, 0);
  }

  inWords = (num) => {
    var a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    var b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
        : "";
    return str;
  };

  render() {
    let suminsured = this.context.state.cyberInsurance.suminsured;
    if (suminsured) {
      if (suminsured.toString().length !== 0) {
        if (
          suminsured.toString().length > 4 &&
          suminsured.toString().length < 7
        ) {
          suminsured = this.inWords(
            this.context.state.cyberInsurance.suminsured
          ).toUpperCase();
        } else {
          suminsured = this.inWords(
            this.context.state.cyberInsurance.suminsured
          ).toUpperCase();
        }
      } else {
        suminsured = 0;
      }
    }

    return (
      <div style={{ paddingBottom: 40 }}>
        <div className="header-area">
          <div className="">
            <img
              onClick={() => this.goBack()}
              className="back-icon"
              src={process.env.PUBLIC_URL + "/back.png"}
            />
          </div>
          <div className="header-text">Existing Insurance Details</div>
          <div class="progress-container">
            <div class="progress-bar" id="myBar" style={{ width: "25%" }}></div>
          </div>
        </div>
        <AppContext.Consumer>
          {(context) => (
            <React.Fragment>
              <div id="slide">
                <div class="existingInsurance_pages">
                  <img className="" src={cyber_icon} />
                </div>

                <div class="super_top_up_main_container">
                  <div>
                    <div class="category_insurance_header">
                      Exsisting Cyber Insurance
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 25,
                    }}
                  >
                    <div class="suminsured_container">
                      <p
                        style={{
                          textAlign: "center",
                          paddingTop: 10,
                          marginBottom: "unset",
                          paddingLeft: 20,
                        }}
                      >
                        Sum Insured
                      </p>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="health-input">
                          <label style={{ fontSize: 45, margin: "unset" }}>
                            &#8377;
                          </label>
                          <input
                            name="suminsured"
                            value={
                              context.state.cyberInsurance.suminsured
                                ? context.state.cyberInsurance.suminsured ===
                                  "0"
                                  ? "1,00,000"
                                  : context.state.cyberInsurance.suminsured
                                : this.state.suminsured
                            }
                            onChange={(e) => this.suminsuredChange(context, e)}
                            className="suminsured-input other_family_input"
                            id="suminsured"
                            type="number"
                            placeholder="100,000"
                            required
                          />
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <p class="textAmount">
                          {context.state.cyberInsurance.suminsured
                            ? context.state.cyberInsurance.suminsured === "0"
                              ? "ONE LAKH"
                              : suminsured
                            : this.state.suminsured}
                        </p>
                      </div>
                      <div
                        className="page-content"
                        style={{ marginBottom: "25%" }}
                      >
                        <input
                          value={context.state.cyberInsurance.suminsured}
                          name="suminsured"
                          onChange={(e) => this.suminsuredChange(context, e)}
                          type="range"
                          min="0"
                          max="5000000"
                          step="500000"
                          className="slider"
                          id="suminsured-range"
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="min_amt">₹1 Lakh</div>
                          <div className="max_amt">₹50 Lakh+</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                  <div style={{ paddingTop:"15%", textAlign: 'center', width: "100%" }}>
                    <div className="brand_icons_header">Powered by</div>
                    <div className="brand_icons">
                      <img src={HDFC_Bank_Logo} style={{marginRight:"8px",height :"20px"}} alt="HDFC_BANK" />
                      <img src={HDFC_Ergo_Logo} style={{marginLeft:"8px"}}  alt="HDFC_ERGO" />
                    </div>
                  </div>

                {context.state.cyberInsurance.suminsured >= 100000 ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      class="btn"
                      onClick={() => this.update_insurance_score()}
                    >
                      Next
                    </button>
                  </div>
                ) : (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button style={{ backgroundColor: "grey" }} class="btn">
                      Next
                    </button>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </AppContext.Consumer>
      </div>
    );
  }
}
Cyber_insurance.contextType = AppContext;
export default Cyber_insurance;
