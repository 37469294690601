import ReactGA from "react-ga";

function GA() {
  ReactGA.initialize("G-N0Q5577YDK",{
    gaOptions:{
      cookieFlags:"Secure"
    }
  });

  ReactGA.pageview(window.location.pathname + window.location.search);
}

function EVENT(event, action, label) {
  ReactGA.event({
    category: event,
    action: action,
    label,
  });
}

let GA_trigger = {
  GA,
  EVENT,
};

export { GA_trigger };
