import React, { Component } from "react";
import AppContext from "../context/AppContext";
import parents from "../svg/parents.svg";
import kids from "../svg/kids.svg";
import spouse from "../svg/spouse.svg";
import myself from "../svg/myself.svg";
import health_icon2 from "../svg/health_icon2.svg";

class Critical_illness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spouse: false,
      myself: false,
      kids: false,
      parents: false,
      suminsured: 0
    };
    this.suminsuredChange = this.suminsuredChange.bind(this);
  }

  updateLoacalStatewithGlobal = () => {
    this.setState({
      spouse: this.context.state.citical_illness.spouse,
      myself: this.context.state.citical_illness.myself,
      kids: this.context.state.citical_illness.kids,
      parents: this.context.state.citical_illness.parents,
      suminsured: this.context.state.citical_illness.suminsured
    });
    const filteredRecommendations = this.context.state.Recommendations.filter(
      recommendation =>
        recommendation.subcategory != "Critical_Illness_Insurance"
    );
    this.context.updateRecommendationsStateOnBack(filteredRecommendations);
  };

  goBack() {
    this.props.history.goBack();
  }
  suminsuredChange(context, e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        context.updateCriticalInsuranceState(this.state);
      }
    );
  }

  checkValues = (id, value) => {
    if (document.getElementById(id).checked) {
      if (value === "Critical_myself") {
        this.setState({ myself: true });
      } else if (value === "Critical_kids") {
        this.setState({ kids: true });
      } else if (value === "Critical_spouse") {
        this.setState({ spouse: true });
      } else {
        this.setState({ parents: true });
      }
    } else {
      if (value === "Critical_myself") {
        this.setState({ myself: false });
      } else if (value === "Critical_kids") {
        this.setState({ kids: false });
      } else if (value === "Critical_spouse") {
        this.setState({ spouse: false });
      } else {
        this.setState({ parents: false });
      }
    }
  };
  nextClicked = e => {
    e.preventDefault();
  };

  componentDidMount() {
    this.updateLoacalStatewithGlobal();
    window.scrollTo(0, 0);
    const context = this.context;
    // const filteredRecommendations = context.state.Recommendations.filter(recommendation => recommendation.subcategory != 'Critical_Illness_Insurance')
    // console.log(filteredRecommendations)
    // context.updateRecommendationsStateOnBack(filteredRecommendations)
  }

  inWords = num => {
    var a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen "
    ];
    var b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety"
    ];

    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
        : "";
    return str;
  };

  render() {
    let suminsured = this.context.state.citical_illness.suminsured;
    if (suminsured) {
      if (suminsured.toString().length !== 0) {
        if (
          suminsured.toString().length > 4 &&
          suminsured.toString().length < 7
        ) {
          suminsured = this.inWords(
            this.context.state.citical_illness.suminsured
          ).toUpperCase();
        } else {
          suminsured = this.inWords(
            this.context.state.citical_illness.suminsured
          ).toUpperCase();
        }
      } else {
        suminsured = 0;
      }
    }

    return (
      <div style={{ paddingBottom: 40 }}>
        <div className="header-area">
          <div className="">
            <img
              onClick={() => this.goBack()}
              className="back-icon"
              src={process.env.PUBLIC_URL + "/back.png"}
            />
          </div>
          <div className="header-text">Existing Insurance Details</div>
          <div class="progress-container">
            <div class="progress-bar" id="myBar" style={{ width: "25%" }}></div>
          </div>
        </div>
        <AppContext.Consumer>
          {context => (
            <React.Fragment>
              <div id="slide">
                <div className="super_top_up_main_container">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div class="existingInsurance_pages">
                      <img src={health_icon2} />
                    </div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span class="category_insurance_header">
                      Existing Critical Illness Insurance
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 25
                    }}
                  >
                    <div class="suminsured_container">
                      <p
                        style={{
                          textAlign: "center",
                          paddingTop: 10,
                          marginBottom: "unset",
                          paddingLeft: 20
                        }}
                      >
                        Sum Insured
                      </p>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="health-input">
                          <label style={{ fontSize: 45, margin: "unset" }}>
                            &#8377;
                          </label>
                          <input
                            name="suminsured"
                            value={
                              context.state.citical_illness.suminsured
                                ? context.state.citical_illness.suminsured ===
                                  "0"
                                  ? "1,00,000"
                                  : context.state.citical_illness.suminsured
                                : this.state.suminsured > 0 &&
                                  this.state.suminsured
                            }
                            onChange={e => this.suminsuredChange(context, e)}
                            className="suminsured-input other_family_input"
                            id="suminsured"
                            type="number"
                            placeholder="1,00,000"
                            required
                          />
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <p class="textAmount">
                          {context.state.citical_illness.suminsured
                            ? context.state.citical_illness.suminsured === "0"
                              ? "ONE LAKH"
                              : suminsured
                            : this.state.suminsured > 0 &&
                              this.state.suminsured}
                        </p>
                      </div>
                      <div
                        className="page-content"
                        style={{ marginBottom: "25%" }}
                      >
                        <input
                          name="suminsured"
                          value={context.state.citical_illness.suminsured}
                          onChange={e => this.suminsuredChange(context, e)}
                          type="range"
                          min="0"
                          max="5000000"
                          step="500000"
                          className="slider"
                          id="suminsured-range"
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                        >
                          <div className="min_amt">₹1 Lakh</div>
                          <div className="max_amt">₹50 Lakh+</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <label className="header_labels">
                    Family members covered
                  </label>

                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginTop: 10,
                        paddingLeft: 20,
                        paddingRight: 20
                      }}
                    >
                      <div className="checkbox_input">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: 10,
                            paddingTop: 10
                          }}
                        >
                          <h5 className="checkbox_title">Spouse</h5>
                          <input
                            type="checkbox"
                            onClick={() =>
                              this.checkValues(
                                "Critical_spouse",
                                "Critical_spouse"
                              )
                            }
                            id="Critical_spouse"
                            className="family_checkbox"
                          />
                        </div>
                        <div>
                          <img className="health-svg" src={spouse} />
                        </div>
                      </div>
                      <div className="checkbox_input">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: 10,
                            paddingTop: 10
                          }}
                        >
                          <h5 className="checkbox_title">Parents</h5>
                          <input
                            onClick={() =>
                              this.checkValues(
                                "Critical_parents",
                                "Critical_parents"
                              )
                            }
                            id="Critical_parents"
                            type="checkbox"
                            class="family_checkbox"
                          />
                        </div>
                        <div>
                          <img className="health-svg" src={parents} />
                        </div>
                      </div>

                      <div className="checkbox_input">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            paddingRight: 10,
                            paddingTop: 10
                          }}
                        >
                          <h5 className="checkbox_title">Kids</h5>
                          <input
                            type="checkbox"
                            onClick={() =>
                              this.checkValues("Critical_kids", "Critical_kids")
                            }
                            id="Critical_kids"
                            className="family_checkbox"
                          />
                        </div>
                        <div>
                          <img className="health-svg" src={kids} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {context.state.citical_illness.suminsured >= 100000 ? (
                  context.state.policyTaken.Motor === true ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        class="btn"
                        onClick={() =>
                          context.updateCritical_illness(this.state, "Motor")
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.Bike === true ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        class="btn"
                        onClick={() =>
                          context.updateCritical_illness(this.state, "Bike")
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.Home_Rental === true ? (
                    //Redirect to Home
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        class="btn"
                        onClick={() =>
                          context.updateCritical_illness(
                            this.state,
                            "Home_Rental"
                          )
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.Cyber === true ? (
                    //Redirect to Cyber
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        class="btn"
                        onClick={() =>
                          context.updateCritical_illness(this.state, "Cyber")
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : (
                    //Redirect to Recommendations
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        class="btn"
                        onClick={() =>
                          context.updateCritical_illness(
                            this.state,
                            "Recommendations"
                          )
                        }
                      >
                        Next
                      </button>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "grey"
                    }}
                  >
                    <button class="btn" style={{ backgroundColor: "grey" }}>
                      Next
                    </button>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </AppContext.Consumer>
      </div>
    );
  }
}

Critical_illness.contextType = AppContext;

export default Critical_illness;
