let scoring_logics = {
  default: {
    mediclaim: 11,
    super_top_up: 4,
    home_structure: 1,
    home_content: 1,
    rented_home_content: 1,
    cyber: 3,
  },
  default_score: 0,
  // Not in Use
  default_score_calculation: function () {
    let score = 0;
    for (const key in this.default) {
      if (this.default[key]) {
        score += this.default[key];
      }
    }
    this.default_score = score;
    return score;
  },
  // 
  has_insured: {
    mediclaim: 24.5,
    super_top_up: 8.17,
    home_structure: 1.75,
    home_content: 1.75,
    rented_home_content: 3.5,
    cyber: 7,
  },
  less_than_recommended: {
    mediclaim: 38.5,
    super_top_up: 12.83,
    home_structure: 2.75,
    home_content: 2.75,
    rented_home_content: 3.5,
    cyber: 7,
  },
  greate_than_recommended: {
    mediclaim: 52.5,
    super_top_up: 17.5,
    home_structure: 3.75,
    home_content: 3.75,
    rented_home_content: 7.5,
    cyber: 15,
  },
};

export { scoring_logics };
