import star from "../svg/star.svg";
import React, { useState } from "react";

function Feedback() {
  const [feedback, setFeedback] = useState(false);
  setTimeout(() => {
    setFeedback(true);
  }, 5000);
  if (feedback) {
    return (
      // <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ display: "none" }}>
        <div class="geeks_active">
          <p style={{ padding: 10, margin: "unset", textAlign: "center" }}>
            Before you go,
            <br /> We request you to share
            <br /> your feedback about this insurance advisory.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <span
              onClick={() =>
                document
                  .getElementsByClassName("geeks_active")[0]
                  .classList.toggle("geeks")
              }
            >
              Skip
            </span>
            <a
              style={{ color: "red" }}
              href="http://surveymonkey.com/r/SY37BS8"
              target="_blank"
              onClick={() =>
                document
                  .getElementsByClassName("geeks_active")[0]
                  .classList.toggle("geeks")
              }
            >
              Click to take the survey
            </a>
          </div>
        </div>
        ;
      </div>
    );
  } else {
    return <></>;
  }
}

export { Feedback };
