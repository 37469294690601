import React, { Component } from "react";
import AppContext from "../context/AppContext";
import { withRouter } from "react-router-dom";
import car from "../svg/car.svg";
import scooter from "../svg/scooter.svg";
import own_home from "../svg/own_home.svg";
import rented_home from "../svg/rented_home.svg";
import Familydetails from "./Familydetails";
import cross from "../svg/cross.svg";
import { Drawer } from "@mui/material";
import HDFC_Bank_Logo from "../svg/HDFC_Bank_Logo.svg"
import HDFC_Ergo_Logo from "../svg/HDFC_Ergo_Logo.svg"


class Assets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      car: false,
      carinput: "",
      cars: [],
      bikeinput: "",
      bikes: [],
      rentedhome: 0,
      homenumbers: 0,
      valueofhomeContent: "",
      bike_scooter: false,
      own_home: false,
      rented_home: false,
      first: false,
      carpanel: false,
      bikepanel: false,
      ownhomepanel: false,
      rentedhomepanel: false,
      addstatus: false,
      invalidStatus: false,
      valueofhome: "",
      pricevalueofhomeContent: "",
      valueofhomeContent_rent: "",
      bottom_drawer: false
    };
  }
  goBack() {
    this.props.history.push("/income");
  }

  componentDidMount() {
    this.setState({
      car: this.context.state.assets.car,
      carinput: "",
      cars: this.context.state.assets.cars,
      bikeinput: "",
      bikes: this.context.state.assets.bikes,
      rentedhome: this.context.state.assets.rentedhome,
      homenumbers: this.context.state.assets.homenumbers,
      bike_scooter: this.context.state.assets.bike_scooter,
      own_home: this.context.state.assets.own_home,
      rented_home: this.context.state.assets.rented_home,
      first: false,
      carpanel: this.context.state.assets.context,
      bikepanel: this.context.state.assets.bikepanel,
      ownhomepanel: this.context.state.assets.ownhomepanel,
      rentedhomepanel: this.context.state.assets.rentedhomepanel,
      addstatus: false,
      invalidStatus: false,
      valueofhome: this.context.state.assets.valueofhome,
      valueofhomeContent: this.context.state.assets.valueofhomeContent,
      valueofhomeContent_rent: this.context.state.assets.valueofhomeContent_rent
    });
  }

  componentWillUnmount() {
    this.context.updateLastPage("/assets");
  }

  checkValues(id, context) {
    if (document.getElementById(id).checked) {
      if (id === "car") {
        this.setState(
          {
            car: true,
            carpanel: true,
            bikepanel: false,
            rentedhomepanel: false,
            ownhomepanel: false
          },
          () => {
            this.updateAssets(context);
          }
        );
      } else if (id === "bike_scooter") {
        this.setState(
          {
            bike_scooter: true,
            carpanel: false,
            bikepanel: true,
            rentedhomepanel: false,
            ownhomepanel: false
          },
          () => {
            this.updateAssets(context);
          }
        );
      } else if (id === "own_home") {
        this.setState(
          {
            own_home: true,
            rentedhomepanel: false,
            carpanel: false,
            bikepanel: false,
            ownhomepanel: true
          },
          () => {
            this.updateAssets(context);
          }
        );
      } else if (id === "rented_home") {
        this.setState(
          {
            rented_home: true,
            rentedhomepanel: true,
            carpanel: false,
            bikepanel: false,
            ownhomepanel: false
          },
          () => {
            this.updateAssets(context);
          }
        );
      }
    } else {
      if (id === "car") {
        this.setState({ car: false, carpanel: false }, () => {
          this.updateAssets(context);
        });
      } else if (id === "bike_scooter") {
        this.setState({ bike_scooter: false, bikepanel: false }, () => {
          this.updateAssets(context);
        });
      } else if (id === "own_home") {
        this.setState({ own_home: false, ownhomepanel: false }, () => {
          this.updateAssets(context);
        });
      } else if (id === "rented_home") {
        this.setState({ rented_home: false, rentedhomepanel: false }, () => {
          this.updateAssets(context);
        });
      }
    }
  }

  increaseQuantity = (status, type, context) => {
    if (type === "car") {
      if (status === "add") {
        this.setState({ carnumbers: this.state.carnumbers + 1 }, () => {
          this.updateAssets(context);
        });
      } else {
        if (this.state.carnumbers !== 0) {
          this.setState({ carnumbers: this.state.carnumbers - 1 }, () => {
            this.updateAssets(context);
          });
        }
      }
    }
    if (type === "bike") {
      if (status === "add") {
        this.setState({ bikenumbers: this.state.bikenumbers + 1 }, () => {
          this.updateAssets(context);
        });
      } else {
        if (this.state.bikenumbers !== 0) {
          this.setState({ bikenumbers: this.state.bikenumbers - 1 }, () => {
            this.updateAssets(context);
          });
        }
      }
    }
    if (type === "home") {
      if (status === "add") {
        this.setState({ homenumbers: this.state.homenumbers + 1 }, () => {
          this.updateAssets(context);
        });
      } else {
        if (this.state.homenumbers !== 0) {
          this.setState({ homenumbers: this.state.homenumbers - 1 }, () => {
            this.updateAssets(context);
          });
        }
      }
    }
    if (type === "rentedhome") {
      if (status === "add") {
        this.setState({ rentedhome: this.state.rentedhome + 1 }, () => {
          this.updateAssets(context);
        });
      } else {
        if (this.state.rentedhome !== 0) {
          this.setState({ rentedhome: this.state.rentedhome - 1 }, () => {
            this.updateAssets(context);
          });
        }
      }
    }
  };

  nextClicked(context) {

    
    console.log("Assets Context", this.state)
    this.props.history.push("/insurance-details");
    context.updateGlobalState("assets", this.state);
    window.gtag("event", "PERSONAL_ASSETS_DETAILS", {
      event_category: "DETAILS",
      event_label: "PERSONAL ASSETS UPDATED"
    });
  }

  updateAssets = context => {
    console.log("comedd");
    context.updateGlobalState("assets", this.state);
  };

  pushCar = data => {
    if (data !== "") {
      this.state.cars.push(data);
      this.setState({ addstatus: true });
      this.setState({ carinput: "" });
      setTimeout(() => {
        this.setState({ addstatus: false });
      }, 2000);
    } else {
      this.setState({ invalidStatus: true });
      this.setState({ carinput: "" });
      setTimeout(() => {
        this.setState({ invalidStatus: false });
      }, 1500);
    }
  };

  pushBike = data => {
    if (data !== "" && data.length === 13) {
      this.state.bikes.push(data);
      this.setState({ addstatus: true });
      this.setState({ bikeinput: "" });
      setTimeout(() => {
        this.setState({ addstatus: false });
      }, 2000);
    } else {
      this.setState({ invalidStatus: true });
      this.setState({ bikeinput: "" });
      setTimeout(() => {
        this.setState({ invalidStatus: false });
      }, 1500);
    }
  };

  RemoveCar = index => {
    this.state.cars.splice(index, 1);
    this.context.updateGlobalState("assets", this.state);
  };

  RemoveBike = index => {
    this.state.bikes.splice(index, 1);
    this.context.updateGlobalState("assets", this.state);
  };

  carNumberFormate = value => {
    let delete_key = false;
    let self = this;
    var el = document.getElementById("carInput");
    el.addEventListener("keydown", function (event) {
      const key = event.key;
      if (key === "Backspace" || key === "Delete") {
        document.getElementById("carInput").value = "";
        delete_key = true;
        self.setState({ carinput: "" });
      }
    });
    console.log(delete_key);

    if (delete_key === false) {
      console.log("in");
      if (
        this.state.carinput.length === 1 &&
        this.state.carinput.split("")[0] !== "-"
      ) {
        this.setState({ carinput: value.toUpperCase() + "-" });
      } else if (
        this.state.carinput.length === 4 &&
        this.state.carinput.split("")[3] !== "-"
      ) {
        this.setState({ carinput: value.toUpperCase() + "-" });
      } else if (
        this.state.carinput.length === 7 &&
        this.state.carinput.split("")[6] !== "-"
      ) {
        this.setState({ carinput: value.toUpperCase() + "-" });
      } else {
        this.setState({ carinput: value.toUpperCase() });
      }
    }
  };

  bikeNumberFormate = value => {
    let delete_key = false;
    let self = this;
    var el = document.getElementById("bikeInput");
    el.addEventListener("keydown", function (event) {
      const key = event.key;
      if (key === "Backspace" || key === "Delete") {
        document.getElementById("bikeInput").value = "";
        delete_key = true;
        self.setState({ bikeinput: "" });
      }
    });

    console.log(delete_key);

    if (delete_key === false) {
      if (delete_key === false) {
        if (
          this.state.bikeinput.length === 1 &&
          this.state.bikeinput.split("")[0] !== "-"
        ) {
          this.setState({ bikeinput: value.toUpperCase() + "-" });
        } else if (
          this.state.bikeinput.length === 4 &&
          this.state.bikeinput.split("")[3] !== "-"
        ) {
          this.setState({ bikeinput: value.toUpperCase() + "-" });
        } else if (this.state.bikeinput.length === 7) {
          this.setState({ bikeinput: value.toUpperCase() + "-" });
        } else {
          this.setState({ bikeinput: value.toUpperCase() });
        }
      }
    }
  };

  setValueofHome = e => {
    let self = this;
    let delete_key = false;
    var el = document.getElementById("bikeInput");
    el.addEventListener("keydown", function (event) {
      const key = event.key;
      if (key === "Backspace" || key === "Delete") {
        delete_key = true;
        document.getElementById("bikeInput").value = "";
        self.setState({ valueofhome: "" });
      }
    });

    console.log(delete_key);

    if (delete_key === false) {
      let dob = e.target.value.toString().split("");
      let formated_price;
      if (e.target.value.length === 12) {
        formated_price =
          dob[0] +
          dob[2] +
          "," +
          dob[3] +
          dob[5] +
          "," +
          dob[6] +
          dob[8] +
          "," +
          dob[9] +
          dob[10] +
          dob[11];
        this.setState({ valueofhome: formated_price });
      } else if (e.target.value.length === 11) {
        formated_price =
          dob[0] +
          dob[2] +
          "," +
          dob[3] +
          dob[4] +
          "," +
          dob[6] +
          dob[7] +
          "," +
          dob[9] +
          dob[10];
        this.setState({ valueofhome: formated_price });
      } else if (e.target.value.length === 10) {
        formated_price =
          dob[0] +
          "," +
          dob[1] +
          dob[3] +
          "," +
          dob[4] +
          dob[6] +
          "," +
          dob[7] +
          dob[8] +
          dob[9];
        this.setState({ valueofhome: formated_price });
      }
      if (e.target.value.length === 9) {
        formated_price =
          dob[0] +
          dob[2] +
          "," +
          dob[3] +
          dob[5] +
          "," +
          dob[6] +
          dob[7] +
          dob[8];
        this.setState({ valueofhome: formated_price });
      } else if (e.target.value.length === 8) {
        formated_price =
          dob[0] + dob[2] + "," + dob[3] + dob[5] + "," + dob[6] + dob[7];
        this.setState({ valueofhome: formated_price });
      } else if (e.target.value.length === 7) {
        formated_price =
          dob[0] + "," + dob[1] + dob[3] + "," + dob[4] + dob[5] + dob[6];
        this.setState({ valueofhome: formated_price });
      } else if (e.target.value.length === 6) {
        formated_price = dob[0] + dob[1] + "," + dob[3] + dob[4] + dob[5];
        this.setState({ valueofhome: formated_price });
      } else if (e.target.value.length === 5) {
        formated_price = dob[0] + dob[1] + "," + dob[3] + dob[4];
        this.setState({ valueofhome: formated_price });
      } else if (e.target.value.length === 4) {
        formated_price = dob[0] + dob[1] + "," + dob[3];
        this.setState({ valueofhome: formated_price });
      } else if (e.target.value.length === 3) {
        formated_price = dob[0] + dob[1] + "," + dob[3];
        this.setState({ valueofhome: formated_price });
      } else if (e.target.value.length === 2) {
        formated_price = dob[0] + dob[1] + ",";
        this.setState({ valueofhome: formated_price });
      } else if (e.target.value.length === 1) {
        formated_price = dob[0];
        this.setState({ valueofhome: formated_price });
      }
    }
  };

  setValueofHome_content = async valueformate => {
    let dob = valueformate.toString().split("");
    let formated_price;
    if (valueformate.toString().length === 9) {
      formated_price =
        dob[0] +
        dob[2] +
        "," +
        dob[3] +
        dob[5] +
        "," +
        dob[6] +
        dob[7] +
        dob[8];
    } else if (valueformate.toString().length === 8) {
      formated_price =
        dob[0] + dob[2] + "," + dob[3] + dob[5] + "," + dob[6] + dob[7];
    } else if (valueformate.toString().length === 7) {
      formated_price =
        dob[0] + "," + dob[1] + dob[3] + "," + dob[4] + dob[5] + dob[6];
    } else if (valueformate.toString().length === 6) {
      formated_price = dob[0] + dob[1] + "," + dob[3] + dob[4] + dob[5];
    } else if (valueformate.toString().length === 5) {
      formated_price = dob[0] + dob[1] + "," + dob[3] + dob[4];
    } else if (valueformate.toString().length === 4) {
      formated_price = dob[0] + dob[1] + "," + dob[3];
    } else if (valueformate.toString().length === 3) {
      formated_price = dob[0] + dob[1] + "," + dob[3];
    } else if (valueformate.toString().length === 2) {
      formated_price = dob[0] + dob[1] + ",";
    } else if (valueformate.toString().length === 1) {
      formated_price = dob[0];
    }

    return formated_price;
  };
  // this.state.pricevalueofhomeContent.toString().length < 8 ? this.setState({ pricevalueofhomeContent:e.target.value}): ''

  inWords = num => {
    var a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen "
    ];
    var b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety"
    ];

    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        "only "
        : "";
    return str;
  };

  render() {
    let RegisterCars;
    let RegisterBikes;
    if (this.state.cars.length !== 0) {
      RegisterCars = this.state.cars.map((items, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: 10,
              paddingBottom: 5
            }}
          >
            <p class="multiple_input">{items}</p>
            <img onClick={() => this.RemoveCar(index)} src={cross} />
          </div>
        );
      });
    }

    if (this.state.bikes.length !== 0) {
      RegisterBikes = this.state.bikes.map((items, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: 10,
              paddingBottom: 5
            }}
          >
            <p class="multiple_input">{items}</p>
            <img onClick={() => this.RemoveBike(index)} src={cross} />
          </div>
        );
      });
    }

    let ownstructure = this.state.valueofhome;
    if (ownstructure) {
      if (ownstructure.toString().length !== 0) {
        if (
          ownstructure.toString().length > 4 &&
          ownstructure.toString().length < 7
        ) {
          ownstructure = this.inWords(this.state.valueofhome).toUpperCase();
        } else {
          ownstructure = this.inWords(this.state.valueofhome).toUpperCase();
        }
      } else {
        ownstructure = 0;
      }
    }

    let valueofhomeContent = this.state.pricevalueofhomeContent;
    if (valueofhomeContent) {
      if (valueofhomeContent.toString().length !== 0) {
        if (
          valueofhomeContent.toString().length > 4 &&
          valueofhomeContent.toString().length < 7
        ) {
          valueofhomeContent = this.inWords(
            this.state.pricevalueofhomeContent
          ).toUpperCase();
        } else {
          valueofhomeContent = this.inWords(
            this.state.pricevalueofhomeContent
          ).toUpperCase();
        }
      } else {
        valueofhomeContent = 0;
      }
    }

    return (
      <AppContext.Consumer>
        {context => (
          <React.Fragment>
            <div className="header-area">
              <div className="">
                <img
                  onClick={() => this.goBack()}
                  className="back-icon"
                  src={process.env.PUBLIC_URL + "/back.png"}
                />
              </div>
              <div className="header-text">
                Assets (Step {context.state.AppSteps[3]}/
                {context.state.AppSteps.length})
              </div>
              <div class="progress-container">
                <div
                  class="progress-bar"
                  id="myBar"
                  style={{ width: "80%" }}
                ></div>
              </div>
            </div>

            <div id="slide" className="Familydetails_maincomponent_family">
              {this.state.addstatus === true ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#29d400",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "50%"
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontWeight: 500,
                        padding: 7,
                        fontSize: 16
                      }}
                    >
                      Successfully Added
                    </span>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {this.state.invalidStatus === true ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "red",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontWeight: 500,
                        padding: 7,
                        fontSize: 16
                      }}
                    >
                      Invalid Vehicle Number
                    </span>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div className="familydetails_content">Assets You Own</div>
              <div className="page-content">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "48%" }}>
                    {this.state.cars.length === 0 ? (
                      <div className="checkbox_input_family">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: 10,
                            paddingTop: 10
                          }}
                        >
                          <div className="checkbox_title">Car</div>
                          {context.state.assets.car === false ||
                            this.state.car === false ? (
                            <input
                              onClick={() => this.checkValues("car", context)}
                              id="car"
                              type="checkbox"
                              checked={false}
                              className="assets_checkbox"
                            />
                          ) : (
                            <input
                              onClick={() => this.checkValues("car", context)}
                              id="car"
                              type="checkbox"
                              checked={true}
                              className="assets_checkbox"
                            />
                          )}
                        </div>
                        {this.state.car ? (
                          <div
                            onClick={() =>
                              this.setState(
                                {
                                  car: false,
                                  carpanel: false,
                                  bikepanel: false,
                                  rentedhomepanel: false,
                                  ownhomepanel: false
                                },
                                () => {
                                  this.updateAssets(context);
                                }
                              )
                            }
                            className="assets-svg"
                          >
                            <img src={car} />
                          </div>
                        ) : (
                          <div
                            onClick={() =>
                              this.setState(
                                {
                                  car: true,
                                  carpanel: true,
                                  bikepanel: false,
                                  rentedhomepanel: false,
                                  ownhomepanel: false,
                                  rented_home: false,
                                  own_home: false,
                                  bike_scooter: false
                                },
                                () => {
                                  this.updateAssets(context);
                                }
                              )
                            }
                            className="assets-svg"
                          >
                            <img src={car} />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="checkbox_input_family_display">
                        <div>
                          <h5 class="input_display">Cars</h5>
                          <p
                            style={{
                              fontWeight: "bold",
                              padding: "unset",
                              margin: "unset",
                              fontSize: "4vw",
                              paddingLeft: 10
                            }}
                          >
                            {RegisterCars}
                          </p>
                          <h3
                            onClick={() =>
                              this.setState(
                                {
                                  car: true,
                                  carpanel: true,
                                  bikepanel: false,
                                  rentedhomepanel: false,
                                  ownhomepanel: false
                                },
                                () => {
                                  this.updateAssets(context);
                                }
                              )
                            }
                            class="input_edit"
                          >
                            Add
                          </h3>
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ width: "48%" }}>
                    {this.state.bikes.length === 0 ? (
                      <div className="checkbox_input_family">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: 10,
                            paddingTop: 10
                          }}
                        >
                          <div className="checkbox_title">Bike/Scooty</div>
                          {context.state.assets.bike_scooter === false ||
                            this.state.bike_scooter === false ? (
                            <input
                              onClick={() =>
                                this.checkValues("bike_scooter", context)
                              }
                              id="bike_scooter"
                              checked={false}
                              type="checkbox"
                              className="assets_checkbox"
                            />
                          ) : (
                            <input
                              onClick={() =>
                                this.checkValues("bike_scooter", context)
                              }
                              checked={true}
                              id="bike_scooter"
                              type="checkbox"
                              className="assets_checkbox"
                            />
                          )}
                        </div>
                        {this.state.bike_scooter ? (
                          <div
                            onClick={() =>
                              this.setState(
                                {
                                  bike_scooter: false,
                                  carpanel: false,
                                  bikepanel: false,
                                  rentedhomepanel: false,
                                  ownhomepanel: false
                                },
                                () => {
                                  this.updateAssets(context);
                                }
                              )
                            }
                            className="assets-svg"
                          >
                            <img src={scooter} />
                          </div>
                        ) : (
                          <div
                            onClick={() =>
                              this.setState(
                                {
                                  bike_scooter: true,
                                  carpanel: false,
                                  bikepanel: true,
                                  rentedhomepanel: false,
                                  ownhomepanel: false,
                                  rented_home: false,
                                  own_home: false,
                                  car: false
                                },
                                () => {
                                  this.updateAssets(context);
                                }
                              )
                            }
                            className="assets-svg"
                          >
                            <img src={scooter} />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="checkbox_input_family_display">
                        <div>
                          <h5 class="input_display">Bike/Scooty</h5>
                          <p
                            style={{
                              fontWeight: "bold",
                              padding: "unset",
                              margin: "unset",
                              fontSize: "4vw",
                              paddingLeft: 10
                            }}
                          >
                            {RegisterBikes}
                          </p>
                          <h3
                            onClick={() =>
                              this.setState(
                                {
                                  bike_scooter: true,
                                  carpanel: false,
                                  bikepanel: true,
                                  rentedhomepanel: false,
                                  ownhomepanel: false
                                },
                                () => {
                                  this.updateAssets(context);
                                }
                              )
                            }
                            class="input_edit"
                          >
                            Add
                          </h3>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ display: "flow-root" }}>
                  <Drawer
                    anchor="bottom"
                    style={{
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 20
                    }}
                    open={this.state.carpanel}
                    onClose={() => {
                      this.setState({ carpanel: false, car: false });
                    }}
                  >
                    {this.state.carpanel === true ? (
                      <div class="assets_input">
                        <div className="asset_input_subcontainer">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "10px"
                            }}
                          >
                            <div className="checkbox_title">
                              Car Registration Number
                            </div>
                          </div>

                          <div style={{}}>
                            <div class="motor_number">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 11
                                }}
                              >
                                <ion-icon
                                  style={{
                                    fontSize: 30,
                                    color: "#e41e26",
                                    marginRight: 10
                                  }}
                                  name="car-sport-outline"
                                ></ion-icon>
                                <input
                                  id="carInput"
                                  class="motor_input2"
                                  type="text"
                                  maxLength="13"
                                  placeholder="MH-03-JE-4095"
                                  value={this.state.carinput}
                                  onChange={e =>
                                    this.carNumberFormate(e.target.value)
                                  }
                                />
                              </div>
                              <br />
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    marginBottom: 10,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    background: "lightgrey",
                                    height: 50,
                                    borderRadius: 6
                                  }}
                                  onClick={() =>
                                    this.pushCar(this.state.carinput)
                                  }
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 16
                                    }}
                                    class="plus"
                                  >
                                    + Add Car
                                  </span>
                                </div>
                                {this.state.carinput === "" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      background: "lightgrey",
                                      height: 50,
                                      borderRadius: 6
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: 16
                                      }}
                                    >
                                      Done
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      background: "#e41e26",
                                      height: 50,
                                      borderRadius: 6
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: 16
                                      }}
                                      onClick={() =>
                                        this.setState(
                                          { carpanel: false, car: false },
                                          () => {
                                            this.pushCar(this.state.carinput);
                                            context.updateGlobalState(
                                              "assets",
                                              this.state
                                            );
                                          }
                                        )
                                      }
                                      class="plus"
                                    >
                                      Done
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Drawer>
                  <Drawer
                    anchor="bottom"
                    style={{
                      borderTopLeftRadius: 120,
                      borderTopRightRadius: 20
                    }}
                    open={this.state.bikepanel}
                    onClose={() => {
                      this.setState({ bike_scooter: false, bikepanel: false });
                    }}
                  >
                    {this.state.bikepanel === true ? (
                      <div class="assets_input_right">
                        <div className="asset_input_subcontainer">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "10px"
                            }}
                          >
                            <div className="checkbox_title">
                              Bike Registration Number
                            </div>
                          </div>
                          <div>
                            <div class="motor_number">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 11
                                }}
                              >
                                <ion-icon
                                  style={{
                                    fontSize: 30,
                                    color: "#e41e26",
                                    marginRight: 10
                                  }}
                                  name="bicycle-outline"
                                ></ion-icon>
                                <input
                                  class="motor_input2"
                                  id="bikeInput"
                                  maxLength="13"
                                  placeholder="MH-03-JE-4095"
                                  type="text"
                                  value={this.state.bikeinput}
                                  onChange={e =>
                                    this.bikeNumberFormate(e.target.value)
                                  }
                                />
                              </div>
                              <br />
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: 10,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  background: "lightgrey",
                                  height: 50,
                                  borderRadius: 6
                                }}
                                onClick={() =>
                                  this.pushBike(this.state.bikeinput)
                                }
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: 16
                                  }}
                                  class="plus"
                                >
                                  +Add Bike
                                </span>
                              </div>

                              {this.state.bikeinput === "" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    background: "lightgrey",
                                    height: 50,
                                    borderRadius: 6
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 16
                                    }}
                                  >
                                    Done
                                  </span>
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    this.setState(
                                      { bikepanel: false, bike_scooter: false },
                                      () => {
                                        this.pushBike(this.state.bikeinput);
                                        context.updateGlobalState(
                                          "assets",
                                          this.state
                                        );
                                      }
                                    )
                                  }
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    background: "#e41e26",
                                    height: 50,
                                    borderRadius: 6
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "white",
                                      fontWeight: "bold",
                                      fontSize: 16
                                    }}
                                    class="plus"
                                  >
                                    Done
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Drawer>
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "48%" }}>
                    {this.state.valueofhome === "" ||
                      this.state.valueofhomeContent === "" ? (
                      <div className="checkbox_input_family">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: 10,
                            paddingTop: 10
                          }}
                        >
                          <div className="checkbox_title">Own Home</div>
                          {context.state.assets.own_home === false ||
                            this.state.own_home === false ? (
                            <input
                              onClick={() =>
                                this.checkValues("own_home", context)
                              }
                              id="own_home"
                              type="checkbox"
                              checked={false}
                              className="assets_checkbox"
                            />
                          ) : (
                            <input
                              onClick={() =>
                                this.checkValues("own_home", context)
                              }
                              id="own_home"
                              type="checkbox"
                              checked={true}
                              className="assets_checkbox"
                            />
                          )}
                        </div>
                        {this.state.own_home ? (
                          <div
                            onClick={() =>
                              this.setState(
                                {
                                  own_home: false,
                                  rentedhomepanel: false,
                                  carpanel: false,
                                  bikepanel: false,
                                  ownhomepanel: false
                                },
                                () => {
                                  this.updateAssets(context);
                                }
                              )
                            }
                            className="assets-svg"
                          >
                            <img src={own_home} />
                          </div>
                        ) : (
                          <div
                            onClick={() =>
                              this.setState(
                                {
                                  own_home: true,
                                  rentedhomepanel: false,
                                  carpanel: false,
                                  bikepanel: false,
                                  ownhomepanel: true,
                                  rented_home: false,
                                  car: false,
                                  bike_scooter: false
                                },
                                () => {
                                  this.updateAssets(context);
                                }
                              )
                            }
                            className="assets-svg"
                          >
                            <img src={own_home} />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="checkbox_input_family_display">
                        <div>
                          <h5 class="input_display">Own Home</h5>
                          {this.state.valueofhome ? (
                            <span class="home_input_opt">Home Price</span>
                          ) : (
                            <span></span>
                          )}
                          <p class="home_value_input">
                            {this.state.valueofhome ? (
                              <label style={{ marginRight: 10 }}>&#8377;</label>
                            ) : (
                              ""
                            )}{" "}
                            {this.state.valueofhome
                              ? this.state.valueofhome
                              : ""}
                          </p>
                          <br />
                          {this.state.valueofhomeContent ? (
                            <span class="home_input_opt">Content Price</span>
                          ) : (
                            <span></span>
                          )}
                          <p class="home_value_input">
                            <label style={{ marginRight: 10 }}>&#8377;</label>{" "}
                            {this.state.valueofhomeContent
                              ? this.state.valueofhomeContent
                              : ""}
                          </p>

                          <h3
                            onClick={() =>
                              this.setState(
                                {
                                  valueofhomeContent: "",
                                  valueofhome: "",
                                  pricevalueofhomeContent: "",
                                  ownhomepanel: true,
                                  rented_home: false,
                                  rentedhomepanel: false,
                                  carpanel: false,
                                  car: false,
                                  bike_scooter: false,
                                  bikepanel: false
                                },
                                () => {
                                  this.updateAssets(context);
                                }
                              )
                            }
                            class="input_edit"
                          >
                            Edit
                          </h3>
                        </div>
                      </div>
                    )}
                  </div>

                  <div style={{ width: "48%" }}>
                    {this.state.valueofhomeContent_rent === "" ? (
                      <div className="checkbox_input_family">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: 10,
                            paddingTop: 10
                          }}
                        >
                          <div className="checkbox_title">Rented Home</div>
                          {context.state.assets.rented_home === false ||
                            this.state.rented_home === false ? (
                            <input
                              onClick={() =>
                                this.checkValues("rented_home", context)
                              }
                              id="rented_home"
                              checked={false}
                              type="checkbox"
                              className="assets_checkbox"
                            />
                          ) : (
                            <input
                              onClick={() =>
                                this.checkValues("rented_home", context)
                              }
                              id="rented_home"
                              checked={true}
                              type="checkbox"
                              className="assets_checkbox"
                            />
                          )}
                        </div>
                        {this.state.rented_home ? (
                          <div
                            onClick={() =>
                              this.setState(
                                {
                                  rented_home: false,
                                  rentedhomepanel: false,
                                  carpanel: false,
                                  bikepanel: false,
                                  ownhomepanel: false,
                                  valueofhomeContent_rent: ""
                                },
                                () => {
                                  this.updateAssets(context);
                                }
                              )
                            }
                            className="assets-svg"
                          >
                            <img src={rented_home} />
                          </div>
                        ) : (
                          <div
                            onClick={() =>
                              this.setState(
                                {
                                  rented_home: true,
                                  rentedhomepanel: true,
                                  carpanel: false,
                                  bikepanel: false,
                                  ownhomepanel: false,
                                  car: false,
                                  own_home: false,
                                  bike_scooter: false
                                },
                                () => {
                                  this.updateAssets(context);
                                }
                              )
                            }
                            className="assets-svg"
                          >
                            <img src={rented_home} />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="checkbox_input_family_display">
                        <div>
                          <h5 class="input_display">Rented Home</h5>
                          {this.state.valueofhomeContent_rent ? (
                            <span class="home_input_opt">Content Price</span>
                          ) : (
                            <span></span>
                          )}

                          <p class="home_value_input">
                            {this.state.valueofhomeContent_rent ? (
                              <label style={{ marginRight: 10 }}>&#8377;</label>
                            ) : (
                              ""
                            )}{" "}
                            {this.state.valueofhomeContent_rent
                              ? this.state.valueofhomeContent_rent
                              : ""}
                          </p>
                          <h3
                            onClick={() =>
                              this.setState(
                                {
                                  valueofhomeContent_rent: "",
                                  pricevalueofhomeContent: "",
                                  rentedhomepanel: true,
                                  carpanel: false,
                                  car: false,
                                  bike_scooter: false,
                                  bikepanel: false,
                                  ownhomepanel: false,
                                  own_home: false
                                },
                                () => {
                                  this.updateAssets(context);
                                }
                              )
                            }
                            class="input_edit"
                          >
                            Edit
                          </h3>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <Drawer
                  anchor="bottom"
                  open={this.state.ownhomepanel}
                  onClose={() => {
                    this.setState({ ownhomepanel: false, own_home: false });
                  }}
                >
                  <div style={{ display: "flow-root" }}>
                    {this.state.ownhomepanel === true ? (
                      <div class="assets_input">
                        <div className="asset_input_subcontainer">
                          <div class="motor_number">
                            <label class="home_input_opt">
                              Current Market Value of Your Home
                            </label>
                            <label
                              style={{
                                fontSize: 10,
                                color: "grey",
                                display: "flex",
                                paddingLeft: 11,
                                textAlign: "left"
                              }}
                            >
                              Market value means existing value of the property
                              for which owner can conclude a sale
                            </label>
                            <label
                              style={{
                                fontSize: 13,
                                color: "grey",
                                display: "flex",
                                paddingLeft: 11,
                                textAlign: "left"
                              }}
                            >
                              {this.state.valueofhome !== ""
                                ? ownstructure
                                : ""}
                            </label>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: 10
                              }}
                            >
                              <label style={{ fontSize: 40, margin: "unset" }}>
                                &#8377;
                              </label>
                              <input
                                class="motor_input"
                                id="bikeInput"
                                maxLength="13"
                                placeholder="min 10 lakh & max 10 crore"
                                type="number"
                                value={this.state.valueofhome}
                                onChange={e =>
                                  this.setState({ valueofhome: e.target.value })
                                }
                              />
                            </div>

                            {this.state.valueofhome !== "" ? (
                              this.state.valueofhome.replaceAll(",", "") >
                                100000000 ||
                                this.state.valueofhome.replaceAll(",", "") <
                                1000000 ? (
                                <div>
                                  <span
                                    style={{
                                      fontSize: 13,
                                      color: "red",
                                      display: "flex",
                                      padding: 10
                                    }}
                                  >
                                    Min 10 lakh & Max 10 crore
                                  </span>
                                  {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <span style={{ color: 'lightgrey' }} class="plus">Done</span>
                                                                </div> */}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                  }}
                                >
                                  {/* <span onClick={() => this.state.valueofhome !== "" ? this.setState({ ownhomepanel: false }) : this.setState({ valueofhomeContent: this.state.pricevalueofhomeContent }, () => {
                                                                    context.updateGlobalState('assets', this.state)
                                                                })} class="plus">Done</span> */}
                                </div>
                              )
                            ) : (
                              <div></div>
                            )}

                            <br />
                            <label class="home_input_opt">
                              Current Market Value of Home Content
                            </label>
                            <label
                              style={{
                                fontSize: 10,
                                color: "grey",
                                display: "flex",
                                paddingLeft: 11,
                                textAlign: "left"
                              }}
                            >
                              Home Content means your belongings like furniture,
                              durables, clothes, utensils, jewellery, etc.
                            </label>
                            <label
                              style={{
                                fontSize: 13,
                                color: "grey",
                                display: "flex",
                                paddingLeft: 11,
                                textAlign: "left"
                              }}
                            >
                              {this.state.pricevalueofhomeContent !== ""
                                ? valueofhomeContent
                                : ""}
                            </label>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: 10
                              }}
                            >
                              <label style={{ fontSize: 40, margin: "unset" }}>
                                &#8377;
                              </label>
                              <input
                                class="motor_input"
                                id="bikeInput2"
                                maxLength="13"
                                onFocus={() =>
                                (document.getElementsByClassName(
                                  "motor_input"
                                )[0].borderBottom = "1px solid red")
                                }
                                placeholder="min 3 lakh & max 25 lakh"
                                type="number"
                                value={this.state.pricevalueofhomeContent}
                                onChange={e =>
                                  this.setState({
                                    pricevalueofhomeContent: e.target.value
                                  })
                                }
                              />
                            </div>

                            {this.state.pricevalueofhomeContent !== "" ? (
                              this.state.pricevalueofhomeContent.replaceAll(
                                ",",
                                ""
                              ) > 2500000 ||
                                this.state.pricevalueofhomeContent.replaceAll(
                                  ",",
                                  ""
                                ) < 300000 ? (
                                <div>
                                  <span
                                    style={{
                                      fontSize: 13,
                                      color: "red",
                                      display: "flex",
                                      padding: 10
                                    }}
                                  >
                                    Min 3 lakh & Max 25 lakh Required
                                  </span>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                  }}
                                ></div>
                              )
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center"
                                }}
                              ></div>
                            )}
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                              }}
                            >
                              {this.state.valueofhome !== "" &&
                                this.state.pricevalueofhomeContent !== "" ? (
                                this.state.valueofhome.replaceAll(",", "") <=
                                  100000000 &&
                                  this.state.valueofhome.replaceAll(",", "") >=
                                  1000000 &&
                                  this.state.pricevalueofhomeContent.replaceAll(
                                    ",",
                                    ""
                                  ) <= 2500000 &&
                                  this.state.pricevalueofhomeContent.replaceAll(
                                    ",",
                                    ""
                                  ) >= 300000 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      background: "#e41e26",
                                      padding: 2,
                                      borderRadius: 6
                                    }}
                                    onClick={() =>
                                      this.setState(
                                        {
                                          valueofhomeContent: this.state
                                            .pricevalueofhomeContent,
                                          ownhomepanel: false,
                                          pricevalueofhomeContent: ""
                                        },
                                        () => {
                                          context.updateGlobalState(
                                            "assets",
                                            this.state
                                          );
                                        }
                                      )
                                    }
                                  >
                                    <span
                                      style={{
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: 16
                                      }}
                                      class="plus"
                                    >
                                      Done
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      background: "lightgrey",
                                      padding: 2,
                                      borderRadius: 6
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: 16
                                      }}
                                      class="plus"
                                    >
                                      Done
                                    </span>
                                  </div>
                                )
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    background: "lightgrey",
                                    padding: 2,
                                    borderRadius: 6
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 16
                                    }}
                                    class="plus"
                                  >
                                    Done
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </Drawer>

                <Drawer
                  anchor="bottom"
                  style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}
                  open={this.state.rentedhomepanel}
                  onClose={() => {
                    this.setState({
                      rentedhomepanel: false,
                      rented_home: false
                    });
                  }}
                >
                  {this.state.rentedhomepanel === true ? (
                    <div class="assets_input">
                      <div className="asset_input_subcontainer">
                        <div class="motor_number">
                          <label class="home_input_opt">
                            Current Market Value of Home Content
                          </label>
                          <label
                            style={{
                              fontSize: 10,
                              color: "grey",
                              display: "flex",
                              paddingLeft: 11,
                              textAlign: "left"
                            }}
                          >
                            Home Content means your belongings like furniture,
                            durables, clothes, utensils, jewellery, etc.
                          </label>
                          <label
                            style={{
                              fontSize: 13,
                              color: "grey",
                              display: "flex",
                              paddingLeft: 11,
                              textAlign: "left"
                            }}
                          >
                            {this.state.pricevalueofhomeContent !== ""
                              ? valueofhomeContent
                              : ""}
                          </label>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 10
                            }}
                          >
                            <label style={{ fontSize: 40, margin: "unset" }}>
                              &#8377;
                            </label>
                            <input
                              class="motor_input"
                              id="bikeInput2"
                              placeholder="min 3 lakh & max 25 lakh"
                              type="number"
                              value={this.state.pricevalueofhomeContent}
                              onChange={e =>
                                this.setState({
                                  pricevalueofhomeContent: e.target.value
                                })
                              }
                            />
                          </div>
                          <br />
                          {this.state.pricevalueofhomeContent !== "" ? (
                            this.state.pricevalueofhomeContent.replaceAll(
                              ",",
                              ""
                            ) > 2500000 ||
                              this.state.pricevalueofhomeContent.replaceAll(
                                ",",
                                ""
                              ) < 300000 ? (
                              <div>
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "red",
                                    display: "flex",
                                    padding: 10
                                  }}
                                >
                                  Min 3 lakh & Max 25 lakh Required
                                </span>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    background: "lightgrey",
                                    padding: 2,
                                    borderRadius: 6
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 16
                                    }}
                                    class="plus"
                                  >
                                    Done
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  background: "#e41e26",
                                  padding: 2,
                                  borderRadius: 6
                                }}
                                onClick={() =>
                                  this.setState(
                                    {
                                      valueofhomeContent_rent: this.state
                                        .pricevalueofhomeContent,
                                      rentedhomepanel: false
                                    },
                                    () => {
                                      context.updateGlobalState(
                                        "assets",
                                        this.state
                                      );
                                    }
                                  )
                                }
                              >
                                <span
                                  style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: 16
                                  }}
                                  class="plus"
                                >
                                  Done
                                </span>
                              </div>
                            )
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                background: "lightgrey",
                                padding: 2,
                                borderRadius: 6
                              }}
                            >
                              <span
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: 16
                                }}
                                class="plus"
                              >
                                Done
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Drawer>
                <div style={{ paddingTop: "35%", textAlign: 'center', width: "100%" }}>
                    <div className="brand_icons_header">Powered by</div>
                    <div className="brand_icons">
                      <img src={HDFC_Bank_Logo} style={{marginRight:"8px" , height :"20px"}} alt="HDFC_BANK" />
                      <img src={HDFC_Ergo_Logo} style={{marginLeft:"8px"}}  alt="HDFC_ERGO" />
                    </div>
                  </div>

                {(this.state.own_home === true &&
                  this.state.valueofhomeContent === "") ||
                  (this.state.rented_home === true &&
                    this.state.valueofhomeContent_rent === "") ||
                  (this.state.car === true && this.state.cars.length === 0) ||
                  (this.state.bike_scooter === true &&
                    this.state.bikes.length === 0) ? (
                  <div class="bottom_btn_container">
                    <button
                      className="btn"
                      style={{ backgroundColor: "lightgrey" }}
                    >
                      Next
                    </button>
                  </div>
                ) : (
                  <div class="bottom_btn_container">
                    <button
                      className="btn"
                      onClick={() => this.nextClicked(this.context)}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </AppContext.Consumer>
    );
  }
}
Assets.contextType = AppContext;
export default Assets;
