import React,{Component} from 'react';


class Motor_maker extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }

    render() {
        return(
            <div>   
                <div className="header-area">
                    <div className=""><img onClick={() => this.goBack()} className="back-icon" className="back-icon" src={process.env.PUBLIC_URL + '/back.png'} /></div>
                    <div className="header-text">Car Details</div>
                    
                </div>

                <div >
                    <div style={{margin:20}}>
                        <h3>
                            Select Car Brand
                        </h3>

                        <div style={{padding:10,display:'flex',alignItems:'center'}} class='city_search'>
                            <input placeholder='Renault' style={{border:'none',outline:'none',backgroundColor:'transparent',width:'90%',fontSize:16,padding:5}} />
                            <ion-icon style={{fontSize:20}} name="search-outline"></ion-icon>
                        </div>
                    </div>
                </div>

            </div>


        )
    }


}

export default Motor_maker;