import React from 'react'
import {Route, Redirect} from "react-router-dom"

const ProtectedRoutes = ({ children, ...rest }) => {
    const isAuth = window.sessionStorage.getItem("sessionToken")
   
    // following condition work for 15min session timeout

    // if(isAuth){
    //     setTimeout(() => {
    //       sessionStorage.clear()
    //       history.push("/login")
    //     }, 900000);
    //   }
    return (
                <Route
                    {...rest}
                    render={({ location }) => {
                        return !isAuth ? (
                            <Redirect
                                to={{
                                    pathname: '/onboarding',
                                    state: { from: location },
                                }}
                            />
                        ) : isAuth? (
                            <Redirect
                                to={{
                                    pathname: '/personal_details',
                                    state: { from: location },
                                }}
                            />
                        ) : (
                            children
                        );
                    }}
                ></Route>
            );
        };
     

export default ProtectedRoutes