import React, { Component } from "react";
import AppContext from "../context/AppContext";
import { withRouter } from "react-router-dom";
import home_icon from "../svg/home_icon.svg";

class Home_insurance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Home_structure_cover: false,
      Home_content_cover: false,
      Home_rent_content_cover: false,
      Home_structure_content_cover: false,
      Not_have: false,
      structure_cover: 0,
      rent_content_cover: 0,
      content_cover: 0,
      rent_content_cover_panel: false,
      content_cover_panel: false,
      structure_cover_panel: false,
    };
  }

  goBack() {
    this.props.history.goBack();
  }

  checkValues = async (id, value, context) => {
    if (document.getElementById(id).checked) {
      if (value === "Home_structure") {
        this.setState(
          {
            Home_structure_cover: true,
            Not_have: false,
            structure_cover_panel: true,
          },
          () => {
            context.updateHome_insurance(this.state);
          }
        );
        // await context.updateCreditScore(7.5, "Add");
      } else if (value === "Home_content") {
        this.setState(
          {
            Home_content_cover: true,
            Not_have: false,
            content_cover_panel: true,
          },
          () => {
            context.updateHome_insurance(this.state);
          }
        );
        // await context.updateCreditScore(7.5, "Add");
      } else if (value === "Home_rent_content") {
        this.setState(
          {
            Home_rent_content_cover: true,
            Not_have: false,
            rent_content_cover_panel: true,
          },
          () => {
            context.updateHome_insurance(this.state);
          }
        );
      } else if (value === "Home_Structure_Content_Cover") {
        if (this.state.Home_content_cover === true) {
          // await context.updateCreditScore(7.5, "less");
        }
        if (this.state.Home_structure_cover === true) {
          // await context.updateCreditScore(7.5, "less");
        }
        this.setState(
          {
            Home_structure_content_cover: true,
            Home_rent_content_cover: false,
            content_cover_panel: false,
            rent_content_cover_panel: false,
            content_cover: 0,
            rent_content_cover: 0,
            Not_have: false,
          },
          () => {
            context.updateHome_insurance(this.state);
          }
        );
        // await context.updateCreditScore(
        //   "home_structure",
        //   "less_than_recommended",
        //   "Add"
        // );
        // await context.updateCreditScore(
        //   "home_content",
        //   "less_than_recommended",
        //   "Add"
        // );
      } else {
        // if (this.state.Home_structure_content_cover === true) {
        //   await context.updateCreditScore(15, "less");
        // }
        // if (this.state.Home_structure_cover === true) {
        //   await context.updateCreditScore(7.5, "less");
        // }
        // if (this.state.Home_content_cover === true) {
        //   await context.updateCreditScore(7.5, "less");
        // }
        this.setState(
          {
            Not_have: true,
            Home_structure_content_cover: false,
            Home_content_cover: false,
            Home_rent_content_cover: false,
            content_cover_panel: false,
            rent_content_cover_panel: false,
            content_cover: 0,
            rent_content_cover: 0,
            Home_structure_cover: false,
          },
          () => {
            context.updateHome_insurance(this.state);
          }
        );
      }
    } else {
      if (value === "Home_structure") {
        this.setState(
          {
            Home_structure_cover: false,
            structure_cover_panel: false,
            structure_cover: 0,
          },
          () => {
            context.updateHome_insurance(this.state);
          }
        );
        // await context.updateCreditScore(7.5, "less");
      } else if (value === "Home_content") {
        this.setState(
          {
            Home_content_cover: false,
            content_cover_panel: false,
            content_cover: 0,
          },
          () => {
            context.updateHome_insurance(this.state);
          }
        );
        // await context.updateCreditScore(7.5, "less");
      } else if (value === "Home_rent_content") {
        this.setState(
          {
            Home_rent_content_cover: false,
            rent_content_cover_panel: false,
            rent_content_cover: 0,
          },
          () => {
            context.updateHome_insurance(this.state);
          }
        );
      } else if (value === "Home_Structure_Content_Cover") {
        this.setState(
          {
            Home_structure_content_cover: false,
            Home_content_cover: false,
            Home_structure_cover: false,
          },
          () => {
            context.updateHome_insurance(this.state);
          }
        );
        // await context.updateCreditScore(
        //   "home_structure",
        //   "less_than_recommended",
        //   "less"
        // );
        // await context.updateCreditScore(
        //   "home_content",
        //   "less_than_recommended",
        //   "less"
        // );
      } else {
        this.setState({ Not_have: false }, () => {
          context.updateHome_insurance(this.state);
        });
      }
    }
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (this.context.state.score_board.home_structure === 2.75) {
      await this.context.updateCreditScore(
        "home_structure",
        "less_than_recommended",
        "less"
      );
    } else if (this.context.state.score_board.home_structure === 3.75) {
      await this.context.updateCreditScore(
        "home_structure",
        "greate_than_recommended",
        "less"
      );
    }
    if (this.context.state.score_board.home_content === 2.75) {
      await this.context.updateCreditScore(
        "home_content",
        "less_than_recommended",
        "less"
      );
    } else if (this.context.state.score_board.home_content === 3.75) {
      await this.context.updateCreditScore(
        "home_content",
        "greate_than_recommended",
        "less"
      );
    }

    if (this.context.state.score_board.rented_home_content === 3.5) {
      await this.context.updateCreditScore(
        "rented_home_content",
        "less_than_recommended",
        "less"
      );
    } else if (this.context.state.score_board.rented_home_content === 7.5) {
      await this.context.updateCreditScore(
        "rented_home_content",
        "greate_than_recommended",
        "less"
      );
    }

    const context = this.context;
    const filteredRecommendations = context.state.Recommendations.filter(
      (recommendation) => recommendation.subcategory != "Home_Insurance"
    );
    context.updateRecommendationsStateOnBack(filteredRecommendations);
  }

  inWords = (num) => {
    var a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    var b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
        : "";
    return str.toUpperCase();
  };

  calculateCreditScore = async (context) => {
    // check in context what added in assets
    if (this.state.Home_structure_content_cover === false) {
      if (context.state.assets.valueofhome !== "") {
        if (this.state.structure_cover !== 0) {
          if (
            this.state.structure_cover <
            parseInt(context.state.assets.valueofhome)
          ) {
            await context.updateCreditScore(
              "home_structure",
              "less_than_recommended",
              "Add"
            );
          } else {
            await context.updateCreditScore(
              "home_structure",
              "greate_than_recommended",
              "Add"
            );
          }
        }
      }

      if (context.state.assets.valueofhomeContent !== "") {
        if (this.state.content_cover !== 0) {
          if (
            this.state.content_cover <
            parseInt(context.state.assets.valueofhomeContent)
          ) {
            await context.updateCreditScore(
              "home_content",
              "less_than_recommended",
              "Add"
            );
          } else {
            await context.updateCreditScore(
              "home_content",
              "greate_than_recommended",
              "Add"
            );
          }
        }
      }

      if (context.state.assets.valueofhomeContent_rent !== "") {
        if (this.state.rent_content_cover) {
          if (
            this.state.rent_content_cover <
            parseInt(context.state.assets.valueofhomeContent_rent)
          ) {
            await context.updateCreditScore(
              "rented_home_content",
              "less_than_recommended",
              "Add"
            );
          } else {
            await context.updateCreditScore(
              "rented_home_content",
              "greate_than_recommended",
              "Add"
            );
          }
        }
      }
    }
    return;
  };

  render() {
    let rent_content_cover_in_words;
    let content_cover_in_words;
    let structure_cover_in_words;
    if (this.state.rent_content_cover) {
      rent_content_cover_in_words = this.inWords(this.state.rent_content_cover);
    }
    if (this.state.structure_cover) {
      structure_cover_in_words = this.inWords(this.state.structure_cover);
    }
    if (this.state.content_cover) {
      content_cover_in_words = this.inWords(this.state.content_cover);
    }
    return (
      <div style={{ paddingBottom: 40 }}>
        <div className="header-area">
          <div className="">
            <img
              onClick={() => this.goBack()}
              className="back-icon"
              src={process.env.PUBLIC_URL + "/back.png"}
            />
          </div>
          <div className="header-text">Existing Insurance Details</div>
          <div class="progress-container">
            <div class="progress-bar" id="myBar" style={{ width: "25%" }}></div>
          </div>
        </div>
        <AppContext.Consumer>
          {(context) => (
            <React.Fragment>
              <div id="slide">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "25%",
                  }}
                >
                  <img className="" src={home_icon} />
                </div>

                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <span class="category_insurance_header">
                      Existing Home Insurance
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <div
                  style={{ marginLeft: 20, paddingTop: 20, marginRight: 18 }}
                >
                  {this.context.state.assets.valueofhome !== "" ? (
                    <div class="checkbox_input_home">
                      {this.state.Home_structure_cover ? (
                        <input
                          onClick={() =>
                            this.checkValues(
                              "Home_structure",
                              "Home_structure",
                              context
                            )
                          }
                          checked={true}
                          id="Home_structure"
                          type="checkbox"
                          class="family_checkbox"
                        />
                      ) : (
                        <input
                          onClick={() =>
                            this.checkValues(
                              "Home_structure",
                              "Home_structure",
                              context
                            )
                          }
                          id="Home_structure"
                          type="checkbox"
                          class="family_checkbox"
                        />
                      )}
                      <h5
                        onClick={async () => {
                          if (this.state.Home_structure_cover === false) {
                            // await context.updateCreditScore(7.5, "Add");
                          }
                          this.setState(
                            {
                              Home_structure_cover: true,
                              Not_have: false,
                              structure_cover_panel: true,
                            },
                            () => {
                              context.updateHome_insurance(this.state);
                            }
                          );
                        }}
                        class="checkbox_title"
                      >
                        Own Home Structure Cover{" "}
                        {this.state.structure_cover_panel === false &&
                        this.state.structure_cover !== 0 ? (
                          `(Rs ${this.state.structure_cover})`
                        ) : (
                          <span></span>
                        )}
                      </h5>
                    </div>
                  ) : (
                    <></>
                  )}
                  {this.state.structure_cover_panel ? (
                    <div class="home_cover_input">
                      <div class="motor_number">
                        <label class="home_input_opt">
                          Existing Structure Cover
                        </label>
                        <label
                          style={{
                            fontSize: 10,
                            color: "grey",
                            display: "flex",
                            paddingLeft: 11,
                            textAlign: "left",
                          }}
                        >
                          Please enter your existing cover for Home Content
                        </label>
                        <label
                          style={{
                            fontSize: 14,
                            color: "grey",
                            display: "flex",
                            paddingLeft: 11,
                            textAlign: "left",
                          }}
                        >
                          {structure_cover_in_words}
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 10,
                          }}
                        >
                          <label style={{ fontSize: 40, margin: "unset" }}>
                            &#8377;
                          </label>
                          <input
                            class="motor_input"
                            id="bikeInput2"
                            placeholder="Enter Amount"
                            type="number"
                            value={this.state.structure_cover}
                            onChange={(e) =>
                              this.setState({
                                structure_cover: e.target.value,
                              })
                            }
                          />
                        </div>
                        {this.state.structure_cover > 1000 ? (
                          <p
                            class="existing_content_cover"
                            onClick={() =>
                              this.setState(
                                { structure_cover_panel: false },
                                () => context.updateHome_insurance(this.state)
                              )
                            }
                          >
                            Done
                          </p>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {this.context.state.assets.valueofhomeContent !== "" ? (
                    <div class="checkbox_input_home">
                      {this.state.Home_content_cover ? (
                        <input
                          onClick={() =>
                            this.checkValues(
                              "Home_content",
                              "Home_content",
                              context
                            )
                          }
                          checked={true}
                          id="Home_content"
                          type="checkbox"
                          class="family_checkbox"
                        />
                      ) : (
                        <input
                          onClick={() =>
                            this.checkValues(
                              "Home_content",
                              "Home_content",
                              context
                            )
                          }
                          id="Home_content"
                          type="checkbox"
                          class="family_checkbox"
                        />
                      )}
                      <h5
                        onClick={async () => {
                          if (this.state.Home_content_cover === false) {
                            // await context.updateCreditScore(7.5, "Add");
                          }
                          this.setState(
                            {
                              Home_content_cover: true,
                              Not_have: false,
                              content_cover_panel: true,
                            },
                            () => {
                              context.updateHome_insurance(this.state);
                            }
                          );
                        }}
                        class="checkbox_title"
                      >
                        Own Home Content Cover{" "}
                        {this.state.content_cover_panel === false &&
                        this.state.content_cover !== 0 ? (
                          `(Rs ${this.state.content_cover})`
                        ) : (
                          <span></span>
                        )}
                      </h5>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {this.state.content_cover_panel ? (
                    <div class="home_cover_input">
                      <div class="motor_number">
                        <label class="home_input_opt">
                          Existing Content Cover
                        </label>
                        <label
                          style={{
                            fontSize: 10,
                            color: "grey",
                            display: "flex",
                            paddingLeft: 11,
                            textAlign: "left",
                          }}
                        >
                          Please enter your existing cover for Home Content
                        </label>
                        <label
                          style={{
                            fontSize: 14,
                            color: "grey",
                            display: "flex",
                            paddingLeft: 11,
                            textAlign: "left",
                          }}
                        >
                          {content_cover_in_words}
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 10,
                          }}
                        >
                          <label style={{ fontSize: 40, margin: "unset" }}>
                            &#8377;
                          </label>
                          <input
                            class="motor_input"
                            id="bikeInput2"
                            placeholder="Enter Amount"
                            type="number"
                            value={this.state.content_cover}
                            onChange={(e) =>
                              this.setState({ content_cover: e.target.value })
                            }
                          />
                        </div>
                        {this.state.content_cover > 1000 ? (
                          <p
                            class="existing_content_cover"
                            onClick={() =>
                              this.setState(
                                { content_cover_panel: false },
                                () => context.updateHome_insurance(this.state)
                              )
                            }
                          >
                            Done
                          </p>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {this.context.state.valueofhomeContent_rent !== "" ? (
                    <div class="checkbox_input_home">
                      {this.state.Home_rent_content_cover ? (
                        <input
                          onClick={() =>
                            this.checkValues(
                              "Home_rent_content",
                              "Home_rent_content",
                              context
                            )
                          }
                          checked={true}
                          id="Home_rent_content"
                          type="checkbox"
                          class="family_checkbox"
                        />
                      ) : (
                        <input
                          onClick={() =>
                            this.checkValues(
                              "Home_rent_content",
                              "Home_rent_content",
                              context
                            )
                          }
                          id="Home_rent_content"
                          type="checkbox"
                          class="family_checkbox"
                        />
                      )}
                      <h5
                        onClick={async () => {
                          if (this.state.Home_rent_content_cover === false) {
                          }
                          this.setState(
                            {
                              Home_rent_content_cover: true,
                              Not_have: false,
                              rent_content_cover_panel: true,
                            },
                            () => {
                              context.updateHome_insurance(this.state);
                            }
                          );
                        }}
                        class="checkbox_title"
                      >
                        Home Rent Content Cover{" "}
                        {this.state.rent_content_cover_panel === false &&
                        this.state.rent_content_cover !== 0 ? (
                          `(Rs ${this.state.rent_content_cover})`
                        ) : (
                          <span></span>
                        )}
                      </h5>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {this.state.rent_content_cover_panel ? (
                    <div class="home_cover_input">
                      <div class="motor_number">
                        <label class="home_input_opt">
                          Existing Rent Content Cover
                        </label>
                        <label
                          style={{
                            fontSize: 10,
                            color: "grey",
                            display: "flex",
                            paddingLeft: 11,
                            textAlign: "left",
                          }}
                        >
                          Please enter your existing cover for Home Content
                        </label>
                        <label
                          style={{
                            fontSize: 14,
                            color: "grey",
                            display: "flex",
                            paddingLeft: 11,
                            textAlign: "left",
                          }}
                        >
                          {rent_content_cover_in_words}
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 10,
                          }}
                        >
                          <label style={{ fontSize: 40, margin: "unset" }}>
                            &#8377;
                          </label>
                          <input
                            class="motor_input"
                            id="bikeInput2"
                            placeholder="Enter Amount"
                            type="number"
                            value={this.state.rent_content_cover}
                            onChange={(e) =>
                              this.setState({
                                rent_content_cover: e.target.value,
                              })
                            }
                          />
                        </div>
                        {this.state.rent_content_cover > 1000 ? (
                          <p
                            class="existing_content_cover"
                            onClick={() =>
                              this.setState(
                                { rent_content_cover_panel: false },
                                () => context.updateHome_insurance(this.state)
                              )
                            }
                          >
                            Done
                          </p>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {/* <div class="checkbox_input_home">
                    {this.state.Home_structure_content_cover ? (
                      <input
                        onClick={() =>
                          this.checkValues(
                            "Home_Structure_Content_Cover",
                            "Home_Structure_Content_Cover",
                            context
                          )
                        }
                        id="Home_structure"
                        checked={true}
                        id="Home_Structure_Content_Cover"
                        type="checkbox"
                        class="family_checkbox"
                      />
                    ) : (
                      <input
                        onClick={() =>
                          this.checkValues(
                            "Home_Structure_Content_Cover",
                            "Home_Structure_Content_Cover",
                            context
                          )
                        }
                        id="Home_structure"
                        id="Home_Structure_Content_Cover"
                        type="checkbox"
                        class="family_checkbox"
                      />
                    )}
                    <h5
                      onClick={async () => {
                        // if (this.state.Home_content_cover === true) {
                        //   // await context.updateCreditScore(7.5, "less");
                        // }
                        // if (this.state.Home_structure_cover === true) {
                        //   await context.updateCreditScore(7.5, "less");
                        // }
                        // if (this.state.Home_structure_content_cover === false) {
                        //   await context.updateCreditScore(15, "Add");
                        // }
                        this.setState(
                          {
                            Home_structure_content_cover: true,
                            Home_content_cover: false,
                            Home_structure_cover: false,
                            Not_have: false,
                            Home_rent_content_cover: false,
                            content_cover_panel: false,
                            rent_content_cover_panel: false,
                            content_cover: 0,
                            rent_content_cover: 0,
                          },
                          () => {
                            context.updateHome_insurance(this.state);
                          }
                        );
                      }}
                      class="checkbox_title"
                    >
                      Home Structure + Content Cover
                    </h5>
                  </div> */}

                  <div class="checkbox_input_home">
                    {this.state.Not_have ? (
                      <input
                        onClick={() =>
                          this.checkValues("Not_Have", "Not_Have", context)
                        }
                        id="Not_Have"
                        checked={true}
                        type="checkbox"
                        class="family_checkbox"
                      />
                    ) : (
                      <input
                        onClick={() =>
                          this.checkValues("Not_Have", "Not_Have", context)
                        }
                        id="Not_Have"
                        type="checkbox"
                        class="family_checkbox"
                      />
                    )}
                    <h5
                      onClick={async () => {
                        // if (this.state.Home_content_cover === true) {
                        //   await context.updateCreditScore(7.5, "less");
                        // }
                        // if (this.state.Home_structure_cover === true) {
                        //   await context.updateCreditScore(7.5, "less");
                        // }
                        // if (this.state.Home_structure_content_cover === true) {
                        //   await context.updateCreditScore(15, "less");
                        // }
                        this.setState(
                          {
                            Home_structure_cover: false,
                            Home_content_cover: false,
                            Home_rent_content_cover: false,
                            content_cover_panel: false,
                            rent_content_cover_panel: false,
                            content_cover: 0,
                            rent_content_cover: 0,
                            Home_structure_content_cover: false,
                            Not_have: true,
                          },
                          () => {
                            context.updateHome_insurance(this.state);
                          }
                        );
                      }}
                      class="checkbox_title"
                    >
                      I am not sure
                    </h5>
                  </div>
                </div>
              </div>

              {this.state.Home_content_cover === false &&
              this.state.Home_structure_content_cover === false &&
              this.state.Home_structure_cover === false &&
              this.state.Home_rent_content_cover === false &&
              this.state.Not_have === false ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button class="btn" style={{ backgroundColor: "lightgrey" }}>
                    Next
                  </button>
                </div>
              ) : context.state.policyTaken.Cyber === true ? (
                //Redirect to Cyber
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    class="btn"
                    onClick={async () => {
                      // await this.calculateCreditScore(context);
                      context.RecommendationforHomePolicy("Cyber");
                    }}
                  >
                    Next
                  </button>
                </div>
              ) : (
                //Redirect to Recommendations
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    class="btn"
                    onClick={async () => {
                      // await this.calculateCreditScore(context);
                      context.RecommendationforHomePolicy("Recommendations");
                    }}
                  >
                    Next
                  </button>
                </div>
              )}
            </React.Fragment>
          )}
        </AppContext.Consumer>
      </div>
    );
  }
}
Home_insurance.contextType = AppContext;
export default Home_insurance;
