import React, { Component } from "react";
import "../index.css";
import AppContext from "../context/AppContext";
import { withRouter } from "react-router-dom";
import svgPerson from "../svg/svgPerson.svg";
import health_icon from "../svg/health_icon.svg";
import motor_icon from "../svg/motor_icon.svg";
import others_icon from "../svg/others_icon.svg";
import HDFC_Bank_Logo from "../svg/HDFC_Bank_Logo.svg"
import HDFC_Ergo_Logo from "../svg/HDFC_Ergo_Logo.svg"



class Insurancedetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      health_options: false,
      vehicle_options: false,
      other_options: false,
      Health_insurance: false,
      Corporate_insurance: false,
      super_top_up_insurance: false,
      Personal_Accident_Insurance: false,
      critical_illness_insurance: false,
      Home_Rental: false,
      Corona_Kavach: false,
      Cyber: false,
      insuranceExistenceChecking: false,
      Motor: false,
      Bike: false,
    };
  }

  componentDidMount() {
    this.context.updateCreditScore(0);
    this.context.state.Recommendations = [];
    this.context.state.nothavePolicyRecommendation = [];
  }

  goBack() {
    this.props.history.push("/assets");
    this.context.updateCreditScore(0);
  }

  componentWillUnmount() {
    this.context.updateLastPage("/insurance-details");
  }

  checkValues = (id, value, context) => {
    if (document.getElementById(id).checked) {
      if (value === "Health_Insurance") {
        this.setState({ Health_insurance: true }, async () => {
          context.updatePolicyTypes(this.state);
          // await context.updateCreditScore("mediclaim", "default", "less");
          // await context.updateCreditScore("mediclaim", "has_insured", "Add");
          // await context.updateCreditScore("mediclaim", "default", "less");
        });
      } else if (value === "Health_Corporate") {
        this.setState({ Corporate_insurance: true }, async () => {
          await context.updatePolicyTypes(this.state);
        });
      } else if (value === "Health_Super-Top-up") {
        this.setState({ super_top_up_insurance: true }, async () => {
          context.updatePolicyTypes(this.state);
          // await context.updateCreditScore("super_top_up", "default", "less");
          // await context.updateCreditScore("super_top_up", "has_insured", "Add");
        });
      } else if (value === "Health_Personal_Accident") {
        this.setState({ Personal_Accident_Insurance: true }, () => {
          context.updatePolicyTypes(this.state);
        });
      } else if (value === "Health_Critical_Illness") {
        this.setState({ critical_illness_insurance: true }, () => {
          context.updatePolicyTypes(this.state);
        });
      } else if (value === "Home_Rental") {
        this.setState({ Home_Rental: true }, () => {
          context.updatePolicyTypes(this.state);
        });
      } else if (value === "Corona_Kavach") {
        this.setState({ Corona_Kavach: true }, () => {
          context.updatePolicyTypes(this.state);
          context.updatePolicyTypes(this.state);
        });
      } else if (value === "Motor") {
        this.setState({ Motor: true }, () => {
          context.updatePolicyTypes(this.state);
          context.updatePolicyTypes(this.state);
        });
      } else if (value === "Bike") {
        this.setState({ Bike: true }, () => {
          context.updatePolicyTypes(this.state);
          context.updatePolicyTypes(this.state);
        });
      } else {
        this.setState({ Cyber: true }, async () => {
          context.updatePolicyTypes(this.state);
          // await context.updateCreditScore("cyber", "default", "less");
          // await context.updateCreditScore("cyber", "has_insured", "Add");
        });
      }
    } else {
      if (value === "Health_Insurance") {
        this.setState({ Health_insurance: false }, async () => {
          context.updatePolicyTypes(this.state);
          // await context.updateCreditScore("mediclaim", "default", "Add");
          // await context.updateCreditScore("mediclaim", "has_insured", "less");
        });
      } else if (value === "Health_Corporate") {
        this.setState({ Corporate_insurance: false }, () => {
          context.updatePolicyTypes(this.state);
        });
      } else if (value === "Health_Super-Top-up") {
        this.setState({ super_top_up_insurance: false }, async () => {
          context.updatePolicyTypes(this.state);
          // await context.updateCreditScore("super_top_up", "default", "Add");
          // await context.updateCreditScore(
          //   "super_top_up",
          //   "has_insured",
          //   "less"
          // );
        });
      } else if (value === "Health_Personal_Accident") {
        this.setState({ Personal_Accident_Insurance: false }, () => {
          context.updatePolicyTypes(this.state);
        });
      } else if (value === "Health_Critical_Illness") {
        this.setState({ critical_illness_insurance: false }, () => {
          context.updatePolicyTypes(this.state);
        });
      } else if (value === "Home_Rental") {
        this.setState({ Home_Rental: false }, () => {
          context.updatePolicyTypes(this.state);
        });
      } else if (value === "Corona_Kavach") {
        this.setState({ Corona_Kavach: false }, () => {
          context.updatePolicyTypes(this.state);
        });
      } else if (value === "Motor") {
        this.setState({ Motor: false }, () => {
          context.updatePolicyTypes(this.state);
        });
      } else if (value === "Bike") {
        this.setState({ Bike: false }, () => {
          context.updatePolicyTypes(this.state);
        });
      } else {
        this.setState({ Cyber: false }, async () => {
          context.updatePolicyTypes(this.state);
          // await context.updateCreditScore("cyber", "default", "Add");
          // await context.updateCreditScore("cyber", "has_insured", "less");
        });
      }
    }
  };
  nextClicked(context) {
    if (
      this.state.Health_insurance === false &&
      this.state.super_top_up_insurance === false &&
      this.state.critical_illness_insurance === false &&
      this.state.Home_Rental === false &&
      this.state.Personal_Accident_Insurance === false &&
      this.state.Corona_Kavach === false &&
      this.state.Corporate_insurance === false &&
      this.state.Cyber === false &&
      this.state.Bike === false &&
      this.state.Motor === false
    ) {
      context.RecommendationForIdonthavePolicy();
    } else {
      setTimeout(() => {
        context.RecommendNonSelectedPolicies();
      }, 1000);

      if (context.state.policyTaken.Health_insurance === true) {
        this.props.history.push("/health-insurance");
      } else if (context.state.policyTaken.Corporate_insurance === true) {
        this.props.history.push("/corporate-insurance");
      } else if (context.state.policyTaken.super_top_up_insurance === true) {
        this.props.history.push("/super-topup");
      } else if (
        context.state.policyTaken.Personal_Accident_Insurance === true
      ) {
        this.props.history.push("/personal-accident-insurance");
      } else if (
        context.state.policyTaken.critical_illness_insurance === true
      ) {
        this.props.history.push("/critical-illness");
      } else if (context.state.policyTaken.Bike === true) {
        this.props.history.push("/Twowheeler_motor");
      } else if (context.state.policyTaken.Motor === true) {
        this.props.history.push("/Motor_details");
      } else if (context.state.policyTaken.Home_Rental === true) {
        this.props.history.push("/home-insurance");
      } else if (context.state.policyTaken.Cyber === true) {
        this.props.history.push("/cyber-insurance");
      }
    }

    window.gtag("event", "PERSONAL_POLICY_DETAILS", {
      event_category: "DETAILS",
      event_label: "PERSONAL EXISTING POLICY UPDATED",
    });
  }

  render() {
    return (
      <div>
        <AppContext.Consumer>
          {(context) => (
            <React.Fragment>
              {this.state.insuranceExistenceChecking === true ? (
                <div>
                  <div className="header-area">
                    <div className="">
                      <img
                        onClick={() =>
                          this.setState({ insuranceExistenceChecking: false })
                        }
                        className="back-icon"
                        src={process.env.PUBLIC_URL + "/back.png"}
                      />
                    </div>
                    <div className="header-text">
                      Insurance (Step {context.state.AppSteps[4]}/
                      {context.state.AppSteps.length})
                    </div>
                    <div className="progress-container">
                      <div
                        className="progress-bar"
                        id="myBar"
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </div>
                  <div
                    id="slide"
                    className="Familydetails_maincomponent"
                    style={{ marginTop: 85 }}
                  >
                    <div>
                      <div className="content-insurance-details">
                        Select from below options
                      </div>
                    </div>

                    <div>
                      <div className="category-dropdown">
                        <div
                          onClick={() =>
                            this.setState({
                              health_options:
                                this.state.health_options === false
                                  ? true
                                  : false,
                            })
                          }
                          className="insurance_category"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItem: "center",
                            }}
                          >
                            <div>
                              <img src={health_icon} />
                            </div>

                            <h5 className="insurance_cat_header">Health</h5>
                          </div>
                          {this.state.health_options === false ? (
                            <ion-icon
                              onClick={() =>
                                this.setState({ health_options: true })
                              }
                              style={{
                                color: "lightgrey",
                                width: 24,
                                height: 24,
                                paddingRight: 20,
                              }}
                              name="chevron-forward-outline"
                            ></ion-icon>
                          ) : (
                            <ion-icon
                              onClick={() =>
                                this.setState({ health_options: false })
                              }
                              style={{
                                color: "lightgrey",
                                width: 24,
                                height: 24,
                                paddingRight: 20,
                              }}
                              name="chevron-down-outline"
                            ></ion-icon>
                          )}
                        </div>
                        <div
                          className={
                            this.state.health_options === true
                              ? "checkbox_input_insurance_container show"
                              : "checkbox_input_insurance_container"
                          }
                        >
                          <div className="insurance_details_webview">
                            <div
                              className="checkbox_input_insurance"
                              style={{
                                paddingBottom: 20,
                                paddingTop: 20,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {this.state.Health_insurance === true ? (
                                <input
                                  onClick={() =>
                                    this.checkValues(
                                      "Health_Health",
                                      "Health_Insurance",
                                      context
                                    )
                                  }
                                  checked={true}
                                  id="Health_Health"
                                  type="checkbox"
                                  className="family_checkbox"
                                />
                              ) : (
                                <input
                                  onClick={() =>
                                    this.checkValues(
                                      "Health_Health",
                                      "Health_Insurance",
                                      context
                                    )
                                  }
                                  id="Health_Health"
                                  type="checkbox"
                                  className="family_checkbox"
                                />
                              )}
                              <p
                                onClick={async () => {
                                  if (this.state.Health_insurance === false) {
                                    // await context.updateCreditScore(
                                    //   "mediclaim",
                                    //   "default",
                                    //   "less"
                                    // );
                                    // await context.updateCreditScore(
                                    //   "mediclaim",
                                    //   "has_insured",
                                    //   "Add"
                                    // );
                                  }
                                  this.setState(
                                    { Health_insurance: true },
                                    () => {
                                      context.updatePolicyTypes(this.state);
                                    }
                                  );
                                }}
                                className="checkbox_title"
                              >
                                Health Insurance
                              </p>
                            </div>
                            <div
                              className="checkbox_input_insurance"
                              style={{
                                paddingBottom: 20,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {this.state.Corporate_insurance === true ? (
                                <input
                                  onClick={() =>
                                    this.checkValues(
                                      "Health_Corporate",
                                      "Health_Corporate",
                                      context
                                    )
                                  }
                                  type="checkbox"
                                  id="Health_Corporate"
                                  checked={true}
                                  className="family_checkbox"
                                />
                              ) : (
                                <input
                                  onClick={() =>
                                    this.checkValues(
                                      "Health_Corporate",
                                      "Health_Corporate",
                                      context
                                    )
                                  }
                                  type="checkbox"
                                  id="Health_Corporate"
                                  checked={false}
                                  className="family_checkbox"
                                />
                              )}
                              <p
                                onClick={() => {
                                  this.setState(
                                    { Corporate_insurance: true },
                                    () => {
                                      context.updatePolicyTypes(this.state);
                                    }
                                  );
                                }}
                                className="checkbox_title"
                              >
                                Corporate / Employer Health Insurance
                              </p>
                            </div>

                            <div
                              className="checkbox_input_insurance"
                              style={{
                                paddingBottom: 20,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {this.state.super_top_up_insurance === true ? (
                                <input
                                  onClick={() =>
                                    this.checkValues(
                                      "Health_Super-Top-up",
                                      "Health_Super-Top-up",
                                      context
                                    )
                                  }
                                  checked={true}
                                  type="checkbox"
                                  id="Health_Super-Top-up"
                                  className="family_checkbox"
                                />
                              ) : (
                                <input
                                  onClick={() =>
                                    this.checkValues(
                                      "Health_Super-Top-up",
                                      "Health_Super-Top-up",
                                      context
                                    )
                                  }
                                  type="checkbox"
                                  id="Health_Super-Top-up"
                                  className="family_checkbox"
                                />
                              )}
                              <p
                                onClick={async () => {
                                  if (
                                    this.state.super_top_up_insurance === false
                                  ) {
                                    // await context.updateCreditScore(
                                    //   "super_top_up",
                                    //   "default",
                                    //   "less"
                                    // );
                                    // await context.updateCreditScore(
                                    //   "super_top_up",
                                    //   "has_insured",
                                    //   "Add"
                                    // );
                                  }
                                  this.setState(
                                    { super_top_up_insurance: true },
                                    () => {
                                      context.updatePolicyTypes(this.state);
                                    }
                                  );
                                }}
                                className="checkbox_title"
                              >
                                Super Top-Up Health Insurance{" "}
                              </p>
                            </div>
                            {/* <div className='checkbox_input_insurance' style={{ paddingBottom: 20, display: 'flex', alignItems: 'center' }}>
                                                            {this.state.Personal_Accident_Insurance === true ?
                                                                <input onClick={() => this.checkValues('Health_Personal_Accident', 'Health_Personal_Accident', context)} type='checkbox' checked={true} id='Health_Personal_Accident' className='family_checkbox' />
                                                                :
                                                                <input onClick={() => this.checkValues('Health_Personal_Accident', 'Health_Personal_Accident', context)} type='checkbox' id='Health_Personal_Accident' className='family_checkbox' />
                                                            }
                                                            <p onClick={() => {
                                                                this.setState({ Personal_Accident_Insurance: true }, () => {
                                                                    context.updatePolicyTypes(this.state)
                                                                    // context.updateCreditScore(0, "Add");
                                                                })
                                                            }} className='checkbox_title'>Personal Accident Insurance</p>
                                                        </div> */}
                            {/* <div
                              className="checkbox_input_insurance"
                              style={{
                                paddingBottom: 20,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {this.state.critical_illness_insurance ===
                              true ? (
                                <input
                                  onClick={() =>
                                    this.checkValues(
                                      "Health_Critical_Illness",
                                      "Health_Critical_Illness",
                                      context
                                    )
                                  }
                                  type="checkbox"
                                  checked={true}
                                  id="Health_Critical_Illness"
                                  className="family_checkbox"
                                />
                              ) : (
                                <input
                                  onClick={() =>
                                    this.checkValues(
                                      "Health_Critical_Illness",
                                      "Health_Critical_Illness",
                                      context
                                    )
                                  }
                                  type="checkbox"
                                  id="Health_Critical_Illness"
                                  className="family_checkbox"
                                />
                              )}
                              <p
                                onClick={() => {
                                  if (
                                    this.state.critical_illness_insurance ===
                                    false
                                  ) {
                                    // context.updateCreditScore(14, "Add");
                                  }
                                  this.setState(
                                    { critical_illness_insurance: true },
                                    () => {
                                      context.updatePolicyTypes(this.state);
                                    }
                                  );
                                }}
                                className="checkbox_title"
                              >
                                Critical Illness Insurance
                              </p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      {/* 
                      <div>
                        <div className="insurance_category">
                          <div>
                            <div>
                              <img src={motor_icon} />
                            </div>
                            <h5
                              onClick={() =>
                                this.setState({ vehicle_options: true })
                              }
                              className="insurance_cat_header"
                            >
                              Motor Vehicle
                            </h5>
                          </div>
                          {this.state.vehicle_options === false ? (
                            <ion-icon
                              onClick={() =>
                                this.setState({ vehicle_options: true })
                              }
                              style={{
                                color: "lightgrey",
                                width: 24,
                                height: 24,
                                paddingRight: 20,
                              }}
                              name="chevron-forward-outline"
                            ></ion-icon>
                          ) : (
                            <ion-icon
                              onClick={() =>
                                this.setState({ vehicle_options: false })
                              }
                              style={{
                                color: "lightgrey",
                                width: 24,
                                height: 24,
                                paddingRight: 20,
                              }}
                              name="chevron-down-outline"
                            ></ion-icon>
                          )}
                        </div>

                        <div
                          className={
                            this.state.vehicle_options === true
                              ? "checkbox_input_insurance_container show"
                              : "checkbox_input_insurance_container"
                          }
                        >
                          <div
                            className="checkbox_input_insurance"
                            style={{
                              paddingBottom: 20,
                              display: "flex",
                              alignItems: "center",
                              marginTop: 10,
                            }}
                          >
                            {this.state.Bike === true ? (
                              <input
                                type="checkbox"
                                onClick={() =>
                                  this.checkValues("Bike", "Bike", context)
                                }
                                value="TP"
                                id="Bike"
                                checked={true}
                                className="family_checkbox"
                              />
                            ) : (
                              <input
                                type="checkbox"
                                onClick={() =>
                                  this.checkValues("Bike", "Bike", context)
                                }
                                value="TP"
                                id="Bike"
                                className="family_checkbox"
                              />
                            )}
                            <p
                              onClick={() =>
                                this.setState({ Bike: true }, () => {
                                  context.updatePolicyTypes(this.state);
                                  context.updateCreditScore(0, "Add");
                                })
                              }
                              className="checkbox_title"
                            >
                              Twowheeler Insurance
                            </p>
                          </div>
                          <div
                            className="checkbox_input_insurance"
                            style={{
                              paddingBottom: 20,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {this.state.Motor === true ? (
                              <input
                                onClick={() =>
                                  this.checkValues("Motor", "Motor", context)
                                }
                                value="Cd"
                                id="Motor"
                                checked={true}
                                type="checkbox"
                                className="family_checkbox"
                              />
                            ) : (
                              <input
                                onClick={() =>
                                  this.checkValues("Motor", "Motor", context)
                                }
                                value="Cd"
                                id="Motor"
                                type="checkbox"
                                className="family_checkbox"
                              />
                            )}
                            <p
                              onClick={() =>
                                this.setState({ Motor: true }, () => {
                                  context.updatePolicyTypes(this.state);
                                  context.updateCreditScore(0, "Add");
                                })
                              }
                              className="checkbox_title"
                            >
                              Fourwheeler Insurance
                            </p>
                          </div>
                        </div>
                      </div> */}

                      <div>
                        <div
                          onClick={() =>
                            this.setState({
                              other_options:
                                this.state.other_options === false
                                  ? true
                                  : false,
                            })
                          }
                          className="insurance_category"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItem: "center",
                            }}
                          >
                            <div>
                              <img src={others_icon} />
                            </div>
                            <h5 className="insurance_cat_header">Others</h5>
                          </div>
                          {this.state.other_options === false ? (
                            <ion-icon
                              onClick={() =>
                                this.setState({ other_options: true })
                              }
                              style={{
                                color: "lightgrey",
                                width: 24,
                                height: 24,
                                paddingRight: 20,
                              }}
                              name="chevron-forward-outline"
                            ></ion-icon>
                          ) : (
                            <ion-icon
                              onClick={() =>
                                this.setState({ other_options: false })
                              }
                              style={{
                                color: "lightgrey",
                                width: 24,
                                height: 24,
                                paddingRight: 20,
                              }}
                              name="chevron-down-outline"
                            ></ion-icon>
                          )}
                        </div>

                        <div
                          className={
                            this.state.other_options === true
                              ? "checkbox_input_insurance_container show"
                              : "checkbox_input_insurance_container"
                          }
                        >
                          <div className="insurance_details_webview">
                            {this.context.state.assets.valueofhome === "" &&
                              this.context.state.assets.valueofhomeContent ===
                              "" &&
                              this.context.state.assets
                                .valueofhomeContent_rent === "" ? (
                              <div></div>
                            ) : (
                              <div
                                className="checkbox_input_insurance"
                                style={{
                                  paddingTop: 20,
                                  paddingTop: 10,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {this.state.Home_Rental === true ? (
                                  <input
                                    onClick={() =>
                                      this.checkValues(
                                        "Home_Rental",
                                        "Home_Rental",
                                        context
                                      )
                                    }
                                    id="Home_Rental"
                                    checked={true}
                                    type="checkbox"
                                    className="family_checkbox"
                                  />
                                ) : (
                                  <input
                                    onClick={() =>
                                      this.checkValues(
                                        "Home_Rental",
                                        "Home_Rental",
                                        context
                                      )
                                    }
                                    id="Home_Rental"
                                    type="checkbox"
                                    className="family_checkbox"
                                  />
                                )}
                                <p
                                  onClick={() =>
                                    this.setState({ Home_Rental: true }, () => {
                                      context.updatePolicyTypes(this.state);
                                    })
                                  }
                                  className="checkbox_title"
                                >
                                  Home Insurance
                                </p>
                              </div>
                            )}
                            {/* <div className='checkbox_input_insurance' style={{ paddingBottom: 20, display: 'flex', alignItems: 'center' }}>
                                                                <input onClick={() => this.checkValues('Corona_Kavach', 'Corona_Kavach', context)} id='Corona_Kavach' type='checkbox' className='family_checkbox' />
                                                                <p className='checkbox_title'>Corona Kavach</p> 
                                                            </div> */}
                            <div
                              className="checkbox_input_insurance"
                              style={{
                                paddingTop: 20,
                                paddingBottom: 20,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {this.state.Cyber === true ? (
                                <input
                                  onClick={() =>
                                    this.checkValues("Cyber", "Cyber", context)
                                  }
                                  id="Cyber"
                                  checked={true}
                                  type="checkbox"
                                  className="family_checkbox"
                                />
                              ) : (
                                <input
                                  onClick={() =>
                                    this.checkValues("Cyber", "Cyber", context)
                                  }
                                  id="Cyber"
                                  type="checkbox"
                                  className="family_checkbox"
                                />
                              )}
                              <p
                                onClick={async () => {
                                  if (this.state.Cyber === false) {
                                    // await context.updateCreditScore(
                                    //   "cyber",
                                    //   "default",
                                    //   "less"
                                    // );
                                    // await context.updateCreditScore(
                                    //   "cyber",
                                    //   "has_insured",
                                    //   "Add"
                                    // );
                                  }
                                  this.setState({ Cyber: true }, () => {
                                    context.updatePolicyTypes(this.state);
                                  });
                                }}
                                className="checkbox_title"
                              >
                                Cyber Insurance
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="bottom_btn_container">

                      <div style={{ paddingTop: "70%", paddingBottom: "20%", textAlign: 'center', width: "100%" }}>
                        <div className="brand_icons_header">Powered by</div>
                        <div className="brand_icons">
                          <img src={HDFC_Bank_Logo} style={{ marginRight: "8px", height :"20px" }} alt="HDFC_BANK" />
                          <img src={HDFC_Ergo_Logo} style={{ marginLeft: "8px" }} alt="HDFC_ERGO" />
                        </div>
                      </div>

                      <button
                        className="btn"
                        onClick={() => this.nextClicked(context)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div class="insurance_details_icon">
                  <div className="header-area">
                    <div className="">
                      <img
                        onClick={() => this.goBack()}
                        className="back-icon"
                        src={process.env.PUBLIC_URL + "/back.png"}
                      />
                    </div>
                    <div className="header-text">
                      Existing Insurance (Step {context.state.AppSteps[4]}/
                      {context.state.AppSteps.length})
                    </div>
                    <div className="progress-container">
                      <div
                        className="progress-bar"
                        id="myBar"
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img style={{ height: "158px" }} src={svgPerson} />
                  </div>
                  <p className="insurance_checking">
                    Do you have any existing insurance policy?
                  </p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      onClick={() =>
                        this.setState({ insuranceExistenceChecking: true })
                      }
                      className="insurance_btn"
                    >
                      Yes
                    </button>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      onClick={() => context.RecommendationForIdonthavePolicy()}
                      className="insurance_button"
                    >
                      No
                    </button>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </AppContext.Consumer>
      </div>
    );
  }
}

Insurancedetails.contextType = AppContext;
export default withRouter(Insurancedetails);
