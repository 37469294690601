import React, { Component } from "react";
import AppContext from "../context/AppContext";
import OtpInput from "react-otp-input";
import Loader from "./Loader.js";
import { GA_trigger } from "../config/ga";
import {secure_data_decode} from "../config/enc_dec";
import {
  kompass_encrypt_fun,
  kompass_security_decode,
} from "../commonfunctions/commonfunction";
import HDFC_Bank_Logo from "../svg/HDFC_Bank_Logo.svg";
import HELOGO from "../svg/HELOGO.svg";
//crypto file
var CryptoJS = require("crypto-js");


let counter;
class OTPvalidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OTP: "",
      count: 300,
      warning: false,
      msg:
        "Please Enter Valid OTP (you have entered either exoired or wrong OTP)",
      validationmsg: "",
      verified: false,
    };
  }

  validateUser = async () => {
    this.setState({
      verified: true,
      validationmsg: "Verifying please wait...",
    });
    // this.props.history.push("/letsGetPersonal");
    try {
      let otp_verification = await fetch(
        `${process.env.REACT_APP_API_URL}/validateuser`,
        // {
        //   method: "post",
        //   headers: { "content-type": "application/json" },
        //   body: JSON.stringify({
        //     mobile: kompass_encrypt_fun(this.context.state.mobileNumber),
        //     otp: kompass_encrypt_fun(this.state.OTP),
        //     token: kompass_encrypt_fun(this.state.OTP),
        //   }),
        // }
        {
          method: "post",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({
            user_personal_data: CryptoJS.AES.encrypt(
            kompass_encrypt_fun({
              mobile: this.context.state.mobileNumber,
              otp: this.state.OTP,
              token: this.state.OTP,
            }),
            process.env.REACT_APP_ENC_SECRET_KEY
          ).toString(),
          }),
        }
        );

      // let varificationResult = kompass_security_decode(
      //   await otp_verification.json()
      // );

      let resultObj = await otp_verification.json()
      console.log(resultObj)
      let varificationResult = await secure_data_decode(resultObj);
      console.log("OTP_LOGS",{varificationResult})

      console.log(
        "KOMPASS ====== LOG",
        varificationResult,
        varificationResult.verified,
        varificationResult.error,
        varificationResult.kompass_token
      );
      console.log('varificationResult.kompass_token', JSON.stringify(varificationResult.kompass_token))
      if (
        varificationResult.verified === "successfully" &&
        varificationResult.error === false &&
        varificationResult.kompass_token
      ) {
        window.sessionStorage.setItem(
          "sessionToken",
          varificationResult.kompass_token
        );
        window.sessionStorage.setItem(
          "AdvisoryToken",
          varificationResult.kompass_token
        );

        await fetch(`${process.env.REACT_APP_API_URL}/saveuserinfo`, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "no-cors",
          },
          body: JSON.stringify({
           // MobileNo: kompass_encrypt_fun(this.context.state.mobileNumber),
           //enc fun 
           MobileNo: CryptoJS.AES.encrypt(this.context.state.mobileNumber,process.env.REACT_APP_ENC_SECRET_KEY).toString(),
          }),
        })
          .then((res) => res.json())
          .then((data) => {
           data= secure_data_decode(data)
            if (data !== "err") {
              this.setState({
                verified: true,
                validationmsg: "Verified  ",
              });
              this.context.updatePersoanlInfo(data, () => {
                setTimeout(() => {
                  // this.props.history.push("/personal_details");
                  this.props.history.push("/letsGetPersonal");
                  this.setState({ verified: false });
                }, 3000);
              });

              // this.context.state.userInfo.userId = data.userid;
              GA_trigger.EVENT(
                "VERIFICATION SUCCESSFUL",
                "USER VERIFIED",
                "VERIFICATION"
              );
            } else {
              this.setState({
                msg: "Something Went wrong. Please try again.",
                warning: true,
                OTP: "",
                verified: false,
                validationmsg: "",
              });
              setTimeout(() => {
                this.setState({ warning: false });
              }, 2000);
            }
          })
          .catch((err) => {
            this.setState({
              msg: "Something Went wrong",
              warning: true,
              OTP: "",
              verified: false,
              validationmsg: "",
            });
            GA_trigger.EVENT(
              "VERIFICATION FAILED",
              "USER VERIFIED",
              "VERIFICATION"
            );
            setTimeout(() => {
              this.setState({ warning: false });
            }, 2000);
          });
      } else {
        this.setState({
          msg:
            "Please Enter Valid OTP(you have entered either expired or wrong OTP)",
          warning: true,
          OTP: "",
          verified: false,
          validationmsg: "",
        });
        setTimeout(() => {
          this.setState({ warning: false });
        }, 3000);
      }
    } catch (err) {
      console.log(err,"Print err")
      this.setState({
        msg: "Something Went wrong",
        warning: true,
        OTP: "",
        verified: false,
        validationmsg: "",
      });
      GA_trigger.EVENT("VERIFICATION FAILED", "USER VERIFIED", "VERIFICATION");
      setTimeout(() => {
        this.setState({ warning: false });
      }, 2000);
    }
  };

  componentDidMount() {
    this.setCounterForResend();
  }

  // componentWillUnmount() {
  //     if(window.event)
  //    {
  //       if(window.event.clientX < 40 && window.event.clientY < 0)
  //      {
  //          alert("Browser back button is clicked...");
  //      }
  //      else
  //      {
  //          alert("Browser refresh button is clicked...");
  //      }
  //    }
  // }

  ResendOtp = () => {
    this.setState({ count: 300, OTP: "" });
    this.setCounterForResend();
    fetch(`${process.env.REACT_APP_API_URL}/generateOTP`, {
      method: "post",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        mobileno: kompass_encrypt_fun(this.context.state.mobileNumber),
      }),
    })
      .then((res) => secure_data_decode(res).json())
      .then((data) => {
        console.log(data);
        if (data !== "Err" && data !== "Limit Exceed") {
          this.context.setOtp(data);
        } else if (data === "Limit Exceed") {
          this.setState({
            warning: true,
            msg: "Your OTP request limit exceeds. Please try after one hour.",
          });
          this.setState({ otp_sending_process: false });
        } else {
          this.setState({
            warning: true,
            msg: "Something Went Wrong Please try again",
          });
          setTimeout(() => {
            this.setState({ warning: false });
          }, 2000);
        }
      })
      .catch((err) => null);
  };

  setCounterForResend = () => {
    counter = setInterval(() => {
      this.setState({ count: this.state.count - 1 });
    }, 1000);
  };

  clock = () => {
    let seconds = this.state.count % 60;
    let minutes = Math.floor(this.state.count / 60);
    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
    return minutes + ":" + seconds;
  };

  render() {
    if (this.state.count === 0) {
      clearInterval(counter);
    }

    return (
      <div id="slide">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "15px 20px 0 20px",
          }}
        >
          <img src={HDFC_Bank_Logo} style={{ height: 36 }} />
          <img src={HELOGO} style={{ height: 36 }} />
        </div>
        <p class="otptitle">Enter 4 digit one time password sent on</p>
        <p class="otpsubtitle">
          <span>+91 {this.context.state.mobileNumber}</span>{" "}
          <label
            style={{ color: "#FF1520" }}
            onClick={() => this.props.history.push("/login")}
          >
            Change?
          </label>
        </p>

        <br />
        {/* <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <input
            value={this.state.otp1}
            onChange={(e) => this.change(e, "otp1")}
            onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
            id="otp1"
            maxLength="1"
            type="number"
            autoFocus
            class="otp_input"
          />
          <input
            value={this.state.otp2}
            onChange={(e) => this.change(e, "otp2")}
            onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
            id="otp2"
            maxLength="1"
            type="number"
            class="otp_input"
          />
          <input
            value={this.state.otp3}
            onChange={(e) => this.change(e, "otp3")}
            onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
            id="otp3"
            maxLength="1"
            type="number"
            class="otp_input"
          />
          <input
            value={this.state.otp4}
            onKeyPress={(e) => this.change(e)}
            onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
            onChange={(e) => this.change(e, "otp4")}
            id="otp4"
            maxLength="1"
            type="number"
            class="otp_input"
          />
        </div> */}
        <div className="otp_input_container">
          <OtpInput
            inputStyle={{
              width: "3em",
              height: "3em",
              marginRight: 10,
              border: "1px solid lightgrey",
              borderRadius: 5,
              fontWeight: "bold",
            }}
            name="otp"
            value={this.state.OTP}
            onChange={(otp) => this.setState({ OTP: otp })}
            isInputNum="true"
            shouldAutoFocus="true"
            numInputs={4}
          />
        </div>
        {this.state.warning === false ? (
          this.state.count === 0 ? (
            <p onClick={() => this.ResendOtp()} class="otpsubtitle2">
              <label class="otpotion">Resend OTP</label>
            </p>
          ) : (
            <p class="otpsubtitle2">
              Resend OTP <label class="otpotion">{this.clock()}</label>
            </p>
          )
        ) : (
          <p
            style={{ color: "red", fontSize: 13, padding: 20 }}
            class="otpsubtitle2"
          >
            {this.state.msg}
          </p>
        )}

        <div style={{ display: "flex", justifyContent: "center" }}>
          {this.state.OTP !== "" && this.state.verified === false ? (
            <button class="btn" onClick={() => this.validateUser()}>
              Done
            </button>
          ) : this.state.verified === true ? (
            <button style={{ opacity: 0.6 }} class="btn">
              <Loader balls_color="white" />
            </button>
          ) : (
            <button style={{ backgroundColor: "lightgrey" }} class="btn">
              Done
            </button>
          )}
        </div>
      </div>
    );
  }
}
OTPvalidation.contextType = AppContext;

export default OTPvalidation;
