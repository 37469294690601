const jwt = require("jsonwebtoken");
let CryptoJS = require("crypto-js");

const kompass_encrypt_fun = (data) => {
  let encrypted_data = jwt.sign(data, process.env.REACT_APP_JWT_SECRET);

  // var encUserInfo = CryptoJS.AES.encrypt(JSON.stringify(encrypted_data), "HDFC_ERGO_DATA").toString();
  // console.log("encUserInfo",encUserInfo);

  // var bytes = CryptoJS.AES.decrypt(encUserInfo, 'HDFC_ERGO_DATA');
  // var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // console.log("decryptedData",decryptedData);

  return encrypted_data;
};

// const userDetailEncrypter = (data) => {
//   let encryptUserInfo = CryptoJS.AES.encrypt(
//     JSON.stringify(data),
//     "HDFC_ERGO_DATA"
//   ).toString();
//   console.log("encUserInfo", encryptUserInfo);
//   return encryptUserInfo;
// };

const kompass_security_decode = (payload) => {
  try {
    var decoded_token = jwt.decode(payload);
    if (decoded_token) {
      return decoded_token;
    }
  } catch (err) {
    return null;
  }
};

const access_checking = () => {
  let token = window.sessionStorage.getItem("sessionToken");
  return token;
};
export {
  kompass_encrypt_fun,
  // userDetailEncrypter,
  kompass_security_decode,
  access_checking,
};
