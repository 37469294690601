import React, { Component } from 'react';


class Motor_uerDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    
changestyle = (id) => {
    if(id === 'namelabel') {
        document.getElementById('age').placeholder = 'Email'
        document.getElementById('agelabel').style.display='none'
        document.getElementById('agelabel').style.position='relative'
        document.getElementById('agelabel').style.top='-85px'
        document.getElementById('agelabel').style.left='30px'
        document.getElementById('agelabel').style.paddingLeft='5px'
        document.getElementById('agelabel').style.paddingRight='5px'
        document.getElementById('agelabel').style.backgroundColor='white'
        document.getElementById('agelabel').style.fontWeight='bold'

        document.getElementById('fullname').placeholder = ''
        document.getElementById(id).style.display='inline'
        document.getElementById(id).style.position='relative'
        document.getElementById(id).style.top='-85px'
        document.getElementById(id).style.left='30px'
        document.getElementById(id).style.paddingLeft='5px'
        document.getElementById(id).style.paddingRight='5px'
        document.getElementById(id).style.backgroundColor='white'
        document.getElementById(id).style.fontWeight='bold'
    }
    else if(id === 'agelabel') {
        document.getElementById('fullname').placeholder = 'Full Name'
        document.getElementById('namelabel').style.display='none'
        document.getElementById('namelabel').style.position='relative'
        document.getElementById('namelabel').style.top='-85px'
        document.getElementById('namelabel').style.left='30px'
        document.getElementById('namelabel').style.paddingLeft='5px'
        document.getElementById('namelabel').style.paddingRight='5px'
        document.getElementById('namelabel').style.backgroundColor='white'
        document.getElementById('namelabel').style.fontWeight='bold'

        document.getElementById('age').placeholder = ''
        document.getElementById(id).style.display='inline'
        document.getElementById(id).style.position='relative'
        document.getElementById(id).style.top='-85px'
        document.getElementById(id).style.left='30px'
        document.getElementById(id).style.paddingLeft='5px'
        document.getElementById(id).style.paddingRight='5px'
        document.getElementById(id).style.backgroundColor='white'
        document.getElementById(id).style.fontWeight='bold'
    }

    

}

    render() {
        return (
            <div>
                <div className="header-area">
                    <div className=""><img onClick={() => this.goBack()} className="back-icon" className="back-icon" src={process.env.PUBLIC_URL + '/back.png'} /></div>
                    <div className="header-text">User Details</div>
                    
                </div>
                <div >
                    <div style={{margin:20}}>
                        <h3>
                            Please Fill Your Details
                        </h3>

                    </div>
                </div>
                <div style={{ height: 80 }}>
                    <div class='personal_input' >
                        <input id='fullname' autoFocus={true} type='text' onFocus={() => this.changestyle('namelabel')} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} placeholder='Full name' class='personal_input' />
                        {this.state.name === '' ?
                            <ion-icon style={{ color: 'rgba(0, 0, 0, 0.34)', fontSize: 18 }} name="person"></ion-icon>
                            :
                            <div></div>
                        }
                    </div>
                    <label style={{ display: 'none' }} id='namelabel'>Full Name</label>
                </div>

                <div style={{ height: 80 }}>
                    <div class='personal_input' >
                        <input id='age' autoFocus={true} type='text' onFocus={() => this.changestyle('agelabel')} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} placeholder='Full name' class='personal_input' />
                        {this.state.name === '' ?
                            <ion-icon style={{ color: 'rgba(0, 0, 0, 0.34)', fontSize: 18 }} name="person"></ion-icon>
                            :
                            <div></div>
                        }
                    </div>
                    <label style={{ display: 'none' }} id='agelabel'>Email Address</label>
                </div>

            </div>

        )
    }


}

export default Motor_uerDetails;