import React, { Component } from 'react';
import AppContext from '../context/AppContext'
import { personal_accident } from "../config/defaultVariables";
import personal_icon from '../svg/personal_icon.svg';
import motor_icon_2 from '../svg/motor_icon_2.svg';


const age = 44;

class Fourwheeler extends Component {
    constructor(props) {
        super(props)
        this.state = {
            registrationNumber:''
        }

    }

    componentDidMount() {
        window.scrollTo(0,0)
        this.setState({
            registrationNumber:this.context.state.motor.fourwheeler
        })
    }

    updateMaincomponent = (context, page) => {
        this.chekcCreditScore(context);
        context.updatePersonalAccidentSumInsured(this.state, page);
    };

    goBack(context){
        this.props.history.goBack();
        context.healthAbortControllerStart()
    }

    registrationdetails(e) {
        this.setState({registrationNumber:e.target.value})
    }
    render() {
        return (

            <div style={{paddingBottom:60}}>
                <AppContext.Consumer>
                    {(context) => (
                        <React.Fragment>
                <div className="header-area">
                    <div className=""><img onClick={() => this.goBack(context)} className="back-icon" src={process.env.PUBLIC_URL + '/back.png'} /></div>
                    <div className="header-text">Existing Fourwheeler Details</div>
                    <div class="progress-container">
                        <div class="progress-bar" id="myBar" style={{ width: "25%" }}></div>
                    </div>
                </div>
                
                            <div id="slide">
                            
                                <div style={{ display: 'flex', justifyContent: 'center',marginTop:'25%' }}>
                                    <img src={motor_icon_2} />
                                </div>

                                <div>
                                     <div style={{ display: 'flex', justifyContent: 'center'}}>
                                        <span class='category_insurance_header'>Fourwheeler Details</span>
                                    </div>

                                </div>

                                <div style={{display:'flex',justifyContent:'center',marginBottom:25}}>
                                    <div class='suminsured_container'>
                                        <p style={{textAlign:'center',paddingTop:10,marginBottom:'unset'}}>Motor Registration Number</p>
                                        <div style={{display:'flex',justifyContent:'center'}}>
                                            <div className="health-input">
                                                <input name="suminsured" value={this.state.registrationNumber} onChange={(e)=>this.registrationdetails(e)} className="other_motor_input" id="suminsured" placeholder="Enter Registration Number" required />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button class='btn' onClick={()=>context.updateRegistartionDeatails('fourwheeler',this.state.registrationNumber)}>Next</button>
                                </div>
                            
                            </div>
                        </React.Fragment>
                    )}
                </AppContext.Consumer>
            </div>

        )
    }

}

Fourwheeler.contextType = AppContext
export default Fourwheeler;