import React, { Component } from "react";

class Motor_varient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      varients: [],
      error: false,
      msg: "",
      searchit: "",
      searchData: []
    };
  }

  componentDidMount() {
    if (this.props.type === "BIKE") {
      fetch(
        `${process.env.REACT_APP_API_URL}/getTwoWheelerProcessData?model=${this.props.model}`
      )
        .then(res => res.json())
        .then(data => {
          if (data !== "error") {
            if (data.length !== 0) {
              this.setState({ varients: data.varients });
            } else {
              this.setState({ msg: "No Data Found", error: true });
            }
          } else {
            this.setState({ msg: "Something Went Wrong", error: true });
          }
        });
    } else {
      fetch(
        `${process.env.REACT_APP_API_URL}/getFourWheelerProcessData?model=${this.props.model}`
      )
        .then(res => res.json())
        .then(data => {
          console.log(data);
          if (data !== "error") {
            if (data.length !== 0) {
              this.setState({ varients: data.varients });
            } else {
              this.setState({ msg: "No Data Found", error: true });
            }
          } else {
            this.setState({ msg: "Something Went Wrong", error: true });
          }
        });
    }
  }

  goBack = () => {
    this.props.changePhase("model");
  };

  selectedVarient = varient => {
    this.props.setVarient(varient);
    this.props.changePhase("registration_year");
  };

  searchit = data => {
    this.setState({ searchData: [], searchit: data });
  };

  render() {
    let modelsData;
    if (this.state.searchit === "" && this.state.varients.length !== 0) {
      modelsData = this.state.varients.map(items => {
        return (
          <span
            class="model_option_container"
            onClick={() => this.selectedVarient(items.TXT_VARIANT)}
          >
            {items.TXT_VARIANT}
          </span>
        );
      });
    }

    let searchData;
    if (this.state.searchit !== "") {
      for (let i = 0; i < this.state.varients.length; i++) {
        if (
          this.state.varients[i].TXT_VARIANT.toLowerCase().includes(
            this.state.searchit
          )
        ) {
          this.state.searchData.push(this.state.varients[i]);
        }
      }
    }

    if (this.state.searchData.length !== 0) {
      searchData = this.state.searchData.map(items => {
        return (
          <span
            onClick={() => this.selectedVarient(items.TXT_VARIANT)}
            class="model_option_container"
          >
            {items.TXT_VARIANT}
          </span>
        );
      });
    }

    return (
      <div>
        <div className="header-area">
          <div className="">
            <img
              onClick={() => this.goBack()}
              className="back-icon"
              className="back-icon"
              src={process.env.PUBLIC_URL + "/back.png"}
            />
          </div>
          <div className="header-text">Car Details</div>
        </div>

        <div>
          <div style={{ margin: 20 }}>
            <h3>
              {this.props.type === "BIKE"
                ? "Select Bike Varient Type"
                : "Select Car Varient Type"}
            </h3>

            <div
              style={{ padding: 10, display: "flex", alignItems: "center" }}
              class="city_search"
            >
              <input
                onChange={e => this.searchit(e.target.value)}
                placeholder="e.g Renault"
                style={{
                  border: "none",
                  outline: "none",
                  backgroundColor: "transparent",
                  width: "90%",
                  fontSize: 16,
                  padding: 5
                }}
              />
              <ion-icon
                style={{ fontSize: 20 }}
                name="search-outline"
              ></ion-icon>
            </div>
          </div>
        </div>

        {this.state.error === true ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh"
            }}
          >
            <p style={{ color: "red" }}>{this.state.msg}</p>
          </div>
        ) : (
          <div>{this.state.searchit === "" ? modelsData : searchData}</div>
        )}
      </div>
    );
  }
}

export default Motor_varient;
