import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AppContext from "../context/AppContext";
import "../App.css";
import parents from "../svg/parents.svg";
import kids from "../svg/kids.svg";
import spouse from "../svg/spouse.svg";
import myself from "../svg/myself.svg";
import supertopup_icon from "../svg/supertopup_icon.svg";
import {
  maxcover_metro_lessthan45,
  mediclaimPercent,
  maxcover_metro_morethan45,
  maxcover_nonmetro_lessthan45,
  maxcover_nonmetro_morethan45,
  dependent_maxcover_metro_lessthan45,
  dependent_maxcover_metro_morethan45,
  dependent_maxcover_nonmetro_lessthan45,
  dependent_maxcover_nonmetro_morethan45,
} from "../config/defaultVariables";
import HDFC_Bank_Logo from "../svg/HDFC_Bank_Logo.svg"
import HDFC_Ergo_Logo from "../svg/HDFC_Ergo_Logo.svg"



class Super_topup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spouse: false,
      myself: false,
      kids: false,
      parents: false,
      suminsured: 0,
    };
    this.suminsuredChange = this.suminsuredChange.bind(this);
  }

  updateLoacalStatewithGlobal = () => {
    this.setState({
      spouse: this.context.state.super_topup.spouse,
      myself: this.context.state.super_topup.myself,
      kids: this.context.state.super_topup.kids,
      parents: this.context.state.super_topup.parents,
      suminsured: this.context.state.super_topup.suminsured,
    });
  };

  suminsuredChange(e, context) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        context.updateSuperTopUpState(this.state);
      }
    );
  }

  goBack() {
    this.props.history.goBack();
  }

  async componentDidMount() {
    this.updateLoacalStatewithGlobal();
    if (this.context.state.score_board.super_top_up > 0) {
      if (this.context.state.score_board.super_top_up === 8.17) {
        await this.context.updateCreditScore(
          "super_top_up",
          "has_insured",
          "less"
        );
      } else if (this.context.state.score_board.super_top_up === 12.83) {
        await this.context.updateCreditScore(
          "super_top_up",
          "less_than_recommended",
          "less"
        );
      } else if (this.context.state.score_board.super_top_up === 17.5) {
        await this.context.updateCreditScore(
          "super_top_up",
          "greate_than_recommended",
          "less"
        );
      }
    }
    window.scrollTo(0, 0);
  }

  checkValues = (id, value) => {
    if (document.getElementById(id).checked) {
      if (value === "super_top_up_myself") {
        this.setState({ myself: true });
      } else if (value === "super_top_up_kids") {
        this.setState({ kids: true });
      } else if (value === "super_top_up_spouse") {
        this.setState({ spouse: true });
      } else {
        this.setState({ parents: true });
      }
    } else {
      if (value === "super_top_up_myself") {
        this.setState({ myself: true });
      } else if (value === "super_top_up_kids") {
        this.setState({ kids: false });
      } else if (value === "super_top_up_spouse") {
        this.setState({ spouse: false });
      } else {
        this.setState({ parents: false });
      }
    }
    console.log(this.state);
  };

  inWords = (num) => {
    var a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    var b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
        : "";
    return str;
  };

  calculateCreditScore = async (context, page) => {
    // let health_covers =
    //   +parseInt(context.state.healthInsurance.suminsured) +
    //   parseInt(context.state.corporateInsurance.suminsured);
    // if (this.context.state.userInfo.city === "Metro") {
    //   if (this.context.state.userInfo.ageInDifferentForm < 45) {
    //     if (
    //       this.context.state.familyDetails.spouse === null &&
    //       this.context.state.familyDetails.father === null &&
    //       this.context.state.familyDetails.mother === null &&
    //       this.context.state.familyDetails.motherinlaw === null &&
    //       this.context.state.familyDetails.fatherinlaw === null
    //     ) {
    //       if (
    //         this.state.suminsured <
    //         maxcover_metro_lessthan45 - health_covers
    //       ) {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "less_than_recommended",
    //           "Add"
    //         );
    //       } else {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "greate_than_recommended",
    //           "Add"
    //         );
    //       }
    //     } else {
    //       if (
    //         this.state.suminsured <
    //         dependent_maxcover_metro_lessthan45 - health_covers
    //       ) {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "less_than_recommended",
    //           "Add"
    //         );
    //       } else {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "greate_than_recommended",
    //           "Add"
    //         );
    //       }
    //     }
    //   } else {
    //     if (
    //       this.context.state.familyDetails.spouse === null &&
    //       this.context.state.familyDetails.father === null &&
    //       this.context.state.familyDetails.mother === null &&
    //       this.context.state.familyDetails.motherinlaw === null &&
    //       this.context.state.familyDetails.fatherinlaw === null
    //     ) {
    //       if (
    //         this.state.suminsured <
    //         maxcover_metro_morethan45 + health_covers
    //       ) {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "less_than_recommended",
    //           "Add"
    //         );
    //       } else {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "greate_than_recommended",
    //           "Add"
    //         );
    //       }
    //     } else {
    //       if (
    //         this.state.suminsured <
    //         dependent_maxcover_metro_morethan45 - health_covers
    //       ) {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "less_than_recommended",
    //           "Add"
    //         );
    //       } else {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "greate_than_recommended",
    //           "Add"
    //         );
    //       }
    //     }
    //   }
    // } else {
    //   if (this.context.state.userInfo.ageInDifferentForm < 45) {
    //     if (
    //       this.context.state.familyDetails.spouse === null &&
    //       this.context.state.familyDetails.father === null &&
    //       this.context.state.familyDetails.mother === null &&
    //       this.context.state.familyDetails.motherinlaw === null &&
    //       this.context.state.familyDetails.fatherinlaw === null
    //     ) {
    //       if (
    //         this.state.suminsured <
    //         maxcover_nonmetro_lessthan45 - health_covers
    //       ) {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "less_than_recommended",
    //           "Add"
    //         );
    //       } else {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "greate_than_recommended",
    //           "Add"
    //         );
    //       }
    //     } else {
    //       if (
    //         this.state.suminsured <
    //         dependent_maxcover_nonmetro_lessthan45 - health_covers
    //       ) {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "less_than_recommended",
    //           "Add"
    //         );
    //       } else {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "greate_than_recommended",
    //           "Add"
    //         );
    //       }
    //     }
    //   } else {
    //     if (
    //       this.context.state.familyDetails.spouse === null &&
    //       this.context.state.familyDetails.father === null &&
    //       this.context.state.familyDetails.mother === null &&
    //       this.context.state.familyDetails.motherinlaw === null &&
    //       this.context.state.familyDetails.fatherinlaw === null
    //     ) {
    //       if (
    //         this.state.suminsured <
    //         maxcover_nonmetro_morethan45 - health_covers
    //       ) {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "less_than_recommended",
    //           "Add"
    //         );
    //       } else {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "greate_than_recommended",
    //           "Add"
    //         );
    //       }
    //     } else {
    //       if (
    //         this.state.suminsured <
    //         dependent_maxcover_nonmetro_morethan45 - health_covers
    //       ) {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "less_than_recommended",
    //           "Add"
    //         );
    //       } else {
    //         // await context.updateCreditScore(
    //         //   "super_top_up",
    //         //   "has_insured",
    //         //   "less"
    //         // );
    //         await context.updateCreditScore(
    //           "super_top_up",
    //           "greate_than_recommended",
    //           "Add"
    //         );
    //       }
    //     }
    //   }
    // }

    context.updateSuperTopUp(this.state, page);
  };

  render() {
    let suminsured = this.context.state.super_topup.suminsured;
    if (suminsured) {
      if (suminsured.toString().length !== 0) {
        if (
          suminsured.toString().length > 4 &&
          suminsured.toString().length < 7
        ) {
          suminsured = this.inWords(
            this.context.state.super_topup.suminsured
          ).toUpperCase();
        } else {
          suminsured = this.inWords(
            this.context.state.super_topup.suminsured
          ).toUpperCase();
        }
      } else {
        suminsured = 0;
      }
    }

    return (
      <div style={{ paddingBottom: 40 }}>
        <div className="header-area">
          <div className="">
            <img
              onClick={() => this.goBack()}
              className="back-icon"
              src={process.env.PUBLIC_URL + "/back.png"}
            />
          </div>
          <div className="header-text">Existing Insurance Details</div>
          <div class="progress-container">
            <div class="progress-bar" id="myBar" style={{ width: "25%" }}></div>
          </div>
        </div>
        <AppContext.Consumer>
          {(context) => (
            <React.Fragment>
              <div id="slide">
                <div class="super_top_up_main_container">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "25%",
                    }}
                  >
                    <img src={supertopup_icon} />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span class="category_insurance_header">
                      Existing Super Top-Up <br />
                      Health Insurance
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 25,
                    }}
                  >
                    <div class="suminsured_container">
                      <p
                        style={{
                          textAlign: "center",
                          paddingTop: 10,
                          marginBottom: "unset",
                          paddingLeft: 20,
                        }}
                      >
                        Sum Insured
                      </p>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="health-input">
                          <label style={{ fontSize: 45, margin: "unset" }}>
                            &#8377;
                          </label>
                          <input
                            name="suminsured"
                            value={
                              context.state.super_topup.suminsured
                                ? context.state.super_topup.suminsured === "0"
                                  ? "1,00,000"
                                  : context.state.super_topup.suminsured
                                : this.state.suminsured > 0 &&
                                  this.state.suminsured
                            }
                            onChange={(e) => this.suminsuredChange(e, context)}
                            className="suminsured-input other_family_input"
                            id="suminsured"
                            type="number"
                            placeholder="100,000"
                            required
                          />
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <p class="textAmount">
                          {context.state.super_topup.suminsured
                            ? context.state.super_topup.suminsured === "0"
                              ? "ONE LAKH"
                              : suminsured
                            : this.state.suminsured > 0 &&
                              this.state.suminsured}
                        </p>
                      </div>
                      <div
                        className="page-content"
                        style={{ marginBottom: "25%" }}
                      >
                        <input
                          value={context.state.super_topup.suminsured}
                          name="suminsured"
                          onChange={(e) => this.suminsuredChange(e, context)}
                          type="range"
                          min="0"
                          max="5000000"
                          step="500000"
                          className="slider"
                          id="suminsured-range"
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="min_amt">₹1 Lakh</div>
                          <div className="max_amt">₹50 Lakh+</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <label class="header_labels">Family members covered</label>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginTop: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                    >
                      <div className="checkbox_input">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: 10,
                            paddingTop: 10,
                          }}
                        >
                          <h5 className="checkbox_title">Spouse</h5>
                          <input
                            type="checkbox"
                            onClick={() =>
                              this.checkValues(
                                "super_top_up_spouse",
                                "super_top_up_spouse"
                              )
                            }
                            id="super_top_up_spouse"
                            className="family_checkbox"
                          />
                        </div>
                        <div>
                          <img className="health-svg" src={spouse} />
                        </div>
                      </div>
                      <div className="checkbox_input">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: 10,
                            paddingTop: 10,
                          }}
                        >
                          <h5 className="checkbox_title">Parents</h5>
                          <input
                            onClick={() =>
                              this.checkValues(
                                "super_top_up_parents",
                                "super_top_up_parents"
                              )
                            }
                            id="super_top_up_parents"
                            type="checkbox"
                            class="family_checkbox"
                          />
                        </div>
                        <div>
                          <img className="health-svg" src={parents} />
                        </div>
                      </div>

                      <div className="checkbox_input">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            paddingRight: 10,
                            paddingTop: 10,
                          }}
                        >
                          <h5 className="checkbox_title">Kids</h5>
                          <input
                            type="checkbox"
                            onClick={() =>
                              this.checkValues(
                                "super_top_up_kids",
                                "super_top_up_kids"
                              )
                            }
                            id="super_top_up_kids"
                            className="family_checkbox"
                          />
                        </div>
                        <div>
                          <img className="health-svg" src={kids} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div style={{ marginTop:"-16%", textAlign: 'center', width: "100%" }}>
                    <div className="brand_icons_header">Powered by</div>
                    <div className="brand_icons">
                      <img src={HDFC_Bank_Logo} style={{marginRight:"8px", height :"20px"}} alt="HDFC_BANK" />
                      <img src={HDFC_Ergo_Logo} style={{marginLeft:"8px"}}  alt="HDFC_ERGO" />
                    </div>
                  </div>


                {context.state.super_topup.suminsured >= 100000 ? (
                  context.state.policyTaken.Personal_Accident_Insurance ===
                  true ? (
                    //Redirect to Personal_Accident_Insurance
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          context.updateSuperTopUp(
                            this.state,
                            "Personal_Accident"
                          )
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.critical_illness_insurance ===
                    true ? (
                    //Redirect to Critical illness
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.calculateCreditScore(
                            context,
                            "Critical_insurance"
                          )
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.Motor === true ? (
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.calculateCreditScore(context, "Motor")
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.Bike === true ? (
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.calculateCreditScore(context, "Bike")
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.Home_Rental === true ? (
                    //Redirect to Home
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.calculateCreditScore(context, "Home_Rental")
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.Cyber === true ? (
                    //Redirect to Cyber
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.calculateCreditScore(context, "Cyber")
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : (
                    //Redirect to Recommendations
                    <div class="bottom_btn_container">
                      <button
                        class="btn"
                        onClick={() =>
                          this.calculateCreditScore(context, "Recommendations")
                        }
                      >
                        Next
                      </button>
                    </div>
                  )
                ) : (
                  <div class="bottom_btn_container">
                    <button class="btn" style={{ backgroundColor: "grey" }}>
                      Next
                    </button>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </AppContext.Consumer>
      </div>
    );
  }
}
Super_topup.contextType = AppContext;

export default Super_topup;
