import React from "react";

const Loader = ({ balls_color, given }) => {
  return (
    <div className="loader">
      <div className="sub_loader">
        <div
          style={{ background: balls_color, width: given, height: given }}
          className="circle_loader"
        ></div>
        <div
          style={{ background: balls_color, width: given, height: given }}
          className="circle_loader"
        ></div>
        <div
          style={{ background: balls_color, width: given, height: given }}
          className="circle_loader"
        ></div>
        <div
          style={{ background: balls_color, width: given, height: given }}
          className="circle_loader"
        ></div>
      </div>
    </div>
  );
};

export default Loader;
