

import React from 'react'
import Carousel from 'nuka-carousel'
import carousal_svg_1 from "../svg/carousal_svg_1.svg"
import carousal_svg_2a from "../svg/carousal_svg_2a.png"
import carousel_svg_3 from "../svg/carousel_svg_3.svg"
import carousel_svg_4 from "../svg/carousel_svg_4.svg"

import letsGetPersonal from "../svg/letsGetPersonal.svg"
import { useHistory } from "react-router-dom"
import laFamily from "../svg/laFamily.svg"

function LetsGetPersonalScreen({ handleClick }) {
    const history = useHistory()
    function handleClick() {
        history.push("/personal_details");
    }

    return (
        <>
            <div style={{ padding: "30px 16px" }}>
                <div className='filler_screen_Header'>Let’s get Personal</div>
                <div className='filler_screen_content'>Your personal details for us
                    to know you better</div>
                <img src={letsGetPersonal} width="100%" alt="image" />
                {/* <div style={{ padding: "0 30px" }}> */}
                {/* <button className='kom_start_btn mt-15' onClick={handleClick}>Continue</button> */}
                {/* </div> */}
            </div>
            <button className='btn' onClick={handleClick}>Continue</button>
        </>
    )
}

export default LetsGetPersonalScreen