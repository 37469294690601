import React, { Component } from "react";
import AppContext from "../context/AppContext";
import { personal_accident } from "../config/defaultVariables";
import personal_icon from "../svg/personal_icon.svg";

const age = 44;

class Personal_insurance_acc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suminsured: 0,
      creditscore: 0
    };
    this.suminsuredChange = this.suminsuredChange.bind(this);
  }

  updateLoacalStatewithGlobal = () => {
    this.setState({
      suminsured: this.context.state.personalAccidentInsurance.suminsured,
      creditscore: this.context.state.personalAccidentInsurance.creditscore
    });
    const filteredRecommendations = this.context.state.Recommendations.filter(
      recommendation =>
        recommendation.subcategory != "Personal_Accident_Insurance"
    );
    this.context.updateRecommendationsStateOnBack(filteredRecommendations);
  };

  chekcCreditScore = async context => {
    if (this.state.suminsured) {
      if (age < 60) {
        let max_cover = context.state.userInfo.salary * 10;
        let covered = (this.state.suminsured / max_cover) * 100;
        let personal_accident_creditscore = (personal_accident * covered) / 100;
        await context.updateCreditScore(personal_accident_creditscore, "Add");
        await context.updateCreditScore(7.5, "less");
      }
    }
  };

  updateMaincomponent = (context, page) => {
    this.chekcCreditScore(context);
    context.updatePersonalAccidentSumInsured(this.state, page);
  };
  suminsuredChange(e, context) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        context.updatePersonalAccState(this.state);
      }
    );
  }

  goBack(context) {
    this.props.history.goBack();
  }

  componentDidMount() {
    this.updateLoacalStatewithGlobal();
    window.scrollTo(0, 0);
  }

  inWords = num => {
    var a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen "
    ];
    var b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety"
    ];

    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
        : "";
    return str;
  };

  render() {
    let suminsured = this.context.state.personalAccidentInsurance.suminsured;
    if (suminsured) {
      if (suminsured.toString().length !== 0) {
        if (
          suminsured.toString().length > 4 &&
          suminsured.toString().length < 7
        ) {
          suminsured = this.inWords(
            this.context.state.personalAccidentInsurance.suminsured
          ).toUpperCase();
        } else {
          suminsured = this.inWords(
            this.context.state.personalAccidentInsurance.suminsured
          ).toUpperCase();
        }
      } else {
        suminsured = 0;
      }
    }

    return (
      <div style={{ paddingBottom: 60 }}>
        <AppContext.Consumer>
          {context => (
            <React.Fragment>
              <div className="header-area">
                <div className="">
                  <img
                    onClick={() => this.goBack(context)}
                    className="back-icon"
                    src={process.env.PUBLIC_URL + "/back.png"}
                  />
                </div>
                <div className="header-text">Existing Insurance Details</div>
                <div class="progress-container">
                  <div
                    class="progress-bar"
                    id="myBar"
                    style={{ width: "25%" }}
                  ></div>
                </div>
              </div>

              <div id="slide">
                <div class="existingInsurance_pages">
                  <img src={personal_icon} />
                </div>

                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span class="category_insurance_header">
                      Existing Personal Accident Insurance
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 25
                  }}
                >
                  <div class="suminsured_container">
                    <p
                      style={{
                        textAlign: "center",
                        paddingTop: 10,
                        marginBottom: "unset"
                      }}
                    >
                      Sum Insured
                    </p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div className="health-input">
                        <label
                          style={{ fontSize: 45, margin: "unset", height: 35 }}
                        >
                          &#8377;
                        </label>
                        <input
                          name="suminsured"
                          value={
                            context.state.personalAccidentInsurance.suminsured
                              ? context.state.personalAccidentInsurance
                                  .suminsured === "0"
                                ? "1,00,000"
                                : context.state.personalAccidentInsurance
                                    .suminsured
                              : this.state.suminsured > 0 &&
                                this.state.suminsured
                          }
                          onChange={e => this.suminsuredChange(e, context)}
                          className="suminsured-input other_family_input"
                          id="suminsured"
                          type="number"
                          placeholder="1,00,000"
                          required
                        />
                      </div>
                    </div>

                    <div style={{ width: "100%" }}>
                      <p class="textAmount">
                        {context.state.personalAccidentInsurance.suminsured
                          ? context.state.personalAccidentInsurance
                              .suminsured === "0"
                            ? "ONE LAKH"
                            : suminsured
                          : this.state.suminsured > 0 && this.state.suminsured}
                      </p>
                    </div>

                    <div
                      className="page-content"
                      style={{ marginBottom: "25%" }}
                    >
                      <input
                        value={
                          context.state.personalAccidentInsurance.suminsured
                        }
                        name="suminsured"
                        onChange={e => this.suminsuredChange(e, context)}
                        type="range"
                        min="0"
                        max="5000000"
                        step="500000"
                        className="slider"
                        id="suminsured-range"
                      />
                      <div className="min_amt">₹1 Lakh</div>
                      <div className="max_amt">₹50 Lakh+</div>
                    </div>
                  </div>
                </div>
                {context.state.personalAccidentInsurance.suminsured >=
                100000 ? (
                  context.state.policyTaken.critical_illness_insurance ===
                  true ? (
                    //Redirect to Critical illness
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        class="btn"
                        onClick={() =>
                          this.updateMaincomponent(
                            context,
                            "Critical_insurance"
                          )
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.Home_Rental === true ? (
                    //Redirect to Home
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        class="btn"
                        onClick={() =>
                          this.updateMaincomponent(context, "Home_Rental")
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : context.state.policyTaken.Cyber === true ? (
                    //Redirect to Cyber
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        class="btn"
                        onClick={() =>
                          this.updateMaincomponent(context, "Cyber")
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : (
                    //Redirect to Recommendations
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        class="btn"
                        onClick={() =>
                          this.updateMaincomponent(context, "Recommendations")
                        }
                      >
                        Next
                      </button>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "grey"
                    }}
                  >
                    <button class="btn">Next</button>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </AppContext.Consumer>
      </div>
    );
  }
}
Personal_insurance_acc.contextType = AppContext;
export default Personal_insurance_acc;
