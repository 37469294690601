import React,{Component} from 'react';


class Motor_Model extends Component{
    constructor(props){
        super(props)
        this.state={
            models:[],
            searchit:'',
            searchData:[],
            error:false,
            msg:'Something Went Wrong'
        }
    }

    componentDidMount() {
        if(this.props.type === 'BIKE'){
        fetch(`${process.env.REACT_APP_API_URL}/getTwoWheelerProcessData?brand=${this.props.brand}`)
        .then(res => res.json())
        .then(data => {
            if(data !== 'error') {
                console.log(this.state.models,data)
                if(data.length !== 0) {
                    this.setState({ models:data.models})
                }
                else {
                    this.setState({msg:'No data found', error:true})
                }
            }
            else {
                this.setState({ error:true,msg:'Something Went Wrong'})
            }
        })
        }
        else {
            fetch(`${process.env.REACT_APP_API_URL}/getFourWheelerProcessData?brand=${this.props.brand}`)
            .then(res => res.json())
            .then(data => {
                if(data !== 'error') {
                    console.log(this.state.models,data)
                    if(data.length !== 0) {
                        this.setState({ models:data.models})
                    }
                    else {
                        this.setState({msg:'No data found', error:true})
                    }
                }
                else {
                    this.setState({ error:true,msg:'Something Went Wrong'})
                }
        })
        
        }
        
    }

    selectedModel = (model) => {
        this.props.setModel(model)
        this.props.changePhase('varient')
    }

    goBack = () => {
        this.props.changePhase('brand')
    }

    searchit = (data) => {
        this.setState({searchData:[],searchit:data})
    }

    render() {
        let modelsData
        if(this.state.searchit === '' && this.state.models.length !== 0) {
            modelsData  = this.state.models.map(items => {
                return (
                    <span class='model_option_container' onClick={()=>this.selectedModel(items)}>{items.model}</span>
                )
            })
        }

        let searchData;
        if(this.state.searchit !== '') {
            for(let i=0;i<this.state.models.length;i++) {
                if(this.state.models[i].model.toLowerCase().includes(this.state.searchit)) {
                    this.state.searchData.push(this.state.models[i])
                }
            }
           
        }

        if(this.state.searchData.length !== 0) {
            searchData = this.state.searchData.map(items => {
                return(
                    <span onClick={()=>this.selectedRTO(items.modelCode)} class='model_option_container'>{items}</span>
                )
            })
        }

        return(
            <div>   
                <div className="header-area">
                    <div className=""><img onClick={() => this.goBack()} className="back-icon" className="back-icon" src={process.env.PUBLIC_URL + '/back.png'} /></div>
                    <div className="header-text">Car Details</div>
                    
                </div>

                <div>
                    <div style={{margin:20}}>
                        <h3>
                            {this.props.type === 'BIKE' ? 'Select Bike Model' : 'Select Car Model'}
                        </h3>

                        <div style={{padding:10,display:'flex',alignItems:'center'}} class='city_search'>
                            <input value={this.state.searchit} onChange={(e)=>this.searchit(e.target.value)} placeholder='Renault' style={{border:'none',outline:'none',backgroundColor:'transparent',width:'90%',fontSize:16,padding:5}} />
                            <ion-icon style={{fontSize:20}} name="search-outline"></ion-icon>
                        </div>
                    </div>
                </div>

                {this.state.error === true ?
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'50vh'}}>
                    <p style={{color:'red'}}>{this.state.msg}</p>
                </div>  
                 :
                <div>
                    {this.state.searchit === '' ? modelsData : searchData }
                </div>
                }

            </div>


        )
    }


}

export default Motor_Model;