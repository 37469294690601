import React from 'react'
import AppContext from "../context/AppContext";
import back from "../svg/back.svg"
import { useHistory } from "react-router-dom"
import laFamily from "../svg/laFamily.svg"

function LaFamiliaScreen() {
    const history = useHistory()

    function handleClick() {
        history.push("/family-details");
    }

    function handleGoBack() {
        history.push("/personal_details");
    }
    function handleSkipClick() {
        history.push("/allthing-money");
    }

    return (
        <AppContext.Consumer>
            {(context) => (
                <>
                <div style={{ padding: "30px 16px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                        <img src={back} alt="icon" onClick={handleGoBack} />
                        <div className='la_familia_skip_btn' onClick={handleSkipClick}>Skip</div>
                    </div>
                    <div className='filler_screen_Header'>La Familia!</div>
                    <div className='filler_screen_content'>Hey <span style={{ textTransform: "capitalize" , fontWeight : 600}}>{String(context.state.userInfo.name).split(" ")[0]}</span>, your family information is important to know your risk appetite</div>
                    <img src={laFamily} width="100%" alt="image" />
                </div>
                    {/* <button className='kom_start_btn mt-15' onClick={handleClick}>Continue</button> */}
                    <button className='btn' onClick={handleClick}>Continue</button>
                </>
            )}
        </AppContext.Consumer>
    )
}
LaFamiliaScreen.contextType = AppContext;
export default LaFamiliaScreen