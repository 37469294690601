import React, { useContext } from "react";
import AppContext from "../context/AppContext";

export default function Terms(props) {
  const context = useContext(AppContext);
  const acceptConditions = () => {
    context.updateDNDstatus(true);
    props.history.push("/login");
  };

  return (
    <div id="slide">
      <h3 style={{ textAlign: "center", margin: "20px 0px 0px 0px" }}>
        Terms & Conditions
      </h3>
      <p style={{ padding: "5px 12px 10px 12px", lineHeight: 1.6 }}>
        I hereby give my consent to HDFC ERGO General Insurance Company Ltd. and
        it's partners to collect, store and process my personal details for the
        purposes of profiling and contact me for purchase of general insurance
        policies.
      </p>

      <p style={{ padding: "5px 12px 10px 12px", lineHeight: 1.6 }}>
        Alternatively, you may click to refuse to consent, or access more
        detailed information and change your preferences before consenting. Your
        preferences will apply to this tool only. The recommendation will be
        based on the inputs provided by you i.e. age, gender, family type, city
        you reside in, annual income and existing insurance policies. Also,
        while the assumptions underlying this calculator are considered
        reasonable at the present time, these assumptions may be reviewed and
        revised in future. Policy issuance will be in accordance with the
        Underwriting Guidelines. Premium may change basis Company’s Underwriting
        Guidelines.
      </p>

      <p style={{ padding: "5px 12px 10px 12px", lineHeight: 1.6 }}>
        We and our partners store or access information on devices , such as
        cookies and process personal data such as unique identifiers and
        standard information sent by a device for the purposes described below.
      </p>
      <ul style={{ margin: "20px 10px 60px 0px" }}>
        <li>Apply market research to generate audience insights</li>
        <li>Develop and improve products</li>
      </ul>
      <div>
        <button onClick={() => acceptConditions()} class="btn">
          Agree & Continue
        </button>
      </div>
    </div>
  );
}
